'use strict';

import { getWearableLoginCode as getWearableLoginCodeAPI } from 'api/users';

import {
    REQUEST_GET_WEARABLE_LOGIN_CODE,
    REQUEST_GET_WEARABLE_LOGIN_CODE_SUCCESS,
    REQUEST_GET_WEARABLE_LOGIN_CODE_FAILURE
} from 'actions/types';

function requestGetWearableLoginCode() {
    return {
        type: REQUEST_GET_WEARABLE_LOGIN_CODE
    };
}

function requestGetWearableLoginCodeSuccess(json) {
    return {
        type: REQUEST_GET_WEARABLE_LOGIN_CODE_SUCCESS,
        payload: {
            user: json
        }
    };
}

function requestGetWearableLoginCodeFailure(error) {
    return {
        type: REQUEST_GET_WEARABLE_LOGIN_CODE_FAILURE,
        payload: error,
        error: true
    };
}

export default function getWearableLoginCode(token, userId) {
    return dispatch => {
        dispatch(requestGetWearableLoginCode());
        return getWearableLoginCodeAPI(token, userId)
            .then((json) => {
                return dispatch(requestGetWearableLoginCodeSuccess(json));
            })
            .catch((error) => {
                return dispatch(requestGetWearableLoginCodeFailure(error));
            });
    };
}