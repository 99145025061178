'use strict';

import _ from 'lodash';
import Link from 'components/ux/linker';
import Actions from 'actions';

import React from 'react';

export const A = React.createClass({
    propTypes: {
        href: React.PropTypes.string
    },

    onClick() {
        const {progress_pages, updateProgressManually, tool, module, section} = this.props;
        if(progress_pages) {
            progress_pages.forEach((page) => {
                let data = {};
                if(_.has(page, 'responses')) {
                    data = page.responses;
                }
                updateProgressManually(data, tool, module, section, section.pages[page.title]);
            });
        }
    },

    render() {
        const className = ['nav-link', this.props.className].filter(v=>v).join(" ");
        if (this.props.href && this.props.href.substring(0, 1) === "/") {
            return (
                <Link className={className} to={this.props.href} title={this.props.title} onClick={this.onClick}>{this.props.children}</Link>
            );
        }
        // allow links that open modals to keep consistent styling
        if (this.props.onClick) {
            return (
                <a className={className} title={this.props.title} onClick={this.props.onClick}>{this.props.children}</a>
            );
        }
        return (
            <a {...this.props}>{this.props.children}</a>
        );
    }
});