export const liquor = [
	{
	    "namefull": "Abita Amber",
		"prcntalc": 0.44,
	    "nameshrt": "Abita Amber"
	},
	{
	    "namefull": "Absolut 100",
	    "prcntalc": 0.5,
	    "nameshrt": "Absolut 100"
	},
	{
	    "namefull": "Absolut Vodka",
	    "prcntalc": 0.4,
	    "nameshrt": "Absolut Vodka"
	},
	{
	    "namefull": "After Shock",
	    "prcntalc": 0.4,
	    "nameshrt": "Premix-After Shoc"
	},
	{
	    "namefull": "Alize",
	    "prcntalc": 0.16,
	    "nameshrt": "Premix-Alize"
	},
	{
	    "namefull": "Amaretto",
	    "prcntalc": 0.22,
	    "nameshrt": "Amaretto"
	},
	{
	    "namefull": "Amaretto Sour",
	    "prcntalc": 0.22,
	    "nameshrt": "AmarettoSour"
	},
	{
	    "namefull": "Amstel",
	    "prcntalc": 0.05,
	    "nameshrt": "Amstel"
	},
	{
	    "namefull": "Amstel Light",
	    "prcntalc": 0.035,
	    "nameshrt": "Amstel Light"
	},
	{
	    "namefull": "Anchor Steam",
	    "prcntalc": 0.049,
	    "nameshrt": "Anchor Steam"
	},
	{
	    "namefull": "Ancient Age Bourbon",
	    "prcntalc": 0.4,
	    "nameshrt": "Bourbon-AncientAg"
	},
	{
	    "namefull": "Anisette",
	    "prcntalc": 0.3,
	    "nameshrt": "Anisette"
	},
	{
	    "namefull": "Apple Pucker",
	    "prcntalc": 0.3,
	    "nameshrt": "Apple Pucker"
	},
	{
	    "namefull": "Armagnac",
	    "prcntalc": 0.4,
	    "nameshrt": "Armagnac"
	},
	{
	    "namefull": "Arrow Creme de Cocoa",
	    "prcntalc": 0.2,
	    "nameshrt": "Creme Cocoa-Arrow"
	},
	{
	    "namefull": "Arrow Creme de Menthe",
	    "prcntalc": 0.21,
	    "nameshrt": "Creme Menthe-Arro"
	},
	{
	    "namefull": "Arrow Curacao",
	    "prcntalc": 0.27,
	    "nameshrt": "Curacao- Arrow"
	},
	{
	    "namefull": "Avalanche",
	    "prcntalc": 0.4,
	    "nameshrt": "Premix-Avalanche"
	},
	{
	    "namefull": "B52 Kahlua Drink",
	    "prcntalc": 0.059,
	    "nameshrt": "B52 Kahlua Drink"
	},
	{
	    "namefull": "Bacardi 121",
	    "prcntalc": 0.605,
	    "nameshrt": "Bacardi 121"
	},
	{
	    "namefull": "Bacardi 151",
	    "prcntalc": 0.755,
	    "nameshrt": "Bacardi 151"
	},
	{
	    "namefull": "Bacardi Limon",
	    "prcntalc": 0.35,
	    "nameshrt": "Bacardi Limon"
	},
	{
	    "namefull": "Bacardi Solero Dark Rum",
	    "prcntalc": 0.38,
	    "nameshrt": "BacardiSolero"
	},
	{
	    "namefull": "Bacardi White Rum",
	    "prcntalc": 0.375,
	    "nameshrt": "BarcardiWhite"
	},
	{
	    "namefull": "Bad Frog",
	    "prcntalc": 0.06,
	    "nameshrt": "Bad Frog"
	},
	{
	    "namefull": "Bahama Mama",
	    "prcntalc": 0.15,
	    "nameshrt": "Schnapp-BahamaMam"
	},
	{
	    "namefull": "Bailey's Irish Cream",
	    "prcntalc": 0.17,
	    "nameshrt": "Bailey's Irish Cr"
	},
	{
	    "namefull": "Banana Liqueur",
	    "prcntalc": 0.24,
	    "nameshrt": "Banana Liqueur"
	},
	{
	    "namefull": "Bartles & Jaymes",
	    "prcntalc": 0.05,
	    "nameshrt": "Bartles & Jaymes"
	},
	{
	    "namefull": "Bartles & Jaymes Light",
	    "prcntalc": 0.02,
	    "nameshrt": "BartlesJaymesLigh"
	},
	{
	    "namefull": "Bass",
	    "prcntalc": 0.051,
	    "nameshrt": "Bass"
	},
	{
	    "namefull": "Becks",
	    "prcntalc": 0.048,
	    "nameshrt": "Becks"
	},
	{
	    "namefull": "Becks Dark",
	    "prcntalc": 0.048,
	    "nameshrt": "Becks Dark"
	},
	{
	    "namefull": "Becks Light",
	    "prcntalc": 0.037,
	    "nameshrt": "Becks Light"
	},
	{
	    "namefull": "Becks Octoberfest",
	    "prcntalc": 0.05,
	    "nameshrt": "Becks Ocotberfest"
	},
	{
	    "namefull": "Beefeater",
	    "prcntalc": 0.475,
	    "nameshrt": "Gin- Beefeater"
	},
	{
	    "namefull": "Beer (Domestic)",
	    "prcntalc": 0.048,
	    "nameshrt": "Beer (Domestic)"
	},
	{
	    "namefull": "Beer (Imported Ales)",
	    "prcntalc": 0.06,
	    "nameshrt": "Beer(ImportedAles"
	},
	{
	    "namefull": "Beer (Imported Porters)",
	    "prcntalc": 0.06,
	    "nameshrt": "Beer(Imp. Porters"
	},
	{
	    "namefull": "Beer (Imported Stouts)",
	    "prcntalc": 0.06,
	    "nameshrt": "Beer(ImportStouts"
	},
	{
	    "namefull": "Beer (Imported)",
	    "prcntalc": 0.06,
	    "nameshrt": "Beer (Imported)"
	},
	{
	    "namefull": "Beer (Lights)",
	    "prcntalc": 0.042,
	    "nameshrt": "Beer (Lights)"
	},
	{
	    "namefull": "Beer (Malt Liquor)",
	    "prcntalc": 0.06,
	    "nameshrt": "Beer (Malt Liquor"
	},
	{
	    "namefull": "Beer (non-alcoholic)",
	    "prcntalc": 0.005,
	    "nameshrt": "Beer (non-alcohol"
	},
	{
	    "namefull": "Benedictine",
	    "prcntalc": 0.43,
	    "nameshrt": "Benedictine"
	},
	{
	    "namefull": "Berghoff Original",
	    "prcntalc": 0.051,
	    "nameshrt": "Berghoff Original"
	},
	{
	    "namefull": "Big Bear",
	    "prcntalc": 0.075,
	    "nameshrt": "Big Bear"
	},
	{
	    "namefull": "Black and White",
	    "prcntalc": 0.4,
	    "nameshrt": "Scotch-Black&Whit"
	},
	{
	    "namefull": "Black Hawk",
	    "prcntalc": 0.45,
	    "nameshrt": "Canadian-BlackHaw"
	},
	{
	    "namefull": "Black Label LA",
	    "prcntalc": 0.024,
	    "nameshrt": "Black Label LA"
	},
	{
	    "namefull": "Black Velvet",
	    "prcntalc": 0.4,
	    "nameshrt": "Canad-BlackVelvet"
	},
	{
	    "namefull": "Bloody Mary",
	    "prcntalc": 0.4,
	    "nameshrt": "Bloody Mary"
	},
	{
	    "namefull": "Blue Carousel",
	    "prcntalc": 0.27,
	    "nameshrt": "Blue Carousel"
	},
	{
	    "namefull": "Bols Anisette",
	    "prcntalc": 0.25,
	    "nameshrt": "Anisette- Bols"
	},
	{
	    "namefull": "Bols Triple Sec",
	    "prcntalc": 0.21,
	    "nameshrt": "Triple Sec- Bols"
	},
	{
	    "namefull": "Bombay Gin",
	    "prcntalc": 0.43,
	    "nameshrt": "Gin- Bombay"
	},
	{
	    "namefull": "Boston Anisette",
	    "prcntalc": 0.21,
	    "nameshrt": "Anisette- Boston"
	},
	{
	    "namefull": "Boston Creme de Menthe",
	    "prcntalc": 0.17,
	    "nameshrt": "Crem Menthe-Bosto"
	},
	{
	    "namefull": "Boston Triple Sec",
	    "prcntalc": 0.17,
	    "nameshrt": "Triple Sec- Bosto"
	},
	{
	    "namefull": "Bourbon",
	    "prcntalc": 0.4,
	    "nameshrt": "Bourbon"
	},
	{
	    "namefull": "Brandy",
	    "prcntalc": 0.42,
	    "nameshrt": "Brandy"
	},
	{
	    "namefull": "Brandy (Fruit)",
	    "prcntalc": 0.43,
	    "nameshrt": "Brandy (Fruit)"
	},
	{
	    "namefull": "Bridgeport Blue Heron Pale",
	    "prcntalc": 0.059,
	    "nameshrt": "Bridgeport Blue H"
	},
	{
	    "namefull": "Brinkhoff's #1",
	    "prcntalc": 0.074,
	    "nameshrt": "Brinkhoff's #1"
	},
	{
	    "namefull": "Brooklyn Brand",
	    "prcntalc": 0.052,
	    "nameshrt": "Brooklyn Brand"
	},
	{
	    "namefull": "Bud Ice",
	    "prcntalc": 0.055,
	    "nameshrt": "Bud Ice"
	},
	{
	    "namefull": "Bud Ice Light",
	    "prcntalc": 0.041,
	    "nameshrt": "Bud Ice Light"
	},
	{
	    "namefull": "Bud Light",
	    "prcntalc": 0.042,
	    "nameshrt": "Bud Light"
	},
	{
	    "namefull": "Budweiser",
	    "prcntalc": 0.048,
	    "nameshrt": "Budweiser"
	},
	{
	    "namefull": "Bull Ice",
	    "prcntalc": 0.082,
	    "nameshrt": "Bull Ice"
	},
	{
	    "namefull": "Busch",
	    "prcntalc": 0.049,
	    "nameshrt": "Busch"
	},
	{
	    "namefull": "Busch (in Utah)",
	    "prcntalc": 0.032,
	    "nameshrt": "Busch (In Utah)"
	},
	{
	    "namefull": "Busch Ice",
	    "prcntalc": 0.059,
	    "nameshrt": "Busch Ice"
	},
	{
	    "namefull": "Busch Light",
	    "prcntalc": 0.042,
	    "nameshrt": "Busch Light"
	},
	{
	    "namefull": "Bushmill's",
	    "prcntalc": 0.4,
	    "nameshrt": "Irish- Bushmill's"
	},
	{
	    "namefull": "Butterscotch Schnapps",
	    "prcntalc": 0.15,
	    "nameshrt": "Schnapp-Buttersco"
	},
	{
	    "namefull": "Buttershots",
	    "prcntalc": 0.15,
	    "nameshrt": "Premix-Buttershot"
	},
	{
	    "namefull": "Camo",
	    "prcntalc": 0.081,
	    "nameshrt": "Camo"
	},
	{
	    "namefull": "Canadian Club",
	    "prcntalc": 0.4,
	    "nameshrt": "Canadian-CanadClu"
	},
	{
	    "namefull": "Canadian Mist",
	    "prcntalc": 0.4,
	    "nameshrt": "Canadian Mist"
	},
	{
	    "namefull": "Canadian Whiskey",
	    "prcntalc": 0.4,
	    "nameshrt": "Canadian"
	},
	{
	    "namefull": "Cape Codder",
	    "prcntalc": 0.4,
	    "nameshrt": "Cape Cod"
	},
	{
	    "namefull": "Capt.Morgan Original Spiced Rum",
	    "prcntalc": 0.35,
	    "nameshrt": "Rum-CaptMorgOrigS"
	},
	{
	    "namefull": "Carlsburg Light",
	    "prcntalc": 0.041,
	    "nameshrt": "Carlsburg Light"
	},
	{
	    "namefull": "Carlton Gold",
	    "prcntalc": 0.049,
	    "nameshrt": "Carlton Gold"
	},
	{
	    "namefull": "Castillo Rum",
	    "prcntalc": 0.4,
	    "nameshrt": "Rum- Castillo"
	},
	{
	    "namefull": "Catamount Amber",
	    "prcntalc": 0.049,
	    "nameshrt": "Catamount Amber"
	},
	{
	    "namefull": "Chamborg",
	    "prcntalc": 0.165,
	    "nameshrt": "Chamborg"
	},
	{
	    "namefull": "Chivas Regal",
	    "prcntalc": 0.43,
	    "nameshrt": "Scotch-ChivasRega"
	},
	{
	    "namefull": "Christian Bros",
	    "prcntalc": 0.4,
	    "nameshrt": "Brandy-Christ.Bro"
	},
	{
	    "namefull": "Club Manhattan",
	    "prcntalc": 0.175,
	    "nameshrt": "Premix-ClubManhat"
	},
	{
	    "namefull": "Club Pina Colada",
	    "prcntalc": 0.125,
	    "nameshrt": "Premix-ClubPinaCo"
	},
	{
	    "namefull": "Coffee Liqueur",
	    "prcntalc": 0.22,
	    "nameshrt": "Coffee Liqueur"
	},
	{
	    "namefull": "Cognac",
	    "prcntalc": 0.4,
	    "nameshrt": "Cognac"
	},
	{
	    "namefull": "Cointreau",
	    "prcntalc": 0.4,
	    "nameshrt": "Cointreau"
	},
	{
	    "namefull": "Colorado Bull Dog",
	    "prcntalc": 0.335,
	    "nameshrt": "ColoradoBullDog"
	},
	{
	    "namefull": "Colt 45",
	    "prcntalc": 0.059,
	    "nameshrt": "Colt 45"
	},
	{
	    "namefull": "Coors",
	    "prcntalc": 0.045,
	    "nameshrt": "Coors"
	},
	{
	    "namefull": "Coors Extra Gold",
	    "prcntalc": 0.05,
	    "nameshrt": "Coors Extra Gold"
	},
	{
	    "namefull": "Coors Light",
	    "prcntalc": 0.042,
	    "nameshrt": "Coors Light"
	},
	{
	    "namefull": "Corona",
	    "prcntalc": 0.046,
	    "nameshrt": "Corona"
	},
	{
	    "namefull": "Cosmopolitan",
	    "prcntalc": 0.336667,
	    "nameshrt": "Cosmopolitan"
	},
	{
	    "namefull": "Crazy Horse",
	    "prcntalc": 0.06,
	    "nameshrt": "Crazy Horse"
	},
	{
	    "namefull": "Creme de Cocoa",
	    "prcntalc": 0.24,
	    "nameshrt": "Creme de Cocoa"
	},
	{
	    "namefull": "Creme de Menthe",
	    "prcntalc": 0.24,
	    "nameshrt": "Creme de Menthe"
	},
	{
	    "namefull": "Crown Lager",
	    "prcntalc": 0.049,
	    "nameshrt": "Crown Lager"
	},
	{
	    "namefull": "Crown Royal",
	    "prcntalc": 0.4,
	    "nameshrt": "Crown Royal"
	},
	{
	    "namefull": "Crown Sterling",
	    "prcntalc": 0.434,
	    "nameshrt": "Canad-CrownSterli"
	},
	{
	    "namefull": "Crown Sterling Gin",
	    "prcntalc": 0.4,
	    "nameshrt": "Gin-Crown Sterlin"
	},
	{
	    "namefull": "Cuervo 1800",
	    "prcntalc": 0.4,
	    "nameshrt": "Cuervo 1800"
	},
	{
	    "namefull": "Cuervo Almndrado Tequila- CuervoAlmndrado",
	    "prcntalc": 0.27,
	    "nameshrt": "Teq-CuervoAlmndra"
	},
	{
	    "namefull": "Cuervo Anejo Tequila",
	    "prcntalc": 0.4,
	    "nameshrt": "Tequila-CuervoAne"
	},
	{
	    "namefull": "Cuervo Gold Tequila",
	    "prcntalc": 0.4,
	    "nameshrt": "Tequila-CuervoGol"
	},
	{
	    "namefull": "Curacao",
	    "prcntalc": 0.23,
	    "nameshrt": "Curacao"
	},
	{
	    "namefull": "Cutty Sark",
	    "prcntalc": 0.4,
	    "nameshrt": "Scotch- Cutty Sar"
	},
	{
	    "namefull": "Dewars",
	    "prcntalc": 0.43,
	    "nameshrt": "Scotch- Dewars"
	},
	{
	    "namefull": "Dock Street Amber",
	    "prcntalc": 0.054,
	    "nameshrt": "Dock Street Amber"
	},
	{
	    "namefull": "Dominion",
	    "prcntalc": 0.054,
	    "nameshrt": "Dominion"
	},
	{
	    "namefull": "Don Carlos Tequila",
	    "prcntalc": 0.4,
	    "nameshrt": "Teq-DonCarlos(all"
	},
	{
	    "namefull": "Dos Equis",
	    "prcntalc": 0.048,
	    "nameshrt": "Dos Equis"
	},
	{
	    "namefull": "Drambuie",
	    "prcntalc": 0.4,
	    "nameshrt": "Drambuie"
	},
	{
	    "namefull": "Dubouchette Amaretto",
	    "prcntalc": 0.28,
	    "nameshrt": "Amaretto-Dubouche"
	},
	{
	    "namefull": "Dubouchette Triple Sec",
	    "prcntalc": 0.3,
	    "nameshrt": "Trip Sec-Dubouche"
	},
	{
	    "namefull": "Early Times Bourbon",
	    "prcntalc": 0.4,
	    "nameshrt": "Bourbon-EarlyTime"
	},
	{
	    "namefull": "EKU 28 Malt Liquor",
	    "prcntalc": 0.109,
	    "nameshrt": "EKU 28 Malt Liqor"
	},
	{
	    "namefull": "Elephant Malt Liquor",
	    "prcntalc": 0.07,
	    "nameshrt": "ElephantMaltLiquo"
	},
	{
	    "namefull": "Eliot Ness",
	    "prcntalc": 0.062,
	    "nameshrt": "Eliot Ness"
	},
	{
	    "namefull": "Elk Mountain Amber",
	    "prcntalc": 0.056,
	    "nameshrt": "Elk Mountain Ambe"
	},
	{
	    "namefull": "Everclear",
	    "prcntalc": 0.95,
	    "nameshrt": "Grain- Everclear"
	},
	{
	    "namefull": "Fat Tire",
	    "prcntalc": 0.053,
	    "nameshrt": "Fat Tire"
	},
	{
	    "namefull": "Fleischmann's",
	    "prcntalc": 0.45,
	    "nameshrt": "Bourb-Fleischmann"
	},
	{
	    "namefull": "Fosters",
	    "prcntalc": 0.05,
	    "nameshrt": "Fosters"
	},
	{
	    "namefull": "Franzia in a Box",
	    "prcntalc": 0.09,
	    "nameshrt": "Franzia Box"
	},
	{
	    "namefull": "Franzia Wine Cooler",
	    "prcntalc": 0.05,
	    "nameshrt": "Franzia Cooler"
	},
	{
	    "namefull": "Fu-Ki Sake",
	    "prcntalc": 0.16,
	    "nameshrt": "Sake- Fu-Ki"
	},
	{
	    "namefull": "Full Sail Amber",
	    "prcntalc": 0.059,
	    "nameshrt": "Full Sail Amber"
	},
	{
	    "namefull": "Fuzzy Navel",
	    "prcntalc": 0.2,
	    "nameshrt": "FuzzyNavel"
	},
	{
	    "namefull": "Geary's Pale",
	    "prcntalc": 0.047,
	    "nameshrt": "Geary's Pale"
	},
	{
	    "namefull": "Genesee Cream Ale",
	    "prcntalc": 0.046,
	    "nameshrt": "Genesee Cream Ale"
	},
	{
	    "namefull": "Genj Sake",
	    "prcntalc": 0.17,
	    "nameshrt": "Sake- Genj"
	},
	{
	    "namefull": "Gilbey's Gin",
	    "prcntalc": 0.4,
	    "nameshrt": "Gin- Gilbey's"
	},
	{
	    "namefull": "Gilbey's Vodka",
	    "prcntalc": 0.4,
	    "nameshrt": "Vodka- Gilbey's"
	},
	{
	    "namefull": "Gin (Bar Stock)",
	    "prcntalc": 0.4,
	    "nameshrt": "Gin (Bar Stock)"
	},
	{
	    "namefull": "Gin (Diluted)",
	    "prcntalc": 0.21,
	    "nameshrt": "Gin (Diluted)"
	},
	{
	    "namefull": "Gin (Flavored)",
	    "prcntalc": 0.35,
	    "nameshrt": "Gin (Flavored)"
	},
	{
	    "namefull": "Glenfiddich",
	    "prcntalc": 0.43,
	    "nameshrt": "Scotch-Glenfiddic"
	},
	{
	    "namefull": "Goldschlager",
	    "prcntalc": 0.535,
	    "nameshrt": "Goldschlager"
	},
	{
	    "namefull": "Gordon's Gin",
	    "prcntalc": 0.4,
	    "nameshrt": "Gin- Gordon's"
	},
	{
	    "namefull": "Grande Marnier",
	    "prcntalc": 0.4,
	    "nameshrt": "Grande Marnier"
	},
	{
	    "namefull": "Grape Pucker (DeKuyper)",
	    "prcntalc": 0.15,
	    "nameshrt": "Grape Pucker"
	},
	{
	    "namefull": "Graves",
	    "prcntalc": 0.95,
	    "nameshrt": "Grain- Graves"
	},
	{
	    "namefull": "Grolsch Lager Beer",
	    "prcntalc": 0.0517,
	    "nameshrt": "Grolsch"
	},
	{
	    "namefull": "Guinness",
	    "prcntalc": 0.04,
	    "nameshrt": "Guinness"
	},
	{
	    "namefull": "H. Walker Creme de Cocoa",
	    "prcntalc": 0.27,
	    "nameshrt": "Crem Coc-H. Walke"
	},
	{
	    "namefull": "H. Walker Creme de Menthe",
	    "prcntalc": 0.3,
	    "nameshrt": "Cr Menthe-H.Walke"
	},
	{
	    "namefull": "Haake Beck Non-Alcoholic",
	    "prcntalc": 0.003,
	    "nameshrt": "Haake Beck"
	},
	{
	    "namefull": "Hard Core Cider",
	    "prcntalc": 0.06,
	    "nameshrt": "Hard Core Cider"
	},
	{
	    "namefull": "Heaven Hill Bourbon",
	    "prcntalc": 0.4,
	    "nameshrt": "Bourbon-HeavenHil"
	},
	{
	    "namefull": "Heaven Hill Ol Style",
	    "prcntalc": 0.5,
	    "nameshrt": "Bourb-HeavHill Ol"
	},
	{
	    "namefull": "Heineken",
	    "prcntalc": 0.05,
	    "nameshrt": "Heineken"
	},
	{
	    "namefull": "Hennessy Cognac",
	    "prcntalc": 0.4,
	    "nameshrt": "Hennessy"
	},
	{
	    "namefull": "Henry Weinhard's Private Reserve",
	    "prcntalc": 0.049,
	    "nameshrt": "Henry Weinhard's"
	},
	{
	    "namefull": "Herradura Tequila",
	    "prcntalc": 0.4,
	    "nameshrt": "Herradura"
	},
	{
	    "namefull": "Heublein Marguerita",
	    "prcntalc": 0.125,
	    "nameshrt": "Premix-HeubMargue"
	},
	{
	    "namefull": "Heublein Pina Colada",
	    "prcntalc": 0.135,
	    "nameshrt": "Premix-HeubPinaCo"
	},
	{
	    "namefull": "Heublein Whiskey Sour",
	    "prcntalc": 0.15,
	    "nameshrt": "Premix-HeuWiskSou"
	},
	{
	    "namefull": "Hiram Walker Triple Sec",
	    "prcntalc": 0.3,
	    "nameshrt": "Trip Sec-HiramWal"
	},
	{
	    "namefull": "Honey Brown - JW Dundee",
	    "prcntalc": 0.036,
	    "nameshrt": "JW Honey Brown"
	},
	{
	    "namefull": "Honey Brown Lager",
	    "prcntalc": 0.052,
	    "nameshrt": "Honey Brown"
	},
	{
	    "namefull": "Hoster 90 Shilling Ale",
	    "prcntalc": 0.0601,
	    "nameshrt": "Hoster 90 Shillin"
	},
	{
	    "namefull": "Hot Damn",
	    "prcntalc": 0.5,
	    "nameshrt": "Premix-Hot Damn"
	},
	{
	    "namefull": "Hurricane",
	    "prcntalc": 0.059,
	    "nameshrt": "Hurricane"
	},
	{
	    "namefull": "Ice House",
	    "prcntalc": 0.055,
	    "nameshrt": "Ice House"
	},
	{
	    "namefull": "Irish Whiskey",
	    "prcntalc": 0.4,
	    "nameshrt": "Whiskey Irish"
	},
	{
	    "namefull": "Jack Daniel Black",
	    "prcntalc": 0.45,
	    "nameshrt": "Bourb-JackDanBlac"
	},
	{
	    "namefull": "Jack Daniel Green Bourbon",
	    "prcntalc": 0.4,
	    "nameshrt": "Bourb-JackDanGree"
	},
	{
	    "namefull": "Jagermeister",
	    "prcntalc": 0.35,
	    "nameshrt": "Jagermeister"
	},
	{
	    "namefull": "Jamaica Club (White)",
	    "prcntalc": 0.655,
	    "nameshrt": "Rum-JamaicaClubWh"
	},
	{
	    "namefull": "Jamaican Club (Gold)",
	    "prcntalc": 0.4,
	    "nameshrt": "Rum-JamaicaClubGl"
	},
	{
	    "namefull": "Jameson Irish Whiskey",
	    "prcntalc": 0.4,
	    "nameshrt": "Jameson"
	},
	{
	    "namefull": "Jeremiah Weed",
	    "prcntalc": 0.5,
	    "nameshrt": "Jeremiah Weed"
	},
	{
	    "namefull": "Jim Beam",
	    "prcntalc": 0.4,
	    "nameshrt": "Bourbon-Jim Beam"
	},
	{
	    "namefull": "Jim Beam 8 year",
	    "prcntalc": 0.5,
	    "nameshrt": "Bourb-JimBeam8yea"
	},
	{
	    "namefull": "Jim Beam Black",
	    "prcntalc": 0.45,
	    "nameshrt": "Jim Beam Black"
	},
	{
	    "namefull": "Johnny Walker",
	    "prcntalc": 0.4,
	    "nameshrt": "Scotch-JWalkerLiq"
	},
	{
	    "namefull": "Johnny Walker Black",
	    "prcntalc": 0.434,
	    "nameshrt": "Scotch-JWalkerBla"
	},
	{
	    "namefull": "Johnny Walker Red",
	    "prcntalc": 0.434,
	    "nameshrt": "Scotch-JWalker Re"
	},
	{
	    "namefull": "Juarez Triple Sec",
	    "prcntalc": 0.15,
	    "nameshrt": "Triple Sec- Juare"
	},
	{
	    "namefull": "Jungle Juice",
	    "prcntalc": 0.95,
	    "nameshrt": "JungleJuice"
	},
	{
	    "namefull": "Kahlua",
	    "prcntalc": 0.27,
	    "nameshrt": "Coffee Liq.-Kahlu"
	},
	{
	    "namefull": "Kamakazi",
	    "prcntalc": 0.382727,
	    "nameshrt": "Kamakazi"
	},
	{
	    "namefull": "Kapala",
	    "prcntalc": 0.27,
	    "nameshrt": "Coffee Liq.-Kapal"
	},
	{
	    "namefull": "Keystone",
	    "prcntalc": 0.048,
	    "nameshrt": "Keystone"
	},
	{
	    "namefull": "Keystone Ice",
	    "prcntalc": 0.053,
	    "nameshrt": "Keystone Ice"
	},
	{
	    "namefull": "Keystone Light",
	    "prcntalc": 0.042,
	    "nameshrt": "Keystone Light"
	},
	{
	    "namefull": "Kiki Beach Key Lime Liquor",
	    "prcntalc": 0.15,
	    "nameshrt": "Kiki Beach"
	},
	{
	    "namefull": "Kiku Masamune Sake",
	    "prcntalc": 0.16,
	    "nameshrt": "Sake-Kiku Masamun"
	},
	{
	    "namefull": "Killians Red",
	    "prcntalc": 0.049,
	    "nameshrt": "Killians Red"
	},
	{
	    "namefull": "King Cobra",
	    "prcntalc": 0.06,
	    "nameshrt": "King Cobra"
	},
	{
	    "namefull": "Kirin",
	    "prcntalc": 0.05,
	    "nameshrt": "Kirin"
	},
	{
	    "namefull": "Kiss of Death",
	    "prcntalc": 0.071,
	    "nameshrt": "Kiss of Death"
	},
	{
	    "namefull": "LA Anheuser Busch",
	    "prcntalc": 0.023,
	    "nameshrt": "LA Anheuser Busch"
	},
	{
	    "namefull": "Labatt Blue",
	    "prcntalc": 0.05,
	    "nameshrt": "Labatt Blue"
	},
	{
	    "namefull": "Legacy Lager",
	    "prcntalc": 0.051,
	    "nameshrt": "Legacy Lager"
	},
	{
	    "namefull": "Leinenkugel's Red",
	    "prcntalc": 0.05,
	    "nameshrt": "Leinenkugel's Red"
	},
	{
	    "namefull": "Leroux Anisette",
	    "prcntalc": 0.3,
	    "nameshrt": "Anisette- Leroux"
	},
	{
	    "namefull": "Leroux Creme de Cocoa",
	    "prcntalc": 0.27,
	    "nameshrt": "Creme Cocoa-Lerou"
	},
	{
	    "namefull": "Lewiston",
	    "prcntalc": 0.95,
	    "nameshrt": "Grain- Lewiston"
	},
	{
	    "namefull": "Liberty",
	    "prcntalc": 0.06,
	    "nameshrt": "Liberty"
	},
	{
	    "namefull": "Licor de Cafe",
	    "prcntalc": 0.27,
	    "nameshrt": "Licor de Cafe"
	},
	{
	    "namefull": "Listerine",
	    "prcntalc": 0.27,
	    "nameshrt": "Listerine"
	},
	{
	    "namefull": "Lite Ultra",
	    "prcntalc": 0.042,
	    "nameshrt": "Lite Ultra"
	},
	{
	    "namefull": "Lone Star",
	    "prcntalc": 0.048,
	    "nameshrt": "Lone Star"
	},
	{
	    "namefull": "Long Island Ice Tea",
	    "prcntalc": 0.362,
	    "nameshrt": "Long Island Tea"
	},
	{
	    "namefull": "Lord Calvert Canadian Whiskey",
	    "prcntalc": 0.4,
	    "nameshrt": "Lord Calvert"
	},
	{
	    "namefull": "Lowenbrau",
	    "prcntalc": 0.049,
	    "nameshrt": "Lowenbrau"
	},
	{
	    "namefull": "Lowenbrau Dark",
	    "prcntalc": 0.049,
	    "nameshrt": "Lowenbrau Dark"
	},
	{
	    "namefull": "Lowenbrau Light",
	    "prcntalc": 0.042,
	    "nameshrt": "Lowenbrau Light"
	},
	{
	    "namefull": "Lucky Lager",
	    "prcntalc": 0.05,
	    "nameshrt": "Lucky Lager"
	},
	{
	    "namefull": "Mad Dog 20/20",
	    "prcntalc": 0.19,
	    "nameshrt": "Wine-Mad Dog 20/2"
	},
	{
	    "namefull": "Magnum 40",
	    "prcntalc": 0.059,
	    "nameshrt": "Magnum 40"
	},
	{
	    "namefull": "Magnum Malt",
	    "prcntalc": 0.059,
	    "nameshrt": "Magnum Malt"
	},
	{
	    "namefull": "Mai Tai",
	    "prcntalc": 0.32,
	    "nameshrt": "MaiTai"
	},
	{
	    "namefull": "Makers Mark",
	    "prcntalc": 0.45,
	    "nameshrt": "Bourbon-MakersMar"
	},
	{
	    "namefull": "Malibu Rum",
	    "prcntalc": 0.24,
	    "nameshrt": "Rum- Malibu"
	},
	{
	    "namefull": "Margarita",
	    "prcntalc": 0.345714,
	    "nameshrt": "Margarita"
	},
	{
	    "namefull": "Martini & Rossi",
	    "prcntalc": 0.16,
	    "nameshrt": "Vermouth-Mart&Ros"
	},
	{
	    "namefull": "McEwan's Scottish Ale",
	    "prcntalc": 0.08,
	    "nameshrt": "McEwan's Scottish"
	},
	{
	    "namefull": "Meisterbrau",
	    "prcntalc": 0.042,
	    "nameshrt": "Meisterbrau"
	},
	{
	    "namefull": "Melon Liqueur",
	    "prcntalc": 0.21,
	    "nameshrt": "Melon Liqueur"
	},
	{
	    "namefull": "Michelob",
	    "prcntalc": 0.049,
	    "nameshrt": "Michelob"
	},
	{
	    "namefull": "Michelob Dark",
	    "prcntalc": 0.049,
	    "nameshrt": "Michelob Dark"
	},
	{
	    "namefull": "Michelob Dry",
	    "prcntalc": 0.05,
	    "nameshrt": "Michelob Dry"
	},
	{
	    "namefull": "michelob light",
	    "prcntalc": 0.0415,
	    "nameshrt": "michelob light"
	},
	{
	    "namefull": "Mickey's",
	    "prcntalc": 0.057,
	    "nameshrt": "Mickey's"
	},
	{
	    "namefull": "Mickeys Ice",
	    "prcntalc": 0.058,
	    "nameshrt": "Mickeys Ice"
	},
	{
	    "namefull": "Midori",
	    "prcntalc": 0.21,
	    "nameshrt": "Midori"
	},
	{
	    "namefull": "Mikes Lemonade",
	    "prcntalc": 0.052,
	    "nameshrt": "Mikes Lemonade"
	},
	{
	    "namefull": "Miller",
	    "prcntalc": 0.047,
	    "nameshrt": "Miller"
	},
	{
	    "namefull": "Miller Genuine Draft",
	    "prcntalc": 0.047,
	    "nameshrt": "MillerGenuineDraf"
	},
	{
	    "namefull": "Miller Lite",
	    "prcntalc": 0.042,
	    "nameshrt": "Miller Lite"
	},
	{
	    "namefull": "Miller Reserve",
	    "prcntalc": 0.047,
	    "nameshrt": "Miller Reserve"
	},
	{
	    "namefull": "Milwaukee's Best",
	    "prcntalc": 0.046,
	    "nameshrt": "Milwaukee's Best"
	},
	{
	    "namefull": "Molinari Sambuca",
	    "prcntalc": 0.42,
	    "nameshrt": "Sambuca- Molinari"
	},
	{
	    "namefull": "Molson Diamond",
	    "prcntalc": 0.025,
	    "nameshrt": "Molson Diamond"
	},
	{
	    "namefull": "Molson Golden",
	    "prcntalc": 0.05,
	    "nameshrt": "Molson Golden"
	},
	{
	    "namefull": "Molson Ice",
	    "prcntalc": 0.056,
	    "nameshrt": "Molson Ice"
	},
	{
	    "namefull": "Molson Signature Cream Ale",
	    "prcntalc": 0.051,
	    "nameshrt": "Molson Signature"
	},
	{
	    "namefull": "Murphy's Irish Stout",
	    "prcntalc": 0.045,
	    "nameshrt": "Irish- Murphy's"
	},
	{
	    "namefull": "Muscatel",
	    "prcntalc": 0.2,
	    "nameshrt": "Muscatel"
	},
	{
	    "namefull": "Myers Rum",
	    "prcntalc": 0.4,
	    "nameshrt": "Rum- Myers"
	},
	{
	    "namefull": "Natural Ice",
	    "prcntalc": 0.059,
	    "nameshrt": "Natural Ice"
	},
	{
	    "namefull": "Natural Light",
	    "prcntalc": 0.042,
	    "nameshrt": "Natural Light"
	},
	{
	    "namefull": "Natural Light Ice",
	    "prcntalc": 0.059,
	    "nameshrt": "Natural Light Ice"
	},
	{
	    "namefull": "Negro Modelo",
	    "prcntalc": 0.06,
	    "nameshrt": "Negro Modelo"
	},
	{
	    "namefull": "New Amsterdam New York",
	    "prcntalc": 0.037,
	    "nameshrt": "New Amsterdam New"
	},
	{
	    "namefull": "NewCastle",
	    "prcntalc": 0.047,
	    "nameshrt": "NewCastle"
	},
	{
	    "namefull": "Newport",
	    "prcntalc": 0.15,
	    "nameshrt": "Amaretto-Newport"
	},
	{
	    "namefull": "Night Train",
	    "prcntalc": 0.18,
	    "nameshrt": "Wine- Night Train"
	},
	{
	    "namefull": "No Face Label Creme de Cocoa",
	    "prcntalc": 0.3,
	    "nameshrt": "Creme Cocoa-No Lb"
	},
	{
	    "namefull": "No Face Label Creme de Menthe",
	    "prcntalc": 0.3,
	    "nameshrt": "Crem Menthe-No Lb"
	},
	{
	    "namefull": "Norwester HefeWeizen",
	    "prcntalc": 0.055,
	    "nameshrt": "Norwester Hefewei"
	},
	{
	    "namefull": "NyQuil",
	    "prcntalc": 0.25,
	    "nameshrt": "NyQuil"
	},
	{
	    "namefull": "Oban Single Malt Scotch",
	    "prcntalc": 0.43,
	    "nameshrt": "Oban"
	},
	{
	    "namefull": "Old Bushmills",
	    "prcntalc": 0.4,
	    "nameshrt": "Old Bushmills"
	},
	{
	    "namefull": "Old Commonwealth St.Patrick",
	    "prcntalc": 0.4,
	    "nameshrt": "Irish-OlCmnwlthSt"
	},
	{
	    "namefull": "Old Crow",
	    "prcntalc": 0.4,
	    "nameshrt": "Bourbon- Old Crow"
	},
	{
	    "namefull": "Old Detroit Amber",
	    "prcntalc": 0.059,
	    "nameshrt": "Old Detroit Amber"
	},
	{
	    "namefull": "Old English 800 Malt Liquor",
	    "prcntalc": 0.068,
	    "nameshrt": "Old English"
	},
	{
	    "namefull": "Old English Ice",
	    "prcntalc": 0.08,
	    "nameshrt": "Old English Ice"
	},
	{
	    "namefull": "Old Grand Dad",
	    "prcntalc": 0.43,
	    "nameshrt": "Bourb-OldGrandDad"
	},
	{
	    "namefull": "Old Milwaukee",
	    "prcntalc": 0.045,
	    "nameshrt": "Old Milwaukee"
	},
	{
	    "namefull": "Old Speckled Hen",
	    "prcntalc": 0.052,
	    "nameshrt": "Old Speckled Hen"
	},
	{
	    "namefull": "Olde English Malt Liqor",
	    "prcntalc": 0.068,
	    "nameshrt": "Olde English"
	},
	{
	    "namefull": "Oregon Pale Ale",
	    "prcntalc": 0.055,
	    "nameshrt": "Oregon Pale Ale"
	},
	{
	    "namefull": "Outrigger Rum",
	    "prcntalc": 0.4,
	    "nameshrt": "Rum- Outrigger"
	},
	{
	    "namefull": "Ouzo",
	    "prcntalc": 0.46,
	    "nameshrt": "Ouzo"
	},
	{
	    "namefull": "Pabst Blue Ribbon",
	    "prcntalc": 0.047,
	    "nameshrt": "Pabst Blue Ribbon"
	},
	{
	    "namefull": "Parrot Bay Coconut Rum",
	    "prcntalc": 0.24,
	    "nameshrt": "Parrot Bay"
	},
	{
	    "namefull": "Peach Schnapps",
	    "prcntalc": 0.2,
	    "nameshrt": "Schnapps- Peach"
	},
	{
	    "namefull": "Peppermint Schnapps",
	    "prcntalc": 0.3,
	    "nameshrt": "Schnapps-Peppermi"
	},
	{
	    "namefull": "Permafrost",
	    "prcntalc": 0.4,
	    "nameshrt": "Permafrost"
	},
	{
	    "namefull": "Pete's Wicked Ale",
	    "prcntalc": 0.053,
	    "nameshrt": "Pete's Wicked Ale"
	},
	{
	    "namefull": "Pete's Wicked Ale Pub Lager",
	    "prcntalc": 0.051,
	    "nameshrt": "Pete's Pub Lager"
	},
	{
	    "namefull": "Petes Honey Wheat",
	    "prcntalc": 0.048,
	    "nameshrt": "PetesHoneyWheat"
	},
	{
	    "namefull": "Petes Octoberfest",
	    "prcntalc": 0.056,
	    "nameshrt": "PetesOctoberfest"
	},
	{
	    "namefull": "Petes Signature Pilsner",
	    "prcntalc": 0.049,
	    "nameshrt": "PetesPilsner"
	},
	{
	    "namefull": "Petes Strawberry Blonde",
	    "prcntalc": 0.051,
	    "nameshrt": "PetesStrawberry"
	},
	{
	    "namefull": "Petes Wicked Ale Summer Brew",
	    "prcntalc": 0.046,
	    "nameshrt": "PetesSummer"
	},
	{
	    "namefull": "Petes Winter Brew",
	    "prcntalc": 0.051,
	    "nameshrt": "PetesWinterBrew"
	},
	{
	    "namefull": "Pilsner Urquell",
	    "prcntalc": 0.041,
	    "nameshrt": "Pilsner Urquell"
	},
	{
	    "namefull": "Popov",
	    "prcntalc": 0.4,
	    "nameshrt": "Vodka- Popov"
	},
	{
	    "namefull": "Port",
	    "prcntalc": 0.2,
	    "nameshrt": "Wine (Port)"
	},
	{
	    "namefull": "Raspberry Weizen",
	    "prcntalc": 0.053,
	    "nameshrt": "Raspberry Weizen"
	},
	{
	    "namefull": "Rebel Yell",
	    "prcntalc": 0.43,
	    "nameshrt": "Bourbon-RebelYell"
	},
	{
	    "namefull": "Red Dog",
	    "prcntalc": 0.055,
	    "nameshrt": "Red Dog"
	},
	{
	    "namefull": "Red Hook ESB",
	    "prcntalc": 0.056,
	    "nameshrt": "Red Hook ESB"
	},
	{
	    "namefull": "Red Hook IPA",
	    "prcntalc": 0.06,
	    "nameshrt": "Red Hook IPA"
	},
	{
	    "namefull": "Red Nectar",
	    "prcntalc": 0.053,
	    "nameshrt": "Red Nectar"
	},
	{
	    "namefull": "Red Wolf",
	    "prcntalc": 0.055,
	    "nameshrt": "Red Wolf"
	},
	{
	    "namefull": "Riunite",
	    "prcntalc": 0.085,
	    "nameshrt": "Wine- Riunite"
	},
	{
	    "namefull": "Rolling Rock",
	    "prcntalc": 0.046,
	    "nameshrt": "Rolling Rock"
	},
	{
	    "namefull": "Ronrico (Gold)",
	    "prcntalc": 0.4,
	    "nameshrt": "Rum-Ronrico Gold"
	},
	{
	    "namefull": "Ronrico (Purple)",
	    "prcntalc": 0.75,
	    "nameshrt": "Rum-Ronrico Purpl"
	},
	{
	    "namefull": "Rum",
	    "prcntalc": 0.4,
	    "nameshrt": "Rum (U.S.)"
	},
	{
	    "namefull": "Rum (Bar Stock)",
	    "prcntalc": 0.4,
	    "nameshrt": "Rum (Bar Stock)"
	},
	{
	    "namefull": "Rum (Cuban)",
	    "prcntalc": 0.4,
	    "nameshrt": "Rum (Cuban)"
	},
	{
	    "namefull": "Rum (Jamaican)",
	    "prcntalc": 0.4,
	    "nameshrt": "Rum (Jamaican)"
	},
	{
	    "namefull": "RumpleMinze",
	    "prcntalc": 0.5,
	    "nameshrt": "Schnapps-RumpleMi"
	},
	{
	    "namefull": "Sake",
	    "prcntalc": 0.18,
	    "nameshrt": "Sake"
	},
	{
	    "namefull": "Sam Adams",
	    "prcntalc": 0.0475,
	    "nameshrt": "Sam Adams"
	},
	{
	    "namefull": "Sam Adams Boston Ale",
	    "prcntalc": 0.0494,
	    "nameshrt": "Sam Adams Boston"
	},
	{
	    "namefull": "Sam Adams Cherry Wheat",
	    "prcntalc": 0.053,
	    "nameshrt": "Sam Adams Cherry"
	},
	{
	    "namefull": "Sam Adams Cream Stout",
	    "prcntalc": 0.0469,
	    "nameshrt": "Sam Adams Cream S"
	},
	{
	    "namefull": "Sam Adams Double Bock",
	    "prcntalc": 0.0845,
	    "nameshrt": "Sam Adams Double"
	},
	{
	    "namefull": "Sam Adams Golder Pilsner",
	    "prcntalc": 0.0463,
	    "nameshrt": "Sam Adams Golder"
	},
	{
	    "namefull": "Sam Adams Honey Porter",
	    "prcntalc": 0.053,
	    "nameshrt": "Sam Adams Honey P"
	},
	{
	    "namefull": "Sam Adams IPA",
	    "prcntalc": 0.0593,
	    "nameshrt": "Sam Adams IPA"
	},
	{
	    "namefull": "Sam Adams Millennium",
	    "prcntalc": 0.195,
	    "nameshrt": "Sam Adams Millenn"
	},
	{
	    "namefull": "Sam Adams Oktoberfest",
	    "prcntalc": 0.057,
	    "nameshrt": "Sam Adams Oktober"
	},
	{
	    "namefull": "Sam Adams Pale Ale",
	    "prcntalc": 0.0525,
	    "nameshrt": "Sam Adams Pale Al"
	},
	{
	    "namefull": "Sam Adams Spring Ale",
	    "prcntalc": 0.052,
	    "nameshrt": "Sam Adams Spring"
	},
	{
	    "namefull": "Sam Adams Summer Ale",
	    "prcntalc": 0.0506,
	    "nameshrt": "Sam Adams Summer"
	},
	{
	    "namefull": "Sam Adams Triple Bock",
	    "prcntalc": 0.175,
	    "nameshrt": "Sam Adams Triple"
	},
	{
	    "namefull": "Sam Adams Winter Lager",
	    "prcntalc": 0.0694,
	    "nameshrt": "Sam Adams Winter"
	},
	{
	    "namefull": "Sambuca",
	    "prcntalc": 0.4,
	    "nameshrt": "Sambuca"
	},
	{
	    "namefull": "Samichlaus Bier",
	    "prcntalc": 0.118,
	    "nameshrt": "Samichlaus Bier"
	},
	{
	    "namefull": "Sauza Tequila Extra",
	    "prcntalc": 0.4,
	    "nameshrt": "Sauza Tequila Ext"
	},
	{
	    "namefull": "Schlitz Bull Ice",
	    "prcntalc": 0.08,
	    "nameshrt": "SchlitzBullIce"
	},
	{
	    "namefull": "Schlitz Ice",
	    "prcntalc": 0.06,
	    "nameshrt": "Schlitz Ice"
	},
	{
	    "namefull": "Schlitz Malt Liquor",
	    "prcntalc": 0.059,
	    "nameshrt": "Schlitz Malt Liqu"
	},
	{
	    "namefull": "Schnapps",
	    "prcntalc": 0.2,
	    "nameshrt": "Schnapps"
	},
	{
	    "namefull": "Schnapps - Fruit",
	    "prcntalc": 0.15,
	    "nameshrt": "Schnapps- Fruit"
	},
	{
	    "namefull": "Scotch",
	    "prcntalc": 0.4,
	    "nameshrt": "Scotch Whiskey"
	},
	{
	    "namefull": "Screwdriver",
	    "prcntalc": 0.4,
	    "nameshrt": "Screwdriver"
	},
	{
	    "namefull": "Seagrams Seven",
	    "prcntalc": 0.4,
	    "nameshrt": "Bourb-SeagramsSev"
	},
	{
	    "namefull": "Seagrams Seven Fuzzy Navel",
	    "prcntalc": 0.065,
	    "nameshrt": "Seagrams Fuzzy"
	},
	{
	    "namefull": "Sex on the Beach",
	    "prcntalc": 0.3,
	    "nameshrt": "SexontheBeach"
	},
	{
	    "namefull": "Sharps",
	    "prcntalc": 0.005,
	    "nameshrt": "Sharps"
	},
	{
	    "namefull": "Sherry",
	    "prcntalc": 0.2,
	    "nameshrt": "Wine (Sherry)"
	},
	{
	    "namefull": "Shiner Blonde",
	    "prcntalc": 0.0441,
	    "nameshrt": "Shiner Blonde"
	},
	{
	    "namefull": "Shiner Bock",
	    "prcntalc": 0.0441,
	    "nameshrt": "Shiner Bock"
	},
	{
	    "namefull": "Shiner Honey Wheat",
	    "prcntalc": 0.0528,
	    "nameshrt": "Shiner Honey Whea"
	},
	{
	    "namefull": "Shiraz",
	    "prcntalc": 0.135,
	    "nameshrt": "Shiraz"
	},
	{
	    "namefull": "Shiraza",
	    "prcntalc": 0.14,
	    "nameshrt": "Shiraza"
	},
	{
	    "namefull": "Sierra Nevada",
	    "prcntalc": 0.056,
	    "nameshrt": "Sierra Nevada"
	},
	{
	    "namefull": "Sierra Nevada Pale Ale",
	    "prcntalc": 0.058,
	    "nameshrt": "Sierra Nevada Pal"
	},
	{
	    "namefull": "Sierra Nevada Porter",
	    "prcntalc": 0.056,
	    "nameshrt": "Sierra Nevada Por"
	},
	{
	    "namefull": "Sierra Nevada Stout",
	    "prcntalc": 0.058,
	    "nameshrt": "Sierra Nevada Sto"
	},
	{
	    "namefull": "Sloe Gin",
	    "prcntalc": 0.3,
	    "nameshrt": "Sloe Gin"
	},
	{
	    "namefull": "Smirnoff",
	    "prcntalc": 0.4,
	    "nameshrt": "Vodka- Smirnoff"
	},
	{
	    "namefull": "Smirnoff Ice",
	    "prcntalc": 0.05,
	    "nameshrt": "Smirnoff Ice"
	},
	{
	    "namefull": "Southern Comfort",
	    "prcntalc": 0.4,
	    "nameshrt": "Southern Comfort"
	},
	{
	    "namefull": "Southpaw Light",
	    "prcntalc": 0.05,
	    "nameshrt": "Southpaw Light"
	},
	{
	    "namefull": "St Ides",
	    "prcntalc": 0.074,
	    "nameshrt": "St Ides"
	},
	{
	    "namefull": "St Pauli Girl",
	    "prcntalc": 0.049,
	    "nameshrt": "St Pauli Girl"
	},
	{
	    "namefull": "Steele Reseve",
	    "prcntalc": 0.081,
	    "nameshrt": "Steele Reserve"
	},
	{
	    "namefull": "Sterling",
	    "prcntalc": 0.047,
	    "nameshrt": "Sterling"
	},
	{
	    "namefull": "Stolichnaya 100",
	    "prcntalc": 0.5,
	    "nameshrt": "Stolichnaya 100"
	},
	{
	    "namefull": "Stolichnaya Vodka",
	    "prcntalc": 0.4,
	    "nameshrt": "Stolichnaya"
	},
	{
	    "namefull": "Stroh's",
	    "prcntalc": 0.043,
	    "nameshrt": "Stroh's"
	},
	{
	    "namefull": "Stroh's Light",
	    "prcntalc": 0.042,
	    "nameshrt": "Stroh's Light"
	},
	{
	    "namefull": "Sun Country",
	    "prcntalc": 0.04,
	    "nameshrt": "Sun Country Coole"
	},
	{
	    "namefull": "Sun Country Light",
	    "prcntalc": 0.02,
	    "nameshrt": "Sun Country Ligh"
	},
	{
	    "namefull": "Sutter Home Merlot",
	    "prcntalc": 0.13,
	    "nameshrt": "Sutter Hm Merlot"
	},
	{
	    "namefull": "Tanqueray Sterling",
	    "prcntalc": 0.4,
	    "nameshrt": "Tanqueray Sterlin"
	},
	{
	    "namefull": "Tanquery",
	    "prcntalc": 0.474,
	    "nameshrt": "Gin- Tanquery"
	},
	{
	    "namefull": "Tecate",
	    "prcntalc": 0.062,
	    "nameshrt": "Tecate"
	},
	{
	    "namefull": "Tequila (Bar Stock)",
	    "prcntalc": 0.4,
	    "nameshrt": "Tequila(Bar Stock"
	},
	{
	    "namefull": "Tequila Rose",
	    "prcntalc": 0.17,
	    "nameshrt": "Tequila Rose"
	},
	{
	    "namefull": "Tequiza",
	    "prcntalc": 0.045,
	    "nameshrt": "Tequiza"
	},
	{
	    "namefull": "Tequiza Extra",
	    "prcntalc": 0.056,
	    "nameshrt": "Tequiza Extra"
	},
	{
	    "namefull": "Texas Tea",
	    "prcntalc": 0.408333,
	    "nameshrt": "TexasTea"
	},
	{
	    "namefull": "Triple Sec",
	    "prcntalc": 0.21,
	    "nameshrt": "Triple Sec"
	},
	{
	    "namefull": "Tullamore Dew",
	    "prcntalc": 0.4,
	    "nameshrt": "TullamoreDew"
	},
	{
	    "namefull": "Ushers",
	    "prcntalc": 0.41,
	    "nameshrt": "Scotch- Ushers"
	},
	{
	    "namefull": "V O Canadian Whiskey",
	    "prcntalc": 0.4,
	    "nameshrt": "V O Canadian Whis"
	},
	{
	    "namefull": "Vera Cruz Triple Sec",
	    "prcntalc": 0.15,
	    "nameshrt": "Trip Sec-Vera Cru"
	},
	{
	    "namefull": "Vermouth",
	    "prcntalc": 0.18,
	    "nameshrt": "Wine (Vermouth)"
	},
	{
	    "namefull": "Victoria Bitter",
	    "prcntalc": 0.049,
	    "nameshrt": "Victoria Bitter"
	},
	{
	    "namefull": "Virgin Bourbon",
	    "prcntalc": 0.53,
	    "nameshrt": "Bourbon- Virgin"
	},
	{
	    "namefull": "Vodka (Bar Stock)",
	    "prcntalc": 0.4,
	    "nameshrt": "Vodka (Bar Stock)"
	},
	{
	    "namefull": "Vodka (Diluted)",
	    "prcntalc": 0.21,
	    "nameshrt": "Vodka (Diluted)"
	},
	{
	    "namefull": "Vodka (Flavored)",
	    "prcntalc": 0.35,
	    "nameshrt": "Vodka (Flavored)"
	},
	{
	    "namefull": "Watermelon Pucker",
	    "prcntalc": 0.15,
	    "nameshrt": "Watermelon Pucker"
	},
	{
	    "namefull": "Whiskey Bar Stock",
	    "prcntalc": 0.4,
	    "nameshrt": "Whisky Bar Stock"
	},
	{
	    "namefull": "Wild Irish Rose",
	    "prcntalc": 0.18,
	    "nameshrt": "Wine-WildIrishRos"
	},
	{
	    "namefull": "Wild Turkey",
	    "prcntalc": 0.4,
	    "nameshrt": "Bourbon-WildTurke"
	},
	{
	    "namefull": "Wine",
	    "prcntalc": 0.12,
	    "nameshrt": "Wine"
	},
	{
	    "namefull": "Wine (Champagne)",
	    "prcntalc": 0.125,
	    "nameshrt": "Wine (Champagne)"
	},
	{
	    "namefull": "Wine (Fortified)",
	    "prcntalc": 0.2,
	    "nameshrt": "Wine (Fortified)"
	},
	{
	    "namefull": "Wine (Plum)",
	    "prcntalc": 0.14,
	    "nameshrt": "Wine (Plum)"
	},
	{
	    "namefull": "Wine (Table) Red, White or Rose",
	    "prcntalc": 0.12,
	    "nameshrt": "Wine (Table)"
	},
	{
	    "namefull": "Wine Cooler",
	    "prcntalc": 0.04,
	    "nameshrt": "Wine Cooler"
	},
	{
	    "namefull": "Yuengling Premium",
	    "prcntalc": 0.043,
	    "nameshrt": "Yuengling Premium"
	},
	{
	    "namefull": "Yukon Jack",
	    "prcntalc": 0.5,
	    "nameshrt": "Canadian-YukonJac"
	},
	{
	    "namefull": "Zima",
	    "prcntalc": 0.037,
	    "nameshrt": "Zima"
	}
]