'use strict';

import _ from 'lodash';
import React from 'react';
import { getLatestTrackerEntry } from 'utils/render';

import { Element } from 'components/elements/element';

export const InformationalText = React.createClass({

    propTypes: {
        components: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
        default_responses: React.PropTypes.object.isRequired,
        norms: React.PropTypes.object,
        responses: React.PropTypes.object.isRequired
    },

    render() {
        const { components, default_responses, norms, responses, trackerEntries, last_tracker_entry_type, dispatch, updateProgressManually, module, section, tool } = this.props;
        // check if a last tracker entry type is set, if so, get it
        const trackerEntry = last_tracker_entry_type ?
            getLatestTrackerEntry(trackerEntries, last_tracker_entry_type)
            : null;
        const mergedResponses = {
            ...default_responses,
            ...responses,
            ...trackerEntry
        };
        return (
            <div className={last_tracker_entry_type ? "informational-text tracker-display" : "informational-text"}>
                {
                    components.map((component, index) => {
                        return (
                            <Element key={'element_' + index} component={component} index={index}
                                     norms={norms} responses={mergedResponses} dispatch={dispatch} updateProgressManually={updateProgressManually} 
                                     module={module} section={section} tool={tool}/>
                        );
                    })
                }
            </div>
        );
    }
});
