'use strict';

import { handleResponse } from 'utils/api';

export const getTools = (token) => {
    return fetch(
        CUAC_SETTINGS.API_ROOT + '/tools',
        {
            method: 'GET',
            headers: {
                'Authorization': 'CUAC ' + btoa(token),
                'Content-Type': 'application/json'
            }
        }
    )
        .then(handleResponse);
};

export const getTool = (token, toolId) => {
    return fetch(
        CUAC_SETTINGS.API_ROOT + '/tools/' + toolId,
        {
            method: 'GET',
            headers: {
                'Authorization': 'CUAC ' + btoa(token),
                'Content-Type': 'application/json'
            }
        }
    )
        .then(handleResponse);
};