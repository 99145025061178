'use strict';

import _ from 'lodash';

import {
    REQUEST_ADD_RESPONSE,
    REQUEST_ADD_RESPONSE_SUCCESS,
    REQUEST_ADD_RESPONSE_FAILURE,
    REQUEST_GET_RESPONSES,
    REQUEST_GET_RESPONSES_SUCCESS,
    REQUEST_GET_RESPONSES_FAILURE,
    REQUEST_LOG_IN_USER_SUCCESS,
    REQUEST_SET_RESPONSE,
    REQUEST_SET_RESPONSE_SUCCESS,
    REQUEST_SET_RESPONSE_FAILURE,
    REQUEST_UPDATE_RESPONSE,
    REQUEST_UPDATE_RESPONSE_SUCCESS,
    REQUEST_UPDATE_RESPONSE_FAILURE,
    LIST_ALL_ALERTS,
    LIST_ALL_ALERTS_SUCCESS,
    LIST_ALL_ALERTS_FAILURE,
    ADD_NEW_ALERT,
    ADD_NEW_ALERT_SUCCESS,
    ADD_NEW_ALERT_FAILURE,
    REQUEST_UPDATE_USER_SUCCESS,
    APPLY_RESPONSE,
    REQUEST_SUBMIT_UPDATES,
    REQUEST_SUBMIT_UPDATES_SUCCESS,
    REQUEST_SUBMIT_UPDATES_FAILURE,
    CACHE_UPDATE,
} from 'actions/types';

export function reducer(state = {
    activeResponseId: ''
}, action = { type: '' }) {
    switch (action.type) {
        case REQUEST_ADD_RESPONSE:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_ADD_RESPONSE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                activeResponseId: action.payload.response._id,
                [action.payload.response._id]: {
                    isFetching: false,
                    response: {
                        ...action.payload.response
                    }
                }
            };
        case REQUEST_ADD_RESPONSE_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_GET_RESPONSES:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_GET_RESPONSES_SUCCESS:
            const responses = {};
            action.payload.responses.forEach((response) => {
                responses[response._id] = {
                    isFetching: false,
                    response
                };
            });
            return {
                ...state,
                isFetching: false,
                ...responses
            };
        case REQUEST_GET_RESPONSES_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_LOG_IN_USER_SUCCESS:
            return {
                ...state,
                activeResponseId: action.payload.user.active_response_id
            };
        case REQUEST_SET_RESPONSE:
            return {
                ...state,
                activeResponseId: action.payload.responseId,
                isFetching: true
            };
        case REQUEST_SET_RESPONSE_SUCCESS:
            return {
                ...state,
                activeResponseId: action.payload.responseId,
                isFetching: false
            };
        case REQUEST_SET_RESPONSE_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_UPDATE_RESPONSE:
            const updatedResponse = _.merge(
                {},
                {
                    ...state[action.payload.responseId].response
                },
                {
                    progress: action.payload.progress,
                    responses: action.payload.responses
                }
            );
            return {
                ...state,
                activeResponseId: action.payload.responseId,
                [action.payload.responseId]: {
                    ...state[action.payload.responseId],
                    isFetching: true,
                    response: {
                        ...updatedResponse
                    }
                }
            };
        case REQUEST_SUBMIT_UPDATES:
            return {
                ...state,
                isFetching: true,
            };
        case REQUEST_SUBMIT_UPDATES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                activeResponseId: action.payload.responseId,
                [action.payload.responseId]: {
                    ...state[action.payload.responseId],
                    isFetching: false,
                    response: {
                        ...state[action.payload.responseId].response,
                        progress: action.payload.response.progress,
                        updates: undefined,
                    },
                },
            };
        case REQUEST_SUBMIT_UPDATES_FAILURE:
            return {
                ...state,
                isFetching: true,
            };
        case REQUEST_UPDATE_RESPONSE_SUCCESS:
            return {
                ...state,
                activeResponseId: action.payload.responseId,
                [action.payload.responseId]: {
                    ...state[action.payload.responseId],
                    isFetching: false,
                    response: {
                        ...state[action.payload.responseId].response,
                        progress: action.payload.response.progress,
                    }
                }
            };
        case CACHE_UPDATE:
            return {
                ...state,
                [action.payload.responseId]: {
                    ...state[action.payload.responseId],
                    response: {
                        ...(state[action.payload.responseId].response || {}),
                        updates: {
                            ...(state[action.payload.responseId].response || {}).updates,
                            ...action.payload.response
                        }
                    }
                }
            };
        case APPLY_RESPONSE:
            return {
                ...state,
                [action.payload.responseId]: {
                    ...state[action.payload.responseId],
                    response: {
                        ...(state[action.payload.responseId].response || {}),
                        responses: {
                            ...(state[action.payload.responseId].response || {}).responses,
                            ...action.payload.response
                        }
                    }
                }
            };
        case REQUEST_UPDATE_RESPONSE_FAILURE:
            return {
                ...state,
                [action.payload.responseId]: {
                    ...state[action.payload.responseId],
                    isFetching: false
                }
            };
        case REQUEST_UPDATE_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                activeResponseId: action.payload.user.active_response_id
            };
        default:
            return state;
    }
}
