import React from 'react';
import {Link} from 'react-router';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { DARK_GRAY, LIGHTER_FOREST_GREEN } from '../../stylesheets/colors';
import { FormattedMessage } from 'react-intl';

export default function ToolTOC({ onLinkClick, tool, moduleTree, open, setOpen, location }) {
    const linkStyle = sectionURL => {
        if (!location.pathname.includes(sectionURL)) return {
            color: '#0E323E',
            opacity: 0.5,
        };
        return {
            backgroundColor: LIGHTER_FOREST_GREEN,
            color: '#4f913e',
        }
    }

  return (
    <div className="no-print">
      <nav id="web-toggle" className={`bg-white mt-4 p-2 ${open ? '' : 'active'}`} style={{ position: 'absolute', top: 50, left: 0, borderRadius: '0 8px 8px 0' }}>
        <a style={{ color: '#0E323E', opacity: 0.5 }} className="btn d-flex align-items-center" role="button" onClick={() => setOpen(true)}>
          <p className="mb-0 me-3">
            <FormattedMessage { ...tool.title } />
          </p>
          <MaterialCommunityIcons name="chevron-double-right" size={28} color="#0E323E" style={{ opacity: 0.5 }} />
        </a>
      </nav>
      <nav id="tool-toc-sidebar" className={`bg-white ${!open ? 'active' : ''}`}>
        <div className="py-2 py-lg-3 pb-0 d-flex justify-content-between align-content-center">
          <h3 className="fw-bold">
            <Link onClick={() => onLinkClick(`/${tool.slug}`)} className="text-dark">
              <FormattedMessage { ...tool.title } />
            </Link>
          </h3>
          <button
            onClick={() => setOpen(false)}
            className="btn-close text-reset ms-1"
            type="button"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="pt-0">
          {
            (moduleTree || []).map(module => (
              <div key={module.url} className="pt-0">
                <hr className="my-4" style={{ backgroundColor: DARK_GRAY, height: 2 }}/>
                <div className="d-flex justify-content-between">
                  <Link onClick={() => onLinkClick(module.url)} className="text-dark">
                    <h5 className="fw-bold">{ module.title }</h5>
                  </Link>
                  {
                    typeof module.progress === 'number' && (
                      <div className="ms-3 text-x-small text-dark text-uppercase progress-bar-single">
                        {module.progress}% Completed
                        <div className="progress">
                          <div className="progress-bar"
                               role="progressbar"
                               style={{ width: `${module.progress}%`}}
                               aria-valuenow="25"
                               aria-valuemin="0"
                               aria-valuemax="100"
                          />
                        </div>
                      </div>
                    )
                  }
                </div>
                <ul className="list-unstyled checkup-items pt-2">
                  {
                    (module.sections || []).map(section => (
                      <li key={section.url} style={linkStyle(section.url)} onClick={() => onLinkClick(section.url)}>
                        { section.title }
                      </li>
                    ))
                  }
                </ul>
              </div>
            ))
          }
        </div>
      </nav>
    </div>
  )
}
