/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require, self, goToUrl */
"use strict";

import React, { forwardRef } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import Tools from 'utils/tools';
import { validator } from 'utils/tools';
import { timezones } from 'utils/timezone';
import TextFieldReduxForm from 'components/ux/text-field-redux-form';
import SelectInput from 'components/ux/mdl/select-field';
import InfoBug from 'components/popover/infobug';
import DateInputReduxForm from 'components/ux/input-date-redux-form';
import Actions from 'actions';
import NumericInput from '../blocks/NumericInput.component.web';
import SwitchInput from '../blocks/SwitchInput.component.web';
import BSButton from '../blocks/BSButton.component.web';
import { Ionicons, MaterialCommunityIcons, FontAwesome } from '@expo/vector-icons';
import MedicalHistory from 'components/forms/medical-history';
import { BLUE } from '../../stylesheets/colors';
import RadioGroupReduxForm from '../ux/RadioGroupReduxForm.component.web';
import PhoneInputReduxForm from '../ux/PhoneInputReduxForm.component.web';
import BrandedString from "../blocks/branded-string";

const weightOptions = [
    {
        "text": {
            "id": "weight_unit_pounds",
            "defaultMessage": "Pounds"
        },
        "value": "pounds"
    },
    {
        "text": {
            "id": "weight_unit_kilograms",
            "defaultMessage": "Kilograms"
        },
        "value": "kilograms"
    }
]

const UserProfile = React.createClass({

    propTypes: {
        fields: React.PropTypes.object.isRequired,
        handleSubmit: React.PropTypes.func.isRequired,
        medicalHistory: React.PropTypes.array
    },

    getInitialState() {
        return {
            medicalHistory: [],
        };
    },

    componentDidMount() {
        this.setState({
            medicalHistory: this.props.medicalHistory,
        });
        this.assignIntlMessages();
    },

    componentDidUpdate() {
        const { two_factor_auth, phone_number } = this.props;
        if (two_factor_auth && (!phone_number || !phone_number.length)) {
            this.props.dispatch(this.props.change('two_factor_auth', false))
            this.props.dispatch(Actions.notify({
                title: "Phone Number Required",
                message: "A phone number is required for Two-Factor Authentication.",
                level: "error",
                autoDismiss: 3
            }));
        }
    },

    UNSAFE_componentWillReceiveProps(nextProps){
        const currentMedHistory = this.props.medicalHistory;
        const nextMedHistory = nextProps.medicalHistory;
        const difference = _.difference(currentMedHistory, nextMedHistory);

        if (difference.length){
            this.setState({medicalHistory: nextMedHistory});
        }
    },

    assignIntlMessages(){
        let formatMessage = this.props.intl.formatMessage;
        medHistory.forEach(function(item){
            item.label = formatMessage(messages[item.value]);

            if (item.infoBug){
                item.infoBug[2] = formatMessage(messages[item.infoBug[2]]);
            }
        });
    },

    handleSubmit(){
        let scrollTarget = 0;
        Array.from(document.querySelectorAll("#user-profile-form [required]")).forEach(htmlElement => {
            if("checkValidity" in htmlElement) htmlElement.checkValidity();
        })
        Tools.scrollTo(document.body, scrollTarget, 1000);
        this.props.handleSubmit();
    },
    updateMedicalHistory(values){
        this.setState({medicalHistory: values});
    },

    renderMedicalHistory(field) {
        const rfOnChange = field.input.onChange;
        
        return <MedicalHistory {...field.input} {...field} />;
    },

    applyDOB(isoDate) {
        this.props.change('dt_dob', isoDate);
    },

    isValid() {
        return this.props.showMedicalHistory || !Object.values(this.props.errors || {}).length;
    },

    phoneNum(val) {
        const stripped = (val || '').replace(/\D/g,'');
        if (!stripped.length || stripped.length === 10) return undefined;
        return 'Invalid phone number';
    },

    render() {
        let locus = this, state = locus.state, props = locus.props;
        const { intl: { formatMessage } } = this.props;
        const name = { name: "name" },
            email = { name: "email" },
            weight = { name: "weight" },
            gender = { name: "gender" },
            dt_dob = { name: "dt_dob" },
            medical_history = { name: "medical_history" },
            phone_number = { name: "phone_number" },
            two_factor_auth = { name: "two_factor_auth" }
            const readOnly = CUAC_SETTINGS.GROUP.profile_read_only;

        const CustomDatepickerInput = forwardRef(({ value, onClick }, ref) => (
          <input style={{ textAlign: 'right' }} value={value} type="text" className="form-control" onClick={onClick} ref={ref}/>
        ));

        return (
            <form id="user-profile-form" className="body-column page-user-profile" style={{maxWidth: 1600}}>
                <div className="grid-layout grid-pad" style={{paddingBottom: 0}}>
                    <div className="section section-user-profile">
                        <div className="material-input-wrap" >
                            {this.props.showProfile && (
                                <>
                                <div className="d-flex align-items-center justify-content-between">
                                    <h1 className="section-title">{formatMessage(messages.user_profile_title)}</h1>
                                    {
                                        !CUAC_SETTINGS?.GROUP?.require_sso && (
                                          <BSButton className="justify-content-center" onClick={() => this.props.onToggleChangePassword()} variant="flat-blue" size="sm">
                                              <MaterialCommunityIcons name="lock-reset" size={20} color={BLUE} />
                                              <div className="ms-1">
                                                  <FormattedMessage
                                                    id="change_password_button"
                                                    description="Submit the change password form"
                                                    defaultMessage="Change Password"
                                                  />
                                              </div>
                                          </BSButton>
                                        )
                                    }
                                </div>
                                <TextFieldReduxForm
                                  {...name}
                                  floatingLabel={true}
                                  tooltip={formatMessage(messages.user_profile_infobug_name_body)}
                                  labelText={formatMessage(messages.user_profile_name_input_label)}
                                  readOnly={!!readOnly}
                                  style={{ textAlign: 'right' }}
                                  required
                                />
                                <TextFieldReduxForm
                                  {...email}
                                  type="email"
                                  floatingLabel={true}
                                  labelText={formatMessage(messages.user_profile_email_input_label)}
                                  readOnly={!!readOnly}
                                  style={{ textAlign: 'right' }}
                                  required
                                />
                                <PhoneInputReduxForm
                                  {...phone_number}
                                  label={formatMessage(messages.user_profile_phoneNo_input_label)}
                                  guide={true}
                                  validate={[ this.phoneNum ]}
                                  placeholder="(xxx) xxx-xxxx"
                                />
                                {!CUAC_SETTINGS.GROUP.require_sso
                                  ? (
                                    <SwitchInput
                                      {...two_factor_auth}
                                      label="Two-Factor Authentication"
                                      inputGroupClassName="justify-content-between"
                                    />
                                  )
                                  : null
                                }

                                <Field
                                  name="user_timezone"
                                  component={field => (
                                    <SelectInput
                                      {...field.input}
                                      style={{ textAlign: 'right' }}
                                      options={timezones.zones.map((timezone, index) => {
                                          if (timezone === '--------') {
                                              return {
                                                  value: 'UTC',
                                                  display: timezone
                                              };
                                          } else {
                                              return {
                                                  value: timezone,
                                                  display: timezone
                                              };
                                          }
                                      })}
                                      labelText="Time Zone">
                                    </SelectInput>
                                  )}
                                />
                                <DateInputReduxForm
                                  {...dt_dob}
                                  floatingLabel={true}
                                  yearLabel={formatMessage(messages.date_input_year_label)}
                                  monthLabel={formatMessage(messages.date_input_month_label)}
                                  dayLabel={formatMessage(messages.date_input_day_label)}
                                  labelText={formatMessage(messages.user_profile_dob_label)}
                                  onChange={this.applyDOB}
                                  wrapperClassName="flex-grow-1"
                                  labelClassName="input-group-text"
                                  readOnly={!!readOnly}
                                  customInput={<CustomDatepickerInput/>}
                                  required
                                />
                                <NumericInput
                                  {...weight}
                                  step="1"
                                  placeholder="0"
                                  style={{ width: 70, border: 'none', textAlign: 'center' }}
                                  label={formatMessage(messages.user_profile_weight_input_label)}
                                  inputGroupClassName="justify-content-between input-group-fancy mb-3"
                                  required
                                />
                                {
                                    this.props.kilogramWeight && (
                                      <RadioGroupReduxForm
                                        name="weight_unit"
                                        title={
                                            <FormattedMessage
                                              id="user_profile_weight_unit"
                                              defaultMessage="Weight Unit"
                                            />
                                        }
                                        options={weightOptions}
                                        fieldsetClassName="d-flex"
                                        inputClassName="me-1"
                                        fieldContainerClassName="me-3"
                                        labelClassName="input-group-text"
                                        containerClassName="input-group-fancy"
                                      />
                                    )
                                }

                                <Field
                                  component={field => {
                                      return (
                                        <SelectInput
                                          {...field.input}
                                          options={[
                                              {  value: 'male', display: 'Male' },
                                              {  value: 'female', display: 'Female' },
                                          ]}
                                          labelText="Gender"
                                          placeHolder="Select a gender"
                                          style={{ textAlign: 'right' }}
                                          required
                                        />
                                      )
                                  }}
                                  { ...gender }
                                />
                            </>
                            )}
                            {
                                this.props.showMedicalHistory && (
                                  <div className="card" style={{ border: 'none' }}>
                                    <div className="card-body">
                                        <MedicalHistory
                                          options={medHistory}
                                          columns={4}
                                          { ...medical_history }
                                        />
                                    </div>
                                  </div>
                                )
                            }
                        </div> {/* END material-input-wrap */}
                    </div> {/* END section-user-profile */}
                </div> {/* END grid-layout grid-pad */}
                <div classname='d-flex flex-column align-items-center'>
                    <BrandedString string_type='profile_info' />
                </div>
                <div className={`d-flex flex-column align-items-center ${this.props.showMedicalHistory && 'my-3'}`}>
                    <BSButton
                      disabled={!this.isValid() || this.props.isFetching}
                      loading={this.props.isFetching}
                      className="justify-content-center"
                      onClick={this.props.handleSubmit}
                      variant="green"
                    >
                        {
                            this.props.showMedicalHistory && (
                              <FontAwesome name="star-o" color="white" size={20} />
                            )
                        }
                        <div className={this.props.showMedicalHistory ? 'ms-2' : 'me-2'}>
                            {formatMessage(this.props.showMedicalHistory ? messages.user_profile_update_button : messages.user_profile_next_button)}
                        </div>
                        {
                            !this.props.showMedicalHistory && (
                              <Ionicons name="arrow-forward" size={24} color="white" />
                            )
                        }
                    </BSButton>
                </div>
            </form>
        );
    }
});

const formFields = ['name', 'email', 'weight', 'weight_unit', 'gender', 'dt_dob', 'medical_history', 'phone_number', 'user_timezone', 'two_factor_auth'];
let form = reduxForm({
    form: 'userProfile',
    fields: formFields,
    validate: validator({
        'name': { required: 'Required' },
        'email': { required: 'Required', email: 'Please enter a valid email' },
        'weight': { required: 'Required', number: 'Must be a number', minNumber: { key: 'weight_unit', kilograms: { error_message: 'Your weight must be at least 36kg', minimum: 36 }, pounds: { error_message: 'Your weight must be at least 80lbs', minimum: 80 }, default: { error_message: 'Your weight must be at least 80lbs', minimum: 80 } } },
        'gender': { required: 'Required' },
        'dt_dob': { required: 'Required', date: 'Please enter a valid date', minDate: 'Your Birth Date must be after 1900' }
    })
})(UserProfile);
const selector = formValueSelector('userProfile') // <-- same as form name
form = connect(state => {
    return formFields.reduce((dict, fieldName) => 
        Object.assign({ [fieldName]: selector(state, fieldName) }, dict),
        {
            errors: state.form?.userProfile?.syncErrors
        }
    );
})(form)


export default injectIntl(form);

var medHistory = [
    { "value": "ADHD" },
    { "value": "Anemia" },
    { "value": "Anxiety" },
    { "value": "Arthritis" },
    { "value": "Arteriosclerosis" },
    { "value": "Atrial Fibrillation" },
    { "value": "Asthma" },
    { "value": "Back Pain" },
    { "value": "Cancer" },
    { "value": "Cardiomyopathy" },
    { "value": "Chronic Bronchitis" },
    { "value": "Chronic Fatigue" },
    { "value": "Chronic Pain" },
    { "value": "Congestive Heart Failure" },
    { "value": "Crohn's Disease" },
    { "value": "Dementia" },
    { "value": "Depression" },
    { "value": "Diabetes" },
    { "value": "Eating Disorders" },
    { "value": "Emphysema/COPD" },
    { "value": "Esophageal Problems" },
    { "value": "Fibromyalgia" },
    { "value": "GI Disorders" },
    { "value": "Gout" },
    { "value": "Heart Attack" },
    { "value": "Hepatitis C" },
    { "value": "High Blood Pressure" },
    { "value": "HIV/AIDS" },
    { "value": "Hyperthyroidism" },
    { "value": "Insomnia" },
    { "value": "Irritable Bowel Syndrome" },
    { "value": "Kidney Disease" },
    { "value": "Liver Disease" },
    { "value": "Tuberculosis" },
    { "value": "Multiple Sclerosis" },
    { "value": "Neuropathy" },
    { "value": "Obesity" },
    { "value": "Pancreatitis" },
    { "value": "Pneumonia" },
    { "value": "PTSD/Trauma" },
    { "value": "Reflux" },
    { "value": "Reynaud's Disease" },
    { "value": "Scoliosis" },
    { "value": "Seizures" },
    {
        "value": "Severe Mental Illness",
        "InfoBug": ["InfoBug", null, [
            ["h5", null, "Severe Mental Illness"],
            ["p", null, "Bipolar Disorder, Schizophrenia, et. al."]
        ]
        ]
    },
    { "value": "Shingles" },
    { "value": "Sjogren's Disease" },
    { "value": "Sleep Disorders" },
    { "value": "Stress" },
    { "value": "Stroke" }
];

var messages = defineMessages({
    "user_profile_infobug_name_title": { id: "user_profile_infobug_name_title", description: "infobug -- user name -- title", defaultMessage: "Your Name" },
    "user_profile_infobug_name_body": { id: "user_profile_infobug_name_body", description: "infobug -- user name -- description", defaultMessage: "First name or nickname.  What should we call you?" },
    "user_profile_infobug_weight_title": { id: "user_profile_infobug_weight_title", description: "infobug -- user weight -- title", defaultMessage: "Your Weight" },
    "user_profile_infobug_weight_body_pounds": { id: "user_profile_infobug_weight_body_pounds", description: "infobug -- user weight -- description", defaultMessage: "Please use pounds (lbs). This allows the program to calculate your blood alcohol level." },
    "user_profile_infobug_weight_body_kilograms": { id: "user_profile_infobug_weight_body_kilograms", description: "infobug -- user weight -- description", defaultMessage: "This allows us to calculate blood alcohol level. Please select pounds or kilograms below." },
    "user_profile_infobug_phone_title": { id: "user_profile_infobug_phone_title", description: "infobug -- user phone -- title", defaultMessage: "Your Phone Number" },
    "user_profile_infobug_phone_body": { id: "user_profile_infobug_phone_body", description: "infobug -- user phone -- description", defaultMessage: "Although it's not required, providing your phone number is highly encouraged. This will allow you to use text alert features in the site. Your information is always confidential and we will only text you if you specifically request it." },
    "user_profile_title": { id: "user_profile_title", description: "section title -- User Profile", defaultMessage: "Profile" },
    "user_profile_name_input_label": { id: "user_profile_name_input_label", description: "user profile text input -- Name", defaultMessage: "Your Name" },
    "user_profile_email_input_label": { id: "user_profile_email_input_label", description: "user profile text iput -- Email", defaultMessage: "Your Email" },
    "user_profile_phoneNo_input_label": { id: "user_profile_phoneNo_input_label", description: "user profile text iput -- Phone Number", defaultMessage: "Your Phone Number" },
    "user_profile_dob_label": { id: "user_profile_dob_label", description: "user profile text iput -- Date of Birth", defaultMessage: "Date Of Birth" },
    "user_profile_weight_input_label": { id: "user_profile_weight_input_label", description: "user profile text iput -- Weight", defaultMessage: "Your Weight" },
    "user_profile_gender_input_male": { id: "user_profile_gender_input_male", description: "user profile radio choice -- Male", defaultMessage: "Male" },
    "user_profile_gender_input_female": { id: "user_profile_gender_input_female", description: "user profile radio choice -- Female", defaultMessage: "Female" },
    "user_profile_update_button": { id: "user_profile_update_button", description: "user profile update button", defaultMessage: "Update" },
    "user_profile_next_button": { id: "user_profile_next_button", description: "user profile next button", defaultMessage: "Next" },
    "date_input_year_label": { id: "date_input_year_label", description: "date choose label -- Year", defaultMessage: "Year" },
    "date_input_month_label": { id: "date_input_month_label", description: "date choose label -- Month", defaultMessage: "Month" },
    "date_input_day_label": { id: "date_imput_day_label", description: "date choose label -- Day", defaultMessage: "Day" },
    "medical_history_title": { "id": "medical_history_title", description: "section title -- Medical History", defaultMessage: "Medical History" },
    "medical_history_instructions": { "id": "medical_history_instructions", description: "section instructions -- Check all that apply", defaultMessage: "We ask for your medical history so that we can provide you feedback about how your current or prior substance use could affect your medical conditions. Please check all that apply." },
    "ADHD": { "id": "ADHD", description: "medical_history conditon: ADHD", defaultMessage: "ADHD" },
    "Anemia": { "id": "Anemia", description: "medical_history conditon: Anemia", defaultMessage: "Anemia" },
    "Anxiety": { "id": "Anxiety", description: "medical_history conditon: ", defaultMessage: "Anxiety" },
    "Arthritis": { "id": "Arthritis", description: "medical_history conditon: Arthritis", defaultMessage: "Arthritis" },
    "Arteriosclerosis": { "id": "Arteriosclerosis", description: "medical_history conditon: Arteriosclerosis", defaultMessage: "Arteriosclerosis" },
    "Atrial Fibrillation": { "id": "Atrial Fibrillation", description: "medical_history conditon: Atrial Fibrillation", defaultMessage: "Atrial Fibrillation" },
    "Asthma": { "id": "Asthma", description: "medical_history conditon:Asthma ", defaultMessage: "Asthma" },
    "Back Pain": { "id": "Back Pain", description: "medical_history conditon: Back Pain", defaultMessage: "Back Pain" },
    "Cancer": { "id": "Cancer", description: "medical_history conditon: Cancer", defaultMessage: "Cancer" },
    "Cardiomyopathy": { "id": "Cardiomyopathy", description: "medical_history conditon: Cardiomyopathy", defaultMessage: "Cardiomyopathy" },
    "Chronic Bronchitis": { "id": "Chronic Bronchitis", description: "medical_history conditon: Chronic Bronchitis", defaultMessage: "Chronic Bronchitis" },
    "Chronic Fatigue": { "id": "Chronic Fatigue", description: "medical_history conditon: Chronic Fatigue", defaultMessage: "Chronic Fatigue" },
    "Chronic Pain": { "id": "Chronic Pain", description: "medical_history conditon: Chronic Pain", defaultMessage: "Chronic Pain" },
    "Congestive Heart Failure": { "id": "Congestive Heart Failure", description: "medical_history conditon: Congestive Heart Failure", defaultMessage: "Congestive Heart Failure" },
    "Crohn's Disease": { "id": "Crohn's Disease", description: "medical_history conditon: Crohn's Disease", defaultMessage: "Crohn's Disease" },
    "Dementia": { "id": "Dementia", description: "medical_history conditon: Dementia", defaultMessage: "Dementia" },
    "Depression": { "id": "Depression", description: "medical_history conditon: Depression", defaultMessage: "Depression" },
    "Diabetes": { "id": "Diabetes", description: "medical_history conditon: Diabetes", defaultMessage: "Diabetes" },
    "Eating Disorders": { "id": "Eating Disorders", description: "medical_history conditon: Eating Disorders", defaultMessage: "Eating Disorders" },
    "Emphysema/COPD": { "id": "Emphysema/COPD", description: "medical_history conditon: Emphysema/COPD", defaultMessage: "Emphysema / COPD" },
    "Esophageal Problems": { "id": "Esophageal Problems", description: "medical_history conditon: Esophageal Problems", defaultMessage: "Esophageal Problems" },
    "Fibromyalgia": { "id": "Fibromyalgia", description: "medical_history conditon: Fibromyalgia", defaultMessage: "Fibromyalgia" },
    "GI Disorders": { "id": "GI Disorders", description: "medical_history conditon: GI Disorders", defaultMessage: "GI Disorders" },
    "Gout": { "id": "Gout", description: "medical_history conditon: Gout", defaultMessage: "Gout" },
    "Heart Attack": { "id": "Heart Attack", description: "medical_history conditon: Heart Attack", defaultMessage: "Heart Attack" },
    "Hepatitis C": { "id": "Hepatitis C", description: "medical_history conditon: Hepatitis C", defaultMessage: "Hepatitis C" },
    "High Blood Pressure": { "id": "High Blood Pressure", description: "medical_history conditon: High Blood Pressure", defaultMessage: "High Blood Pressure" },
    "HIV/AIDS": { "id": "HIV/AIDS", description: "medical_history conditon: HIV/AIDS", defaultMessage: "HIV/AIDS" },
    "Hyperthyroidism": { "id": "Hyperthyroidism", description: "medical_history conditon: Hyperthyroidism", defaultMessage: "Hyperthyroidism" },
    "Insomnia": { "id": "Insomnia", description: "medical_history conditon: Insomnia", defaultMessage: "Insomnia" },
    "Irritable Bowel Syndrome": { "id": "Irritable Bowel Syndrome", description: "medical_history conditon: Irritable Bowel Syndrome", defaultMessage: "Irritable Bowel Syndrome" },
    "Kidney Disease": { "id": "Kidney Disease", description: "medical_history conditon: Kidney Disease", defaultMessage: "Kidney Disease" },
    "Liver Disease": { "id": "Liver Disease", description: "medical_history conditon: Liver Disease", defaultMessage: "Liver Disease" },
    "Tuberculosis": { "id": "Tuberculosis", description: "medical_history conditon: Tuberculosis", defaultMessage: "Tuberculosis" },
    "Multiple Sclerosis": { "id": "Multiple Sclerosis", description: "medical_history conditon: Multiple Sclerosis", defaultMessage: "Multiple Sclerosis" },
    "Neuropathy": { "id": "Neuropathy", description: "medical_history conditon: Neuropathy", defaultMessage: "Neuropathy" },
    "Obesity": { "id": "Obesity", description: "medical_history conditon: Obesity", defaultMessage: "Obesity" },
    "Pancreatitis": { "id": "Pancreatitis", description: "medical_history conditon: Pancreatitis", defaultMessage: "Pancreatitis" },
    "Pneumonia": { "id": "Pneumonia", description: "medical_history conditon: Pneumonia", defaultMessage: "Pneumonia" },
    "PTSD/Trauma": { "id": "PTSD/Trauma", description: "medical_history conditon: PTSD/Trauma", defaultMessage: "PTSD/Trauma" },
    "Reflux": { "id": "Reflux", description: "medical_history conditon: Reflux", defaultMessage: "Reflux" },
    "Reynaud's Disease": { "id": "Reynaud's Disease", description: "medical_history conditon: Reynaud's Disease", defaultMessage: "Reynaud's Disease" },
    "Scoliosis": { "id": "Scoliosis", description: "medical_history conditon: Scoliosis", defaultMessage: "Scoliosis" },
    "Seizures": { "id": "Seizures", description: "medical_history conditon: Seizures", defaultMessage: "Seizures" },
    "Severe Mental Illness": { "id": "Severe Mental Illness (e.g. bipolar, schizophrenia)", description: "medical_history conditon: Severe Mental Illness (e.g. bipolar, schizophrenia)", defaultMessage: "Severe Mental Illness" },
    "Shingles": { "id": "Shingles", description: "medical_history conditon: Shingles", defaultMessage: "Shingles" },
    "Sjogren's Disease": { "id": "Sjogren's Disease", description: "medical_history conditon: Sjogren's Disease", defaultMessage: "Sjogren's Disease" },
    "Sleep Disorders": { "id": "Sleep Disorders", description: "medical_history conditon: Sleep Disorders", defaultMessage: "Sleep Disorders" },
    "Stress": { "id": "Stress", description: "medical_history conditon: Stress", defaultMessage: "Stress" },
    "Stroke": { "id": "Stroke", description: "medical_history conditon: Stroke", defaultMessage: "Stroke" },
    "Severe Mental Illness Infobug Title": { "id": "Severe Mental Illness", description: "infobug title: Severe Mental Illness", defaultMessage: "Severe Mental Illness" },
    "Severe Mental Illness Infobug Contents": { "id": "bipolar disorder, schizophrenia, et. al.", description: "infobug contents: bipolar disorder, schizophrenia, et. al.", defaultMessage: "bipolar disorder, schizophrenia, et. al." }
});
