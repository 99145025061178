/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import _ from 'lodash';
import React from 'react';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import InfoBug from 'components/popover/infobug';

import { TrackerTypes } from 'api/tracker-entries';
import Accordion from 'components/ux/accordion';
import Button from 'components/ux/mdl/button';
import { faChevronCircleDown, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
export const HappinessList = React.createClass({

    propTypes: {
        tag: React.PropTypes.string,
        trackerEntries: React.PropTypes.object.isRequired
    },

    getInitialState() {
        return {
            openEntries: []
        };
    },

    toggleEntry(index){
        let openEntries = this.state.openEntries;
        if (openEntries.indexOf(index) == -1){
            openEntries.push(index);
        } else {
            openEntries = _.without(openEntries, index);
        }

        this.setState({
            openEntries: openEntries
        });
    },

    render() {
        const { tag, trackerEntries } = this.props;
        const locus = this, openEntries = this.state.openEntries, toggleEntry = this.toggleEntry;
        const fullOrder = _.get(trackerEntries, TrackerTypes.HAPPINESS + 'Order', []);
        const fullOrderEntries = _.map(fullOrder, (trackerEntryId) => {
            return _.get(trackerEntries, trackerEntryId, {});
        });
        const filteredEntries = typeof tag !== 'undefined'
            ?
            _.filter(fullOrderEntries, (trackerEntry) => {
                return _.get(trackerEntry, 'tag') === tag;
            })
            : fullOrderEntries;
        const orderEntries = filteredEntries.slice(0, 20);
        const popOverLabelStyle = {
            fontWeight: '700',
            padding: '0 8px 0 0',
            textTransform: 'uppercase',
            color: 'rgb(139, 195, 74)',
            display: 'inline-block',
            width: '50%',
            textAlign: 'right'
        };
        const popOverTime = {
            fontSize: '13px',
            padding: '0 0 4px 0',
            margin: '-12px 0 8px 0',
            lineHeight: '16px'
        };
        const popOverPStyle = {
            fontSize: '13px',
            padding: '0 0 4px 0',
            margin: '0',
            lineHeight: '16px'
        };

        return (
            <div className="section section-tracker-list col-1-1">
                <div className="contain-box-wrap" style={{maxWidth:'690px', marginTop: '20px'}}>
                    <div className="contain-box-main" >

                        <h1 className="page-title contain-box-title">
                            <FormattedMessage id="happiness_list_title" defaultMessage="Your Recent Happiness" />
                        </h1>
                        <div className="tracker-list-data">
                            <div className="tracker-episode header">
                                <div className="tracker-row">
                                     <div className="tracker-entry tracker-entry-date-time">
                                         <FormattedMessage id="happiness_data_header_date" defaultMessage="Date/Time" />
                                     </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                            {orderEntries.map((fullTrackerEntry, index) => {
                                const trackerEntry = _.get(fullTrackerEntry, 'data', {});
                                const dateUpdated = new Date(fullTrackerEntry.dt_u),
                                    isOpen = openEntries.indexOf(index) !== -1,
                                    accordionButton = 'accordion-toggle' + (isOpen ? ' open' : ''),
                                    accordionButtonIcon = (isOpen ? faChevronCircleDown : faChevronCircleRight);

                                return _.isEmpty(trackerEntry) ? null : (
                                    <div className="tracker-episode happiness-tracker" key={'happiness_' + index}>
                                        <div className="tracker-row">
                                            <Button className={accordionButton} flat={true} onClick={toggleEntry.bind(locus, index)} style={{float:"left"}}>
                                                <FontAwesomeIcon icon={accordionButtonIcon} />
                                            </Button>
                                            <div className="tracker-entry tracker-entry-date-time">
                                                <div className="tracker-date px-4" style={{display:"inline-block"}}>
                                                    <FormattedDate
                                                        value={dateUpdated}
                                                        day="numeric"
                                                        month="short"
                                                        year="numeric" />
                                                </div>
                                                <div className="tracker-time px-4" style={{display:"inline-block"}}>
                                                    <FormattedTime
                                                        value={dateUpdated}
                                                        hour="numeric"
                                                        minute="numeric" />
                                                </div>
                                            </div>
                                            <div className="clearfix" />
                                            <Accordion collapsed={!isOpen} className="tracker-entry-block">
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="happiness_tracker_entry_date_label"
                                                                defaultMessage="Date" />
                                                        </span>
                                                        <FormattedDate
                                                            value={dateUpdated}
                                                            day="numeric"
                                                            month="short"
                                                            year="numeric" />&nbsp;&nbsp;
                                                        <FormattedTime
                                                            value={dateUpdated}
                                                            hour="numeric"
                                                            minute="numeric" />
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="happiness_tracker_entry_abstinence_label"
                                                                defaultMessage="Abstinence/Sobriety"/>
                                                        </span>
                                                        {trackerEntry.abstinence}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="happiness_tracker_entry_professional_label"
                                                                defaultMessage="Job Or Education Progress"/>
                                                        </span>
                                                        {trackerEntry.professional}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="happiness_tracker_entry_money_label"
                                                                defaultMessage="Money Management"/>
                                                        </span>
                                                        {trackerEntry.money}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="happiness_tracker_entry_social_label"
                                                                defaultMessage="Social Life"/>
                                                        </span>
                                                        {trackerEntry.social}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="happiness_tracker_entry_personal_label"
                                                                defaultMessage="Personal Life"/>
                                                        </span>
                                                        {trackerEntry.personal}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="happiness_tracker_entry_family_label"
                                                                defaultMessage="Marriage/Family Relationships"/>
                                                        </span>
                                                        {trackerEntry.family}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="happiness_tracker_entry_legal_label"
                                                                defaultMessage="Legal Issues"/>
                                                        </span>
                                                        {trackerEntry.legal}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="happiness_tracker_entry_emotional_label"
                                                                defaultMessage="Emotional Life"/>
                                                        </span>
                                                        {trackerEntry.emotional}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="happiness_tracker_entry_communication_label"
                                                                defaultMessage="Communication"/>
                                                        </span>
                                                        {trackerEntry.communication}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="happiness_tracker_entry_health_label"
                                                                defaultMessage="Eating, Sleeping, Or Exercise"/>
                                                        </span>
                                                        {trackerEntry.health}
                                                    </p>
                                            </Accordion>

                                            <div className="clearfix" />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});
