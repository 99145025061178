import React, {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {Modal, InputGroup, Form, ListGroup} from 'react-bootstrap';
import {connect} from 'react-redux';
import Actions from '../../actions';
import BSButton from '../blocks/BSButton.component.web';
import {Group} from '../../types';
import {FontAwesome} from '@expo/vector-icons';
interface Props {
    open: boolean;
    close: () => void;
    group?: Group;
    groups?: Group[];
    setGroup: Dispatch<SetStateAction<Group|undefined>>;
}

function GroupSelectModal({ setGroup, group, groups, open, close }: Props) {
    const [q, setQ] = useState('');

    useEffect(() => {
        if (!open) setQ('');
    }, [ open ])

    const filteredGroups = useMemo(() => {
        if (!q.length || q.length < 2) return (groups || []).slice(0, 10);
        return (groups || []).filter(({ name }) => {
            return !!name?.length && name.toLowerCase()?.includes(q.toLowerCase());
        }).slice(0, 20)
    }, [ groups, q ])

    const onSelect = useCallback((group: Group) => (e: any) => {
        e.preventDefault();
        setGroup(group);
        close();
    }, [ setGroup, close ])

    return (
        <Modal
            show={open}
            onHide={close}
            backdrop="static"
            keyboard={true}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Select a Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesome name="search" size={20} color="#000" />
                    </InputGroup.Text>
                    <Form.Control
                        value={q}
                        onChange={e => setQ(e.target.value)}
                        placeholder="Search groups..."
                        aria-label="Group"
                        aria-describedby="basic-addon1"
                    />
                </InputGroup>
                <ListGroup onChange={console.log} defaultActiveKey={group?._id}>
                    {
                        filteredGroups.map(x => (
                            <ListGroup.Item key={x._id} action href={x._id} onClick={onSelect(x)}>
                                { x.name }
                            </ListGroup.Item>
                        ))
                    }
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <BSButton variant="link-green" onClick={close} label="Close" />
            </Modal.Footer>
        </Modal>
    )
}

export default connect(state => ({
    user: state.auth.user
}), {
    notify: Actions.notify
})(GroupSelectModal)
