'use strict';

import {
    REQUEST_GET_TERMS
} from 'actions/types';

export function reducer(state = {
    isFetching: false
}, action = { type: '' }) {
    switch (action.type) {
        case REQUEST_GET_TERMS:
            return {
                ...state,
                isFetching: true
            };
        default:
            return state;
    }
}