import React, {forwardRef, useEffect, useRef, useState} from 'react';
import { Field } from 'redux-form';
import Moment from 'moment';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { FontAwesome } from '@expo/vector-icons';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'


type InputProps = {
    input: any;
    meta: any;
    onChange: (date: Date) => void;
    outputFormat?: string;
    label?: string;
    labelClassName?: string;
}

const Input = ({ input, meta, outputFormat, ...props }: InputProps) => {
    const [date, setDate] = useState(Moment());
    const ref = useRef();

    useEffect(() => {
        if (!input.value || !date) return;
        const valMoment = Moment(input.value, 'hh:mm A'),
            dateMoment = Moment(date);
        if (valMoment.format('hh:mm A') !== dateMoment.format('hh:mm A'))
            setDate(valMoment.toDate())
    }, [ input.value ])

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <input style={{ textAlign: 'right' }} className="form-control" value={value} onClick={onClick} ref={ref} />
    ));
    const renderDatePicker = (startDate, setStartDate) => {
        return <TimePicker
            label={props.label}
            defaultValue={startDate}
            className="form-control"
            onChange={(date) => {
                setStartDate(date);
                input.onChange(date.format(outputFormat || 'h:mm A'));
            }}
          />
      };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className="input-group mb-3 input-group-fancy">
                <div className='flex-grow-1'>
                    {renderDatePicker(date, setDate)}
                </div>
                <button className="btn" type="button" id="button-addon2" onClick={() => ref?.current?.setOpen(true)}>
                    <FontAwesome name="clock-o" size={22} color="#a2aeb9" />
                </button>
            </div>
        </LocalizationProvider>
    )
}

type Props = {
    name: string;
    outputFormat?: string;
    label?: string;
    labelClassName?: string;
}

const TimePickerReduxForm = (props: Props) => {
    return (
        <Field
            component={Input}
            { ...props }
        />
    )
}

export default TimePickerReduxForm;
