/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import _ from 'lodash';
import React from 'react';
import Link from 'components/ux/linker';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Selectors from 'selectors';
const { userNormsSelector, userProfileSelector } = Selectors;

import { Block } from 'components/blocks/block';
import Actions from 'actions';
import Button from 'components/ux/mdl/button';
import InfoBug from 'components/popover/infobug';
import { faPrint, faAdjust, faBolt, faWineGlassAlt as faGlass, faThList, faComment as faCommentingO, faBell as faBellO, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import BSButton from '../components/blocks/BSButton.component.web';
import { MaterialCommunityIcons, FontAwesome } from '@expo/vector-icons';
import { BLACK } from '../stylesheets/colors';
import TrackerNav from '../components/blocks/TrackerNav.component.web';

export const DrinkTrackerFeedbackPage = React.createClass({

    renderTrackerNavButtons() {
        const { auth: { user }, tools} = this.props;
        const toolIds = _.get(user, 'tool_ids', []);
        var userTools = _.map(toolIds, (toolId) => {
            return _.get(tools, toolId);
        });
        _.remove(userTools, (tool) => {
            if(_.get(tool, 'slug')) {
                return (tool.slug.indexOf('checkup') > -1 || tool.slug.indexOf('precheck') > -1);
            }
        });
        if(userTools.length === 0) {
            return null;
        }
        return <TrackerNav />
    },

    showRemindersModal() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'newReminder',
            edit_reminder: {},
            modalContent: true
        }));
    },

    showHelpFeedbackModal() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'helpFeedback',
            modalContent: true
        }));
    },


    render() {


        const charts = [
            {
                "type" : "informational_text",
                "default_responses" : {},
                "components" : [ 
                    {
                        "type" : "p",
                        "children" : [ 
                            {
                                "type" : "formatted_message",
                                "text" : {
                                    "id" : "drink_feedback_p0",
                                    "defaultMessage" : "Here is your feedback on your tracked drinking."
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "stat_group" : "drink_day",
                "title" : {
                    "defaultMessage" : "Drinks Per Day",
                    "id" : "moderate_drink_day_chart_title"
                },
                "lines" : [ 
                    {
                        "response_key" : "drinks_regular_limit",
                        "label" : {
                            "defaultMessage" : "Your Regular Limit",
                            "id" : "moderate_drink_day_std_drink_count_drinks_regular_limit_label"
                        }
                    }, 
                    {
                        "response_key" : "drinks_absolute_limit",
                        "label" : {
                            "defaultMessage" : "Your Absolute Limit",
                            "id" : "moderate_drink_day_std_drink_count_drinks_absolute_limit_label"
                        }
                    }
                ],
                "period" : 1,
                "metrics" : [ 
                    {
                        "key" : "std_drink_count",
                        "label" : {
                            "defaultMessage" : "Drinks Per Day",
                            "id" : "moderate_drink_day_chart_label"
                        }
                    }
                ],
                "type" : "chart"
            },
            {
                "stat_group" : "drink_week",
                "title" : {
                    "defaultMessage" : "Drinking Days Per Week",
                    "id" : "moderate_drink_week_day_count_chart_title"
                },
                "lines" : [ 
                    {
                        "response_key" : "drinking_days_limit",
                        "label" : {
                            "defaultMessage" : "Your Limit",
                            "id" : "moderate_drink_week_day_count_drinking_days_limit_label"
                        }
                    }
                ],
                "period" : 7,
                "metrics" : [ 
                    {
                        "key" : "day_count",
                        "label" : {
                            "defaultMessage" : "Drinking Days Per Week",
                            "id" : "moderate_drink_week_day_count_chart_label"
                        }
                    }
                ],
                "type" : "chart"
            },
            {
                "stat_group" : "peak_bac_day",
                "title" : {
                    "defaultMessage" : "Peak BAC Per Day",
                    "id" : "moderate_peak_bac_day_chart_title"
                },
                "lines" : [ 
                    {
                        "response_key" : "peak_bac_regular_limit",
                        "label" : {
                            "defaultMessage" : "Your Regular Limit",
                            "id" : "moderate_peak_bac_day_peak_bac_peak_bac_regular_limit_label"
                        }
                    }, 
                    {
                        "response_key" : "peak_bac_absolute_limit",
                        "label" : {
                            "defaultMessage" : "Your Absolute Limit",
                            "id" : "moderate_peak_bac_day_peak_bac_peak_bac_absolute_limit_label"
                        }
                    }
                ],
                "period" : 1,
                "metrics" : [ 
                    {
                        "key" : "peak_bac",
                        "label" : {
                            "defaultMessage" : "Peak BAC Per Day",
                            "id" : "moderate_peak_bac_day_chart_label"
                        }
                    }
                ],
                "type" : "chart"
            },
            {
                "stat_group" : "hours_intoxicated_week",
                "title" : {
                    "defaultMessage" : "Hours Intoxicated Per Week",
                    "id" : "moderate_hours_intoxicated_week_chart_title"
                },
                "lines" : [],
                "period" : 7,
                "metrics" : [ 
                    {
                        "key" : "hours",
                        "label" : {
                            "defaultMessage" : "Hours Intoxicated Per Week",
                            "id" : "moderate_hours_intoxicated_week_chart_label"
                        }
                    }
                ],
                "type" : "chart"
            }
        ];
        
        // get the moderate tool, so we can get the moderate responses
        let moderate_tool = false;
        let moderate_response = false;
        for(const key in this.props.tools) {
            if(this.props.tools[key].slug == 'moderate') {
                moderate_tool = this.props.tools[key];
            }
        }
        if(moderate_tool) {
            for(const key in this.props.responses) {
                if(_.get(this.props.responses[key], 'response.tool_id') == moderate_tool._id) {
                    moderate_response = this.props.responses[key].response;
                }
            }
        }

        return (
          <div className="container">
              <div className="row">
                  <div className="col-12 col-lg-8 offset-lg-2">
                      <div className="card my-4" style={{ border: 'none' }}>
                          <div className="card-body">
                              { this.renderTrackerNavButtons() }
                              <div className="d-flex align-items-center my-2">
                                  <BSButton variant="flat-blue" linkTo="/drinks">
                                      <MaterialCommunityIcons name="arrow-left" size={24} color={BLACK} />
                                  </BSButton>
                                  <h3 className="ms-2 card-title mb-0">
                                      <FormattedMessage
                                        id='drink-feedback-page-title'
                                        defaultMessage='Drink Feedback'
                                      />
                                  </h3>
                              </div>
                              <p>
                                  <em>Instructions:</em> You can focus on a period of time in the history by zooming in (or out) with your scroll wheel or pinching/zooming on mobile. When you're zoomed, you can drag left or right to change the period of time being focused upon.
                              </p>
                              {
                                  charts.map((component, index) => {
                                      return (
                                        <Block
                                          key={'block_' + index}
                                          component={component}
                                          index={index}
                                          norms={this.props.norms}
                                          params={{toolSlug: 'moderate'}}
                                          responses={_.get(moderate_response, 'responses', {})}
                                          userProfile={this.props.userProfile}
                                          trackerEntryStats={this.props.trackerEntryStats}
                                        />
                                      );
                                  })
                              }
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        );
    }
});

export default connect(
    state => ({
        auth: state.auth,
        activeResponseId: state.responses.activeResponseId,
        activeResponse: _.get(state.responses[state.responses.activeResponseId], 'response'),
        responses: state.responses,
        plans: state.plans.plans,
        tools: state.tools,
        trackerEntryStats: state.trackerEntryStats,
        ...userNormsSelector(state),
        ...userProfileSelector(state)
    })
)(DrinkTrackerFeedbackPage);
