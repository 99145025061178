'use strict';

import _ from 'lodash';

import { reducer as originalReducer } from 'redux-form';

import { sumValues } from 'utils/formulas';

import {
    REQUEST_LOG_IN_USER_SUCCESS,
    REQUEST_LOG_IN_USER_FAILURE,
    REQUEST_CHANGE_PASSWORD_SUCCESS,
    REQUEST_CHANGE_PASSWORD_FAILURE
} from 'actions/types';
import { combineReducers } from 'redux';

let reducer = originalReducer.plugin({
    login: (state, action) => {
        switch (action.type) {
            case REQUEST_LOG_IN_USER_SUCCESS:
            case REQUEST_LOG_IN_USER_FAILURE:
                return {
                    ...state,
                    password: {}
                };
            default:
                return state;
        }
    },
    changePassword: (state, action) => {
        switch (action.type) {
            case REQUEST_CHANGE_PASSWORD_SUCCESS:
            case REQUEST_CHANGE_PASSWORD_FAILURE:
                return {
                    ...state,
                    password: {},
                    newPassword: {}
                };
            default:
                return state;
        }
    }
});

// Todo: This doesn't work and I'm not sure what it is meant to do.
if(false){
reducer = reducer.normalize({
    drinkTracker: {
        std_drink_count: (value, previousValue, allValues) => {
            return value && parseFloat(value);
        }
    },

    happinessTracker: {
        average: (value, previousValue, allValues) => {
            const values = _.pick(allValues, (value, key) => {
                return [
                    'abstinence',
                    'professional',
                    'money',
                    'social',
                    'personal',
                    'family',
                    'legal',
                    'emotional',
                    'communication',
                    'health'
                ].indexOf(key) !== -1;
            });
            return (sumValues(values) / Object.keys(values).length).toString();
        }
    },
    moodTracker: {
        positive_score: (value, previousValue, allValues) => {
            const positiveValues = _.pick(allValues, (value, key) => {
                return [
                        'interested',
                        'excited',
                        'strong',
                        'enthusiastic',
                        'proud',
                        'alert',
                        'inspired',
                        'determined',
                        'attentive',
                        'active'
                    ].indexOf(key) !== -1;
            });
            return sumValues(positiveValues).toString();
        },
        negative_score: (value, previousValue, allValues) => {
            const negativeValues = _.pick(allValues, (value, key) => {
                return [
                        'distressed',
                        'upset',
                        'guilty',
                        'scared',
                        'hostile',
                        'irritable',
                        'ashamed',
                        'nervous',
                        'jittery',
                        'afraid'
                    ].indexOf(key) !== -1;
            });
            return sumValues(negativeValues).toString();
        }
    },
    urgeTracker: {
        duration: (value, previousValue, allValues) => {
            return value && parseInt(value, 10);
        },
        intensity: (value, previousValue, allValues) => {
            return value && parseInt(value, 10);
        }
    },
    userProfile: {
        weight: (value, previousValue, allValues) => {
            return value && parseInt(value, 10);
        }
    }
});
}
export { reducer };