'use strict';

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { REQUEST_LOG_IN_USER_SUCCESS } from 'actions/types';
import { formValueSelector } from 'redux-form';

import Actions from 'actions';
import notify from 'actions/notify';
import Login from 'components/forms/login';
import TwoFactor from 'components/forms/two-factor';
import SSO from 'components/forms/sso';
import Cookies from 'cookies-js';

const LoginPage = React.createClass({

    propTypes: {
        auth: React.PropTypes.object.isRequired,
        dispatch: React.PropTypes.func.isRequired,
        messages: React.PropTypes.object.isRequired,
    },

    getInitialState() {
        return {
            two_factor_auth: false,
            user_id: '',
            login_data: null,
            loading: false,
        };
    },

    toggleLoading(loading) {
        this.setState({ loading });
    },

    onLogIn(data) {
        this.setState({ login_data: data })
        if(this.props.remember_me) {
            Cookies.set('rememberMe', data.email, { expires: 21 * 24 * 60 * 60 });
        }
        this.props.dispatch(Actions.logInUser(data.email, data.password))
        .then((result) => {
            if (result.type === REQUEST_LOG_IN_USER_SUCCESS){
                if(_.get(result.payload, 'user.two_factor_auth')){
                    this.setState({two_factor_auth: true});
                    this.setState({user_id: _.get(result.payload, 'user._id')});
                } else {
                    // check if we have a redirect url parameter
                    if(_.get(this.props.location.query, 'redirect')) {
                        let extraQueryArgs = '';
                        for(const key in this.props.location.query) {
                            if(key !== 'redirect') {
                                extraQueryArgs += '&' + key + '=' + this.props.location.query[key];
                            }
                        }
                        // if we are coming from a protected page, redirect back to that page
                        browserHistory.push(_.get(this.props.location.query, 'redirect') + extraQueryArgs);
                    } else {
                        const { admin, system } = result.payload?.user?.roles || {},
                            isAdmin = !!admin?.length || (!!system?.length && system.includes('admin'));
                        if (isAdmin) browserHistory.push('/admin');
                        // otherwise, send to the dashboard
                        else browserHistory.push('/');
                    }
                }
            }
            else {
                const msg = result.payload?.message || 'An error occurred!';
                this.props.dispatch(Actions.notify({
                    title: "Error",
                    message: msg,
                    level: 'error',
                    autoDismiss: 4
                }))
            }
        });
    },

    onTwoFactorLogIn(data) {
        this.props.dispatch(Actions.twoFactorLogIn(this.state.user_id, data.token2FA))
        .then((result) => {
            if (result.type === REQUEST_LOG_IN_USER_SUCCESS){
                // check if we have a redirect url parameter
                if(_.get(this.props.location.query, 'redirect')) {
                    // if we are coming from a protected page, redirect back to that page
                    browserHistory.push(_.get(this.props.location.query, 'redirect'));
                } else {
                    // otherwise, send to the dashboard
                    browserHistory.push('/');
                }
            }
        });
    },

    componentDidMount() {
        const { redirect, sessionKey } = this.props.location?.query ?? {};

      if (Cookies.get('shown_coupon')) {
          Cookies.expire('shown_coupon');
      }
      //check for SSO
      if(!!sessionKey?.length) {
          this.toggleLoading(true);
        this.props.dispatch(Actions.updateDisplay('appSpinner', 'LOGGING_YOU_IN'));
        this.props.dispatch(Actions.ssoLogInUser(sessionKey))
        .then((result) => {
            this.toggleLoading(false);
            if (result.type === REQUEST_LOG_IN_USER_SUCCESS){
              this.props.dispatch(Actions.updateDisplay('appSpinner', 'STOP'));
              // check if we have a redirect url parameter
              if(!!redirect?.length) {
                // if we are coming from a protected page, redirect back to that page
                browserHistory.push(redirect);
              } else {
                // otherwise, send to the dashboard
                browserHistory.push('/');
              }
            } else {
                this.props.dispatch(Actions.updateDisplay('appSpinner', 'STOP'));
                this.props.dispatch(notify({
                    title: "Sorry",
                    message: "We could not log you in.",
                    level: 'error',
                    autoDismiss: 4
            }));
            }
        }).catch(() => {
            this.toggleLoading(false);
        })
      }
    },

    onClearLogOutMessage(){
        this.props.dispatch(Actions.dismissMessage('login'));
    },

    loginAgain() {
        this.onLogIn(this.state.login_data);
    },

    render() {
        const { require_sso, sso_link, code } = CUAC_SETTINGS?.GROUP ?? {};

        if(this.state.two_factor_auth) {
            const { auth, messages } = this.props;
            const isFetching = _.get(auth, 'isFetching');
            const message = _.get(messages, 'login');
            return (
                <TwoFactor
                    onSubmit={this.onTwoFactorLogIn}
                    onClearLogOutMessage={this.onClearLogOutMessage}
                    isFetching={isFetching}
                    loginAgain={this.loginAgain}
                    message={message} />
                );             
        }
        else if(!!require_sso) {
            const ssoLink = !!sso_link?.length ? sso_link : "https://api.checkupandchoices.com/sso?sso2&groupCode=" + code;
            return (
                <SSO loading={this.state.loading} ssoLink={ssoLink}/>
            );
        } else {
            const { auth, messages } = this.props;
            const isFetching = _.get(auth, 'isFetching');
            const message = _.get(messages, 'login');
            return (
                <Login
                    onSubmit={this.onLogIn}
                    onClearLogOutMessage={this.onClearLogOutMessage}
                    isFetching={isFetching}
                    message={message} />
            );
        }
    }
});

const selector = formValueSelector('login') // <-- same as form name
export default connect(
    state => ({
        auth: state.auth,
        messages: state.messages,
        remember_me: selector(state, 'remember_me')
    })
)(LoginPage);
