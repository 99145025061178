'use strict';

import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { Form } from 'react-bootstrap';
import {LIGHT_GRAY} from "../../stylesheets/colors";

const TableInputCell = props => {
    const [value, setValue] = useState(undefined);

    useEffect(() => {
        if (props.value !== undefined && value === undefined) setValue(props.value);
    }, [ props.value ]);

    return (
        <td className="item p-1" key={props.id}>
            <Form.Control
                onChange={e => {
                    let value = e.target.value;
                    if (typeof value === 'string' && value.length) value = parseFloat(value);
                    setValue(value);
                    props.updateResponse({ [props.responseKey]: value });
                }}
                id={props.id}
                type="number"
                value={value || ''}
                onFocus={() => {
                    document.getElementById(props.id).select();
                }}
                style={{ width: 70, border: 'none', backgroundColor: 'white' }}
                placeholder="0"
            />
        </td>
    )
}

const NumberTableForm = React.createClass({
    propTypes: {
        columnHeaders: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
        rowHeaders: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
        updateResponse: React.PropTypes.func.isRequired,
        intl: React.PropTypes.object.isRequired
    },

    renderHeaders() {
        const { columnHeaders } = this.props;
        const headers = columnHeaders.map((columnHeader) => {
            return (
                <th className="header" key={columnHeader.id}>
                    <FormattedMessage {...columnHeader} />
                </th>
            );
        });
        return (
            <tr className={"header-row count-" + (columnHeaders.length+1)} >
                <td key="blank"></td>
                {headers}
            </tr>
        );
    },

    renderBody() {
        const { columnHeaders, form, rowHeaders, updateResponse, intl: { formatMessage } } = this.props;
        const headers = [];
        columnHeaders.forEach( (header) => headers.push(header.id) );
        return rowHeaders.map((rowHeader, index) => {
            const rowData = columnHeaders.map((columnHeader, j) => {
                const responseKey = rowHeader.id + '_' + columnHeader.id,
                    { [responseKey]: val } = this.props.responses || {};
                const id = `${form ? `${form}_` : ''}` + rowHeader.id + '_' + columnHeader.id;
                return (
                    <TableInputCell
                        key={id}
                        id={id}
                        responseKey={responseKey}
                        value={val}
                        updateResponse={updateResponse}
                    />
                )
            });

            return (
                <tr className={"count-" + (headers.length+1) } key={form + '_' + rowHeader.id + '_' + index}>
                    <td className="p-1">
                        <FormattedMessage {...rowHeader} />
                    </td>
                    {rowData}
                </tr>
            );
        });

    },

    render() {
        const { formName } = this.props;
        let styles = {};
        if((formName || '').indexOf("_your_drinking_heavy_times") > -1) {
            styles = {margin: '0 auto', display: 'block', maxWidth: '450px'};
        }
        return (
            <div className="p-2">
                <form className="number-table" style={styles}>
                    <div className="table-responsive-md">
                        <table className="table table-borderless">
                            <thead>
                            {this.renderHeaders()}
                            </thead>
                            <tbody>
                            {this.renderBody()}
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        );
    }
});

export default injectIntl(NumberTableForm);
