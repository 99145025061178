import React, {useRef, useState} from 'react';
import { AntDesign } from '@expo/vector-icons';
import { Field } from 'redux-form';
import {StyleProp} from "react-native";
import {LIGHTER_FOREST_GREEN} from "../../stylesheets/colors";

type Props = {
    name?: string;
    placeholder?: string;
    style?: StyleProp<any>;
    inputGroupClassName?: string;
    onChange?: (val: number) => void;
    value?: number;
    step?: number|string;
    min?: number|string;
    max?: number|string;
    labelClassName?: string;
    meta?: {
        error?: string;
    };
    input?: {
        value?: string;
        onChange: (val: number) => void;
    };
    label?: string | React.ReactNode;
    currency?: boolean;
}

export const NumberInput = (props: Props) => {
    const inputRef = useRef();
    const [wasVisited, setWasVisited] = useState(false);

    const onChange = (val: number) => {
        if (props.onChange) props.onChange(val);
        else if (props.input) props.input.onChange(val);
    }

    const min = () => {
        if (typeof props.min === 'string' || typeof props.min === 'number')
            return parseFloat(props.min)
        else 
            return 0;
    }

    const max = () => {
        if (typeof props.max === 'string' || typeof props.max === 'number')
            return parseFloat(props.max);
    }

    const change = (delta: number) => {
        const value = props.value || props.input?.value,
            parsedVal = typeof value === 'number' || (typeof value === 'string' && value.length) ? parseFloat(value) : 0,
            newVal = Math.round((parsedVal + delta) * 100) / 100;
        setWasVisited(true);
        const parsedMin = min(),
            parsedMex = max();
        if (typeof parsedMin === 'number' && newVal < parsedMin) return;
        if (typeof parsedMex === 'number' && newVal > parsedMex) return;
        onChange(props.currency ? newVal.toFixed(2) : newVal);
    }

    const step = () => {
        if (typeof props.step === 'number' || typeof props.step === 'string') return parseFloat(props.step);
        return 1;
    }

    return (
        <div className={`input-group ${props.inputGroupClassName || ''} ${(wasVisited && props.meta?.error ? ' has-validation ': '')}`}>
            {
                props.label && (
                    <div
                        className={`d-flex flex-column align-items-start justify-content-center ${props.labelClassName || 'input-group-text'}`}
                        onClick={() => inputRef?.current?.focus()}
                    >
                        { props.label }
                        {
                            wasVisited && props.meta?.error && (
                                <small className="text-danger">{props.meta.error}</small>
                            )
                        }
                    </div>
                )
            }
            <div className="d-flex align-items-center">
                <button
                    className="btn"
                    type="button"
                    id="button-addon1"
                    onClick={() => change(step() * -1)}
                >
                    <AntDesign name="minus" size={24} color="#0E323E" />
                </button>
                {
                    props.currency && (
                        <span
                            style={{ backgroundColor: LIGHTER_FOREST_GREEN, border: 'none' }}
                            className="input-group-text"
                            id="dollar-sign-addon"
                        >
                            $
                        </span>
                    )
                }
                <input
                    ref={inputRef}
                    type="number"
                    className="form-control flex-grow-0"
                    placeholder={props.placeholder}
                    value={'value' in props ? props.value : props.input?.value}
                    onChange={e => {
                        const val = e.target.value;
                        const parsedVal = typeof val === 'number' || (typeof val === 'string' && val.length) ? parseFloat(val) : null;
                        setWasVisited(true);
                        onChange(parsedVal)
                    }}
                    style={props.style || {}}
                />
                <button
                    className="btn"
                    type="button"
                    id="button-addon2"
                    onClick={() => change(step())}
                >
                    <AntDesign name="plus" size={24} color="#0E323E" />
                </button>
            </div>
        </div>
    )
}

const NumericInput = (props) => {
    return (
        <Field
            component={NumberInput}
            { ...props }
        />
    )
}

export default NumericInput;
