/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TrackerTypes } from 'api/tracker-entries';
import Actions from 'actions';
import { connect } from 'react-redux';
import Paginator from 'lib/Paginator';
import Moment from 'moment'; Moment.locale('en');
import momentTZ from 'lib/moment-timezone-with-data';
import { itemsPerPage } from 'utils/pagination';
import { Dropdown } from 'react-bootstrap';
import { MaterialCommunityIcons, FontAwesome, Entypo } from '@expo/vector-icons';
import BSButton from '../blocks/BSButton.component.web';
import { FOREST_GREEN } from '../../stylesheets/colors';
import { browserHistory } from 'react-router';
import TrackerNav from '../blocks/TrackerNav.component.web';
import TrackerEntryTable from '../blocks/TrackerEntryTable.component.web';
import ConfirmModal from "../blocks/ConfirmModal.component.web";

const COLUMNS = [
    { label: 'Date/Time', key: 'date' },
    { label: 'Type', key: 'type' },
    { label: '# Std. Drinks', key: 'std_drink_count' },
    { label: 'With', key: 'with' },
    { label: 'Location', key: 'location', collapse: true },
    { label: 'Mood', key: 'mood', collapse: true },
    { label: '', key: 'actions' },
];

const DrinkList = React.createClass({
    propTypes: {
        tag: React.PropTypes.string,
        dispatch: React.PropTypes.func.isRequired,
        trackerEntries: React.PropTypes.object.isRequired
    },

    getInitialState() {
        return {
            showConfirmModal: false,
            onConfirmDelete: undefined,
            displayedEntries: {
                start: 0,
                end: 5
            }
        };
    },

    deleteDrinkTrackerEntry(trackerEntryId, currentPage, totalEntries) {
        this.props.dispatch(Actions.updateDisplay('appSpinner', 'PLEASE_WAIT'));
        const { auth, dispatch } = this.props;
        const token = _.get(auth, 'user.token');
        const userId = _.get(auth, 'user._id');
        dispatch(Actions.deleteTrackerEntry(token, userId, trackerEntryId, TrackerTypes.DRINK))
        .then(() => {
            this.updateCurrentPage(currentPage, totalEntries);
            dispatch(Actions.updateDisplay('appSpinner', 'STOP'));
            this.setState({ showConfirmModal: false, onConfirmDelete: undefined });
        });
    },

    updateCurrentPage(currentPage, totalEntries) {
        if(currentPage.start >= itemsPerPage && (totalEntries - 1) === 0) {
            this.setState({
                displayedEntries: {
                    start: this.state.displayedEntries.start - itemsPerPage,
                    end: this.state.displayedEntries.end - itemsPerPage
                }
            });
        }
    },

    onChangePage(page) {
        var end = page * itemsPerPage;
        var start = end - itemsPerPage;
        this.setState({
            displayedEntries: {
                start: start,
                end: end
            }
        });
    },

    rowData() {
        const { tag, trackerEntries } = this.props;
        const user_timezone = _.get(this.props.auth, 'user.user_timezone');
        const fullOrder = _.get(trackerEntries, TrackerTypes.DRINK + 'Order', []);
        const fullOrderEntries = _.map(fullOrder, (trackerEntryId) => {
            return _.get(trackerEntries, trackerEntryId, {});
        });
        const filteredEntries = typeof tag !== 'undefined'
          ?
          _.filter(fullOrderEntries, (trackerEntry) => {
              return _.get(trackerEntry, 'tag') === tag;
          })
          : fullOrderEntries;
        const sortedEntries = _.sortByOrder(filteredEntries, ['data.dt_occurred'], ['desc']);
        const page = {start: this.state.displayedEntries.start, end: this.state.displayedEntries.end};
        const orderEntries = sortedEntries.slice(page.start, page.end);
        return orderEntries.map(fullTrackerEntry => {
            const trackerEntry = _.get(fullTrackerEntry, 'data', {});
            var dtOccurred = Moment(trackerEntry.dt_occurred.split('+')[0]).format('YYYY-MM-DD HH:mm:ss');
            dtOccurred = momentTZ.tz(dtOccurred, 'UTC');
            //TODO why doesnt this work?
            var dateOccurred = dtOccurred.clone().tz(user_timezone);
            // console.log('date occurred', dateOccurred)
            // var dateOccurred = dtOccurred.clone();
            var timeOccurred = dateOccurred.format('hh:mm A');
            dateOccurred = dateOccurred.format('MMM DD, YYYY');
            return {
                'date': `${dateOccurred} ${timeOccurred}`,
                'type': trackerEntry.drink_type,
                'std_drink_count': trackerEntry.std_drink_count,
                'with': trackerEntry.with_whom,
                'location': trackerEntry.where,
                'mood': trackerEntry.mood,
                'actions': (
                  <Dropdown>
                      <Dropdown.Toggle variant="light" size="sm">
                          <MaterialCommunityIcons name="dots-vertical" size={24} color="black" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                                browserHistory.push(`/drink-tracker/${fullTrackerEntry._id}`);
                            }}
                            className="d-flex align-items-center"
                          >
                              <FontAwesome name="edit" size={24} color="black" />
                              <span className="ms-2">Edit</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                                this.setState({
                                    showConfirmModal: true,
                                    onConfirmDelete: () =>
                                        this.deleteDrinkTrackerEntry(fullTrackerEntry._id, page, orderEntries.length)
                                })
                            }}
                            className="d-flex align-items-center"
                          >
                              <FontAwesome name="trash" size={24} color="black" />
                              <span className="ms-2">Delete</span>
                          </Dropdown.Item>
                      </Dropdown.Menu>
                  </Dropdown>
                )
            }
        })
    },

    pagination() {
        const { tag, trackerEntries } = this.props;
        const fullOrder = _.get(trackerEntries, TrackerTypes.DRINK + 'Order', []);
        const fullOrderEntries = _.map(fullOrder, (trackerEntryId) => {
            return _.get(trackerEntries, trackerEntryId, {});
        });
        const filteredEntries = typeof tag !== 'undefined'
            ?
            _.filter(fullOrderEntries, (trackerEntry) => {
                return _.get(trackerEntry, 'tag') === tag;
            })
            : fullOrderEntries;
        if (!filteredEntries.length) return null;
        return (
            <Paginator
                className={'pagination'}
                page={this.state.displayedEntries.end / itemsPerPage}
                maxVisible={5}
                max={Math.ceil(fullOrder.length / itemsPerPage)}
                onChange={this.onChangePage}
            />
        )
    },

    render() {
        const confirmMessage = {
            id: "delete_drink_tracker_confirm_instructions",
            description: "Drink Tracker -- Delete entry, confirmation message",
            defaultMessage: "Are you sure you want to delete this tracker entry?"
        };
        return (
            <>
                <div className="container my-4">
                    <div className="col-12 col-lg-8 offset-lg-2">
                        <div className="card" style={{ border: 'none' }}>
                            <div className="card-body">
                                <TrackerNav />
                                <h3 className="card-title mt-2">
                                    <FormattedMessage
                                        id="drink_list_title"
                                        defaultMessage="Your Recent Drinks"
                                    />
                                </h3>
                                <p className="text-muted">
                                    <FormattedMessage
                                        id="drink_list_text"
                                        defaultMessage="Please enter your drinks as they happened and days you didn't drink at all."
                                    />
                                </p>
                                <TrackerEntryTable
                                    columns={COLUMNS}
                                    rows={this.rowData()}
                                >
                                    <div className="w-100 d-flex flex-column-reverse flex-lg-row align-items-lg-center justify-content-between">
                                        <div className="d-flex align-items-center flex-grow-1">
                                            <BSButton variant="green" linkTo="/drink-tracker">
                                                <FontAwesome name="plus" color="white" size={20}/>
                                                <span className="ms-2">Add New</span>
                                            </BSButton>
                                            <BSButton variant="link-green" className="ms-3" linkTo="/drink-feedback">
                                                <Entypo name="bar-graph" size={23} color={FOREST_GREEN} />
                                                <span className="ms-2">Drink Feedback</span>
                                            </BSButton>
                                        </div>
                                        <div className="my-2 my-lg-0">
                                            { this.pagination() }
                                        </div>
                                    </div>
                                </TrackerEntryTable>
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmModal
                    show={this.state.showConfirmModal}
                    onConfirm={() => this.state.onConfirmDelete()}
                    onCancel={() => {
                        this.setState({ showConfirmModal: false, onConfirmDelete: undefined })
                    }}
                    message={
                        <FormattedMessage { ...confirmMessage } />
                    }
                />
            </>
        );
    }
});
export default connect(
    state => ({
        auth: state.auth,
        tools: state.tools
    })
)(DrinkList);
