import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import Actions from '../../actions';
import { connect } from 'react-redux';
import DayPicker from 'react-day-picker';
import DayPartModal from '../modal/daypart-modal';

function isPastDay(d, to) {
   return d.isAfter(to, 'day');
 }

 function isBeforeDay(d, from) {
   return d.isBefore(from, 'day');
 }

function isSameDay(d1, d2) {
   return d1.isSame(d2, 'day');
 }
 
function isBetween(d, d1, d2) {
   return d.isBetween(d1, d2, 'day');
 }

const QuantityFrequency = React.createClass ({

   UNSAFE_componentWillMount() {
      const { responses } = this.props;
      const yesterday = moment(responses.dt_c).startOf('day').subtract(1, 'days');
      const fourWeeksAgo = moment(responses.dt_c).startOf('day').subtract(28, 'days');
      const lastSaturday = moment(responses.dt_c).startOf('day').startOf('week').subtract(1, 'days');
      const oneWeekAgo = moment(responses.dt_c).startOf('day').startOf('week').subtract(7, 'days');
      this.setState({
      markedDates: [],
      yesterday,
      fourWeeksAgo,
      lastSaturday,
      oneWeekAgo
    });
  },

  componentDidMount() {
    this.buildMarkedDays()
  },

  componentDidUpdate(prevProps, prevState) {
    const { stateResponses } = this.props;
    const newResponses = _.get(stateResponses[stateResponses.activeResponseId], 'response.responses');
    const oldResponses = _.get(prevProps.stateResponses[prevProps.stateResponses.activeResponseId], 'response.responses');  
    if(_.size(newResponses) > _.size(oldResponses)) {
      this.buildMarkedDays();
      this.updateTotalDataKeys();
    }
  },


   handleClick(e, day, modifiers) {
      const { updateResponse, isRegularUser } = this.props;
      const { yesterday, lastSaturday} = this.state;
      day = moment(day).startOf('day');
      if(modifiers.indexOf('disabled') === -1) {  
         this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'dayPart',
            modalContent: true,
            updateResponse: updateResponse,
            day: day,
            yesterday: yesterday,
            lastSaturday: lastSaturday,
            isRegularUser: isRegularUser,
         }));
      }
   },


   buildMarkedDays() {
		const { stateResponses, isRegularUser } = this.props;
		const { fourWeeksAgo, oneWeekAgo } = this.state;
		const responses = _.get(stateResponses[stateResponses.activeResponseId], 'response.responses');
		let markedDates = [];
		let compareDate = isRegularUser === 'false' ? oneWeekAgo : fourWeeksAgo
		for(const key in responses) {
			if(key.indexOf('day_') > -1) {
				const dayNumber = key.split('day_')[1].split('_')[0];
				const date = moment(compareDate).add(parseInt(dayNumber) - 1, 'days').format('YYYY-MM-DD')
				const index = markedDates.indexOf(date);
				if(index === -1) {
					markedDates.push(date)
				}
			}
		}
		this.setState({markedDates})
  },


	updateTotalDataKeys() {
   	const { isRegularUser, stateResponses, updateResponse } = this.props;
		const responses = _.get(stateResponses[stateResponses.activeResponseId], 'response.responses');

		let occasionsUsed = 0;
		let morningsUsed = 0;
		let afternoonsUsed = 0;
		let eveningsUsed = 0;
		let nightsUsed = 0;
		let hoursUsed = 0;
		let jointsUsed = 0;
		let pipeUsed = 0;
		let vaporUsed = 0;
		let oralUsed = 0;
		let otherUsed = 0;
		let aBitEffect = 0;
		let moderateEffect = 0;
		let heavyEffect = 0;
		let tooMuchEffect = 0;
		let daysUsed = [];

		for (var key in responses) {
			if(key.indexOf('day_') > -1) {
				const dayNumber = key.split('day_')[1].split('_')[0];
				const index = daysUsed.indexOf(dayNumber);
				if(index === -1) {
					daysUsed.push(dayNumber)
				}
			}

			if (key.includes('morning_hours_affected')) {
				morningsUsed += 1;
			}
			if (key.includes('afternoon_hours_affected')) {
				afternoonsUsed += 1;
			}
			if (key.includes('evening_hours_affected')) {
				eveningsUsed += 1;
			}
			if (key.includes('night_hours_affected')) {
				nightsUsed += 1;
			}
			if (key.includes('_form')) {
				if (responses[key] == 'pipe') {
					pipeUsed += 1;
				}
				if (responses[key] == 'joint') {
					jointsUsed += 1;
				}
				if (responses[key] == 'vapor') {
					vaporUsed += 1;
				}
				if (responses[key] == 'oral') {
					oralUsed += 1;
				}
				if (responses[key] == 'other') {
					otherUsed += 1;
				}
			}
			if (key.includes('_quantity')) {
				if (responses[key] == 'a_bit') {
					aBitEffect += 1;
				}
				if (responses[key] == 'moderate') {
					moderateEffect += 1;
				}
				if (responses[key] == 'heavy') {
					heavyEffect += 1;
				}
				if (responses[key] == 'too_much') {
					tooMuchEffect += 1;
				}
			}
   
			if (key.includes('morning_hours_affected')) {
				morningsUsed += 1;
			}
			if (key.includes('afternoon_hours_affected')) {
				afternoonsUsed += 1;
			}
			if (key.includes('evening_hours_affected')) {
				eveningsUsed += 1;
			}
			if (key.includes('night_hours_affected')) {
				nightsUsed += 1;
			}
			if (key.includes('_form')) {
				if (responses[key] == 'pipe') {
					pipeUsed += 1;
				}
				if (responses[key] == 'joint') {
					jointsUsed += 1;
				}
				if (responses[key] == 'vapor') {
					vaporUsed += 1;
				}
				if (responses[key] == 'oral') {
					oralUsed += 1;
				}
				if (responses[key] == 'other') {
					otherUsed += 1;
				}
			}
			if (key.includes('quantity')) {
				if (responses[key] == 'a_bit') {
					aBitEffect += 1;
				}
				if (responses[key] == 'moderate') {
					moderateEffect += 1;
				}
				if (responses[key] == 'heavy') {
					heavyEffect += 1;
				}
				if (responses[key] == 'too_much') {
					tooMuchEffect += 1;
				}
			}
			if (key.includes('hours_affected')) {
				hoursUsed += parseInt(responses[key]);
				occasionsUsed += 1;
			}
   	}

	let mostUsedForm;
	let mostUsedFormCount = 0;

	if (jointsUsed > mostUsedFormCount) {
		mostUsedForm = 'joint';
		mostUsedFormCount = jointsUsed;
	}

	if (pipeUsed > mostUsedFormCount) {
		mostUsedForm = 'pipe';
		mostUsedFormCount = pipeUsed;
	}

	if (oralUsed  > mostUsedFormCount) {
		mostUsedForm = 'oral';
		mostUsedFormCount = oralUsed;
	}

	if (vaporUsed > mostUsedFormCount) {
		mostUsedForm = 'vapor';
		mostUsedFormCount = vaporUsed;
	}

	if (otherUsed > mostUsedFormCount) {
		mostUsedForm = 'other';
		mostUsedFormCount = otherUsed;
	}

   let mostUsedTime;
   let mostUsedTimeCount = 0;

	if (morningsUsed > mostUsedTimeCount) {
		mostUsedTime = 'morning';
			mostUsedTimeCount = morningsUsed;
	}

	if (afternoonsUsed > mostUsedTimeCount) {
		mostUsedTime = 'afternoon';
		mostUsedTimeCount = afternoonsUsed;
	}

	if (eveningsUsed  > mostUsedTimeCount) {
		mostUsedTime = 'evening';
		mostUsedTimeCount = eveningsUsed;
	}

	if (nightsUsed > mostUsedTimeCount) {
		mostUsedTime = 'night';
		mostUsedTimeCount = nightsUsed;
	}

   let multiplier = 1;
   //check for false because this datakey is inverted in the metadata
   if(isRegularUser == 'false') {
      multiplier = 4;
   }

	const updates = {};
	updates['mjcu_total_occasions_used'] = occasionsUsed * multiplier;
	updates['mjcu_total_mornings_used'] = morningsUsed * multiplier;
	updates['mjcu_total_afternoons_used'] = afternoonsUsed * multiplier;
	updates['mjcu_total_evenings_used'] = eveningsUsed * multiplier;
	updates['mjcu_total_night_used'] = nightsUsed * multiplier;
	updates['mjcu_total_hours_used'] = hoursUsed * multiplier;
	updates['mjcu_total_joint_used'] = jointsUsed * multiplier;
	updates['mjcu_total_pipe_used'] = pipeUsed * multiplier;
	updates['mjcu_total_vapor_used'] = vaporUsed * multiplier;
	updates['mjcu_total_oral_used'] = oralUsed * multiplier;
	updates['mjcu_total_other_used'] = otherUsed * multiplier;
	updates['mjcu_total_a_bit_effect'] = aBitEffect * multiplier;
	updates['mjcu_total_moderate_effect'] = moderateEffect * multiplier;
	updates['mjcu_total_heavy_effect'] = heavyEffect * multiplier;
	updates['mjcu_total_too_much_effect'] = tooMuchEffect * multiplier;
	updates['mjcu_most_used_consumed'] = mostUsedForm;
	updates['mjcu_most_used_time'] = mostUsedTime;
	updates['mjcu_total_days_used'] = daysUsed.length * multiplier;
	updates['mjcu_percentage_hours_high'] = ((updates['mjcu_total_hours_used'] / 448) * 100).toFixed(2);

   updateResponse(updates, {});
  },


   render() {

      const { isRegularUser } = this.props;
      const { markedDates } = this.state;

      let from;
      let to;
      //regular user datakey is inverted in the metadata
      if(isRegularUser == 'true') {
         from = this.state.fourWeeksAgo;
         to = this.state.yesterday;
      }
      else {
         from = this.state.oneWeekAgo;
         to = this.state.lastSaturday;
      }
         
    
      const modifiers = {
           "selected": (day) => {
           day = moment(day).startOf('day'); 
           return (markedDates.indexOf(day.format('YYYY-MM-DD')) > -1);      
         },
          "disabled": (day) => {
            day = moment(day).startOf('day'); 
            return (from && isBeforeDay(day, from)) ||
              (to && isPastDay(day, to));      
          } 
       };
      

      return(
         <DayPicker modifiers={modifiers} onDayClick={this.handleClick} className="QuantityFrequencyDaypicker"/>
      )
   }
});

export default connect(
  state => ({
      stateResponses: state.responses
  })
)(QuantityFrequency);