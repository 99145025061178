import React from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react'
import {
    ConnectDropTarget,
    ConnectDragSource,
    DropTargetMonitor,
    DragSourceMonitor,
} from 'react-dnd'
import {
    DragSource,
    DropTarget,
    DropTargetConnector,
    DragSourceConnector,
} from 'react-dnd'
import { ItemTypes } from './ItemTypes'
import { XYCoord } from 'dnd-core'
import { CardDragObject } from './ItemTypes'
import {BLACK, FOREST_GREEN, LIGHT_GRAY} from "../../stylesheets/colors";
import { Ionicons } from '@expo/vector-icons';

const style = {
    border: 'none',
    cursor: 'move',
    boxShadow: '0 8px 5px -5px #777',
    backgroundColor: LIGHT_GRAY,
}

export interface CardProps {
    id: any
    text: string
    index: number
    moveCard: (dragIndex: number, hoverIndex: number) => void
    isDragging: boolean
    connectDragSource: ConnectDragSource
    connectDropTarget: ConnectDropTarget
}

interface CardInstance {
    getNode(): HTMLDivElement | null
}

const Card = forwardRef<HTMLDivElement, CardProps>(function Card(
    { text, isDragging, connectDragSource, connectDropTarget, ...rest },
    ref,
) {
    const elementRef = useRef(null)
    connectDragSource(elementRef)
    connectDropTarget(elementRef)

    const opacity = isDragging ? 0 : 1
    useImperativeHandle<any, CardInstance>(ref, () => ({
        getNode: () => elementRef.current,
    }))
    return (
        <div className="mb-3 card" ref={elementRef} style={{ ...style, opacity }}>
            <div className="card-body d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <p className="h3 fw-bold mb-0" style={{ color: FOREST_GREEN }}>{ rest.index + 1 }</p>
                    <p className="ms-2 mb-0">{text}</p>
                </div>
                <Ionicons name="move" size={24} color={BLACK} />
            </div>
        </div>
    )
})

export default DropTarget(
    ItemTypes.CARD,
    {
        hover(
            props: CardProps,
            monitor: DropTargetMonitor,
            component: CardInstance,
        ) {
            if (!component) {
                return null
            }
            // node = HTML Div element from imperative API
            const node = component.getNode()
            if (!node) {
                return null
            }

            const dragIndex = monitor.getItem<CardDragObject>().index
            const hoverIndex = props.index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = node.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            props.moveCard(dragIndex, hoverIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            monitor.getItem<CardDragObject>().index = hoverIndex
        },
    },
    (connect: DropTargetConnector) => ({
        connectDropTarget: connect.dropTarget(),
    }),
)(
    DragSource(
        ItemTypes.CARD,
        {
            beginDrag: (props: CardProps) => ({
                id: props.id,
                index: props.index,
            }),
        },
        (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging(),
        }),
    )(Card),
)
