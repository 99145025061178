import { StyleSheet } from 'react-native';
const rules = {
    checkboxLabel: {},
    checkBoxOptionInner: { display: "flex", alignItems: "center", flexDirection: "row", minHeight: "1.5rem", paddingLeft: "1.5em", marginBottom: ".125rem" },
    checkBoxOptionOutter: { marginBottom: "1rem", padding: '1rem', position: "relative", display: "flex", flexDirection: "column", minWidth: 0, wordWwrap: "break-word", backgroundColor: "#fff", backgroundClip: "border-box", border: "1px solid rgba(0,0,0,.125)", borderRadius: ".25rem" },
    checkBoxSelected: { backgroundColor: "#268ec2", color: "#fff" },
    checkBoxOptionLabel: { paddingLeft: "2rem" },
}
const ss = StyleSheet.create(rules);
export { ss as base, rules };

