'use strict';

import React from 'react';

export const IMG = React.createClass({

    render() {
        return (
            <img src={CUAC_SETTINGS.S3_ROOT + this.props.src} alt={this.props.alt} className={this.props.className}/>
        );
    }
});
