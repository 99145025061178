import React from 'react';
import { Modal } from "react-bootstrap";
import {LIGHTER_FOREST_GREEN} from "../../stylesheets/colors";
import BSButton from "./BSButton.component.web";
import {FormattedMessage} from "react-intl";

type Props = {
    show: boolean;
    onConfirm: () => () => void;
    onCancel: () => void;
    message: string|React.ReactNode;
}

const ConfirmModal = (props: Props) => {
    return (
        <Modal show={props.show} onHide={props.onCancel}>
            <Modal.Header closeButton style={{ backgroundColor: LIGHTER_FOREST_GREEN }}>
                <Modal.Title>
                    <FormattedMessage
                        id="confirm_modal_title"
                        description="Confirmation modal title"
                        defaultMessage="Are you sure?"
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{ props.message }</p>
                <div className="d-flex align-items-center">
                    <BSButton variant="green" onClick={props.onConfirm}>
                        <FormattedMessage
                            id="overview_button_start_fresh_confirm_yes"
                            description="Confirm -- yes"
                            defaultMessage="Yes"
                        />
                    </BSButton>
                    <BSButton className="ms-2" variant="link-green" onClick={props.onCancel}>
                        <FormattedMessage
                            id="overview_button_start_fresh_confirm_no"
                            description="Confirm -- no"
                            defaultMessage="No"
                        />
                    </BSButton>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConfirmModal;