'use strict';

import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Actions from 'actions';
import { TrackerTypes } from 'api/tracker-entries';
import DrinkTracker from 'components/forms/drink-tracker';
import Moment from 'moment'; Moment.locale('en');
import momentTZ from 'lib/moment-timezone-with-data';
import { FormattedScoreMessage } from 'components/elements/formatted-score-message';
import ButtonSpinner from 'components/ux/button-spinner';

const NewDrinkTracker = React.createClass({

    propTypes: {
        auth: React.PropTypes.object.isRequired,
        dispatch: React.PropTypes.func.isRequired
    },

    getInitialState: function() {
        const { auth } = this.props;
        const user_timezone = _.get(auth, 'user.user_timezone');
        var isNew = true;
        var drinkTrackerEntry = this.props.drinkTrackerEntry || {};
        var initialDateTime = momentTZ.tz(new Date(), user_timezone);
        var initialValues = {
            dt_occurred: initialDateTime.format('YYYY-MM-DD'),
            tm_occurred: initialDateTime.format('hh:mm A'),
            std_drink_count: 1
        };

        if(drinkTrackerEntry._id){
            isNew = false;

            var tmOccurred = moment(drinkTrackerEntry.entry.dt_occurred.split('+')[0]).format('YYYY-MM-DD HH:mm:ss');
            tmOccurred = momentTZ.tz(tmOccurred, 'UTC');
            var userTzTmOccurred = tmOccurred.clone().tz(user_timezone);
            initialValues = {
                drink_type: drinkTrackerEntry.entry.drink_type,
                dt_occurred: userTzTmOccurred.format('YYYY-MM-DD'),//YYYY-MM-DD
                tm_occurred: userTzTmOccurred.format('hh:mm A'),
                mood: drinkTrackerEntry.entry.mood,
                std_drink_count: drinkTrackerEntry.entry.std_drink_count,
                where: drinkTrackerEntry.entry.where,
                with_whom: drinkTrackerEntry.entry.with_whom
            };
        }
        return {
            isNew: isNew,
            initialValues: initialValues,
            addNew: false,
            showFeedback: false,
            feedbackUpdated: false,
            feedbackDate: new Date().toISOString().split('T')[0]+ "T00:00:00"
        };
    },

    componentDidUpdate(prevProps, prevState) {
        if(
            _.get(this.props, 'trackerEntryStats.trackerEntryStats.peak_bac_day') 
            && 
            _.get(prevProps, 'trackerEntryStats.trackerEntryStats.peak_bac_day')
            ) {
            if(this.props.trackerEntryStats.trackerEntryStats.peak_bac_day !== prevProps.trackerEntryStats.trackerEntryStats.peak_bac_day) {
                this.setState({feedbackUpdated: true});
            }
        }
        if(!_.get(this.props, 'trackerEntryStats.trackerEntryStats.peak_bac_day') && this.state.showFeedback) {
            const that = this;
            setTimeout(function() {
                const { auth, dispatch } = that.props;
                const token = _.get(auth, 'user.token');
                const userId = _.get(auth, 'user._id');
                dispatch(Actions.getTrackerEntryStats(token, userId, 'drink'));
            }, 1000);
        } 
    },

    addDrink(data) {
        const { auth, dispatch } = this.props;
        const token = _.get(auth, 'user.token');
        const userId = _.get(auth, 'user._id');
        this.getDateFromDTOccured(data.dt_occurred);
        dispatch(Actions.addTrackerEntry(token, userId, data, TrackerTypes.DRINK))
        .then(() => {
            this.setState({showFeedback: true});
        });
    },

    editDrink(data, trackerEntryId) {
        const { auth, dispatch } = this.props;
        const token = _.get(auth, 'user.token');
        const userId = _.get(auth, 'user._id');
        this.getDateFromDTOccured(data.dt_occurred);
        dispatch(Actions.updateTrackerEntry(token, userId, trackerEntryId, data))
        .then(() => {
            this.setState({showFeedback: true});
        });
    },

    getDateFromDTOccured(data) {
        const { auth } = this.props;
        const user_timezone = _.get(auth, 'user.user_timezone');
        let date = momentTZ.tz(data+"+00:00", user_timezone).format('YYYY-MM-DD');
        this.setState({feedbackDate: date});
    },

    closeModal() {
        this.props.dispatch(Actions.closeModal('appModal', {
            modalNotifications: '',
            modalContent: false
        }));
    },

    onFormSubmit(data) {
        this.setState({addNew: false});
        this.setState({feedbackUpdated: false});
        const { auth } = this.props;
        const user_timezone = _.get(auth, 'user.user_timezone');
        var dtOccurred = data.dt_occurred.split('T')[0] + ' ' + data.tm_occurred;
        dtOccurred = Moment(dtOccurred, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm:ss');
        dtOccurred = momentTZ.tz(dtOccurred, user_timezone); //currently it is in NY.
        //convert it to UTC.
        var utcDtOccurred = dtOccurred.clone().tz('UTC');

        var payload = {
            "dt_occurred": utcDtOccurred.format('YYYY-MM-DDTHH:mm:ss'),
            "drink_type": data.drink_type,
            "std_drink_count": data.std_drink_count,
            "with_whom": data.with_whom,
            "where": data.where,
            "mood": data.mood
        };
        if(this.state.isNew) {
            this.addDrink(payload);
        }
        else {
            this.editDrink(payload, this.props.drinkTrackerEntry._id);
        }
    },

    onFormSubmitAndAddNew() {
        this.setState({addNew: true});
    },

    render() {
        const { trackerEntries, trackerEntryStats } = this.props;
        const isFetching = _.get(trackerEntries, 'isFetching');
        let peak_bac = "";
        var that = this;
        if(_.get(trackerEntryStats, 'trackerEntryStats.peak_bac_day')) {
            trackerEntryStats.trackerEntryStats.peak_bac_day.map(function(data) {
                if(_.get(data, '_id')) {
                    if(data._id.split('T')[0] == that.state.feedbackDate) {
                        peak_bac = data.peak_bac;
                    }
                }
            });
        }
        let date = Moment(this.state.feedbackDate, 'YYYY-MM-DD').format("MMMM Do");
        
        const s3_root = CUAC_SETTINGS.S3_ROOT;

        return (
            <div className="container bg-white">
                { this.state.showFeedback 
                    ? 
                        this.state.feedbackUpdated
                            ?
                                <div>
                                    <p><FormattedScoreMessage id="peak_bac_day_text" values={{BAC: peak_bac, date: date}} defaultMessage="{date} estimated peak BAC: {BAC}." /></p>
                                    {this.state.addNew 
                                        ?
                                            null
                                        : 
                                            <ButtonSpinner spinner={false} style={{margin: '0 0 0 10px'}}
                                                onClick={this.closeModal} ripple={true} raised={true} colored={true} >
                                                    <FormattedMessage id="close_drink_tracker_abstinent"
                                                        description="Close modal button for drink tracker"
                                                        defaultMessage="Close" />
                                            </ButtonSpinner>
                                    }
                                </div>
                            : <div>
                                <img src={s3_root + "/app/img/ux/ajax.ball.loader.01.gif"} alt="Feedback loading"></img>
                                <p><FormattedScoreMessage id="peak_bac_day_loading" defaultMessage="Your feedback is loading." /></p>
                                {this.state.addNew 
                                        ?
                                            null
                                        : 
                                            <ButtonSpinner spinner={false} style={{margin: '0 0 0 10px'}}
                                                onClick={this.closeModal} ripple={true} raised={true} colored={true} >
                                                    <FormattedMessage id="close_drink_tracker_abstinent"
                                                        description="Close modal button for drink tracker"
                                                        defaultMessage="Close" />
                                            </ButtonSpinner>
                                }
                                </div>
                        
                    : <DrinkTracker onSubmit={this.onFormSubmit} onClick={this.onFormSubmitAndAddNew} isFetching={isFetching} initialValues={this.state.initialValues} />
                }
                { this.state.addNew ? <DrinkTracker onSubmit={this.onFormSubmit} onClick={this.onFormSubmitAndAddNew} isFetching={isFetching} initialValues={this.state.initialValues} /> : null }
            </div>
        );
    }
});

export default connect(
    state => ({
        auth: state.auth,
        trackerEntries: state.trackerEntries,
        drinkTrackerEntry: state.modal.drinkTrackerEntry,
        trackerEntryStats: state.trackerEntryStats
    })
)(NewDrinkTracker);
