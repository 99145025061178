/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require */
"use strict";

// ========= LIBRARY COMPONENTS =========
import _ from 'lodash';
import React from 'react';
import Actions from 'actions';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ButtonSpinner from 'components/ux/button-spinner';

const ToolDescriptions = React.createClass({

    render: function() {
        const toolIds = _.get(this.props.auth, 'user.tool_ids', []);
        const userTools = _.map(toolIds, (toolId) => {
            return _.get(this.props.tools, toolId);
        }).filter(x => !!x);
        let message = [];
        _.map(userTools, (tool) => {
            switch (tool.slug) {
                case "checkup":
                    message.push(
                        <div key={tool._id}>
                        <h4>The CheckUp - Alcohol</h4>
                        <p>Most individuals start with the CheckUp, a brief, 30-minute program that allows them to confidentially assess their relationship with alcohol. The CheckUp provides personalized, clinically valid feedback and helps individuals think through any changes they might want to make to their drinking.</p>
                        </div>
                    );
                    break;
                case "checkin":
                    message.push(
                        <div key={tool._id}>
                        <h4>The CheckIn - Alcohol</h4>
                        <p>The CheckIn is a shorter program that individuals can complete every three months after doing the CheckUp. Taking the CheckIn allows individuals to monitor their drinking and drinking-related problems, to track their moods, and to note progress towards any goals they may have set.</p>
                        </div>
                    );
                    break;
                case "moderate":
                    message.push(
                        <div key={tool._id}>
                        <h4>Moderate - Alcohol</h4>
                        <p>Moderate—Alcohol is a longer program for individuals who want to cut back on their drinking without abstaining entirely. The program consists of twelve sections that individuals work through at their own pace. Many, but not all, individuals start with a CheckUp before beginning Moderate—Alcohol. At any time, individuals can switch from Moderate—Alcohol to Abstain—Alcohol.</p>
                        </div>
                    );
                    break;
                case "abstain":
                    message.push(
                        <div key={tool._id}>
                        <h4>Abstain - Alcohol</h4>
                        <p>Abstain—Alcohol is for individuals who know they want to stop drinking altogether. The program consists of 12 sections that individuals work through at their own pace to achieve and maintain abstinence.</p>
                        </div>
                    );
                    break;
                case "abstain-marijuana":
                    message.push(
                        <div key={tool._id}>
                        <h4>Abstain - Marijuana</h4>
                        <p>Abstain—Marijuana is for individuals interested in abstaining from marijuana. The program consists of 12 sections that individuals work through at their own pace to achieve and maintain abstinence.</p>
                        </div>
                    );
                    break;
                case "abstain-opioids":
                    message.push(
                        <div key={tool._id}>
                        <h4>Abstain - Opioids</h4>
                        <p>Abstain—Opioids is for individuals who want to abstain from opioid use. The program consists of 12 sections that individuals work through at their own pace to achieve and maintain abstinence.</p>
                        </div>
                    );
                    break;
                case "abstain-stimulants":
                    message.push(
                        <div key={tool._id}>
                        <h4>Abstain - Stimulants</h4>
                        <p>Abstain—Stimulants is for individuals who want to stop using stimulants. The program consists of 12 sections that individuals work through at their own pace to achieve and maintain abstinence.</p>
                        </div>
                    );
                    break;
                case "abstain-gambling":
                    message.push(
                        <div key={tool._id}>
                        <h4>Abstain - Gambling</h4>
                        <p>The gambling module contains motivational and cognitive-behavioral exercises similar to those of alcohol and drugs. We have tailored it to the specific issues of problematic gambling in consultation with an expert in the field, Dr. Nancy Petry.</p>
                        </div>
                    );
                    break;
            }
        })
        return (
            <div className="container">
                <div>
                    <p>You have access to the following CheckUp & Choices programs. Please read the following descriptions to learn about each program and decide where to start. You can switch between programs at any time and your progress is always saved.</p>
                </div>
                {message}
            </div>
        );
    }
});

export default connect(
    state => ({
        auth: state.auth,
        tools: state.tools
    })
)(ToolDescriptions);
