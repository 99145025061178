'use strict';

import _ from 'lodash';
import Moment from 'moment'; Moment.locale('en');
import React from 'react';
import { findDOMNode } from 'react-dom';
import { c3formatDates } from 'utils/tools';
import { canUseTool } from 'utils/render';
import Button from 'components/ux/mdl/button';

import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import ToolComparisonChart from './tool-comparison-chart';

const MultiComparisonChart = React.createClass({

    getInitialState() {
        const comparisons = this.props.compare;

        let defaultDataKey = '';

        for (var key in comparisons) {
            if (comparisons[key].compared_baseline){
                defaultDataKey = comparisons[key].compared_baseline;
            } else {
                defaultDataKey = comparisons[key].compared_data_key;
            }
            break;
        }
        
        return(
            {dataKeyShow : defaultDataKey}
        )
    },

    renderDataKeyButtons() {
        const comparisons = this.props.compare;
        const that = this;

        return (
            <div className="section section-overview" style={{marginBottom: '-40px'}}>
                <div className="nav tracker-nav">
                    {comparisons.map((comparison, index) => {
                        let whichDataKey = '';
                        if (comparison.compared_baseline) {
                            whichDataKey = comparison.compared_baseline;
                        } else {
                            whichDataKey = comparison.compared_data_key;
                        }
                        return (
                            <Button flat={true} key={"compare_button" + index} onClick={() => that.showDataKey(whichDataKey)} >
                                <FormattedMessage {...comparison.sub_title} />
                            </Button>
                        )
                    })}
                </div>
            </div>
        )
    },

    showDataKey(dataKey) {
        let stateUpdate = {};
        stateUpdate['dataKeyShow'] = dataKey;
        this.setState(stateUpdate);
    },

    render() {
        const comparisons = this.props.compare;
        const responses = this.props.responses;
        const allResponses = this.props.allResponses;
        const tools = this.props.tools;
        const auth = this.props.auth;
        const text_feedback = this.props.text_feedback;

        const dataKeyButtons = this.renderDataKeyButtons();

        return (
            <div>
                {dataKeyButtons}
                {comparisons.map((comparison, index) => {
                    let whichDataKey = '';
                    if (comparison.compared_baseline) {
                        whichDataKey = comparison.compared_baseline;
                    } else {
                        whichDataKey = comparison.compared_data_key;
                    }
                    if (this.state.dataKeyShow == whichDataKey) {
                        return (
                            <ToolComparisonChart
                                key={"tool_comparison_" + index} 
                                title={comparison.title}
                                sub_title={comparison.sub_title}
                                responses={responses}
                                allResponses={allResponses}
                                tools={tools}
                                compare_to_tools={comparison.compare_to_tools}
                                compared_data_key={comparison.compared_data_key}
                                compared_baseline={comparison.compared_baseline}
                                auth={auth}
                                text_feedback={comparison.text_feedback}
                                compare_multi_data_keys={comparison.compare_multi_data_keys}
                            />
                        )
                    }
                })}
            </div>
        );
    }
});

export default injectIntl(MultiComparisonChart);
