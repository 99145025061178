const alertType = {
	'email': 1,
	'phoneNo': 2,
	'both': 3
};

const messageType = {
	'once': 1,
	'recurring': 2
};

export function getReminderTypeEnum(types) {
	return (types.length === 1) ? alertType[types[0]] : alertType['both'];
}

export function getMessageTypeEnum(type) {
	return messageType[type];
}
