'use strict';

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import Actions from 'actions';
import { TrackerTypes } from 'api/tracker-entries';
import GoalTracker from 'components/forms/goal-tracker';
import Moment from 'moment'; Moment.locale('en');

const NewGoalTracker = React.createClass({

    propTypes: {
        auth: React.PropTypes.object.isRequired,
        dispatch: React.PropTypes.func.isRequired
    },

    getInitialState: function() {
        const { auth } = this.props;
        var isNew = true;
        var goalTrackerEntry = this.props.goalTrackerEntry || {};

        var initialValues = {

        };

        if(goalTrackerEntry._id){
            isNew = false;
            initialValues = {
                area: goalTrackerEntry.entry.area,
                expected_outcome: goalTrackerEntry.entry.expected_outcome,
                goal: goalTrackerEntry.entry.goal,
                actions: goalTrackerEntry.entry.actions,
                time_frame: goalTrackerEntry.entry.time_frame
            };
        }
        return {
            isNew: isNew,
            initialValues: initialValues
        };
    },

    addGoal(data) {
        const { auth, dispatch } = this.props;
        const token = _.get(auth, 'user.token');
        const userId = _.get(auth, 'user._id');
        dispatch(Actions.addTrackerEntry(token, userId, data, TrackerTypes.GOAL))
        .then(() => {
            this.closeModal();
        });
    },

    editGoal(data, trackerEntryId) {
        const { auth, dispatch } = this.props;
        const token = _.get(auth, 'user.token');
        const userId = _.get(auth, 'user._id');
        dispatch(Actions.updateTrackerEntry(token, userId, trackerEntryId, data))
        .then(() => {
            this.closeModal();
        });
    },

    closeModal() {
        this.props.dispatch(Actions.closeModal('appModal', {
            modalNotifications: '',
            modalContent: false
        }));
    },

    onFormSubmit(data) {
        if(this.state.isNew) {
            this.addGoal(data);
        }
        else {
            this.editGoal(data, this.props.goalTrackerEntry._id);
        }
    },

    render() {
        const { trackerEntries } = this.props;
        const isFetching = _.get(trackerEntries, 'isFetching');

        return (
            <div className="container">
                <GoalTracker onSubmit={this.onFormSubmit} isFetching={isFetching} initialValues={this.state.initialValues} />
            </div>
        );
    }
});

export default connect(
    state => ({
        auth: state.auth,
        trackerEntries: state.trackerEntries,
        goalTrackerEntry: state.modal.goalTrackerEntry
    })
)(NewGoalTracker);
