import React, {Dispatch, forwardRef, SetStateAction, useEffect, useMemo, useState} from 'react';
import {Col, Container, Row, Dropdown} from "react-bootstrap";
import {connect} from "react-redux";
import {adminGetGroupList} from "../../api/groups";
import {FontAwesome, MaterialIcons} from '@expo/vector-icons';
import {Group, User} from '../../types';
import {DropdownToggleProps} from 'react-bootstrap/DropdownToggle';
import {Image} from 'react-native';
const logo = require('../../../assets/site-logo-small.png');
import Actions from '../../actions';
import {browserHistory} from 'react-router';
import GroupSelectModal from './GroupSelectModal';

declare global {
    interface Window {
        CUAC_SETTINGS: {
            GROUP?: {
                name: string;
                _id: string;
                code: string;
            }
        }
    }
}

type Props = {
    user?: User;
    setGroup: Dispatch<SetStateAction<Group|undefined>>;
    group?: Group;
    logout: () => Promise<void>;
    notify: (payload: { title: string; message: string; level: 'error'|'success' }) => void;
}

const CustomDropdownToggle = forwardRef<HTMLAnchorElement, DropdownToggleProps>(({ children, onClick }, ref) => (
    <a
        href=""
        onClick={(e: any) => {
            e.preventDefault();
            if (onClick) onClick(e);
        }}
        ref={ref}
        className="h-100 d-flex align-items-center justify-content-center"
    >
        { children }
    </a>
))

const Header = ({ logout, setGroup, group, user, notify }: Props) => {
    const [groups, setGroups] = useState<Group[]>([]);
    const [groupSelectOpen, setGroupSelectOpen] = useState(false);

    useEffect(() => {
        (async () => {
            if (!user?.token?.length) return;
            const { admin, system } = user.roles || {},
                groupId = system?.includes('admin') ? undefined : admin?.[0];
            try {
                const res: Group[] = await adminGetGroupList(user.token, groupId);
                if (res?.length) {
                    const { code } = window.CUAC_SETTINGS?.GROUP ?? {},
                        group = !!code?.length ? res.find(({ code: c }) => c === code) : res[0];
                    setGroup(group);
                }
                setGroups(res);
            }
            catch (e) {
                notify({ title: 'Error', message: 'Failed to fetch your groups.', level: 'error' });
            }
        })()
    }, [ user ])

    const userAvatar = useMemo(() => {
        if (!user) return null;
        const { email, name, roles } = user,
            char = name?.charAt(0)?.toUpperCase() ?? email?.charAt(0)?.toUpperCase() ?? '',
            superAdmin = roles?.system?.includes('admin');
        return (
            <div className="d-flex align-items-center">
                <div className="me-2 flex-shrink-0 d-flex align-items-center justify-content-center rounded-circle" style={{ backgroundColor: 'lightgrey', width: '40px', height: '40px' }}>
                    <span className="text-white fw-bold fs-3">{ char ?? '' }</span>
                </div>
                <div className="d-flex flex-column">
                    <span className="fs-6">{ name }</span>
                    <span style={{ fontSize: 10 }}>{ superAdmin ? 'Super-' : '' }Admin</span>
                </div>
            </div>
        )
    }, [ user ])

    const accountDropdown = useMemo(() => {
        return (
            <Dropdown title="Actions" className="flex-fill" onChange={console.log}>
                <Dropdown.Toggle as={CustomDropdownToggle} id="actions-dropdown">
                    { userAvatar }
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => browserHistory.push('/dashboard')} className="d-flex align-items-center">
                        <MaterialIcons name="admin-panel-settings" size={20} />
                        <span className="ms-2">Exit Admin</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => browserHistory.push('/account')} className="d-flex align-items-center">
                        <MaterialIcons name="account-circle" size={20} />
                        <span className="ms-2">Edit Account</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={async () => {
                        await logout();
                        browserHistory.push('/logout');
                    }} className="d-flex align-items-center">
                        <MaterialIcons name="logout" size={20} />
                        <span className="ms-2">Logout</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }, [ browserHistory, user, userAvatar, logout ])

    const groupSelect = useMemo(() => {
        if ((groups || []).length <= 10) return (
            <Dropdown title="Group Select" className="flex-fill" onChange={console.log}>
                <Dropdown.Toggle as={CustomDropdownToggle} id="group-dropdown">
                    <span className="me-2 fw-bold">
                        { group?.name || 'Select Group' }
                    </span>
                    <FontAwesome size={20} name="caret-down" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        (groups || []).map(group => (
                            <Dropdown.Item key={group._id}>
                                { group.name }
                            </Dropdown.Item>
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
        )
        return (
            <a href="#" onClick={e => {
                e.preventDefault();
                setGroupSelectOpen(true);
            }} className="fw-bold d-flex align-items-center" style={{ color: 'gray' }}>
                <span style={{ fontSize: 14 }}>{ group?.name || 'Select Group' }</span>
                <FontAwesome className="ms-1" size={20} name="caret-down" />
            </a>
        )
    }, [ group, groups, setGroupSelectOpen ])

    return (
        <>
            <Container fluid className="h-100 w-100 bg-white border-bottom shadow-sm">
                <Row className="w-100 h-100" style={{ }}>
                    <Col xs={2} className="p-2 d-flex align-items-center justify-content-start">
                        <Image
                            style={{ flex: 1, height: '100%', width: '100%' }}
                            source={logo}
                            resizeMode="contain"
                        />
                    </Col>
                    <Col xs={8} className="border-end d-flex align-items-center justify-content-center py-3">

                    </Col>
                    <Col xs={1} className="d-flex border-end">
                        { groupSelect }
                    </Col>
                    <Col xs={1} className="d-flex align-items-center">
                        { accountDropdown }
                    </Col>
                </Row>
            </Container>
            <GroupSelectModal
                setGroup={setGroup}
                group={group}
                groups={groups}
                open={groupSelectOpen}
                close={() => setGroupSelectOpen(false)}
            />
        </>
    );
};

export default connect(undefined, {
    logout: Actions.logOutUser,
    notify: Actions.notify
})(Header);
