'use strict';

import React from 'react';
import BSButton from 'components/blocks/BSButton.component';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';

const SSO = React.createClass({
    
    propTypes: {
        ssoLink: React.PropTypes.string.isRequired,
        loading: React.PropTypes.bool,
    },

     getInitialState() {
        const allow_reg = !this.props.viewTerms;
        return {
            allow_reg
        };
    },

    onTermsAccept() {
        this.setState({allow_reg: !this.state.allow_reg});
    },

    goToSSO() {
        if(this.state.allow_reg) {
            window.location = this.props.ssoLink;
        } else {
            this.props.showWarning();
        }
    },

    // ======= RENDER ======================================================

    render() {
        const { viewTerms } = this.props;

        return (<>
            <div className="container">
                <div className="row">
                        <div className="col-12 col-lg-6 offset-lg-3">
                            <div className="card my-4">
                                <div className="card-body" style={{textAlign:'center'}}>
                                    <h3 className="card-title">Login</h3>
                                    <div className="reg-icon-circle">
                                        <img src={CUAC_SETTINGS.S3_ROOT + "/app/img/ux/reg-icon.png"} alt="Registration icon" style={{textAlign: 'center'}}/>
                                    </div>
                                    <p className="my-2"><FormattedMessage id="sso_message"
                                        description="Message for SSO Registration and Login"
                                        values={{message: CUAC_SETTINGS.GROUP.sso_message}}
                                        defaultMessage="{message}"/></p>
                                    {viewTerms
                                        ?
                                            <div className="checkbox" >
                                                <input type="checkbox" id="accept_terms" name="accept_terms" value="true" className="box" onChange={this.onTermsAccept}/>
                                                <label htmlFor="accept_terms" id="accept_terms">
                                                    <FormattedMessage id="terms_of_service_0" defaultMessage="I accept the "/>
                                                    <Link to="/terms">
                                                        <FormattedMessage id="terms_of_service_1" defaultMessage="Terms of Service"/>
                                                    </Link>
                                                </label>
                                            </div>
                                        : 
                                            null
                                    }
                                    
                                    <BSButton
                                        loading={this.props.loading}
                                        disabled={this.props.loading}
                                        onClick={this.goToSSO}
                                        style={{margin:'5px auto'}}
                                    >
                                        <FormattedMessage id="sso_button_message"
                                        description="Message for SSO Registration and Login Button"
                                        defaultMessage="Login Now "/>
                                        <MaterialCommunityIcons style={{ marginLeft: 5 }} name="login-variant" size={20} color="white" />
                                    </BSButton>
                                </div> {/* card body */}
                            </div>{/* card */}
                        </div>{/* col */}
                    </div>{/* row */}
            </div> {/* container */}
        </>);
    }
});

export default SSO;
