'use strict';

import {
    SET_COUPON
} from 'actions/types';

export default function setCoupon(couponCode) {
    return {
        type: SET_COUPON,
        payload: {
            couponCode
        }
    };
}