'use strict';

import { getPlans as getPlansAPI } from 'api/plans';

import {
    REQUEST_GET_PLANS,
    REQUEST_GET_PLANS_SUCCESS,
    REQUEST_GET_PLANS_FAILURE
} from 'actions/types';

function requestGetPlans() {
    return {
        type: REQUEST_GET_PLANS
    };
}

function requestGetPlansSuccess(json) {
    return {
        type: REQUEST_GET_PLANS_SUCCESS,
        payload: {
            plans: json
        }
    };
}

function requestGetPlansFailure(error) {
    return {
        type: REQUEST_GET_PLANS_FAILURE,
        payload: error,
        error: true
    };
}

export default function getPlans(token, groupId) {
    return dispatch => {
        dispatch(requestGetPlans());
        return getPlansAPI(token, groupId)
            .then((json) => {
                return dispatch(requestGetPlansSuccess(json));
            })
            .catch((error) => {
                return dispatch(requestGetPlansFailure(error));
            });
    };
}