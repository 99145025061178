'use strict';

import { deleteReminder as deleteReminderAPI } from 'api/reminders';
import notify from 'actions/notify';
import Actions from 'actions';

import {
    REQUEST_DELETE_REMINDER,
    REQUEST_DELETE_REMINDER_SUCCESS,
    REQUEST_DELETE_REMINDER_FAILURE

} from 'actions/types';

function requestDeleteReminder() {
    return {
        type: REQUEST_DELETE_REMINDER
    };
}

function requestDeleteReminderSuccess() {
    return {
        type: REQUEST_DELETE_REMINDER_SUCCESS        
    };
}

function requestDeleteReminderFailure(error) {
    return {
        type: REQUEST_DELETE_REMINDER_FAILURE,
        payload: error,
        error: true
    };
}

export default function deleteReminder(userId, token, reminderId) {
    return dispatch => {
        dispatch(requestDeleteReminder());
        return deleteReminderAPI(userId, token, reminderId)
        .then((json) => {
            return dispatch(Actions.getAllReminders(userId, token))
            .then(() => {
                dispatch(notify({
                    title: "Reminder deleted",
                    message: "Your Reminder was deleted successfully.",
                    level: 'success',
                    autoDismiss: 4
                }));
                return dispatch(requestDeleteReminderSuccess());
            });
        })
        .catch((error) => {            
            dispatch(notify({
                title: "Network Error",
                message: "Cannot DELETE Your Reminder. Please try again in a few minutes.",
                level: 'error',
                autoDismiss: 4
            }));

            return dispatch(requestDeleteReminderFailure(error));
        });
    };
}