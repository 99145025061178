/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, global, require */
'use strict';

import './main.css';
import './legacy2.css';
import "./conventional.css";
import 'utils/cssloader';

import _ from 'lodash';
// import 'react-mdl/extra/material.css';
import 'react-mdl/extra/material.js';

import Cookies from 'cookies-js';
import { useScroll } from 'react-router-scroll';
import config from '../config';

import React, { useState, useEffect } from 'react';
// https://stackoverflow.com/questions/46671373/upgrading-a-project-to-react-16-with-old-dependencies
import PropTypes from 'prop-types'
import createClass from 'create-react-class'
Object.assign(React, {
    PropTypes,
    createClass
})
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { Router, browserHistory, applyRouterMiddleware } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import initializeStore from 'store';
import getRoutes from './routes';

import { getNorms } from 'api/norms';
import { getTermsById as getTerms } from 'api/terms';
import { getResponses } from 'api/responses';
import { getSubscriptions } from 'api/subscriptions';
import { getTool, getTools } from 'api/tools';
import { getTrackerEntries } from 'api/tracker-entries';
import { getTrackerEntryStats } from 'api/tracker-entry-stats';
import { getAllReminders } from 'api/reminders';
import {
    addUnregisteredUser,
    getUser
} from 'api/users';
import { DndProvider } from 'react-dnd'
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import Loading from './components/blocks/Loading.component.web'; // or any other pipeline
import TagManager from 'react-gtm-module'
import {extractFormulas} from "./reducers/tools";

const tagManagerArgs = {
    gtmId: config.GOOGLE_TAG
}

TagManager.initialize(tagManagerArgs)

class DebugRouter extends Router {
    constructor(props) {
        super(props);
    }
}


let user, userId, userStatus, token;
const Timer = { results: {} };
const Time = (key) => {
    if (Timer[key + '_start']) {
        Timer[key + '_stop'] = new Date().getTime();
        Timer.results[key] = Timer[key + '_stop'] - Timer[key + '_start'];
    } else {
        Timer[key + '_start'] = new Date().getTime();
    }
};

const waitForSettings = async (calls = 0) => {
    const sleep = seconds => new Promise(done => setTimeout(done, seconds * 1000));
    if ("CUAC_SETTINGS" in window) return true;
    const script = document.getElementById("settingsjsscript");
    if (!script) {
        var c = document.location.hostname.split(".")[0],
            s = document.createElement("script"),
            p = document.location.search.substr(1).split("&").map(s => s.split('=')).concat([["code", "new"]]);
        if (c === "localhost") c = p.filter(([k, v]) => k == "code")[0][1];
        s.type = "text/javascript";
        s.id = "settingsjsscript";
        s.src = config.API_URL + "js/settings.js?code=" + c + "&ts=" + Date.now().toString();
        document.head.appendChild(s);
    }
    await sleep(1);
    if (calls < 5)
        return await waitForSettings(calls + 1);
    else
        return false;
}

let initializeUser = async () => {
    try {
        await waitForSettings();
        Time('intializeUser');
        let userData = Cookies.get('userData');
        if (userData) {
            userData = userData.split(':');
            userId = userData[0];
            token = userData[1];
        }
        if (typeof userId !== 'undefined' &&
            typeof token !== 'undefined') {
            return getUser(token, userId)
                .then((json) => {
                    Cookies.set('userData', json._id + ':' + json.token);
                    return json;
                })
                .catch(() => {
                    userId = undefined;
                    token = undefined;
                    return addUnregisteredUser();
                });
        } else {
            return addUnregisteredUser().then((json) => {
                Cookies.set('userData', json._id + ':' + json.token);
                return json;
            });
        }
    } catch (e) {
        console.log(e);
    }
};

const removeSpinner = () => {
    const spinner = document.querySelector('.site-loader');
    if (spinner) {
        spinner.parentNode.removeChild(spinner);
    }
};

const initialState = {
    app: {
      toolTOCOpen: true,
    },
    auth: {
        isFetching: false,
        selectedPlan: {},
        user: {}
    },
    norms: {},
    terms: {},
    responses: {
        isFetching: false,
        activeResponseId: ''
    },
    subscriptions: {
        isFetching: false
    },
    reminders: {
        isFetching: false,
        reminders: []
    },
    tools: {
        isFetching: false
    },
    trackerEntries: {
        isFetching: false,
        abcOrder: [],
        drinkOrder: [],
        goalOrder: [],
        happinessOrder: [],
        lapseOrder: [],
        moodOrder: [],
        planOrder: [],
        valueOrder: [],
        solutionOrder: [],
        urgeOrder: [],
        agreementOrder: []
    },
    trackerEntryStats: {
        isFetching: false
    }
};


const loadParallel = (json) => {
    Time('loadParallel');
    user = json;
    userId = _.get(user, '_id');
    userStatus = _.get(user, 'status');
    token = _.get(user, 'token');
    initialState.auth.user = user;
    initialState.responses.activeResponseId = _.get(user, 'active_response_id', '');
    const toolId = _.get(CUAC_SETTINGS, 'GROUP.screener_tool_id');
    const toolIds = _.get(user, 'tool_ids', []);

    var subscriptions = (!token || userStatus !== 'normal') ? false
        : new Promise((resolve, reject) => {
            Time('subscriptions');
            getSubscriptions(token)
                .then((json) => {
                    initialState.subscriptions.subscriptions = json;
                    Time('subscriptions');
                    resolve(true);
                });
        });

    var responses = (!token || !userId || !['normal', 'unregistered'].includes(userStatus)) ? false
        : new Promise((resolve, reject) => {
            Time('responses');
            getResponses(token, userId)
                .then((json) => {
                    json.forEach((response) => {
                        initialState.responses[response._id] = {
                            isFetching: false,
                            response
                        };
                    });
                    Time('responses');
                    resolve(true);
                });
        });

    var screenerTool = (!token || !toolId) ? false
        : new Promise((resolve, reject) => {
            Time('screenerTool');
            getTool(token, toolId)
                .then((json) => {
                    initialState.tools[toolId] = extractFormulas(json);
                    Time('screenerTool');
                    resolve(true);
                });
        });

    var otherTools = (!token || toolIds === []) ? false
        : new Promise((resolve, reject) => {
            Time('otherTools');
            getTools(token)
                .then((json) => {
                    json.forEach((tool) => {
                        initialState.tools[tool._id] = extractFormulas(tool);
                    });
                    Time('otherTools');
                    resolve(true);
                });
        });

    var trackerEntries = (!token || !userId || userStatus !== 'normal') ? false
        : new Promise((resolve, reject) => {
            Time('trackerEntries');
            getTrackerEntries(token, userId)
                .then((json) => {
                    json.forEach((trackerEntry) => {
                        initialState.trackerEntries[trackerEntry._id] = trackerEntry;
                        initialState.trackerEntries[trackerEntry.tracker_type_name + 'Order'].push(trackerEntry._id);
                    });
                    Time('trackerEntries');
                    resolve(true);
                });
        });

    var trackerEntryStats = (!token || !userId || userStatus !== 'normal') ? false
        : new Promise((resolve, reject) => {
            Time('trackerEntryStats');
            getTrackerEntryStats(token, userId)
                .then((json) => {
                    json.forEach((trackerEntryStats) => {
                        initialState.trackerEntryStats.trackerEntryStats = trackerEntryStats;
                    });
                    Time('trackerEntryStats');
                    resolve(true);
                });
        });

    var reminders = (!token || !userId || userStatus !== 'normal') ? false
        : new Promise((resolve, reject) => {
            Time('reminders');
            getAllReminders(userId, token)
                .then((json) => {
                    initialState.reminders.reminders = json;
                    Time('reminders');
                    resolve(true);
                });
        });

    var norms = (!token) ? false
        : new Promise((resolve, reject) => {
            Time('norms');
            getNorms(token)
                .then((json) => {
                    json.forEach((norm) => {
                        initialState.norms[norm.type] = norm;
                    });
                    Time('norms');
                    resolve(true);
                });
        });

    var terms = new Promise((resolve, reject) => {
        Time('term');
        getTerms(_.get(CUAC_SETTINGS, 'GROUP.terms_id'))
            .then((json) => {
                initialState.terms = json;
                Time('term');
                resolve(true);
            })
            .catch((err) => {
                resolve(true);
            });
    });

    return Promise.all([
        subscriptions,
        responses,
        screenerTool,
        otherTools,
        trackerEntries,
        trackerEntryStats,
        terms,
        reminders,
        norms]
    ).then((values) => {
        Time('loadParallel');
        return true;
    });
};

// Scroll to top of page on route change unless we're scrolling to a particular field
const shouldScroll = (prevRouterProps, { location }) => {
  const field = _.get(location, 'query.field');
  return !field || !field.length;
}

export default function () {
    const [isReady, setIsReady] = useState(false);
    useEffect(() => {
        (async () => {
            await waitForSettings();
            const json = await initializeUser()
            await loadParallel(json);
            setIsReady(true);
        })()
    }, []);
    if (isReady && "CUAC_SETTINGS" in window) {
        console.log("CUAC_SETTINGS loaded", CUAC_SETTINGS);
        const store = initializeStore(initialState);
        const history = syncHistoryWithStore(browserHistory, store)

        return (
            <DndProvider backend={MultiBackend} options={HTML5toTouch}>
              <Provider store={store}>
                <IntlProvider locale="en">
                  <DebugRouter
                    routes={getRoutes(store)}
                    history={history}
                    render={applyRouterMiddleware(useScroll(shouldScroll))}
                  />
                </IntlProvider>
              </Provider>
            </DndProvider>
        );
    } else {
        return <Loading loading={true} text={<h4 className="mb-0 ms-2 fw-bold">Loading...</h4>} />
    }
}
