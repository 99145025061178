'use strict';
import React, { forwardRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import Moment from 'moment'; Moment.locale('en');
import momentTZ from 'lib/moment-timezone-with-data';
import InfoBug from 'components/popover/infobug';
import DateInputReduxForm from 'components/ux/input-date-redux-form';
import TextFieldReduxForm from 'components/ux/text-field-redux-form';
import { FontAwesome } from '@expo/vector-icons';
import NumericInput from '../blocks/NumericInput.component.web';
import TimePickerReduxForm from '../ux/TimePickerReduxForm.component.web';
import BSButton from '../blocks/BSButton.component.web';
import { LIGHTEST_BLUE } from '../../stylesheets/colors';
import { Form } from 'react-bootstrap';
import RadioGroupReduxForm from "../ux/RadioGroupReduxForm.component.web";
import Actions from 'actions';

const messages = defineMessages({
    "urge_tracker_dt_occurred_label": {
        id: "urge_tracker_dt_occurred_label",
        description: "Date Occurred",
        defaultMessage: "Date Occurred"
    },
    "urge_tracker_duration_placeholder": {
        id: "urge_tracker_duration_placeholder",
        description: "Duration Placeholder",
        defaultMessage: "# of minutes"
    },
    "urge_tracker_with_whom_label": {
        id: "urge_tracker_with_whom_label",
        description: "With Whom",
        defaultMessage: "With Whom"
    },
    "urge_tracker_where_label": {
        id: "urge_tracker_where_label",
        description: "Where",
        defaultMessage: "Where"
    },
    "urge_tracker_mood_label": {
        id: "urge_tracker_mood_label",
        description: "Your Mood",
        defaultMessage: "Your Mood"
    },
    "urge_tracker_strategy_label": {
        id: "urge_tracker_strategy_label",
        description: "Strategy",
        defaultMessage: "Strategy"
    },
    "date_input_year_label": {
        id: "date_input_year_label",
        description: "date choose label -- Year",
        defaultMessage: "Year"
    },
    "date_input_month_label": {
        id: "date_input_month_label",
        description: "date choose label -- Month",
        defaultMessage: "Month"
    },
    "date_input_day_label": {
        id: "date_imput_day_label",
        description: "date choose label -- Day",
        defaultMessage: "Day"
    }
});

const intensityOptions = [
    {
        "text": {
            "id": "number_1",
            "defaultMessage": "1"
        },
        "value": 1
    },
    {
        "text": {
            "id": "number_2",
            "defaultMessage": "2"
        },
        "value": 2
    },
    {
        "text": {
            "id": "number_3",
            "defaultMessage": "3"
        },
        "value": 3
    },
    {
        "text": {
            "id": "number_4",
            "defaultMessage": "4"
        },
        "value": 4
    },
    {
        "text": {
            "id": "number_5",
            "defaultMessage": "5"
        },
        "value": 5
    },
    {
        "text": {
            "id": "number_6",
            "defaultMessage": "6"
        },
        "value": 6
    },
    {
        "text": {
            "id": "number_7",
            "defaultMessage": "7"
        },
        "value": 7
    },
    {
        "text": {
            "id": "number_8",
            "defaultMessage": "8"
        },
        "value": 8
    },
    {
        "text": {
            "id": "number_9",
            "defaultMessage": "9"
        },
        "value": 9
    },
    {
        "text": {
            "id": "number_10",
            "defaultMessage": "10"
        },
        "value": 10
    }
];

const urgeTypeOptions = [
    {
        "text": {
            "id": "urge_1",
            "defaultMessage": "Drink"
        },
        "value": "drink"
    },
    {
        "text": {
            "id": "urge_2",
            "defaultMessage": "Marijuana"
        },
        "value": "marijuana"
    },
    {
        "text": {
            "id": "urge_3",
            "defaultMessage": "Stimulant"
        },
        "value": "stimulants"
    },
    {
        "text": {
            "id": "urge_4",
            "defaultMessage": "Gambling"
        },
        "value": "gambling"
    },
    {
        "text": {
            "id": "urge_5",
            "defaultMessage": "Opioids"
        },
        "value": "opioids"
    }
];

const validate = values => {
    const errors = {};
    let { urge_type, intensity, duration } = values || {};
    if (!urge_type || !urge_type.length) errors['urge_type'] = 'Required';
    if (typeof intensity === 'string') intensity = parseInt(intensity);
    if (typeof duration === 'string') duration = parseFloat(duration);
    if (typeof intensity !== 'number' || !intensity) errors['intensity'] = 'Required';
    if (typeof duration !== 'number' || !duration) errors['duration'] = 'Required';
    return errors;
}

const UrgeTracker = (props) => {
    const [noUrges, setNoUrges] = useState(false);

    useEffect(() => {
        if (!props.editing) return;
        const { intensity, duration, urge_type } = props.initialValues || {};
        if (!urge_type && !intensity && !duration) {
            setNoUrges(true);
        }
    }, []);

    useEffect(() => {
        if (noUrges) {
            props.change('intensity', 0);
            props.change('duration', 0);
            props.change('urge_type', null);
        }
    }, [ noUrges ]);

    const CustomDatepickerInput = forwardRef(({ value, onClick }, ref) => (
      <input style={{ textAlign: 'right' }} value={value} type="text" className="form-control" onClick={onClick} ref={ref}/>
    ));

    const onSave = async () => {
        try {
            await props.handleSubmit();
            props.hideModal();
        }
        catch (e) {
            const msg = e.message || 'An error occurred!';
            props.notify({
                title: 'Error',
                message: msg,
                level: 'error',
                autoDismiss: 4
            })
        }
    }

    const onSaveAndAddNew = async () => {
        await props.handleSubmit();
        const userTimezone = props.user?.user_timezone,
          date = userTimezone ? momentTZ(new Date(), userTimezone) : Moment();
        props.dispatch(props.initialize({
            dt_occurred: date.format('YYYY-MM-DD'),
            tm_occurred: date.format('hh:mm A'),
        }));
    }

    const isValid = () => noUrges || !Object.values(props.errors || {}).length;

    return (
      <form id="urge_tracker" name="urge_tracker" className="d-flex flex-column mt-3">
          <DateInputReduxForm
            name="dt_occurred"
            floatingLabel={true}
            yearLabel={props.intl.formatMessage(messages.date_input_year_label)}
            monthLabel={props.intl.formatMessage(messages.date_input_month_label)}
            dayLabel={props.intl.formatMessage(messages.date_input_day_label)}
            labelText={props.intl.formatMessage(messages.urge_tracker_dt_occurred_label)}
            customInput={<CustomDatepickerInput/>}
            wrapperClassName="flex-grow-1"
            labelClassName="form-label me-2"
          />
          <TimePickerReduxForm
            name="tm_occurred"
            outputFormat="hh:mm A"
            label="Time"
            labelClassName="form-label me-2"
          />
          <div className="d-flex justify-content-end mb-2">
              <Form.Check
                type="checkbox"
                id="no_urges_check"
                label="No Urges"
                value={true}
                checked={noUrges}
                onChange={() => setNoUrges(!noUrges)}
              />
          </div>
          {
              !noUrges && (
                <div className="p-3 mb-2" style={{ borderRadius: '.25rem', border: `1px solid ${LIGHTEST_BLUE}` }}>
                    <RadioGroupReduxForm
                        name="intensity"
                        options={intensityOptions}
                        fieldsetClassName="d-flex flex-column flex-lg-row"
                        fieldContainerClassName="d-flex flex-row flex-lg-column align-items-lg-center me-lg-3"
                        labelClassName="form-label fw-bold"
                        inputClassName="me-2 me-lg-0"
                        title={
                            <FormattedMessage
                                id="urge_tracker_intensity_label"
                                defaultMessage="Intensity"
                            />
                        }
                        errorText={!noUrges && props.errors?.intensity}
                    />
                    <NumericInput
                      name="duration"
                      placeholder="0"
                      style={{ width: 70, border: 'none', textAlign: 'center' }}
                      label="Duration (in minutes)"
                      inputGroupClassName="justify-content-between align-items-center mb-3"
                      step={1}
                      min={0}
                      labelClassName="form-label fw-bold"
                    />
                    <RadioGroupReduxForm
                        name="urge_type"
                        options={urgeTypeOptions}
                        fieldsetClassName="d-flex flex-column flex-lg-row"
                        fieldContainerClassName="me-3 d-flex"
                        inputClassName="me-2"
                        labelClassName="form-label fw-bold"
                        title={
                            <FormattedMessage
                                id="urge_tracker_type_label"
                                defaultMessage="Urge Type"
                            />
                        }
                        errorText={!noUrges && props.errors?.urge_type}
                    />
                </div>
              )
          }
          <div className="row">
              <div className="col-12 col-lg-6">
                  <TextFieldReduxForm
                    name="with_whom"
                    floatingLabel={true}
                    labelText={props.intl.formatMessage(messages.urge_tracker_with_whom_label)}
                  />
              </div>
              <div className="col-12 col-lg-6">
                  <TextFieldReduxForm
                    name="where"
                    floatingLabel={true}
                    labelText={props.intl.formatMessage(messages.urge_tracker_where_label)}
                  />
              </div>
          </div>
          <div className="row">
              <div className="col-12 col-lg-6">
                  <TextFieldReduxForm
                    name="mood"
                    floatingLabel={true}
                    labelText={props.intl.formatMessage(messages.urge_tracker_mood_label)}
                  />
              </div>
              <div className="col-12 col-lg-6">
                  <TextFieldReduxForm
                    name="strategy"
                    floatingLabel={true}
                    labelText={
                        <div className="d-flex">
                            <span>
                                { props.intl.formatMessage(messages.urge_tracker_strategy_label) }
                            </span>
                            <InfoBug>
                                <FormattedMessage
                                  id="urge_tracker_strategy_infobug"
                                  defaultMessage="What you did in response to the urge."
                                />
                            </InfoBug>
                        </div>
                    }
                  />
              </div>
          </div>
          <div className="d-flex align-items-center">
              <BSButton loading={props.isFetching} variant="green" onClick={onSave} disabled={props.isFetching || !isValid()}>
                  <FontAwesome name="star-o" color="white" size={20}/>
                  <div className="ms-2">
                      <FormattedMessage
                        id="urge_tracker_button_save"
                        description="Submit the urge tracker form to save an urge"
                        defaultMessage="Save"
                      />
                  </div>
              </BSButton>
              <BSButton loading={props.isFetching} className="ms-2" variant="link-green" onClick={onSaveAndAddNew} disabled={props.isFetching || !isValid()}>
                  <FormattedMessage
                    id="urge_tracker_button_save_add"
                    description="Submit the Urge tracker form to save an Urge"
                    defaultMessage="Save & Add New"
                  />
              </BSButton>
          </div>
      </form>
    )
}

const formFields = ['dt_occurred', 'tm_occurred', 'intensity', 'duration', 'urge_type', 'with_whom', 'where', 'mood', 'strategy'];
let form = reduxForm({
    form: 'urgeTracker',
    fields: formFields,
    validate,
})(UrgeTracker);

const selector = formValueSelector('urgeTracker') // <-- same as form name
form = connect(state => ({
    ...formFields.reduce((dict, fieldName) =>
        ({ ...dict, [fieldName]: selector(state, fieldName) }), {}
    ),
    errors: state.form.urgeTracker?.syncErrors,
    user: state.auth?.user,
}), {
    notify: Actions.notify,
})(form)

export default injectIntl(form);
