/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { browserHistory } from 'react-router';
import Actions from 'actions';
import { getScoreOptionIndex } from 'utils/render';
import Selectors from 'selectors';
const { pageSelector, userNormsSelector, userProfileSelector } = Selectors;
import { getPageStatus } from 'utils/progress';
import { scrollTo } from 'utils/tools';
import { calculateResponses } from 'utils/formulas';
import { Block } from 'components/blocks/block';
import { Ionicons } from '@expo/vector-icons';
import BSButton from 'components/blocks/BSButton.component';
import { FOREST_GREEN } from '../stylesheets/colors';
import {applyResponse} from "../actions/add-response";
import ToolLayout from '../components/blocks/ToolLayout.component.web';
import {cacheUpdate, submitUpdates} from "../actions/update-response";
import SaveToolButton from "../components/blocks/SaveToolButton";
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const cookies = new Cookies();


export const DynamicPage = React.createClass({
    propTypes: {
        tool: React.PropTypes.object.isRequired,
        module: React.PropTypes.object.isRequired,
        section: React.PropTypes.object.isRequired,
        page: React.PropTypes.object.isRequired,
        activeResponse: React.PropTypes.object.isRequired,
        activeResponseId: React.PropTypes.string.isRequired,
        sub_header: React.PropTypes.object,
        dispatch: React.PropTypes.func.isRequired
    },

    getInitialState() {
        return {
            nextPageCheck: false,
            progressPercentage: 0,
            isSaving: false,
        };
    },

    getCookieName() {
        return `user-token-${_.get(this.props, "tool._id")}`;
    },

    componentDidMount() {
        this.focusField();
        this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
        window.addEventListener('beforeunload', this.beforeUnload);
        document.querySelectorAll('div.no-print div.norm-display').forEach((e) => e.parentNode.classList.remove('no-print'))
    },

    async routerWillLeave() {
        await this.submitUpdates();
    },

    async componentWillUnmount() {
        // Submit page's updates before the user leaves
        await this.submitUpdates();
        window.removeEventListener('beforeunload', this.beforeUnload)
    },

    beforeUnload(e) {
        e.preventDefault();
        const { updates } = this.props.activeResponse || {};
        if (updates && Object.keys(updates || {}).length) {
            return e.returnValue = 'Are you sure? Your unsaved changes may be lost.';
        }
    },

    UNSAFE_componentWillMount() {
        const { activeResponse} = this.props;
        // if we have empty progress, but we auto started a tool with the screener responses, update the progress
        if(!_.isEmpty(activeResponse.responses) && _.get(activeResponse, 'progress.response_count') === 0) {
          this.updateResponse(activeResponse.responses, {}, null);
        }
        this.updateProgressIndicator();
    },

    componentDidUpdate(prevProps, prevState) {
        // check if our page changed
        const { activeResponse, location, tool, module, section, page } = this.props;

        if (prevProps.location?.pathname !== location?.pathname) {
            this.focusField();
            this.createUserTokenCookie();
        }

        if(prevProps.page !== this.props.page) {
            // if the page changes, we don't want to check for unanswered questinos
            this.setState({nextPageCheck: false});
            // Remove focus from any focused element
            if (document.activeElement) {
                document.activeElement.blur();
            }

            if(_.get(page, 'next_page_parameter')) {
                // if the page has a next page parameter, we need to get the right response
                // set up our variables
                const next_page_parameter = _.get(page, 'next_page_parameter');
                const answer = _.get(activeResponse, next_page_parameter);
                const options = _.get(page, 'next_page_options');
                const check = _.get(page, 'next_page_check');
                // figure out which option we want
                if (answer == null && options !== undefined) {
                    // set progress
                    const sendModule = module;
                    const sendSection = section;
                    const progressPages = options.progress_pages;

                    for (var i = 0; i < progressPages.length; i++) {
                        //get page
                        const pageTitle = progressPages[i].title;
                        let sendUpdates = {};
                        const sendPage = section.pages[pageTitle];

                        if (_.has(progressPages[i], 'responses')) {
                            //get responses to set to true in update
                            sendUpdates = progressPages[i].responses;
                        }
                        // send the update
                        this.updateProgressManually(sendUpdates, tool, sendModule, sendSection, sendPage);
                    }

                } else if (check) {
                    // figure out which option we want
                    const scoreOptionIndex = getScoreOptionIndex(answer, check);
                    // get our progress sections to update, if any
                    if (check[scoreOptionIndex].progress_pages !== undefined) {
                        // set progress
                        const sendModule = module;
                        const sendSection = section;
                        const progressPages = check[scoreOptionIndex].progress_pages;

                        for (var i = 0; i < progressPages.length; i++) {
                            //get page
                            const pageTitle = progressPages[i].title;
                            let sendUpdates = {};
                            const sendPage = section.pages[pageTitle];


                            if (_.has(progressPages[i], 'responses')) {
                                //get responses to set to true in update
                                sendUpdates = progressPages[i].responses;
                            }
                            // send the update
                            this.updateProgressManually(sendUpdates, tool, sendModule, sendSection, sendPage);
                        }
                    }
                }
            }
            //check for and update our progressbar if we have it set
            this.updateProgressIndicator();
        } else {
            if(this.state.nextPageCheck && page.require_answers) {
                this.setUnansweredQuestions();
            }
        }
    },

    async UNSAFE_componentWillReceiveProps(nextProps) {
        const { module: { id: moduleId }, page: { id: pageId }, section: { id: sectionId }, tool: { slug: toolSlug } } = this.props;
        const nextModule = _.get(nextProps, 'module');
        const nextPage = _.get(nextProps, 'page');
        const nextSection = _.get(nextProps, 'section');
        const nextTool = _.get(nextProps, 'tool');
        if ((pageId !== _.get(nextPage, 'id'))
            || (sectionId !== _.get(nextSection, 'id'))
            || (moduleId !== _.get(nextModule, 'id'))
            || (toolSlug !== _.get(nextTool, 'slug'))) {
            await this.submitUpdates();
        }
    },

    createUserTokenCookie() {
        const { location } = this.props;
        const cookie_name = this.getCookieName();

        const cookie = {
            page: location?.pathname,
        };
        const expires = new Date();
        expires.setSeconds(expires.getSeconds() + (7 * 24 * 60 * 60)); // 7 days for expire
        cookies.remove(cookie_name);
        cookies.set(cookie_name, cookie, { path: '/', expires: expires, sameSite: true});
        return cookies.get(cookie_name);
    },

    checkAnswers(event, next_page) {
        event.preventDefault();
        const page = this.props.page;
        const responses = this.props.activeResponse?.responses ?? {};
        let requireIf = true;
        for(let i = 0; i < page.components.length; i++) {
            const components = page.components[i];
            if(_.get(components, 'fields')) {
                _.forEach(components.fields, function(field) {
                    let requiredAnswer = _.get(responses, field.id, false);

                    // this name is not good.
                    if(field && field.is_optional)
                      requiredAnswer = true

                    let element = document.getElementsByName(field.id);
                    if (element && element.length) {
                        element = element[0].parentElement.parentElement.parentElement;
                        // remove answer class
                        element.className = element.className.replace('needs-answer', '');
                        if (!requiredAnswer) {
                            requireIf = false;
                            // add the invalid class
                            element.className += ' needs-answer';
                        }
                    }
                });
            }
        }
        if(!requireIf) {
            this.props.dispatch(Actions.notify({
                title: "Page not complete",
                message: "Please answer all the questions.",
                level: 'error',
                autoDismiss: 4
            }));
            scrollTo(document.body, document.querySelector('.needs-answer').offsetTop, 500);
        } else {
            // this.props.dispatch(push(next_page));
            browserHistory.push(next_page);
        }
    },

    updateProgressIndicator() {
        // progress percentage for the progress indicator
        if(CUAC_SETTINGS.GROUP.show_progress) {
            let page_count = 0;
            let completed_page_count = 0;
            const { activeResponse } = this.props;
            const progress = activeResponse.progress;
            const modules = progress.modules;
            for (const module in modules) {
                if (typeof modules[module] === 'object') {
                    const sections = modules[module].sections;
                    for (const section in sections) {
                        if (typeof sections[section] === 'object') {
                            const pages = sections[section].pages;
                            for (const page in pages) {
                                if (typeof pages[page] === 'object') {
                                    page_count++;
                                    const visited = pages[page].visited;
                                    const question_count = pages[page].question_count;
                                    const response_count = pages[page].response_count;
                                    const status = getPageStatus({
                                        visited,
                                        question_count,
                                        response_count
                                    });
                                    if(status.completed) {
                                        completed_page_count++;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            const progress_percentage = Math.round((completed_page_count/page_count)*100);
            this.setState({progressPercentage: progress_percentage});
        }
    },

    nextButtonClickChecker(nextUrl) {
        const { activeResponseId, responses } = this.props;
        // check if we are still fetching, if we are, just set a timeout to be called again
        if(responses[activeResponseId].isFetching) {
            const that = this;
            setTimeout(() => {
                that.nextButtonClickChecker(nextUrl);
            }, 300)
        } else {
            this.nextButtonClick(nextUrl);
        }
    },

    async saveButtonClick() {
        this.setState({isSaving: true});
        let res;
        try {
            res = await this.submitUpdates();
        } catch(e) {
            console.error(e);
        } finally {
            this.setState({isSaving: false});
        }
        return res;
    },

    async nextButtonClick(nextUrl) {
        await this.submitUpdates();
        const { activeResponse, module, section, page, tool } = this.props;
        if(_.get(activeResponse, 'progress.modules.' + module.id + '.sections.' + section.id + ".pages." + page.id + ".question_count")) {
            const response_count = activeResponse.progress.modules[module.id].sections[section.id].pages[page.id].response_count;
            const question_count = activeResponse.progress.modules[module.id].sections[section.id].pages[page.id].question_count;
            if(response_count == question_count || (!page.require_answers && !tool.require_answers)) {
                // check if the page and conditionally required questions;
                if(!page.require_if || (page.require_if && this.checkRequireIf())) {
                    browserHistory.push(nextUrl);
                } else {
                    const el = document.querySelector('.is-invalid');
                    if (el) scrollTo(document.body, el.offsetTop, 500);
                    // sent the notification
                    this.props.dispatch(Actions.notify({
                        title: "Page not complete",
                        message: page.require_if_message,
                        level: 'error',
                        autoDismiss: 4
                    }));
                }
            } else {
                if (tool.require_answers) {
                    this.props.dispatch(Actions.notify({
                        title: "PAGE NOT COMPLETE",
                        message: "This page is not complete.",
                        level: 'error',
                        autoDismiss: 4
                    }));
                } else {
                    this.props.dispatch(Actions.notify({
                        title: "Page not complete",
                        message: "Please answer all the questions.",
                        level: 'error',
                        autoDismiss: 4
                    }));
                }
                this.setState({nextPageCheck: true});
                this.setUnansweredQuestions();
            }
        } else {
            browserHistory.push(nextUrl);
        }
    },

    checkRequireIf() {
        const page = this.props.page;
        const responses = this.props.activeResponse.responses;
        if (page.require_if) {
            let requireIf = true;
            for(const key in page.require_if) {
                const answer = _.get(responses, key, 0);
                // if the answer is 0, check for required questions
                if(answer == 0) {
                    const requiredIfQuestions = page.require_if[key];
                    _.forEach(requiredIfQuestions, function(question) {
                        const requiredAnswer = _.get(responses, question, 0);
                        if (requiredAnswer > 0) {
                            requireIf = false;
                            // add the invalid class
                            const el = document.getElementById(key);
                            if (el) {
                                if (el.tagName === 'INPUT') el.className += ' is-focused is-invalid';
                                else el.parentElement.className += ' is-focused is-invalid';
                            }
                        }
                    });
                }
            }
            return requireIf;
        } else {
            return true;
        }
    },

    setUnansweredQuestions() {
        const { activeResponse, module, section, page } = this.props;
        const red = '#de3226';
        if(_.get(activeResponse, 'progress.modules.' + module.id + '.sections.' + section.id + ".pages." + page.id + ".question_count")) {
            const responses = activeResponse.progress.modules[module.id].sections[section.id].pages[page.id].responses;
            for(var key in responses) {
                const element = document.querySelector('[name=' + key + ']');
                if(element && element.parentElement && element.parentElement.parentElement) {
                    const legend = element.parentElement.parentElement.getElementsByTagName('legend')[0];
                    if(legend) {
                        if(!responses[key]) {
                        legend.style.color = red;
                        } else {
                            legend.style.color = ''
                        }
                    } else {
                        if(element && element.parentElement && element.parentElement.parentElement && element.parentElement.parentElement.parentElement && element.parentElement.parentElement.parentElement.parentElement) {
                            const question = element.parentElement.parentElement.parentElement.parentElement.querySelector('.question');
                            if(question) {
                                if(!responses[key]) {
                                    question.style.color = red;
                                } else {
                                    question.style.color = ''
                                }
                            } else {
                                const label = element.parentElement.parentElement.parentElement.parentElement.getElementsByTagName('label')[0];
                                if(label) {
                                    if(!responses[key]) {
                                        label.style.color = red;
                                    } else {
                                        label.style.color = ''
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },

    updateResponse(data, formulas, formName) {
        const { activeResponse, activeResponseId, dispatch, userProfile } = this.props;
        // build an array of the immediately impacted formulas, as well as any that need to be
        // re-calculated upstream
        const impactedFormulas = [];

        // Getting empty object sometimes, this fixes that.
        if(typeof formulas === 'object' && formulas !== null && Object.keys(formulas).length < 1)
            formulas = [];

        (formulas || []).forEach(formula => {
            impactedFormulas.push(formula);
            const { impacts_formulas } = formula;
            if (Array.isArray(impacts_formulas)) {
                impacts_formulas.forEach(dataKey => {
                    const { [dataKey]: x } = (this.props.tool || {}).formulas || {};
                    if (x) impactedFormulas.push(x);
                })
            }
        })

        const calculatedData = calculateResponses(
            impactedFormulas,
            {
                ..._.get(activeResponse, 'responses', {}),
                ...userProfile
            },
            data
        );
        // cache this data in redux
        dispatch(applyResponse(activeResponseId, calculatedData));
        dispatch(cacheUpdate(activeResponseId, calculatedData));
        if (formName) dispatch(initialize(formName, {}));
    },

    // Bulk submit the current page's response updates to server. This is a blocking operation
    // and should be invoked on page change/leave
    async submitUpdates() {
        const { token, _id: userId } = this.props.auth?.user ?? {};
        if (!token || !userId) return;
        const { updates, _id: responseId } = this.props.activeResponse;
        if (!updates && !Object.keys(updates || {}).length) return;
        return this.props.dispatch(submitUpdates(token, userId, responseId, this.props.module, this.props.section, this.props.page));
    },

    updateProgressManually(data, tool, module, section, page) {
        const { activeResponseId, auth, dispatch } = this.props;
        const token = _.get(auth, 'user.token');
        const userId = _.get(auth, 'user._id');
        dispatch(Actions.updateResponse(token, userId, activeResponseId, data, tool, module, section, page));
    },

    onClickLink(target) {
        browserHistory.push(target);
    },

    addTrackerEntry(data, trackerEntryName, tag, resetValues) {
        const { auth, dispatch } = this.props;
        const token = _.get(auth, 'user.token');
        const userId = _.get(auth, 'user._id');
        dispatch(Actions.addTrackerEntry(token, userId, data, trackerEntryName, tag));
        dispatch(initialize(
            trackerEntryName + 'Tracker',
            typeof resetValues !== 'undefined' ? resetValues : {}
        ));
    },

    showRemindersModal() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'newReminder',
            edit_reminder: {},
            modalContent: true
        }));
    },

    showHelpFeedbackModal() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'helpFeedback',
            modalContent: true
        }));
    },

    previousPageURL() {
        const returnUrl = _.get(this.props.location, 'query.returnUrl');
        if (returnUrl) return returnUrl;
        if (!this.props.page.previous_page) return;
        const prevPage = this.props.page.previous_page,
            prevPageParts = prevPage.split('/').filter(x => !!x.length);
        // if previous page URL actually links to a page, return it
        if (prevPageParts.length === 4) return prevPage;
        const { moduleSlug, sectionSlug, pageSlug } = this.props.params,
            currPageURL = `/${this.props.tool.slug}/${moduleSlug}/${sectionSlug}/${pageSlug}`;
        // build array of sequenced page URLs
        const pageURLS = this.props.tool.metadata.order.reduce((acc, moduleKey) => {
            const { [moduleKey]: module } = this.props.tool.metadata.modules;
            (module.order || []).forEach(sectionKey => {
                const { [sectionKey]: section } = module.sections || {};
                (section.order || []).forEach(pageKey => {
                    const { [pageKey]: page } = section.pages || {};
                    acc.push(`/${this.props.tool.slug}/${module.slug}/${section.slug}/${page.slug}`);
                })
            });
            return acc;
        }, []),
            pageIdx = pageURLS.indexOf(currPageURL);
        // find index of current page, and return previous if possible
        if (pageIdx >= 1) return pageURLS[pageIdx - 1];
        return prevPage;
    },

    async prevButtonClick(url) {
        await this.submitUpdates();
        browserHistory.push(url);
    },

    renderSequenceNav() {
        const { activeResponse, page } = this.props;
        const btns = [];
        const previousURL = this.previousPageURL();
        if (previousURL) {
            btns.push(
              <BSButton disabled={this.state.isSaving} onClick={() => this.prevButtonClick(previousURL)} variant="link-green" key="prev-page-btn">
                  <Ionicons name="arrow-back" size={24} color={FOREST_GREEN} />
                  <div className="ps-1">
                      <FormattedMessage
                        id="dynamic_page_sequence_button_previous"
                        description="Take the user to the previous page"
                        defaultMessage="Previous"
                      />
                  </div>
              </BSButton>
            )
        }

        let nextUrl = null;

        if(_.get(page, 'next_page_parameter')) {
            // if the page has a next page parameter, we need to get the right response
            // set up our variables
            const next_page_parameter = _.get(page, 'next_page_parameter');
            const answer = _.get(activeResponse, next_page_parameter);
            const options = _.get(page, 'next_page_options');
            const check = _.get(page, 'next_page_check');
            if(options) {
                // figure out which option we want
                if (answer == null) {
                    // set our next page url
                    nextUrl = options.next_page;

                } else {
                    // if the answer is there use next page
                    nextUrl = _.get(page, 'next_page');
                }
            } else if(check) {
                // figure out which option we want
                const scoreOptionIndex = getScoreOptionIndex(answer, check);
                // set our next page url
                nextUrl = check[scoreOptionIndex].next_page;
            }
        } else if(_.get(page, 'next_page')) {
            // if we only have a next page parameter, just use that
            nextUrl = _.get(page, 'next_page');
        }
        btns.push(
            <SaveToolButton
                save={this.saveButtonClick}
                disabled={this.state.isSaving}
            />
        );

        if (nextUrl) {
            btns.push(
              <BSButton disabled={this.state.isSaving} variant="green" onClick={e => this.checkAnswers(e, nextUrl)} key="next-page-btn">
                  <div className="pe-1">
                      <FormattedMessage
                        id="dynamic_page_sequence_button_next"
                        description="Take the user to the next page"
                        defaultMessage="Next"
                        disabled={CUAC_SETTINGS.GROUP.isDisabled}
                      />
                  </div>
                  <Ionicons name="arrow-forward" size={24} color="white" />
              </BSButton>
            )
        }

        return (
            <div className="sequence-nav">
                <div className="sequence-buttons d-flex align-items-center justify-content-between">
                    { btns }
                </div>
            </div>
        );

    },

    focusField() {
        const field = _.get(this.props.location, 'query.field');
        if (!field || !field.length) return;
        let el = document.getElementById(field);
        if (!el) {
            el = document.getElementsByName(field);
            if (!el.length) return;
            el = el[0];
        }
        el.scrollIntoView({ behavior: 'smooth' });
    },

    render() {
        const { activeResponse, module, norms, page, section, tool, tools, auth, dispatch, trackerEntries, trackerEntryStats, userProfile, params, responses } = this.props;
        return (
          <ToolLayout tool={this.props.tool} activeResponse={this.props.activeResponse} loading={this.props.responses.isFetching}>
              <h3 className="card-title fw-bold">
                  <FormattedMessage { ...page.long_title } />
                  {page.long_title.defaultMessage === 'Feedback Summary' &&
                      <Button onClick={() => {
                        print();
                        }} variant="green" size="sm" style={{margin: 'auto', marginLeft: '1vw'}} className="no-print">
                          <FontAwesomeIcon icon={faPrint} color={"white"} /><span className='text-small'>&nbsp;&nbsp;Print / Save</span>
                      </Button>
                  }
              </h3>
              {!section.hide_page_nav &&
                <div id="page-nav" className="my-4">
                  {
                      (section.order || []).map((pageKey, idx) => {
                          const {[pageKey]: p} = section.pages || {},
                              isActive = p.slug === page.slug,
                              url = `/${tool.slug}/${module.slug}/${section.slug}/${p.slug}`;

                          const onClick = e => {
                              e.preventDefault();
                              browserHistory.push(url);
                          }

                          return (
                              <a href="#" onClick={onClick} className="p-1 text-center" style={{
                                  flex: 1,
                                  borderRight: idx < (section.order.length - 1) ? '1px solid #7b7b7b' : 'none'
                              }}>
                                  <div className="d-flex flex-column justify-content-center align-items-center">
                                      <div className="mb-2 d-flex align-items-center justify-content-center" style={{
                                          height: 36,
                                          width: 36,
                                          borderRadius: 18,
                                          backgroundColor: isActive ? FOREST_GREEN : 'transparent'
                                      }}>
                                          <p className="h3 fw-bold mb-0"
                                             style={{color: isActive ? 'white' : '#7b7b7b'}}>{idx + 1}</p>
                                      </div>
                                      <p className="text-uppercase"
                                         style={{color: isActive ? FOREST_GREEN : '#6e6e6e'}}>
                                          <FormattedMessage {...p.short_title} />
                                      </p>
                                  </div>
                              </a>
                          )
                      })
                  }
              </div>
                }
              <div className="section overview-tool modal-link-override">
                  {
                      page.components.map((component, index) => {
                          return (
                            <div className="my-4" key={'block_' + index}>
                                <Block
                                  component={component}
                                  index={index}
                                  norms={norms}
                                  activeResponse={_.omit(activeResponse, ['progress', 'responses'])}
                                  responses={_.get(activeResponse, 'responses', {})}
                                  userProfile={userProfile}
                                  updateResponse={this.updateResponse}
                                  updateProgressManually={this.updateProgressManually}
                                  addTrackerEntry={this.addTrackerEntry}
                                  trackerEntries={trackerEntries}
                                  trackerEntryStats={trackerEntryStats}
                                  tool={tool}
                                  module={module}
                                  section={section}
                                  auth={auth}
                                  dispatch={dispatch}
                                  params={params}
                                  allResponses={responses}
                                  tools={tools}
                                />
                            </div>
                          );
                      })
                  }
              </div>
              <div className="mt-3">
                  {this.renderSequenceNav()}
              </div>
          </ToolLayout>
        )
    }
});

export default connect(
    (state, props) => ({
        auth: state.auth,
        activeResponseId: state.responses.activeResponseId,
        activeResponse: _.get(state.responses[state.responses.activeResponseId], 'response'),
        trackerEntries: state.trackerEntries,
        trackerEntryStats: state.trackerEntryStats,
        ...pageSelector(state, props),
        ...userNormsSelector(state),
        ...userProfileSelector(state),
        responses: state.responses,
        tools: state.tools
    })
)(DynamicPage);
