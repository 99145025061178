'use strict';

import _ from 'lodash';
import Moment from 'moment'; Moment.locale('en');
import React from 'react';
import { findDOMNode } from 'react-dom';
import { c3formatDates } from 'utils/tools';
import isMobile from 'utils/is-mobile';

import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import d3 from 'd3';
import c3 from 'c3';

const Chart = React.createClass({

    propTypes: {
        intl: React.PropTypes.object,
        period: React.PropTypes.number,
        responses: React.PropTypes.object,
        stat_group: React.PropTypes.string,
        sub_title: React.PropTypes.object,
        title: React.PropTypes.object,
        trackerEntryStats: React.PropTypes.object,
        metrics: React.PropTypes.arrayOf(React.PropTypes.object)
    },

    componentDidMount() {
        this.buildChart(this.props);
    },

    UNSAFE_componentWillUpdate(nextProps) {
        this.buildChart(nextProps);
    },

    buildFullChartData(chartData, metrics, period) {
        const fullChartData = [];
        const endDate = Moment.utc();
        let currentDate = Moment.utc(_.get(chartData, '[0]._id'));
        let chartDataIndex = 0;
        while (currentDate.isSameOrBefore(endDate) && chartDataIndex < chartData.length) {
            const currentDateStats = {
                '_id': currentDate.toISOString()
            };
            const currDateStr = currentDate.startOf('day').format('YYYY-MM-DD'),
              chartDateStr = Moment(_.get(chartData, '[' + chartDataIndex + ']._id')).startOf('day').format('YYYY-MM-DD');
            if (currDateStr === chartDateStr) {
                metrics.forEach((metric) => {
                    currentDateStats[metric.key] = chartData[chartDataIndex][metric.key];
                });
                fullChartData.push(currentDateStats);
                chartDataIndex++;
            }
            currentDate.add(period, 'd');
        }
        return fullChartData;
    },

    chart: null,
    goldColor: 'gold',

    calculateStarPoints(centerX, centerY, arms, outerRadius, innerRadius) {
       var results = "";

       var angle = Math.PI / arms;

       for (var i = 0; i < 2 * arms; i++)
       {
          // Use outer or inner radius depending on what iteration we are in.
          var r = (i & 1) == 0 ? outerRadius : innerRadius;
          
          var currX = centerX + Math.cos(i * angle) * r;
          var currY = centerY + Math.sin(i * angle) * r;

          // Our first time we simply append the coordinates, subsequet times
          // we append a ", " to distinguish each coordinate pair.
          if (i == 0)
          {
             results = currX + "," + currY;
          }
          else
          {
             results += ", " + currX + "," + currY;
          }
       }

       return results;
    },

    buildChart(props) {
        const { intl: { formatMessage }, lines, period, responses, stat_group, trackerEntryStats, metrics, chart, chart_type } = props;
        let chartData = _.get(trackerEntryStats, 'trackerEntryStats.' + stat_group, []);
        if (_.isEmpty(chartData)) {
            return false;
        }
        // filter the urge counts for no intensity
        if(stat_group === 'urge_day' && metrics[0].key === 'count' && _.get(chartData[0], 'avg_intensity')) {
            chartData = chartData.filter((data) => {
                return data.avg_intensity > 0;
            });
        }
        // if we are on a mobile device, we need to limit the amount of data shown
        // the negative number is the amount of data points
        if (isMobile()) {
            chartData = chartData.slice(-10);
        }
        const fullChartData = this.buildFullChartData(chartData, metrics, period);
        const el = this.refs.chartTarget;
        const dates = ['x'].concat(_.pluck(fullChartData, '_id'));
        const columns = metrics.map((metric) => {
            return [formatMessage(metric.label)].concat(_.pluck(fullChartData, metric.key));
        }).concat([dates]);
        let absoluteLimit;
        let regularLimit;
        const chartLines = _.filter((typeof lines !== 'undefined' ? lines : []).map((line) => {
            const value = _.get(responses, line.response_key);
            if(line.label.id.indexOf('absolute') > -1) {
                absoluteLimit = parseFloat(value, 10);
            } else if (line.label.id.indexOf('regular') > -1) {
                regularLimit = parseFloat(value, 10);
            }
            return typeof value !== 'undefined'
            ? {
                value: parseFloat(value, 10),
                text: formatMessage(line.label),
                position: 'start',
                class: 'grid-lines'
            }
            : undefined;
        }), (chartLine) => {
            return typeof chartLine !== 'undefined';
        });
        let type = "bar";
        if(chart_type) {
            type = chart_type;
        }
        let labels = false;
        // we set the labels for true so we can replace 0 with star
        if((_.get(this.props.params, "toolSlug") == 'moderate' && stat_group.indexOf('mood') === -1) || stat_group === 'urge_day') {
            labels = true;
        } 
        var that = this;
        this.chart = c3.generate({
            bindto: el,
            data: {
                x: 'x',
                columns: columns,
                xFormat: '%Y-%m-%dT%H:%M:%S.000Z',
                color:function(color,d){
                    if(_.get(that.props.params, "toolSlug") == 'moderate' && stat_group.indexOf('mood') === -1) {
                        if(d.value === 0) {
                            return that.goldColor;
                        } else if (absoluteLimit && d.value > absoluteLimit) {
                            return '#ef5756';
                        } else if (regularLimit && d.value > regularLimit) {
                            return '#f07f4f';
                        } else {
                            return color;
                        }
                    } else if (stat_group === 'urge_day') { 
                        if(d.value === 0) {
                            return that.goldColor;
                        } else {
                            return color;
                        }
                    } else {
                        return color;
                    }
                },
                type: type,
                labels: labels
            },
            bar: {
                width: {
                    ratio: 0.15 // this makes bar width 50% of length between ticks
                }
                // or
                //width: 100 // this makes bar width 100px
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: function(x) {
                            if(stat_group === 'drink_week' || stat_group === 'hours_intoxicated_week') {
                                const date = Moment(x);
                                const weekLater = Moment(x).add(6, 'days');
                                let firstDateFormat = 'D';
                                const lastDateFormat = 'D MMM YY'
                                // if we go into a different month, add that, otherwise just use the day.
                                if(!date.isSame(weekLater, 'month')) {
                                    firstDateFormat = 'D MMM';
                                }
                                return date.format(firstDateFormat) + '-' + weekLater.format(lastDateFormat);
                            } else {
                                return Moment(x).format('D MMM YYYY');
                            }
                        },
                        rotate: -60,
                        multiline: false,
                        culling: (stat_group === 'drink_week' || stat_group === 'hours_intoxicated_week') ? false : true
                    },
                    height: 80
                },
                y: {
                    tick: {
                        format: function (d) {
                            if(d === 0 && _.get(that.props.params, "toolSlug") == 'moderate' && stat_group.indexOf('mood') === -1) {
                                return "Abstinent"
                            } else {
                                return d
                            }
                        }
                    }
                }
            },
            grid: {
                x: {
                    show: false
                },
                y: {
                    show: _.isEmpty(chartLines),
                    lines: chartLines
                }
            },
            zoom: {
                enabled: true
            },
            color: {
                pattern: ['#8BC34A', '#de3226', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a']
            },
            tooltip: {
                format: {
                    title: function (d) {
                        const format = d3.time.format('%e %b %Y');
                        return format(d);
                    },
                    value: function (value) { 
                        if(value === 0 && _.get(that.props.params, "toolSlug") == 'moderate' && stat_group.indexOf('mood') === -1) { 
                            return "Abstinent"
                        } else if(value === 0 && stat_group === 'urge_day') { 
                            return "No Urges"
                        } else {
                            return value; 
                        }
                    }
                }
            },
            onrendered: function() {
                if(_.get(that.props.params, "toolSlug") == 'moderate' || stat_group === 'urge_day') {
                    let select;
                    if(stat_group === 'urge_day') {
                        select = d3.select("#" + stat_group + metrics[0].key).select('.c3-texts');
                    } else {
                        select = d3.select("#" + stat_group).select('.c3-texts');    
                    }
                    let texts = select.selectAll('text');
                    let stars = select.selectAll('polygon');
                    stars.remove();
                    if (texts.length) {
                        texts[0].forEach(function(t) {
                            if(t.style.fill == that.goldColor) {
                                select.append("svg:polygon")
                                    .attr("points", that.calculateStarPoints(t.x.baseVal.getItem(0).value, t.y.baseVal.getItem(0).value - 3, 5, 7, 3.5))
                                    .style('fill', t.style.fill)
                                    .style('stroke', '#333');
                            }
                            t.remove();
                        });
                    }
                }
            }

        });

    },


    render() {
        const { title, sub_title, stat_group, metrics } = this.props;
        return (
            <div className="chart-outer-wrap" id={stat_group === 'urge_day' ? stat_group + metrics[0].key: stat_group}>
                <h4 className="chart-title">
                    <FormattedMessage {...title} />
                </h4>
                {
                    sub_title && (
                      <div className="instructions"><FormattedMessage {...sub_title} /></div>
                    )
                }
                <div className="chart-inner-wrap" ref="chartTarget" />
            </div>
        );
    }
});

export default injectIntl(Chart);
