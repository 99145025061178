/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';
import _ from 'lodash';
import React, { useEffect, useState, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import Moment from 'moment'; Moment.locale('en');
import Actions from 'actions';
import Selectors from 'selectors';
const { userNormsSelector, userProfileSelector } = Selectors;
import InfoBug from 'components/popover/infobug';
import { Accordion } from 'react-bootstrap';
import ToggleSwitch from 'components/ux/switch';
import { canUseTool } from 'utils/render';
import Cookies from 'cookies-js';
import notify from 'actions/notify';
import TrackerNav from "../components/blocks/TrackerNav.component.web";
import BSButton from "../components/blocks/BSButton.component.web";
import { Ionicons } from "@expo/vector-icons";
import { BLUE, LIGHT_FOREST_GREEN } from '../stylesheets/colors';
import Loading from '../components/blocks/Loading.component.web';
import BrandedImage from "../components/blocks/branded-image";
import UniversalCookies from 'universal-cookie';

const cookies = new UniversalCookies();

const ToolItem = props => {
    const onClick = () => {
        if (props.response) props.editResponse(props.response.response?._id, props.tool);
        else props.addResponse(props.tool);
    }

    const progress = () => {
        if (!props.response) return null;
        const { progress } = props.response.response,
          { response_count, question_count } = progress || {};
        if (!question_count) return null;
        const width = Math.round(((response_count || 0) / question_count) * 100);
        return (
          <div className="ms-lg-2 mb-2">
              <p className="text-small mb-0">{ width }% Completed</p>
              <div className="progress w-100">
                  <div className="progress-bar"
                       role="progressbar"
                       style={{ width: `${width}%`, backgroundColor: BLUE }}
                       aria-valuenow={width}
                       aria-valuemin="0"
                       aria-valuemax="100"
                  />
              </div>
          </div>
        )
    }

    const btnLabel = () => {
        if (props.response && props.response.response && !(props.response.response.isComplete || props.response.response.tool_complete)) {
            return(
                <FormattedMessage
                    id="overview_button_begin"
                    description="continue with tool"
                    defaultMessage="Continue"
                />
            ); 
        } else if (props.response) {
            return (
                <FormattedMessage
                    id="overview_button_begin"
                    description="Review completed response"
                    defaultMessage="Review"
                />
            )
        } else {
            return (
                <FormattedMessage
                    id="overview_button_begin"
                    description="Let's get started"
                    defaultMessage="Begin"
                />
            )
        }
    }

    return (
      <li
        key={props.tool._id}
        className="list-group-item"
      >
          <div className="d-flex flex-column flex-lg-row w-100 justify-content-between mb-2">
              <h5 className="mb-0">
                  <FormattedMessage { ...props.tool.long_title } />
              </h5>
              { !props.hideProgress && progress() }
          </div>
          <div className="d-flex flex-column flex-lg-row justify-content-between mb-2">
              <p className="mb-2 me-2"> </p>
              <BSButton
                variant="green"
                onClick={onClick}
                disabled={props.disableButton}
              >
                  <div className="me-2">
                      { btnLabel() }
                  </div>
                  <Ionicons name="arrow-forward" size={20} color="white"/>
              </BSButton>
          </div>
          {
              props.showEmailToggle && _.get(props.tool, 'guided_email_id') && (
                <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center me-2">
                        <p className="mb-0">Receiving Guided Program Emails</p>
                        <InfoBug>
                            <FormattedMessage
                              id="guided_email_infobug"
                              defaultMessage="We recommend using the guided email feature to move through the program. If you check the box, we'll send you an email weekly for 12 weeks. The email will guide you through the program step by step which improves your chances of learning the tools."
                            />
                        </InfoBug>
                    </div>
                    <ToggleSwitch
                      switchId={props.tool._id}
                      onSwitch={(_, checked) => props.onEmailSwitch(props.tool._id, checked)}
                      checked={props.checkAllowEmail(props.tool._id)}
                      labelTrue="On"
                      labelFalse="Off"
                    />
                </div>
              )
          }
      </li>
    )
}

const OverviewPage = props => {
    const [orderedResponses, setOrderedResponses] = useState(undefined);
    const [loadingResponses, setLoadingResponses] = useState(false);
    const [responses, setResponses] = useState(undefined);
    const [processingButton, setProcessingButton] = useState(false);

    useEffect(() => {
        setLoadingResponses(!orderedResponses || !orderedResponses.length);
    }, [ orderedResponses ]);

    useEffect(() => {
        (async () => {
            const token = _.get(props, 'auth.user.token');
            const userId = _.get(props, 'auth.user._id');
            const toolId = _.get(props.activeResponse, 'tool_id');
            const emailResponses = [ ...(responses || []) ];
            // The initialization function for the guided emails switch value.
            const json = await props.dispatch(Actions.getUserEmailEntry(token, userId, toolId))
            const payload = json.payload.response;
            if (payload) emailResponses.push({id: toolId, allow_email: payload.allow_email, showToggle: true});
            else emailResponses.push({id: toolId, allow_email: false, showToggle: true});
            setResponses(responses);
        })();

        if (!isOnOwnSubdomain()) {
            props.dispatch(Actions.updateDisplay('appSpinner', 'STOP'));
            props.dispatch(Actions.showModal('appModal', {
                modalNotifications: 'wrongSubdomain',
                modalContent: true
            }));
        }
        else {
            if (!_.isEmpty(props.plans) || _.get(CUAC_SETTINGS.GROUP, 'default_program_id')) {
                orderResponses();
            }
            else {
                // if we are still fetching plans, show them this
                props.dispatch(Actions.updateDisplay('appSpinner', 'LOGGING_YOU_IN'));
            }
            if (CUAC_SETTINGS.GROUP.code && props.location.pathname === "/" && props.auth.user.tool_ids.length < 4) {
                if (!Cookies.get('shown_coupon')) showUpgradeModal();
                else if (_.get(props.auth, 'user.roles.subscriber') && props.auth.user.roles.subscriber.indexOf(CUAC_SETTINGS.GROUP._id) !== -1) {
                    props.dispatch(Actions.notify({
                        title: "Ready to make changes in your life?",
                        message: "Upgrade to our Choices Program to get help in the “how to” of cutting back or quitting.",
                        level: 'success',
                        autoDismiss: 20,
                        action: {
                            label: "Upgrade Now",
                            callback: function() {
                                browserHistory.push('/account/plans');
                            }
                        }
                    }));
                }
            }
        }
    }, []);

    useEffect(() => {
        if (orderedResponses === undefined && isOnOwnSubdomain())
            orderResponses();
    }, [ props ]);

    const renderPrintProgress = () => {
        const { auth: { user }, tools, responses, plans} = props;
        let toolArray = [];
        for (let key in tools) {
            if (typeof tools[key] === 'object' && _.get(tools, key + ".slug") !== "screener") {
                if (canUseTool(user, tools[key], responses, plans)) {
                    toolArray.push(
                      <BSButton
                        variant="link-green"
                        linkTo={`/progress/` + tools[key].slug}
                        key={'progress-' + tools[key]._id}
                      >
                          <FormattedMessage {...tools[key].long_title} />
                      </BSButton>
                    )
                }
            }
        }

        if (!toolArray.length || !CUAC_SETTINGS.GROUP.print_progress || !CUAC_SETTINGS.GROUP.print_progress_message) return null;
        return (
          <div className="card mt-4" style={{ border: 'none' }}>
              <div className="card-body">
                  <h3 className="card-title">
                      <FormattedMessage
                        id="overview_print_progress_title"
                        description="Overview -- print progress section -- title"
                        defaultMessage="Print Your Progress"
                      />
                  </h3>
                  {
                      CUAC_SETTINGS.GROUP.print_progress_message && (
                        <p className="text-muted">
                            <FormattedMessage {...CUAC_SETTINGS.GROUP.print_progress_message}/>
                        </p>
                      )
                  }
                  {toolArray}
              </div>
          </div>
        )
    }

    const showUpgradeModal = () => {
        Cookies.set('shown_coupon', true);
        props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'genericModal',
            modalContent: true,
            modalCustomTitle: {
                "defaultMessage": "Ready to make changes in your life?",
                "id": "modal_more_tools"
            },
            modalCustomContent: {
                "components": [
                    {
                        "children": [
                            {
                                "text": {
                                    "defaultMessage": "Upgrade to our Choices Program to get help in the “how to” of cutting back or quitting.",
                                    "id": "modal_more_tools_p_0"
                                },
                                "type": "formatted_message"
                            }
                        ],
                        "type": "p",
                        className: 'mb-2 text-center'
                    },
                    {
                        "children": [
                            {
                                "text": {
                                    "defaultMessage": "Save $34 with coupon code TIME4CHANGE",
                                    "id": "modal_more_tools_p_1"
                                },
                                "type": "formatted_message",
                            }
                        ],
                        "className": "text-center bg-theme-light-yellow text-dark p-2 rounded",
                        "type": "h4"
                    },
                ]
            },
            modalComponent: (
              <p className="mt-3 d-flex justify-content-center">
                  <BSButton
                    variant="green"
                    onClick={() => {
                        browserHistory.push('/account/plans');
                        props.dispatch(Actions.closeModal('appModal', {
                            modalNotifications: '',
                            edit_reminder: {},
                            moodEntry: {},
                            urgeEntry: {},
                            drinkTrackerEntry: {},
                            goalTrackerEntry: {},
                            solutionTrackerEntry: {},
                            modalContent: false
                        }));
                    }}>
                      <FormattedMessage
                        id="go_to_account_page"
                        description="Go to accout page with coupon"
                        defaultMessage="Learn More"
                      />
                  </BSButton>
              </p>
            ),
            modalNorms: {},
            modalResponses: {},
            scroll: false
        }));
    }

    const showEmailToggle = id => {
        return _.find((responses || []), function(response){
            if(id === response.id)
                return response.showToggle;
        })
    }

    const onEmailSwitch = async (toolId, checked) => {
        const token = _.get(props.auth, 'user.token');
        const userId = _.get(props.auth, 'user._id');
        const allowEmail = !!checked;
        const emailResponses = [];
        const json = await props.dispatch(Actions.updateUserEmailEntry(token, userId, toolId, allowEmail))
        _.map(responses, function(response, index) {
            if(response.id === toolId){
                response.allow_email = json.payload.response ? json.payload.response.allow_email : false;
            }
            var showToggle = response.allow_email !== undefined;
            emailResponses.push({id: response.id, allow_email: response.allow_email, showToggle: showToggle});
            if(index === email_responses.length - 1)
                setResponses(emailResponses);
        })
    }

    const addResponse = async tool => {
        await handleResponse(tool);
    }

    const editResponse = async (responseId, tool) => {
        await handleResponse(tool, responseId);
    }

    const handleResponse = async (tool, responseId = null) => {
        setProcessingButton(true);
        const token = _.get(props, 'auth.user.token');
        const userId = _.get(props, 'auth.user._id');
        const cookie = cookies.get(`user-token-${_.get(tool, "_id")}`);

        if (responseId)
            props.dispatch(Actions.setResponse(token, userId, responseId));
        else
            await props.dispatch(Actions.addResponse(token, userId, tool));

        setProcessingButton(false);

        if (cookie)
            browserHistory.push(cookie.page);
        else
            browserHistory.push('/' + tool.slug);
    }

    const isOnOwnSubdomain = () =>
      _.get(props.auth, 'user.roles.subscriber') && props.auth.user.roles.subscriber.indexOf(CUAC_SETTINGS.GROUP._id) !== -1;

    const renderTrackerNavButtons = () => {
        const { auth: { user }, tools} = props;
        const toolIds = _.get(user, 'tool_ids', []);
        let userTools = _.map(toolIds, (toolId) => {
            return _.get(tools, toolId);
        });
        _.remove(userTools, (tool) => {
            if(_.get(tool, 'slug')) {
                return (tool.slug.indexOf('checkup') > -1 || tool.slug.indexOf('precheck') > -1);
            }
        });
        if (!userTools.length) return null;
        // show a pop up for reminders
        if (!Cookies.get('shown-tracker-reminder')) {
            Cookies.set('shown-tracker-reminder', true);
            props.dispatch(notify({
                title: "Trackers",
                message: "Welcome back. Please enter your urge and drink/no drink data first.",
                level: 'success',
                autoDismiss: 4
            }))
        }
        return <TrackerNav />
    }

    const compare = (a,b) => {
        a = Moment(a.dt_c, 'YYYY-MM-DD hh:mm A');
        b = Moment(b.dt_c, 'YYYY-MM-DD hh:mm A');
        return a.valueOf() - b.valueOf();
    }

    const arrayUnique = (array) => {
        let flags = [], output = [], l = array.length, i;
        for( i=0; i<l; i++) {
            if( flags[array[i].tool_id]) continue;
            flags[array[i].tool_id] = true;
            output.push(array[i]);
        }
        return output;
    }
    
    const checkForRecurring = (tool, responseCount, responseArray) => {
        const { tools } = props;
        if(_.get(tool, 'recurring')) {
            // go through our rules
            for(let j = 0; j < tool.recurring.length; j++) {
                for(let i = 0; i < responseArray.length; i++) {
                    if(responseArray[i].tool_complete) {
                        // see if the response is in the slugs listed in the rule
                        const slug = _.get(tools[responseArray[i].tool_id], 'slug');
                        if(tool.recurring[j].slug == slug) {
                            // if so, check if it is after the interval
                            const timeToCompare = responseArray[i].completion_time ? responseArray[i].completion_time : responseArray[i].dt_u;
                            const newDate = Moment(timeToCompare, 'YYYY-MM-DD hh:mm A').add(tool.recurring[j].interval, tool.recurring[j].interval_type);
                            const today = Moment();
                            if(newDate.isBefore(today)) {
                                // if we have a tool that has started + the interval before today, then we should recur
                                // but first we should check the total numbers of recurrances for that tool slug
                                if(_.get(responseCount, tool.slug, 0) < tool.recurring[j].times) {
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    const renderToStart = () => {
        const { auth: { user }, tools, responses, plans} = props;
        const toolIds = _.get(user, 'tool_ids', []);
        const actualTools = _.map(toolIds, (toolId) => {
            return _.get(tools, toolId);
        });
        // get our response counts
        let responseCount = {}
        // create a response array, sorted by dt_c
        let responseArray = [];
        _.forOwn(responses, (val, key)=>{
            if(_.get(val, 'response.dt_c')) {
                responseArray.push(val.response);
            }
            // get our global response counts
            if(_.get(val, 'response.tool_id')) {
                const tool = tools[val.response.tool_id];
                // only include responses if the user can use the tool (maybe has more than one subdomain)
                if(canUseTool(user, tool, responses, plans)) {
                    const slug = _.get(tool, 'slug');
                    if(typeof responseCount[slug] !== 'undefined') {
                        responseCount[slug]++;
                    } else {
                        responseCount[slug] = 1;
                    }
                }
            }
        });
        // order the responses by date
        responseArray.sort(compare);
        // only get one response per tool, so we know the lastest date only
        responseArray = arrayUnique(responseArray);

        const unstartedTools = [],
          alreadyStartedTools = [];
        const that = this;
        actualTools.forEach( (tool)=> {
            if (
              (typeof tool !== 'undefined')
              &&
              canUseTool(user, tool, responses, plans)
              && _.get(tool, '_id') !== CUAC_SETTINGS.GROUP.screener_tool_id
            ) {
                let unstarted = true;
                const canRecur = checkForRecurring(tool, responseCount, responseArray);

                if(!canRecur) {
                    _.forOwn(responses, (val, key)=>{
                        if (key !== 'activeResponseId' && key !== 'isFetching') {
                            if (val.response.tool_id === tool._id) {
                                unstarted = false;
                            }
                        }
                    });
                }
                if (unstarted){
                    if(_.get(tool, 'recurring')) {
                        if(canRecur) {
                            unstartedTools.push(tool);
                        }
                    } else {
                        unstartedTools.push(tool);
                    }
                } else {
                    alreadyStartedTools.push(tool);
                }

            }
        });
        return unstartedTools.map(tool => (
          <Fragment key={tool._id}>
              <ToolItem
                tool={tool}
                addResponse={addResponse}
                editResponse={editResponse}
                onEmailSwitch={onEmailSwitch}
                checkAllowEmail={checkAllowEmail}
                showEmailToggle={showEmailToggle(tool._id)}
                disableButton={processingButton}
              />
          </Fragment>
        ))
    }

    const renderCompleted = () => {
        const { tools } = props;
        if (_.isEmpty(orderedResponses)) return null;
        const completed = (orderedResponses || []).map(response => {
            const { tool_id: toolId } = response.response;
            const tool = _.get(tools, toolId);
            if (typeof tool === 'undefined') return null;
            if(!(response?.response?.isComplete || response?.response?.tool_complete)) { return null; }
            return (
              <Fragment key={toolId}>
                  <ToolItem
                    addResponse={addResponse}
                    editResponse={editResponse}
                    tool={tool}
                    response={response}
                    hideProgress={true}
                    onEmailSwitch={onEmailSwitch}
                    checkAllowEmail={checkAllowEmail}
                    showEmailToggle={showEmailToggle(tool._id)}
                    disableButton={processingButton}
                  />
              </Fragment>
            )
        }).filter(x => !!x);
        return completed;
    }
    
    const renderInProgress = () => {
        const { tools } = props;
        if (_.isEmpty(orderedResponses)) return null;
        return (orderedResponses || []).map(response => {
            const { tool_id: toolId } = response.response;
            const tool = _.get(tools, toolId);
            if (typeof tool === 'undefined') return null;
            if(response?.response?.isComplete || response?.response?.tool_complete) { return null; }
            return (
              <Fragment key={toolId}>
                  <ToolItem
                    addResponse={addResponse}
                    editResponse={editResponse}
                    tool={tool}
                    response={response}
                    onEmailSwitch={onEmailSwitch}
                    checkAllowEmail={checkAllowEmail}
                    showEmailToggle={showEmailToggle(tool._id)}
                    disableButton={processingButton}
                  />
              </Fragment>
            )
        }).filter(x => !!x)
    }

    const checkAllowEmail = id => {
        const response = (responses || []).find(response => response.id === id);
        return response?.allow_email;
    }

    const renderTools = () => {
        const inProgress = renderInProgress(),
            completed = renderCompleted(),
            toStart = renderToStart();
        if (inProgress && inProgress.length && loadingResponses) return <Loading loading={loadingResponses} />;
        if ((!inProgress || !inProgress.length) && (!toStart || !toStart.length)) return null;
        return (
          <Accordion flush defaultActiveKey={inProgress && inProgress.length ? 'in_progress' : 'get_started'}>
              {
                  !!(inProgress && inProgress.length) && (
                    <Accordion.Item eventKey="in_progress">
                        <Accordion.Header style={{ backgroundColor: LIGHT_FOREST_GREEN }}>
                            <h3>
                                <FormattedMessage
                                  id="overview_in_progress_section_title"
                                  defaultMessage="In Progress"
                                  description="overview page, title of the in-progress section"
                                />
                            </h3>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ul className="list-group list-group-flush">
                                {inProgress}
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                  )
              }
              {
                  !!(toStart && toStart.length) && (
                    <Accordion.Item eventKey="get_started">
                        <Accordion.Header style={{ backgroundColor: LIGHT_FOREST_GREEN }}>
                            <h3>
                                {
                                    CUAC_SETTINGS.GROUP.get_started_text
                                      ? <FormattedMessage {...CUAC_SETTINGS.GROUP.get_started_text}/>
                                      : <FormattedMessage
                                        id="overview_add_tools_title"
                                        description="Overview -- get started section -- title"
                                        defaultMessage="Get Started"/>
                                }
                            </h3>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ul className="list-group list-group-flush">
                                {toStart}
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                  )
              }
              {
                  !!(completed && completed.length) && (
                    <Accordion.Item eventKey="completed">
                        <Accordion.Header style={{ backgroundColor: LIGHT_FOREST_GREEN }}>
                            <h3>
                                {
                                    CUAC_SETTINGS.GROUP.completed_responses_text
                                      ? <FormattedMessage {...CUAC_SETTINGS.GROUP.completed_responses_text}/>
                                      : <FormattedMessage
                                        id="overview_completed_title"
                                        description="Overview -- completed -- title"
                                        defaultMessage="Completed"/>
                                }
                            </h3>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ul className="list-group list-group-flush">
                                {completed}
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                  )
              }
          </Accordion>
        )
    }

    const orderResponses = () => {
        if (_.isEmpty(orderedResponses) && (!_.isEmpty(props.plans) || _.get(CUAC_SETTINGS.GROUP, 'default_program_id'))) {
            const ordered = [];
            const { responses, plans, activeResponseId, tools } = props;
            const user = _.get(props, 'auth.user');
            // let email_responses = state.responses;
            _.map(responses, (response, responseId) => {
                const toolId = _.get(response, 'response.tool_id');
                if (toolId && toolId !== CUAC_SETTINGS.GROUP.screener_tool_id) {
                    const tool = _.get(tools, toolId);
                    const userCanUseTool = canUseTool(user, tool, responses, plans);
                    if (userCanUseTool) {
                        if (responseId === activeResponseId) ordered.unshift(response);
                        else ordered.push(response);
                    }
                }
            })
            setOrderedResponses(ordered);
            props.dispatch(Actions.updateDisplay('appSpinner', 'STOP'));
            if (_.isEmpty(orderedResponses) && orderedResponses !== undefined) {
                props.dispatch(Actions.showModal('appModal', {
                    modalNotifications: 'toolDescriptions',
                    modalContent: true
                }));
            }
        }
    }

    const trakerNavBtns = renderTrackerNavButtons();
    
    return (
      <div className="container mb-4">
          {
              trakerNavBtns && (
                <div className="row mt-4">
                    <div className="col">
                        <div className="card" style={{ border: 'none' }}>
                            <div className="card-body">
                                { trakerNavBtns }
                            </div>
                        </div>
                    </div>
                </div>
              )
          }
          <div className="row mt-4">
              <div className="col-12 col-lg-6">
                  <div className="card" style={{ border: 'none' }}>
                      <div className="card-body">
                          { renderTools() }
                      </div>
                  </div>
              </div>
              <div className="col-12 col-lg-6">
                  <BrandedImage image_type='dashboard_image' default_image={require("assets/screener-1.png")}/>
              </div>
          </div>
          <div className="row">
              <div className="col">
                  { renderPrintProgress() }
              </div>
          </div>
      </div>
    )
}

export default connect(
    function(state) {
        return ({
        auth: state.auth,
        activeResponseId: state.responses.activeResponseId,
        activeResponse: _.get(state.responses[state.responses.activeResponseId], 'response'),
        responses: state.responses,
        trackerEntryStats: state.trackerEntryStats,
        plans: state.plans.plans,
        tools: state.tools,
        ...userNormsSelector(state),
        ...userProfileSelector(state)
        })
    }
)(OverviewPage);
