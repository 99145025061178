/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require */
"use strict";

// ========= LIBRARY COMPONENTS =========
import React  from 'react';

const BrandedImage = React.createClass({

    propTypes: {
        image_type: React.PropTypes.string,
        default_image: React.PropTypes.string
    },

    render: function() {
        const {default_image, image_type} = this.props;
        if (CUAC_SETTINGS.GROUP[image_type]) {
            return (
                <div className="brand custom-brand">
                    <img style={{display: 'block', margin: 'auto'}} src={CUAC_SETTINGS.S3_ROOT + CUAC_SETTINGS.GROUP[image_type]} alt="CheckUp and Choices image."
                         className='img-fluid'/>
                </div>
            );
        } else if(!!default_image) {
            return (
                <div className="brand">
                    <img src={default_image} alt="CheckUp and Choices image."
                         className='img-fluid' />
                </div>
            );
        } else {
            return <></>
        }
    }
});

export default BrandedImage;
