import React  from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import {injectIntl, defineMessages } from "react-intl";
import { validator } from 'utils/tools';
import TextFieldReduxForm from 'components/ux/text-field-redux-form';
import BSButton from "../blocks/BSButton.component.web";
import { MaterialCommunityIcons } from '@expo/vector-icons';

const messages = defineMessages({
    change_password_title: {
        id: 'change_password_title',
        description: 'Title of form -- Change Password',
        defaultMessage: 'Update'
    },
    change_password_label_old_password: {
        id: 'change_password_label_old_password',
        description: 'Label for the old password input',
        defaultMessage: 'Old Password'
    },
    change_password_label_new_password: {
        id: 'change_password_label_new_password',
        description: 'Label for the new password input',
        defaultMessage: 'New Password'
    }
});

type Props = {
    intl: any;
    onSubmit: () => void;
    handleSubmit: () => void;
}

const ChangePassword = (props: Props) => {
    const { intl: { formatMessage } } = props;

    return (
        <form>
            <TextFieldReduxForm
                name="password"
                type="password"
                floatingLabel={true}
                labelText={formatMessage(messages.change_password_label_old_password)}
            />
            <TextFieldReduxForm
                name="newPassword"
                type="password"
                floatingLabel={true}
                labelText={formatMessage(messages.change_password_label_new_password)}
            />
            <div className="d-flex flex-column align-items-center">
                <BSButton variant="green" className="justify-content-center" onClick={props.handleSubmit}>
                    <MaterialCommunityIcons name="lock-reset" size={24} color="white" />
                    <div className="ms-1">
                        { formatMessage(messages.change_password_title) }
                    </div>
                </BSButton>
            </div>
        </form>
    )
}

const formFields = ['password', 'newPassword'];
let form = reduxForm({
    form: 'changePassword',
    fields: formFields,
    validate: validator({
        'password': { minLength:  { error_messge: 'Must be at least 6 characters', minimum: 6 } },
        'newPassword': { minLength:  { error_messge: 'Must be at least 6 characters', minimum: 6 } }
    })
})(ChangePassword);

const selector = formValueSelector('changePassword')
form = connect(state => {
    return formFields.reduce((dict, fieldName) => Object.assign({ [fieldName]: selector(state, fieldName) }, dict), {});
})(form);


export default injectIntl(form)
