/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import _ from 'lodash';
import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import TextValuesChosen from 'components/elements/text-values-chosen';
import TextValuesSuggested from 'components/elements/text-values-suggested';
import TextValueListForm from 'components/forms/text-value-list-form';

const TextValueList = (props) => {
    const updateResponse = (data) => {
        props.updateResponse({ [props.data_key]: data }, props.formulas, props.form_name);
    }

    const onAdd = (data) => {
        const value = (typeof data === 'string') ? data : data.newTextValue;
        if (!value || !value.length || responses().indexOf(value) >= 0) return;
        const newResponses = [ ...responses(), value ];
        updateResponse(newResponses)
    }

    const onRemove = (value) => {
        const newResponses = responses().filter(x => x !== value);
        updateResponse(newResponses)
    }

    const suggestions = () => {
        if (props.suggestions_data_key) {
            const suggestion_response = _.get(props.responses, props.suggestions_data_key, []);
            let chosenSuggestions = [];
            for(var i = 0; i < props.suggestions.length; i++) {
                for(var j = 0; j < suggestion_response.length; j++) {
                    if(suggestion_response[j] == props.suggestions[i].defaultMessage) {
                        chosenSuggestions.push(props.suggestions[i]);
                    }
                }
            }
            return chosenSuggestions;
        }
        return props.suggestions;
    }

    const responses = () => (props.responses || {})[props.data_key] || [];

    return (
        <Row className="phrase-chooser mt-4">
            {
                !_.isEmpty(suggestions()) && (
                  <Col xs={12} lg={6}>
                      <Card className="bg-theme-blue mb-4 bg-squiggle" style={{ border: 'none' }}>
                          <Card.Body>
                              <TextValuesSuggested
                                suggestions_title={props.suggestions_title}
                                suggestions={suggestions()}
                                responses={responses()}
                                onAddNewTextValue={onAdd}
                                removeTextValue={onRemove}
                              />
                          </Card.Body>
                      </Card>
                  </Col>
                )
            }
            <Col xs={12} lg={_.isEmpty(suggestions()) ? 12 : 6}>
                <Card className="bg-theme-yellow mb-4 bg-squiggle-alt flex-1" style={{ border: 'none' }}>
                    <Card.Body>
                        <TextValuesChosen
                          responses_title={props.responses_title}
                          responses={responses()}
                          removeTextValue={onRemove}
                        >
                            {
                                !props.no_add && (
                                  <TextValueListForm
                                    onSubmit={onAdd}
                                  />
                                )
                            }
                        </TextValuesChosen>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default injectIntl(TextValueList);
