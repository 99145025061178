/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import _ from 'lodash';
import React from 'react';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import InfoBug from 'components/popover/infobug';

import { TrackerTypes } from 'api/tracker-entries';
import Accordion from 'components/ux/accordion';
import Button from 'components/ux/mdl/button';
import { PrintButton } from 'components/elements/print-button';
import { faChevronCircleDown, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

export const LapseList = React.createClass({

    propTypes: {
        tag: React.PropTypes.string,
        trackerEntries: React.PropTypes.object.isRequired
    },

    getInitialState() {
        return {
            openEntries: []
        };
    },

    toggleEntry(index){
        let openEntries = this.state.openEntries;
        if (openEntries.indexOf(index) == -1){
            openEntries.push(index);
        } else {
            openEntries = _.without(openEntries, index);
        }

        this.setState({
            openEntries: openEntries
        });
    },

    render() {
        const { tag, trackerEntries } = this.props;
        const locus = this, openEntries = this.state.openEntries, toggleEntry = this.toggleEntry;
        const fullOrder = _.get(trackerEntries, TrackerTypes.LAPSE + 'Order', []);
        const fullOrderEntries = _.map(fullOrder, (trackerEntryId) => {
            return _.get(trackerEntries, trackerEntryId, {});
        });
        const filteredEntries = typeof tag !== 'undefined'
            ?
            _.filter(fullOrderEntries, (trackerEntry) => {
                return _.get(trackerEntry, 'tag') === tag;
            })
            : fullOrderEntries;
        const orderEntries = filteredEntries.slice(0, 20);
        const printButtonText = { 
            id : "print-button", 
            defaultMessage : "Click here to print" 
        };

        return (
            <div className="section section-tracker-list col-1-1">
                <div className="contain-box-wrap" style={{maxWidth:'690px', marginTop: '20px'}}>
                    <div className="contain-box-main" >

                        <h3 className="section-title contain-box-title">
                            <FormattedMessage id="lapse_list_title" defaultMessage="Your Recent Lapse Change Plans" />
                        </h3>
                        <div className="tracker-list-data">
                            <div className="tracker-episode header">
                                <div className="tracker-row">
                                    <div className="tracker-entry tracker-entry-date-time">
                                        <FormattedMessage id="drink_data_header_date"
                                                          defaultMessage="Updated"/>
                                    </div>
                                    <div className="tracker-entry tracker-entry-type">
                                        <FormattedMessage id="lapse_data_header_title" defaultMessage="Title" />
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                            {orderEntries.map((fullTrackerEntry, index) => {
                                const trackerEntry = _.get(fullTrackerEntry, 'data', {});
                                const dateUpdated = new Date(fullTrackerEntry.dt_u),
                                    isOpen = openEntries.indexOf(index) !== -1,
                                    accordionButton = 'accordion-toggle' + (isOpen ? ' open' : ''),
                                    accordionButtonIcon = (isOpen ? faChevronCircleDown : faChevronCircleRight);

                                return !_.isEmpty(trackerEntry) ?
                                    <div className="tracker-episode" key={'lapse_' + index}>
                                        <div className="tracker-row">
                                            <div className="tracker-entry tracker-entry-date-time">
                                                <div className="tracker-date">
                                                    <FormattedDate
                                                        value={dateUpdated}
                                                        day="numeric"
                                                        month="short"
                                                        year="numeric"/>
                                                </div>
                                                <div className="tracker-time">
                                                    <FormattedTime
                                                        value={dateUpdated}
                                                        hour="numeric"
                                                        minute="numeric"/>
                                                </div>
                                            </div>
                                            <div className="tracker-entry tracker-entry-title">{trackerEntry.title}</div>

                                            <Button className={accordionButton} flat={true} onClick={toggleEntry.bind(locus, index)}>
                                                <FontAwesomeIcon icon={accordionButtonIcon} />
                                            </Button>
                                            <Accordion collapsed={!isOpen} className="tracker-entry-block">
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="lapse_data_entry_title_label"
                                                                defaultMessage="Title" />
                                                        </span>
                                                        {trackerEntry.title}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="lapse_data_entry_triggers_label"
                                                                defaultMessage="What were the triggers?"/>
                                                        </span>
                                                        {trackerEntry.triggers}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="lapse_data_entry_mistakes_label"
                                                                defaultMessage="What mistakes did you make in dealing with these triggers?"/>
                                                        </span>
                                                        {trackerEntry.mistakes}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="lapse_data_entry_changes_to_make_label"
                                                                defaultMessage="What changes are you going to make?" />
                                                        </span>
                                                        {trackerEntry.changes_to_make}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="lapse_data_entry_reasons_to_change_label"
                                                                defaultMessage="What are your most important reasons for changing?" />
                                                        </span>
                                                        {trackerEntry.reasons_to_change}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="lapse_data_entry_steps_in_changing_label"
                                                                defaultMessage="How do you plan to achieve your goals? What are the specific steps involved? When, where, and how will you take them?" />
                                                        </span>
                                                        {trackerEntry.steps_in_changing}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="lapse_data_entry_how_others_can_help_label"
                                                                defaultMessage="How can others help? How can you get them to help you?"/>
                                                        </span>
                                                        {trackerEntry.how_others_can_help}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="lapse_data_entry_possible_setbacks_label"
                                                                defaultMessage="What could go wrong or undermine your plan? How can you stick with your plan despite these setbacks or problems?"/>
                                                        </span>
                                                        {trackerEntry.possible_setbacks}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="lapse_data_entry_good_things_from_changing_label"
                                                                defaultMessage="What good things will happen as a result of changing?"/>
                                                        </span>
                                                        {trackerEntry.good_things_from_changing}
                                                    </p>
                                                    <PrintButton text={printButtonText} />
                                            </Accordion>
                                            <div className="clearfix" />
                                        </div>
                                    </div>
                                    : null;
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});
