'use strict';

import _ from 'lodash';
import ruleJS from 'ruleJS';

import { addDays } from 'utils/date';

let parser;

const checkParser = () => {
    if (!parser) {
        parser = new ruleJS();
        parser.init();
    }
};

export const calculateResponses = (formulas, responses, updates) => {
    if (_.isEmpty(formulas)) { return updates; }
    checkParser();
    const now = new Date().toISOString();
    const updatedResponses = {
        now,
        ...responses,
        ...updates
    };
    const calculatedUpdates = updates;
    for (const formula of formulas) {
        if (!_.isEmpty(_.intersection(_.keys(calculatedUpdates), _.keys(formula.default_responses)))) {
            const formulaType = _.get(formula, 'type', '');
            let result;
            switch (formulaType) {
                case 'add_days':
                    const addDaysArgs = formula.formula.split(',');
                    const startDate = _.get(updatedResponses, addDaysArgs[0]);
                    const days = parseInt(_.get(updatedResponses, addDaysArgs[1]), 10);
                    result = !_.isEmpty(startDate) && _.isFinite(days) ? addDays(startDate, days) : '';
                    break;
                case 'contains':
                    const containsArgs = formula.formula.split(',');
                    const containsList = _.get(updatedResponses, containsArgs[0]);
                    result = _.indexOf(containsList, containsArgs[1]) !== -1 ? 1 : 0;
                    break;
                case 'is_empty':
                    const isEmptyList = formula.formula.split(',');
                    const isEmptyResponseList = _.map(isEmptyList, (key) => _.get(updatedResponses, key));
                    const isEmptyCheckFunction = _.get(formula, 'mode') === 'every' ? _.every : _.any;
                    result = isEmptyCheckFunction(isEmptyResponseList, _.isEmpty) ? 0 : 1;
                    break;
                case 'is_undefined':
                    const isUndefinedList = formula.formula.split(',');
                    const isUndefinedResponseList = _.map(isUndefinedList, (key) => _.get(updatedResponses, key));
                    const isUndefinedCheckFunction = _.get(formula, 'mode') === 'every' ? _.every : _.any;
                    result = isUndefinedCheckFunction(isUndefinedResponseList, _.isUndefined) ? 0 : 1;
                    break;
                case 'greater_than_zero':
                    const isGreaterThanZeroList = formula.formula.split(',');
                    const isGreaterThanZeroResponseList = _.map(isGreaterThanZeroList, (key) => _.get(updatedResponses, key, 0));
                    const isGreaterThanZeroCheckFunction = _.get(formula, 'mode') === 'every' ? _.every : _.any;
                    result = isGreaterThanZeroCheckFunction(isGreaterThanZeroResponseList, (value) =>{return value > 0 ? true : false}) ? 1 : 0;
                    break;
                case 'min_length':
                    const minLengthArgs = formula.formula.split(',');
                    const minLengthList = _.get(updatedResponses, minLengthArgs[0]);
                    const length = parseInt(minLengthArgs[1], 10);
                    result = minLengthList.length >= length ? 1 : 0;
                    break;
                default:
                    result = calculateResponse(parser, formula.formula, updatedResponses, formula.default_responses);
            }
            updatedResponses[formula.data_key] = result;
            calculatedUpdates[formula.data_key] = result;
        }
    }
    return calculatedUpdates;
};

const calculateResponse = (parser, formula, responses, defaults) => {
    const replaceResponse = (match, offset, fullstring) => {
        const matchKey = match.slice(1, match.length - 1);
        return (responses || {})[matchKey] || (defaults || {})[matchKey];
    };
    // Formulas like 'SUM(INT({alcohol_related_problems_sip_foolish_risks} >0)...' are resulting in errors.
    // These needs to be wrapped in an IF now. EG: 'SUM(INT(IF({alcohol_related_problems_sip_foolish_risks}>0,1,0))...)'
    const finalFormula = formula.replace(/\{\w+\}/gi, replaceResponse)
    const parsed = parser.parse(finalFormula);
    return parsed.result;
};

export const sumValues = (values) => {
    return Object.values(values).reduce((previousValue, currentValue, index, array) => {
        previousValue = previousValue ? previousValue : 0;
        currentValue = currentValue ? currentValue : 0;
        return parseInt(previousValue, 10) + parseInt(currentValue, 10);
    }, 0);
}

