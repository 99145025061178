/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, Stripe */
'use strict';

import React from 'react';

import { Block } from 'components/blocks/block';
import { Element } from 'components/elements/element';

export const ContrastFeedback = React.createClass({

    getInitialState() {
        return(
            {
                feedback_type : null
            }
        )
    },

    componentDidMount() {
        this.buildCompare(this.props);
    },

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (this.state.feedback_type !== nextState.feedback_type) {
            this.buildCompare(nextProps);
        }
    },

    buildCompare(props) {
        const { responses, allResponses, tools, contrast_to_tools, contrast_data_key} = props;
        
        let comparedToolScore = 0;
        let compare_to_tool_id = false;
        let toolName;
        
        //declare empty vars for later use
        var responsesToSort = [];
        var toolScores = [];
        var toolNames = [];

        // need to get our responses so we can sort them later
        for (var key in allResponses) {
            if (_.get(allResponses[key].response, 'dt_c')){
                responsesToSort.push({response: allResponses[key].response});
            }
        }
        // sort our reponses by date created
        const sortedResponses = _.sortBy(responsesToSort, 'response.dt_c');

        // loop through our reponses that have been sorted
        for (var i=0; i < sortedResponses.length; i++) {
            // make sure there is a tool id to compare against
            compare_to_tool_id = sortedResponses[i].response.tool_id;
            // this is the single data key compare

            if (compare_to_tool_id !== false && _.get(sortedResponses[i].response, 'tool_id') == compare_to_tool_id && (_.get(sortedResponses[i].response.responses, contrast_data_key) ||_.get(sortedResponses[i].response.responses, contrast_data_key) === 0)) {
                // grab response and push to array for building our data 
                comparedToolScore = _.get(sortedResponses[i].response.responses, contrast_data_key);
                toolScores.push(comparedToolScore);
            }
        }

        //compare the last two data keys and get our feedback type
        this.compareScores(toolScores.slice(-2));

    },

    compareScores(toolScores) {
        let feedbackType;
        if (toolScores[0] < toolScores[1]) {
            feedbackType = 'greater_than';
        }
        if (toolScores[0] > toolScores[1]) {
            feedbackType = 'less_than';
        }
        if (toolScores[0] == toolScores[1]) {
            feedbackType = 'equals';
        }

        this.setState({feedback_type: feedbackType});
    },

    render() {
        const { text_feedback, responses, allResponses } = this.props;
        const feedback_type = this.state.feedback_type; 
        return (
            <div>
                { feedback_type ? 
                    text_feedback[feedback_type].components.map((component, index) => {
                    return (
                        <Block
                            key={'block_' + index}
                            component={component}
                            index={index}
                            responses={responses}
                            allResponses={allResponses}
                            />
                        )
                    })
                    :
                    null
                }
            </div>
        );
    }
});
