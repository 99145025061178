'use strict';

import _ from 'lodash';

import { getGroup as getGroupAPI } from 'api/groups';

export default function getGroup(token, groupId) {
    return dispatch => {
        return getGroupAPI(token, groupId)
            .then((json) => {
                return json.code;
            })
    };
}