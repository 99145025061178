'use strict';

import {
    REQUEST_GET_SUBSCRIPTIONS,
    REQUEST_GET_SUBSCRIPTIONS_SUCCESS,
    REQUEST_GET_SUBSCRIPTIONS_FAILURE,
    REQUEST_GET_USER,
    REQUEST_GET_USER_SUCCESS,
    REQUEST_GET_USER_FAILURE,
    REQUEST_LOG_OUT_USER_SUCCESS
} from 'actions/types';

export function reducer(state = {
    isFetching: false,
    subscriptions: []
}, action = { type: '' }) {
    switch (action.type) {
        case REQUEST_GET_SUBSCRIPTIONS:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_GET_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                subscriptions: action.payload.subscriptions
            };
        case REQUEST_GET_SUBSCRIPTIONS_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_GET_USER:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_GET_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                subscriptions: _.get(action.payload.user, 'stripe_customer.subscriptions')
            };
        case REQUEST_GET_USER_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_LOG_OUT_USER_SUCCESS:
            return {
                ...state,
                subscriptions: {}
            };
        default:
            return state;
    }
}

