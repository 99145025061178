'use strict';

import React from 'react';

export const EmptyForm = React.createClass({
    render() {
        return (
            <div />
        );
    }
});