'use strict';
import React from 'react';
import { findDOMNode } from 'react-dom';
import SelectInput from 'components/ux/mdl/select-field';
import { liquor } from 'constants/liquor';
import { FormattedMessage } from 'react-intl';
import { NumberInput } from '../blocks/NumericInput.component.web';
import { LIGHT_YELLOW } from '../../stylesheets/colors';

const DrinkCalculator = React.createClass({

    getInitialState() {
        return {
            amount: '0',
            drinkChoice: 'alcohol',
            stDrink: 0,
            liquorList: [],
            measurement: 'oz'
        };
    },

    componentDidMount() {
      let drinkChoiceSelect = findDOMNode(this.refs.drinkChoice).lastChild;
      let { fields } = this.props;

      drinkChoiceSelect[0] = new Option("Please select one...", "");
      let liquorList = [];
      liquor.map((option, index)=>{
        drinkChoiceSelect[index+1] = new Option(option.namefull, option.namefull);
        liquorList[option.namefull] = option.prcntalc;
      });
      this.setState({liquorList: liquorList});

      let measurementSelect = findDOMNode(this.refs.measurement).lastChild;
      measurementSelect[0] = new Option("ounces", "oz");
      measurementSelect[1] = new Option("milliliters", "ml");
    },

    componentDidUpdate() {
        this.showNumberOfDrinks();
    },

    handleAmountChange(value) {
        if(typeof value === 'number') {
            this.setState({amount: value}, this.calculateDrinks);
        }
    },

    handleDrinkChoiceChange(value) {
        this.setState({drinkChoice: value}, this.calculateDrinks);
    },

    handleMeasurementChange(value) {
        this.setState({measurement: value}, this.calculateDrinks);
    },

    calculateDrinks() {
        if(typeof this.state.liquorList[this.state.drinkChoice] !== 'undefined') {
            const percentAlcohol = this.state.liquorList[this.state.drinkChoice];
            let amount = this.state.amount;
            if(this.state.measurement === 'ml') {
                amount = amount * 0.033814;
            }
            this.setState({ stDrink: ((percentAlcohol * amount) / 0.6).toFixed(1)});
        }
    },

    showNumberOfDrinks() {
        const drinkContainer = document.getElementById('drink-container');
        while (drinkContainer.hasChildNodes()) {
            drinkContainer.removeChild(drinkContainer.lastChild);
        }
        for(var i = 1; i <= this.state.stDrink; i++) {
            var div = document.createElement("div");
            div.className = "shot-glass";
            drinkContainer.appendChild(div);
        }
        let remainder = this.state.stDrink % 1;
        var div = document.createElement("div");
        div.className = "shot-glass";
        div.style.height = 74*remainder + "px";
        div.style.marginTop = 74 - 74*remainder + 2 + "px";
        drinkContainer.appendChild(div);

    },

    render() {
        return (
            <div>
                <p>
                    <FormattedMessage
                      id="drink_calculator_text"
                      description="Drink Calculator Text"
                      defaultMessage="How much alcohol is in your light beer? How many standard drinks in a Margarita? In a Long Island Iced Tea? Good question. It's often hard to know just how much alcohol is in a mixed drink. Here's a wizard that can give you answers."
                    />
                </p>
                <form className="material-input-wrap" id='drink-calculator'>
                  <SelectInput
                    value={this.state.measurement}
                    ref="measurement"
                    id="measurement"
                    labelText="Ounces or Milliliters?"
                    options={[]}
                    onChange={this.handleMeasurementChange}
                    style={{ textAlign: 'right' }}
                  >
                  </SelectInput>
                  <NumberInput
                    id="drinkCalculatorAmount"
                    label="Amount"
                    onChange={this.handleAmountChange}
                    onBlur={this.handleAmountChange}
                    value={this.state.amount}
                    inputGroupClassName="input-group-fancy justify-content-between mb-3"
                    style={{ width: 70, border: 'none', textAlign: 'center' }}
                    step={0.5}
                    min={0}
                  />
                  <SelectInput
                    value={this.state.drinkChoice}
                    ref="drinkChoice"
                    id="drinkChoice"
                    labelText="Which drink?"
                    options={[]}
                    onChange={this.handleDrinkChoiceChange}
                    style={{ textAlign: 'right' }}
                    wrapperClassName="my-3"
                  >
                  </SelectInput>
                </form>
                <div className="my-3" id="drink-container"></div>
                <div className="my-3" style={{ width: 'auto', backgroundColor: LIGHT_YELLOW, padding: '2%', borderRadius: 5 }}>
                    <p className="h5 mb-0 text-center fw-bold">
                      <FormattedMessage
                        id="drink_calculator_value"
                        description="Drink Calculator Value"
                        values={{drinks: this.state.stDrink, amount: this.state.amount, drinkChoice: this.state.drinkChoice, measurement: this.state.measurement}}
                        defaultMessage="{amount, number} {measurement} of {drinkChoice} is {drinks, plural, one {# standard drink} other {# standard drinks}}."
                      />
                    </p>
                </div>
                <div className="text-center">
                  <small className="text-muted">
                    <FormattedMessage
                      id="drink_calculator_attribution"
                      description="Drink Calculator Atrribution"
                      defaultMessage="Data set courtesy of the Center for Alcohol, Substance Abuse, &amp; Addictions at the University of New Mexico."
                    />
                  </small>
                </div>
            </div>
        );
    }
});

export default DrinkCalculator;
