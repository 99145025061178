'use strict';

import _ from 'lodash';
import notify from 'actions/notify';

import { addGroupToUser as addGroupToUserAPI } from 'api/users';

import {
    REQUEST_UPDATE_USER,
    REQUEST_UPDATE_USER_SUCCESS,
    REQUEST_UPDATE_USER_FAILURE
} from 'actions/types';

function requestUpdateUser() {
    return {
        type: REQUEST_UPDATE_USER,
        payload: {}
    };
}

function requestUpdateUserSuccess(user) {
    return {
        type: REQUEST_UPDATE_USER_SUCCESS,
        payload: { user }
    };
}

function requestUpdateUserFailure(error) {
    return {
        type: REQUEST_UPDATE_USER_FAILURE,
        payload: error,
        error: true
    };
}

export default function addGroupToUser(token, userId) {
    return dispatch => {
        dispatch(requestUpdateUser());
        return addGroupToUserAPI(token, userId)
            .then((json) => {
                return dispatch(requestUpdateUserSuccess(json));
            })
            .catch((error) => {
                dispatch(notify({
                    title: "Network Error",
                    message: "Your account didn't update.  Please try again in a few minutes.",
                    level: 'error',
                    autoDismiss: 0
                }));
                return dispatch(requestUpdateUserFailure(error));
            });
    };
}