/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, Stripe */
'use strict';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { eventPreventDefault as prevent } from 'utils/tools';
import { Spinner } from 'react-bootstrap';
import { FontAwesome } from '@expo/vector-icons';
import { BLUE } from '../../stylesheets/colors';

const Field = ({ key, field, selected, onClick, disabled, ...props }) => {
    return (
      <div
        className={"form-check-fancy-dark mb-3 phrase " + (selected ? " form-check-fancy-dark__selected selected" : "")}
        key={key}
        data-message={field.defaultMessage}
        onClick={onClick}
        aria-label={selected ? "Remove suggestion: " + field.defaultMessage : "Add suggestion: " + field.defaultMessage}
        role="button"
        tabIndex="0"
      >
          <div className="form-check d-flex align-items-center ps-0 ps-lg-0">
              <div
                className="d-flex align-items-center justify-content-center me-3 me-lg-2"
                style={{ backgroundColor: 'white', height: 24, width: 24, minHeight: 24, minWidth: 24, borderRadius: '.25rem' }}
              >
                  {
                      selected && (
                        <FontAwesome name="check" color={BLUE} size={13} />
                      )
                  }
              </div>
              <input
                className="form-check-input d-none"
                data-message={field.defaultMessage}
                checked={selected}
                id={String(field.id) + "cb"}
                type="checkbox"
                name="flexRadioDefault"
                disabled={disabled}
                onChange={onClick}
              />
              <label
                className="form-check-label"
                htmlFor={String(field.id) + "cb"}
              >
                  <FormattedMessage {...field} />
              </label>
          </div>
      </div>
    )
}

const TextValuesSuggested = props => {
    const renderField = useCallback(field => {
        return (
          <div key={field.id}>
              <Field
                disabled={props.updating}
                onClick={onClick}
                selected={props.responses.indexOf(field.defaultMessage) !== -1}
                field={field}
              />
          </div>
        );
    }, [ props.responses ]);

    const onClick = e => {
        prevent(e);
        if (props.updating) return;
        const message = e.target.getAttribute('data-message') || e.currentTarget.getAttribute('data-message');
        const selected = props.responses.indexOf(message) !== -1;
        if (selected) props.removeTextValue(message);
        else props.onAddNewTextValue(message);
    }

    return (
      <div className={props.className ? ("suggested-phrases " + props.className) : "suggested-phrases"}>
          <div className="d-flex align-items-center justify-content-between mb-3">
              <h4 className="title fw-bold">
                  <FormattedMessage {...props.suggestions_title} />
              </h4>
              {
                  props.updating && (
                    <Spinner animation="border" variant="warning" />
                  )
              }
          </div>
          {
              (props.suggestions || []).map(renderField)
          }
      </div>
    )
}

export default TextValuesSuggested;
