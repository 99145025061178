'use strict';

export const convertUnderscoreToCamelCase = (input) => {
    return input.replace(/_([a-z])/gi, (s, match) => {
        return match.toUpperCase();
    });
};

export const convertToKeyString = (input) => {
    return input
        .toLowerCase()
        .replace(/ /g, '_')
        .replace(/[^\w]+/g, '');
};