import React from 'react';
import ToolTOC from './ToolTOC.component';
import {browserHistory, withRouter} from "react-router";
import {connect} from "react-redux";
import Selectors from 'selectors';
import {submitUpdates} from "../../actions/update-response";
const { pageSelector } = Selectors;

function ToolTOCContainer({ tool, response, open, setOpen, ...props }) {
  const moduleTree = () => {
    const { order, modules } = tool.metadata || {},
      { modules: moduleProgress } = response.progress || {};
    return (order || []).map(key => {
      const { [key]: module } = modules || {},
        { [key]: moduleProg } = moduleProgress || {},
        { question_count, response_count } = moduleProg || {},
        progress = typeof question_count === 'number' && !!question_count
          ? Math.round((((response_count || 0) / question_count) * 100))
          : null,
        { sections, order: secOrder, slug } = module || {},
        moduleTitle = module?.long_title?.defaultMessage,
        moduleURL = `/${tool.slug}/${slug}`;
      return {
        title: moduleTitle,
        url: moduleURL,
        progress,
        sections: secOrder.map(secKey => {
          const { [secKey]: section } = sections || {},
            secTitle = section?.long_title?.defaultMessage;
          return {
            title: secTitle,
            url: `/${tool.slug}/${slug}/${section.slug}`
          }
        })
      }
    })
  }

  const onLinkClick = async (url) => {
    const { token, _id: userId } = props.user ?? {},
        { updates, _id: responseId } = response || {};
    if (updates && !!Object.keys(updates || {}).length) {
      await props.dispatch(submitUpdates(token, userId, responseId, props.module, props.section, props.page));
    }
    browserHistory.push(url);
  }

  return (
    <ToolTOC
      open={open}
      setOpen={setOpen}
      tool={tool}
      moduleTree={moduleTree()}
      location={props.location}
      onLinkClick={onLinkClick}
    />
  )
}

export default withRouter(connect((state, props) => ({
  user: state.auth.user,
  ...pageSelector(state, props)
}))(ToolTOCContainer))
