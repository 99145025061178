'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import TextFieldReduxForm from 'components/ux/text-field-redux-form';
import { validator } from 'utils/tools';
import BSButton from '../blocks/BSButton.component.web';
import { FontAwesome } from "@expo/vector-icons";

const messages = defineMessages({
    login_label_2fa: {
        id: 'token2FA',
        description: 'Label for two factor auth token input',
        defaultMessage: 'Enter Verification Code'
    }
});


const TwoFactor = React.createClass({

    propTypes: {
        fields: React.PropTypes.object.isRequired,
        message: React.PropTypes.string.isRequired,
        handleSubmit: React.PropTypes.func.isRequired,
        onClearLogOutMessage: React.PropTypes.func.isRequired
    },

    renderMessage: function() {
        if (this.props.message !== 'Your credentials are not valid.') return null;
        return (
          <div className="alert alert-danger d-flex align-items-center">
              <FontAwesome name="exclamation-circle" color="#842029" size={20} />
              <div className="ms-2">
                  <FormattedMessage
                    id="login_message_failure"
                    description="Tell the user that the token failed"
                    defaultMessage="Invalid or old Verification Code, please re-enter your code."
                  />
              </div>
          </div>
        )
    },

    isValid() {
        const { token2FA } = this.props;
        return token2FA && token2FA.length === 6;
    },

    render() {
        const { handleSubmit, intl: { formatMessage }} = this.props;
        return (
          <div className="container">
              <div className="row">
                  <div className="col-8 offset-2">
                      <div className="card mt-4" style={{ border: 'none' }}>
                          <div className="card-body">
                              <h3 className="card-title">
                                  <FormattedMessage
                                    id="2fa_title"
                                    description="Title for the 2FA form"
                                    defaultMessage="Verification Code"
                                  />
                              </h3>
                              {this.renderMessage()}
                              <p className="text-muted">
                                  <FormattedMessage
                                    id="2fa_message"
                                    description="message for the 2FA form"
                                    defaultMessage={'A six digit verification code was sent to the phone number associated with your account. Designed to help protect your information, two factor authentication can be turned off in the "My Account" section of your profile.'} />
                              </p>
                              <form id="2fa" name="2fa" onSubmit={handleSubmit}>
                                  <TextFieldReduxForm
                                    name="token2FA"
                                    id="token2FA"
                                    floatingLabel={true}
                                    labelText={formatMessage(messages.login_label_2fa)}
                                  />
                              </form>
                              <div className="d-flex align-items-center">
                                  <BSButton variant="green" disabled={!this.isValid()} loading={this.props.isFetching} onClick={handleSubmit}>
                                      <div className="me-2">
                                          <FontAwesome name="star" color="white" size={20} />
                                      </div>
                                      <FormattedMessage
                                        id="login_button_login"
                                        description="Submit the login form to log in"
                                        defaultMessage="Login"
                                      />
                                  </BSButton>
                                  <BSButton className="ms-1" variant="link-green" disabled={this.props.isFetching} onClick={this.props.loginAgain}>
                                      <FormattedMessage
                                        id="login_in_again"
                                        description="Tell the user that they can login again to get a new token"
                                        defaultMessage="Need a new code?"
                                      />
                                  </BSButton>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        )
    }
});


const formFields = ['token2FA'];
let form = reduxForm({
    form: '2fa',
    fields: formFields,
    validate: validator ({
        'token2FA': { required: 'Required', number: 'Please enter your Authorization Token'}
    })
})(TwoFactor);
const selector = formValueSelector('2fa') // <-- same as form name
form = connect(state => {
    return formFields.reduce((dict, fieldName) => Object.assign({ [fieldName]: selector(state, fieldName) }, dict), {});
})(form)


export default injectIntl(form);
