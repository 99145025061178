'use strict';

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import Actions from 'actions';
import ResetPassword from 'components/forms/reset-password';

export const ResetPasswordPage = React.createClass({

    propTypes: {
        auth: React.PropTypes.object.isRequired,
        dispatch: React.PropTypes.func.isRequired,
        messages: React.PropTypes.object.isRequired,
        params: React.PropTypes.object.isRequired
    },

    componentDidMount() {
        this.props.dispatch(Actions.getPasswordReset(_.get(this.props, 'params.reset_key')));
    },

    onSubmit(data) {
        this.props.dispatch(Actions.resetPassword(_.get(this.props, 'params.reset_key'), data.password));
    },

    render() {
        const isFetching = _.get(this, 'props.auth.isFetching'),
            message = _.get(this, 'props.messages.resetPassword');
        return (
            <ResetPassword
                isFetching={isFetching}
                message={message}
                onSubmit={this.onSubmit} />
        );

    }

});

export default connect(
    state => ({
        auth: state.auth,
        messages: state.messages
    })
)(ResetPasswordPage);