/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
// import TextFieldReduxForm from 'components/ux/text-field-redux-form';
import InputDateReduxForm from 'components/ux/input-date-redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

export const DateQuestion = React.createClass({
//    mixins: [],
//    statics: {},

    propTypes: {
        field: React.PropTypes.object.isRequired,
        fieldName: React.PropTypes.string.isRequired,
        formatMessage: React.PropTypes.func.isRequired,
        max: React.PropTypes.string,
        min: React.PropTypes.string,
        placeholder_text: React.PropTypes.object,
        style: React.PropTypes.object,
        text: React.PropTypes.object,
        updateResponse: React.PropTypes.func.isRequired
    },

//    getInitialState() {},
//    getDefaultProps() {},
//    componentWillMount() {},
//    componentDidMount() {},
//    componentWillReceiveProps(nextProps) {},
//    shouldComponentUpdate(nextProps, nextState) {},
//    componentWillUpdate(nextProps, nextState) {},
//    componentDidUpdate(prevProps, prevState) {},
//    componentWillUnmount() {},

    onBlur(event) {
    },

    onChange(dateString) {

        const { field: { name, id } } = this.props;
        if (this.props.field.onChange) { this.props.field.onChange((id|| name), dateString); }
        this.props.updateResponse({ [id || name]: dateString });
    },

    render() {
        const { field, fieldName, style, text, placeholder_text, formatMessage } = this.props;

        const label = !text ? null : (
            <label htmlFor={fieldName}>
                <FontAwesomeIcon icon={faAngleDoubleRight} /><FormattedMessage {...text} />
            </label>
        );

        const placeHolder = placeholder_text ? formatMessage(placeholder_text) : 'Date';
        const value = (field.value === field.name) ? field.intialValue || field.defaultValue : field.value;

        return (
            <div className="question-block date" style={style}>
                <div className="question">
                    {label}
                    <div style={{textAlign: 'center'}}>
                        <InputDateReduxForm name={fieldName} id={fieldName} {...field} value={value}
                            floatingLabel={true} labelText={placeHolder}  onChange={this.onChange} />
                    </div>
                </div>
            </div>
        );
    }
});
