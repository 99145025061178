'use strict';

import _ from 'lodash';

import { dismissUserMessage as dismissUserMessageAPI } from 'api/users';

import {
    REQUEST_UPDATE_USER_SUCCESS,
    REQUEST_UPDATE_USER_FAILURE
} from 'actions/types';

function requestDismissUserMessageSuccess(user) {
    return {
        type: REQUEST_UPDATE_USER_SUCCESS,
        payload: { user }
    };
}

function requestDismissUserMessageFailure(error) {
    return {
        type: REQUEST_UPDATE_USER_FAILURE,
        payload: error,
        error: true
    };
}

export default function dismissUserMessage(token, userId) {
    return dispatch => {
        return dismissUserMessageAPI(token, userId)
            .then((json) => {
                return dispatch(requestDismissUserMessageSuccess(json));
            })
            .catch((error) => {
                return dispatch(requestDismissUserMessageFailure(error));
            });
    };
}