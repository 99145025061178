'use strict';

import _ from 'lodash';

import { createSelector } from 'reselect';

const authSelector = state => state.auth;

export const userProfileSelector = createSelector(
    authSelector,
    (auth) => {
        const user = _.get(auth, 'user');
        return {
            userProfile: _.pick(
                user,
                ['bac_sec', 'bac_table', 'dt_dob', 'gender', 'medical_history', 'name', 'weight', 'two_factor_auth']
            )
        };
    }
);

