import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FOREST_GREEN } from '../../stylesheets/colors';
import { FontAwesome } from "@expo/vector-icons";

type Props = {
    text: any;
    id: string;
    responses?: { [key: string]: any };
}

const FieldLabel = (props: Props) => {
    return (
        <label htmlFor={props.id} className="ms-3 mb-3 d-flex align-items-center">
            <FontAwesome  name="angle-double-right" size={30} color={FOREST_GREEN} />
            <div className="ms-2 fw-bold">
                <FormattedMessage values={props.responses || {}} {...props.text} />
            </div>
        </label>

    )
}

export default FieldLabel;
