'use strict';

import { deleteSubscription as deleteSubscriptionAPI } from 'api/subscriptions';
import notify from 'actions/notify';
const dataLayer = [];
import Actions from 'actions';

import {
    REQUEST_DELETE_SUBSCRIPTION,
    REQUEST_DELETE_SUBSCRIPTION_SUCCESS,
    REQUEST_DELETE_SUBSCRIPTION_FAILURE
} from 'actions/types';

function requestDeleteSubscription() {
    return {
        type: REQUEST_DELETE_SUBSCRIPTION
    };
}

function requestDeleteSubscriptionSuccess() {
    return {
        type: REQUEST_DELETE_SUBSCRIPTION_SUCCESS
    };
}

function requestDeleteSubscriptionFailure(error) {
    return {
        type: REQUEST_DELETE_SUBSCRIPTION_FAILURE,
        payload: error,
        error: true
    };
}

export default function deleteSubscription(token, userId, subscriptionId, values) {
    return dispatch => {
        dispatch(requestDeleteSubscription());
        return deleteSubscriptionAPI(token, subscriptionId, values)
            .then((json) => {
                return Promise.all([
                    dispatch(Actions.getUser(token, userId)),
                    dispatch(Actions.getResponses(token, userId)),
                    dispatch(Actions.getTools(token))
                ])
                .then(() => {
                    dispatch(notify({
                        title: "Subscription Cancelled",
                        message: "Your subscription has been cancelled.",
                        level: 'success',
                        autoDismiss: 4
                    }));

                     window.dataLayer.push({
                        'event': 'subscriptionCancelled',
                        'userId': userId,
                        'groupCode': CUAC_SETTINGS.GROUP.code
                    });

                    return dispatch(requestDeleteSubscriptionSuccess());
                });
            })
            .catch((error) => {
                dispatch(notify({
                    title: "Cancellation Failed",
                    message: error.message,
                    level: 'error',
                    autoDismiss: 0
                }));
                return dispatch(requestDeleteSubscriptionFailure(error));
            });
    };
}
