'use strict';

import React  from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import TextFieldReduxForm from 'components/ux/text-field-redux-form';
import { validator } from 'utils/tools';
import BSButton from "../blocks/BSButton.component.web";
import { FontAwesome, Ionicons } from '@expo/vector-icons';
import { Spinner } from "react-bootstrap";

const messages = defineMessages({
    reset_password_label_input: {
        id: 'reset_password_label_input',
        description: 'Label for reset password input',
        defaultMessage: 'New Password'
    },
    reset_password_label_confirm: {
        id: 'reset_password_label_confirm',
        description: 'Label for reset password confirmation field',
        defaultMessage: 'Confirm Password'
    }
});

const ResetPassword = React.createClass({

    propTypes: {
        fields: React.PropTypes.object.isRequired,
        handleSubmit: React.PropTypes.func.isRequired,
        message: React.PropTypes.string
    },

    renderBadToken(){
        return (
            <>
                <p className="h5">
                    <FormattedMessage id="reset_password_bad_token_title" defaultMessage="Expired" />
                </p>
                <p>
                    <p>
                        <FormattedMessage
                            id="reset_password_bad_token_instructions"
                            defaultMessage="This reset link has expired. "
                        />
                        <FormattedMessage
                            id="reset_password_bad_token_instructions2"
                            defaultMessage="You can request a new reset and try again."
                        />
                    </p>
                    <BSButton variant="green" linkTo="/forgot-password">
                        <FontAwesome name="chevron-circle-right" color="white" size={20} />
                        <div className="ms-2">
                            <FormattedMessage
                                id="reset_password_bad_token_link_forgot"
                                defaultMessage="New Password Reset"
                            />
                        </div>
                    </BSButton>
                </p>
            </>
        )
    },

    renderResetSuccess() {
        return (
            <>
                <p>
                    <FormattedMessage
                        id="reset_password_success_instructions"
                        defaultMessage="Success! You can now log in."
                    />
                </p>
                <BSButton variant="green" linkTo="/login">
                    <div className="me-2">
                        <FormattedMessage id="reset_password_sent_back" defaultMessage="Log In" />
                    </div>
                    <Ionicons name="arrow-forward" size={20} color="white" />
                </BSButton>
            </>
        )
    },

    isValid() {
        return !Object.values(this.props.errors || {}).length;
    },

    renderResetForm() {
        const { handleSubmit, intl: { formatMessage }} = this.props;

        return (
            <form id="reset_password" name="reset_password" className="d-flex flex-column">
                <p className="text-muted">
                    <FormattedMessage
                        id="reset_password_instructions_1"
                        defaultMessage="Choose a new Password."
                    />
                </p>
                <TextFieldReduxForm
                    name="password"
                    type="password"
                    loatingLabel={true}
                    labelText={formatMessage(messages.reset_password_label_input)}
                />
                <TextFieldReduxForm
                    name="confirmPassword"
                    type="password"
                    floatingLabel={true}
                    errorText={this.props.errors?.confirmPassword}
                    labelText={formatMessage(messages.reset_password_label_confirm)}
                />
                <div className="d-flex">
                    <BSButton variant="green" disabled={this.props.isFetching || !this.isValid()} loading={this.props.isFetching} onClick={handleSubmit}>
                        <FontAwesome name="star-o" color="white" size={20} />
                        <div className="ms-2">
                            <FormattedMessage
                                id="reset_password_button_submit"
                                description="Update the user password"
                                defaultMessage="Save Password"
                            />
                        </div>
                    </BSButton>
                    <BSButton variant="link-green" lintTo="/login" className="ms-1">
                        <FormattedMessage
                            id="reset_password_back_to_login_2"
                            description="Take the user from the reset password page to the login page"
                            defaultMessage="Back to Login"
                        />
                    </BSButton>
                </div>
            </form>
        )
    },

    renderStatusPending(){
        return (
            <Spinner animation="border" variant="info" />
        )
    },

    render: function() {
        const success = this.props.message === 'SUCCESS',
            badToken =  this.props.message === 'BAD_TOKEN',
            goodToken =  this.props.message === 'GOOD_TOKEN',
            renderBadToken = this.renderBadToken,
            renderResetForm = this.renderResetForm,
            renderStatusPending = this.renderStatusPending,
            renderResetSuccess = this.renderResetSuccess;

        return (
            <div className="container">
                <div className="row">
                    <div className="col-8 offset-2">
                        <div className="card mt-4" style={{ border: 'none' }}>
                            <div className="card-body">
                                <h3 className="card-title">
                                    <FormattedMessage
                                        id="reset_password_title"
                                        description="Title for Password Request page"
                                        defaultMessage="Password Reset"
                                    />
                                </h3>
                                {
                                    badToken ? renderBadToken() :
                                        success ? renderResetSuccess() :
                                            goodToken ? renderResetForm() :
                                                renderStatusPending()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

});

const formFields = ['password', 'confirmPassword'];
let form = reduxForm({
    form: 'reset_password',
    fields: formFields,
    validate: validator ({
        'password': { required: 'Required', minLength: { error_messge: 'Must be at least 6 characters', minimum: 6 } },
        'confirmPassword': { required: 'Required', confirm: { error_message: 'Does not match', confirm_target: 'password'} }
    })
})(ResetPassword);

const selector = formValueSelector('reset_password') // <-- same as form name
form = connect(state => {
    return formFields.reduce((dict, fieldName) => Object.assign({ [fieldName]: selector(state, fieldName) }, dict), {
        errors: state.form.reset_password?.syncErrors,
    });
})(form);

export default injectIntl(form);
