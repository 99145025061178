'use strict';

import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Field } from 'components/fields/field';

import { convertToKeyString } from 'utils/string';

export const FormListForm = React.createClass({
    propTypes: {
        fields: React.PropTypes.object.isRequired,
        fieldsMetadata: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
        intl: React.PropTypes.object,
        listResponse: React.PropTypes.arrayOf(React.PropTypes.string),
        updateResponse: React.PropTypes.func.isRequired
    },

    render() {
        const { fields, fieldsMetadata, intl: { formatMessage }, listResponse, updateResponse } = this.props;
        const components = listResponse.map((value, valueIndex) => {
            const nestedComponents = [<h4 key={'h4_' + valueIndex}>{value}</h4>];
            nestedComponents.push(fieldsMetadata.map((fieldMetadata, fieldIndex) => {
                const mergedFieldMetadata = {
                    ...fieldMetadata,
                    id: fieldMetadata.id + '_' + convertToKeyString(value)
                };
                const field = _.get(fields, mergedFieldMetadata.id);
                return (
                    <Field key={'field_' + valueIndex + '_' + fieldIndex}
                           fieldMetadata={mergedFieldMetadata}
                           field={field}
                           formatMessage={formatMessage}
                           updateResponse={updateResponse} />
                );
            }));
            return (
                _.flatten(nestedComponents)
            );
        });

        return (
            <div>
                <form>
                    {components}
                </form>
            </div>
        );
    }
});