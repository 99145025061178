'use strict';

import _ from 'lodash';
import React from 'react';
import { FormattedDateMessage } from 'components/elements/formatted-date-message';
import { FormattedBACMessage } from 'components/elements/formatted-bac-message';
import { FormattedScoreMessage } from 'components/elements/formatted-score-message';
import { FormattedMessage } from 'react-intl';
import { BACTable } from 'components/elements/bac-table';
import { DynamicUnorderedList } from 'components/elements/dynamic-unordered-list';
import InfoBug from 'components/popover/infobug';
import { NormLookup } from 'components/elements/norm-lookup';
import { NormPrintableTable } from 'components/elements/norm-printable-table';
import { P } from 'components/elements/p';
import { IMG } from 'components/elements/img';
import { Unescaped } from 'components/elements/unescaped';
import { A } from 'components/elements/a';
import { H3 } from 'components/elements/h3';
import { Video } from 'components/elements/video';
import { DrinkCalculatorButton } from 'components/elements/drink-calculator-button';
import GenericModalButton from 'components/elements/generic-modal-button';
import { PrintButton } from 'components/elements/print-button';
import { CalorieFeedback } from 'components/elements/calorie-feedback';
import { AudioPlayer } from 'components/blocks/AudioPlayer.component';

export const Element = React.createClass({
    propTypes: {
        component: React.PropTypes.object.isRequired,
        index: React.PropTypes.number.isRequired,
        norms: React.PropTypes.object,
        responses: React.PropTypes.object.isRequired
    },

    renderElement(component, index, responses, norms) {
        const componentType = _.get(component, 'type');
        const className = _.get(component, 'className');
        let props = _.get(component, 'props', { key: 'rE' + index, className: className });
        let dispatch = this.props.dispatch;
        const children = _.get(component, 'children', []);
        const updateProgressManually = this.props.updateProgressManually;
        const module = this.props.module;
        const section = this.props.section;
        const tool = this.props.tool;
        let elementType;
        switch (componentType) {
            case 'bac_table':
                elementType = BACTable;
                props = {
                    ...props,
                    ...component,
                    responses
                };
                break;
            case 'dynamic_ul':
                elementType = DynamicUnorderedList;
                props = {
                    ...props,
                    ...component,
                    responses
                };
                break;
            case 'formatted_date_message':
                elementType = FormattedDateMessage;
                props = {
                    ...props,
                    ...component.text,
                    values: responses
                };
                break;
            case 'formatted_bac_message':
                elementType = FormattedBACMessage;
                props = {
                    ...props,
                    ...component.text,
                    values: responses
                };
                break;
            case 'formatted_message':
                elementType = FormattedScoreMessage;
                props = {
                    ...props,
                    ...component.text,
                    values: responses
                };
                break;
            case 'infobug':
                elementType = InfoBug;
                break;
            case 'infobug_big':
                elementType = InfoBug;
                props = {
                    ...props,
                    extended: true
                };
                break;
            case 'img':
                elementType = IMG;
                props = {
                    ...props,
                    ...component
                };
                break;
            case 'p':
                elementType = P;
                props = {
                    ...props,
                    ...component,
                    norms,
                    responses,
                    updateProgressManually,
                    module,
                    section,
                    tool
                };
                break;
            case 'unescaped':
                elementType = Unescaped;
                props = {
                    ...props,
                    ...component,
                    norms,
                    responses
                };
                break;
            case 'h3':
                elementType = H3;
                props = {
                    ...props,
                    ...component,
                    norms,
                    responses,
                    updateProgressManually,
                    module,
                    section,
                    tool
                };
                break;
            case 'a':
                elementType = A;
                props = {
                    ...props,
                    ...component,
                    norms,
                    responses,
                    updateProgressManually,
                    module,
                    section,
                    tool
                };
                break;
            case 'video':
                elementType = Video;
                props = {
                    ...props
                };
                break;
            case 'audio':
                elementType = AudioPlayer;
                props = {
                    ...props,
                    ...component
                };
                break;
            case 'norm_lookup':
                elementType = NormLookup;
                const norm = _.get(norms, component.norm_key);
                props = {
                    ...props,
                    ...component,
                    norm,
                    responses
                };
                break;
            case 'norm_printable_table':
                elementType = NormPrintableTable;
                props = {
                    ...props,
                    ...component,
                    norms,
                    responses
                };
                break;
            case 'drink_calculator':
                elementType = DrinkCalculatorButton;
                props = {
                    ...props,
                    dispatch
                }
                break;
            case 'print_button':
                elementType = PrintButton;
                props = {
                    ...props,
                    ...component,
                    dispatch
                }
                break;
            case 'generic_modal':
                elementType = GenericModalButton;
                props = {
                    ...props,
                    ...component,
                    norm,
                    responses,
                    dispatch,
                    link_only: true,
                }
                break;
            case 'calorie_feedback':
                elementType = CalorieFeedback;
                props = {
                    ...props,
                    ...component,
                    responses
                }
                break;
            default:
                elementType = componentType;
        }
        return React.createElement(
            elementType,
            props,
            ...children.map((childComponent, index) => {
                return this.renderElement(childComponent, index, responses, norms);
            })
        );
    },

    render() {
        const { component, index, responses, norms } = this.props;
        return (
            this.renderElement(component, index, responses, norms)
        );
    }
});
