/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require, self, goToUrl */
"use strict";

// ========= LIBRARY COMPONENTS =========
import React from 'react';
import { findDOMNode } from 'react-dom';
import classnames from 'classnames';
import { faTimesCircle as faTimesCircleO } from '@fortawesome/free-solid-svg-icons';
import { FontAwesome } from '@expo/vector-icons';

var ConfirmPopOver = React.createClass({
    propTypes: {
        className: React.PropTypes.string,
        popClass: React.PropTypes.string,
        show: React.PropTypes.bool
    },

    getInitialState: function() {
        return { showPopOver: false, blockStyle: {} };
    },

    getDefaultProps: function() {},

    componentDidMount: function() {
        this.adjustScreenPosition();
    },

    UNSAFE_componentWillReceiveProps: function(nextProps) {
        this.adjustScreenPosition();
    },

    componentDidUpdate() {
        this.adjustScreenPosition();
    },

    // ======== LOCAL METHODS ==============

    adjustScreenPosition: function(propEl){
        const el = propEl || findDOMNode(this.refs.popoverBlock);

        function translate(offset){
            return {
                "WebkitTransform": "translate(" + offset + "px, 0)",
                "MozTransform": "translate(" + offset + "px, 0)",
                "msTransform": "translate(" + offset + "px, 0)",
                "OTransform": "translate(" + offset + "px, 0)",
                "transform": "translate(" + offset + "px, 0)"
            };
        }

        if (el){
            const rect = el.getBoundingClientRect();

            let adjust = false,
                newStyle = {};

            if (rect.left < 20 ) {  // too close to left edge
                adjust = true;
                newStyle = translate(20 - rect.left);
            } else if ((rect.right + 20) > window.innerWidth) { // too close to right edge
                adjust = true;
                newStyle = translate( -1 * (Math.floor(rect.right) + 20 - window.innerWidth) );
            }

            if (adjust){
                _.forOwn(newStyle, (val, name) => {
                    el.style[name] = val;
                });
            }

        }
    },

    onClickClose() {
        if (this.props.onClickClose) { this.props.onClickClose(); }
    },

    // ======== RENDER ==============

    render: function() {
        const { className, popClass, show } = this.props,
            outer = classnames('popOver-parent', { 'active': show }, className),
            inner = classnames('popOver-block', popClass);

        return !show ? null : (
            <div className={outer}>
                <div className={inner} ref="popoverBlock">
                    <div className="close" onClick={this.onClickClose}>
                        <FontAwesome name="times" size={18} color="black" />
                    </div>
                    {this.props.children}
                </div>
            </div>
        );
    }
});

export default ConfirmPopOver;
export { ConfirmPopOver };