'use strict';

import React, { findDOMNode, forwardRef, useRef } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import TimePicker from 'react-time-picker';
import Moment from 'moment'; Moment.locale('en');

import { eventPreventDefault as prevent } from 'utils/tools';
import InfoBug from 'components/popover/infobug';
import ButtonSpinner from 'components/ux/button-spinner';
import DateInputReduxForm from 'components/ux/input-date-redux-form';
import RadioSetReduxForm from 'components/ux/radio-set-dynamic';
import momentTZ from 'lib/moment-timezone-with-data';
import { faStar as faStarO } from '@fortawesome/free-solid-svg-icons';
import { validator } from 'utils/tools';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import TimePickerReduxForm from "../ux/TimePickerReduxForm.component.web";
import RadioGroupReduxForm from "../ux/RadioGroupReduxForm.component.web";
import BSButton from "../blocks/BSButton.component.web";
import {FontAwesome} from "@expo/vector-icons";

const messages = defineMessages({
    "mood_tracker_dt_occurred_label": {
        id: "mood_tracker_dt_occurred_label",
        description: "Date Occurred",
        defaultMessage: "Date Occurred"
    },
    "mood_tracker_duration_placeholder": {
        id: "mood_tracker_duration_placeholder",
        description: "Duration Placeholder",
        defaultMessage: "# of minutes"
    },
    "mood_tracker_with_whom_label": {
        id: "mood_tracker_with_whom_label",
        description: "With Whom",
        defaultMessage: "With Whom"
    },
    "mood_tracker_where_label": {
        id: "mood_tracker_where_label",
        description: "Where",
        defaultMessage: "Where"
    },
    "mood_tracker_mood_label": {
        id: "mood_tracker_mood_label",
        description: "Your Mood",
        defaultMessage: "Your Mood"
    },
    "mood_tracker_strategy_label": {
        id: "mood_tracker_strategy_label",
        description: "Strategy",
        defaultMessage: "Strategy"
    },
    "date_input_year_label": {
        id: "date_input_year_label",
        description: "date choose label -- Year",
        defaultMessage: "Year"
    },
    "date_input_month_label": {
        id: "date_input_month_label",
        description: "date choose label -- Month",
        defaultMessage: "Month"
    },
    "date_input_day_label": {
        id: "date_imput_day_label",
        description: "date choose label -- Day",
        defaultMessage: "Day"
    }
});

const options = [
    {
        "text": {
            "id": "very_slightly_or_not_at_all",
            "defaultMessage": "Very slightly or not at all"
        },
        "value": "1"
    },
    {
        "text": {
            "id": "a_little",
            "defaultMessage": "A little"
        },
        "value": "2"
    },
    {
        "text": {
            "id": "moderately",
            "defaultMessage": "Moderately"
        },
        "value": "3"
    },
    {
        "text": {
            "id": "quite_a_bit",
            "defaultMessage": "Quite a bit"
        },
        "value": "4"
    },
    {
        "text": {
            "id": "extremely",
            "defaultMessage": "Extremely"
        },
        "value": "5"
    }
];

const positives = [
    'interested',
    'excited',
    'strong',
    'enthusiastic',
    'proud',
    'alert',
    'inspired',
    'determined',
    'attentive',
    'active'
];
const negatives = [
    'distressed',
    'upset',
    'guilty',
    'scared',
    'hostile',
    'irritable',
    'ashamed',
    'nervous',
    'jittery',
    'afraid'
];

const MoodTracker = (props) => {
    const topRef = useRef();

    const CustomDatepickerInput = forwardRef(({ value, onClick }, ref) => (
        <input style={{ textAlign: 'right' }} value={value} type="text" className="form-control" onClick={onClick} ref={ref}/>
    ));

    const onSave = async (reset = false) => {
        const errorFields = document.querySelectorAll('.input-group.invalid');
        if (errorFields.length) {
            const firstError = errorFields[0];
            firstError.scrollIntoView();
            return;
        }

        props.change('positive_score', positives.reduce((a, b) => props[b] ? a + parseInt(props[b]) : a, 0));
        props.change('negative_score', negatives.reduce((a, b) => props[b] ? a + parseInt(props[b]) : a, 0));

        await props.handleSubmit();
        if (reset) {
            const userTimezone = props.user?.user_timezone,
              date = userTimezone ? momentTZ(new Date(), userTimezone) : Moment();
            topRef.current?.scrollIntoView();
            props.dispatch(props.initialize({
                dt_occurred: date.format('YYYY-MM-DD'),
                tm_occurred: date.format('hh:mm A'),
            }))
        }
        if (!reset) props.hideModal();
    }

    const isFieldValid = name => {
        const { [name]: val } = props;
        return typeof val === 'number' || !!(val && val.length);
    }

    const isValid = () => {
        return formFields.filter(key => !['positive_score', 'negative_score'].includes(key)).map(key => {
            const { [key]: val } = props;
            if (typeof val === 'string') return !!val.length;
            if (typeof val === 'number') return true;
            return !!val;
        }).every(Boolean);
    }

    return (
        <form
            id="mood_tracker"
            name="mood_tracker"
            className="d-flex flex-column mt-3"
        >
            <div ref={topRef}/>
            <DateInputReduxForm
                name="dt_occurred"
                floatingLabel={true}
                yearLabel={props.intl.formatMessage(messages.date_input_year_label)}
                monthLabel={props.intl.formatMessage(messages.date_input_month_label)}
                dayLabel={props.intl.formatMessage(messages.date_input_day_label)}
                labelText={props.intl.formatMessage(messages.mood_tracker_dt_occurred_label)}
                customInput={<CustomDatepickerInput/>}
                wrapperClassName="flex-grow-1"
                labelClassName="form-label me-2"
            />
            <TimePickerReduxForm
                name="tm_occurred"
                outputFormat="hh:mm A"
                label="Time"
                labelClassName="form-label me-2"
            />
            {
                [
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_interested_label"
                                defaultMessage="Interested"
                            />
                        ),
                        name: 'interested'
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_distressed_label"
                                defaultMessage="Distressed"
                            />
                        ),
                        name: 'distressed',
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_excited_label"
                                defaultMessage="Excited"
                            />
                        ),
                        name: 'excited',
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_upset_label"
                                defaultMessage="Upset"
                            />
                        ),
                        name: 'upset'
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_strong_label"
                                defaultMessage="Strong"
                            />
                        ),
                        name: 'strong',
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_guilty_label"
                                defaultMessage="Guilty"
                            />
                        ),
                        name: 'guilty'
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_scared_label"
                                defaultMessage="Scared"
                            />
                        ),
                        name: 'scared'
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_hostile_label"
                                defaultMessage="Hostile"
                            />
                        ),
                        name: 'hostile'
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_enthusiastic_label"
                                defaultMessage="Enthusiastic"
                            />
                        ),
                        name: 'enthusiastic'
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_proud_label"
                                defaultMessage="Proud"
                            />
                        ),
                        name: 'proud'
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_irritable_label"
                                defaultMessage="Irritable"
                            />
                        ),
                        name: 'irritable'
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_alert_label"
                                defaultMessage="Alert"
                            />
                        ),
                        name: 'alert'
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_ashamed_label"
                                defaultMessage="Ashamed"
                            />
                        ),
                        name: 'ashamed'
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_inspired_label"
                                defaultMessage="Inspired"
                            />
                        ),
                        name: 'inspired'
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_nervous_label"
                                defaultMessage="Nervous"/>
                        ),
                        name: 'nervous'
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_determined_label"
                                defaultMessage="Determined"/>
                        ),
                        name: 'determined'
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_attentive_label"
                                defaultMessage="Attentive"/>
                        ),
                        name: 'attentive'
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_jittery_label"
                                defaultMessage="Jittery"/>
                        ),
                        name: 'jittery'
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_active_label"
                                defaultMessage="Active"/>
                        ),
                        name: 'active'
                    },
                    {
                        label: (
                            <FormattedMessage
                                id="mood_tracker_afraid_label"
                                defaultMessage="Afraid"/>
                        ),
                        name: 'afraid'
                    }
                ].map(({ label, name }) => (
                    <div key={name}>
                        <RadioGroupReduxForm
                          name={name}
                          options={options}
                          fieldsetClassName="d-flex flex-column mb-3"
                          fieldContainerClassName="d-flex align-items-center mb-1"
                          labelClassName="form-label"
                          containerClassName="input-group-fancy"
                          inputClassName="me-1"
                          title={label}
                          errorText={isFieldValid(name) ? null : 'Required field'}
                        />
                    </div>
                ))
            }
            <div className="d-flex align-items-center">
                <BSButton loading={props.isFetching} variant="green" onClick={() => onSave(false)}>
                    <FontAwesome name="star-o" color="white" size={20}/>
                    <div className="ms-2">
                        <FormattedMessage
                            id="mood_tracker_button_save"
                            description="Submit the mood tracker form to save an mood"
                            defaultMessage="Save"
                        />
                    </div>
                </BSButton>
                <BSButton loading={props.isFetching} onClick={() => onSave(true)} className="ms-2" variant="link-green">
                    <FormattedMessage
                        id="mood_tracker_button_save_add"
                        description="Submit the Mood tracker form to save a Mood"
                        defaultMessage="Save & Add New"
                    />
                </BSButton>
            </div>
        </form>
    )
}

const formFields = [
    'dt_occurred',
    'tm_occurred',
    'positive_score',
    'negative_score',
    'interested',
    'distressed',
    'excited',
    'upset',
    'strong',
    'guilty',
    'scared',
    'hostile',
    'enthusiastic',
    'proud',
    'irritable',
    'alert',
    'ashamed',
    'inspired',
    'nervous',
    'determined',
    'attentive',
    'jittery',
    'active',
    'afraid'
];
let form = reduxForm({
    form: 'moodTracker',
    fields: formFields,
    validate: validator ({
        'dt_occurred': { required: 'Required', date: 'Please enter a valid date and time' },
        'interested': { required: 'Required' },
        'distressed': { required: 'Required' },
        'excited': { required: 'Required' },
        'upset': { required: 'Required' },
        'strong': { required: 'Required' },
        'guilty': { required: 'Required' },
        'scared': { required: 'Required' },
        'hostile': { required: 'Required' },
        'enthusiastic': { required: 'Required' },
        'proud': { required: 'Required' },
        'irritable': { required: 'Required' },
        'alert': { required: 'Required' },
        'ashamed': { required: 'Required' },
        'inspired': { required: 'Required' },
        'nervous': { required: 'Required' },
        'determined': { required: 'Required' },
        'attentive': { required: 'Required' },
        'jittery': { required: 'Required' },
        'active': { required: 'Required' },
        'afraid': { required: 'Required' }
    })
})(MoodTracker);
   
const selector = formValueSelector('moodTracker') // <-- same as form name
form = connect(state => ({
    ...formFields.reduce((dict, fieldName) =>
        ({ ...dict, [fieldName]: selector(state, fieldName) }), {}
    ),
    user: state.auth?.user,
}))(form);

export default injectIntl(form);
