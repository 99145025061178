'use strict';

import _ from 'lodash';
import React from 'react';
import NumberTableForm from 'components/forms/number-table-form';

export const NumberTable = React.createClass({
    propTypes: {
        column_headers: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
        field_options: React.PropTypes.object.isRequired,
        form_name: React.PropTypes.string.isRequired,
        responses: React.PropTypes.object.isRequired,
        response_keys: React.PropTypes.arrayOf(React.PropTypes.string).isRequired,
        row_headers: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
        updateResponse: React.PropTypes.func.isRequired
    },

    componentDidMount() {
        const { response_keys, responses, formulas} = this.props;
        // set up our default values for number tables
        let defaultValues = responses;
        let updateDefaultValues = false;
        // go through the formulas, get the default responses
        _.forEach(formulas, function(formula) {
            for(var key in formula.default_responses) {
                // if the response doesn't have the key, use the default, as long as it is in the response keys
                if(typeof responses[key] === 'undefined' && response_keys.indexOf(key) > -1) {
                    updateDefaultValues = true;
                    defaultValues[key] = parseFloat(formula.default_responses[key]);
                }
            }
        });
        // update the state with the default values in case they don't click in a box
        if(updateDefaultValues) this.updateResponse(defaultValues);
    },

    render() {
        const { column_headers, responses, row_headers} = this.props;
        return (
            <NumberTableForm
                rowHeaders={row_headers}
                columnHeaders={column_headers}
                responses={responses}
                updateResponse={this.updateResponse}
            />
        );
    },

    updateResponse(data) {
        this.props.updateResponse(data, this.props.formulas);
    }
});
