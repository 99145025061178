'use strict';

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { defineMessages, FormattedMessage } from 'react-intl';
import Actions from 'actions';
import { REQUEST_UPDATE_SUBSCRIPTION_SUCCESS,
         REQUEST_ADD_SUBSCRIPTION_SUCCESS } from 'actions/types';
import { loadStripe } from '@stripe/stripe-js';
import config from '../../config';
import {Elements} from '@stripe/react-stripe-js';
import CardForm from '../components/forms/CardForm.component.web';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { BLACK } from '../stylesheets/colors';
import BSButton from '../components/blocks/BSButton.component.web';
const stripePromise = loadStripe(config.STRIPE_KEY);

const messages = defineMessages({
    card_change_successful: {
        id: 'card_change_successful',
        description: 'card change successful',
        defaultMessage: 'You card has been updated!'
    },
});

const PaymentInformationPage = React.createClass({
    propTypes: {
        dispatch: React.PropTypes.func.isRequired,
        auth: React.PropTypes.object,
        subscriptions: React.PropTypes.arrayOf(React.PropTypes.object)
    },

    getInitialState() { return { loading: false }; },

    onChangePlan() {
        this.props.dispatch(Actions.clearSelectedPlan());
        browserHistory.push('/account/plans');
    },

    onSaveComplete(result) {
        this.spinnerStop();
        this.setState({ loading: false });
        if ([REQUEST_ADD_SUBSCRIPTION_SUCCESS, REQUEST_UPDATE_SUBSCRIPTION_SUCCESS].includes(result.type)) {
            this.props.dispatch(Actions.notify({
                title: 'Success!',
                message: 'Your card has been updated!',
                level: 'success'
            }));
            browserHistory.push('/account');
        }
        else {
            this.props.dispatch(Actions.notify({
                title: 'Error!',
                message: 'An error occurred',
                level: 'error'
            }));
        }
    },

    addSubscription(stripeToken) {
        const { auth } = this.props;
        const token = _.get(auth, 'user.token');
        const userId = _.get(auth, 'user._id');
        const selectedPlan = _.get(auth, 'selectedPlan');
        this.setState({ loading: true });
        this.props.dispatch(Actions.addSubscription(token, userId, selectedPlan, stripeToken))
            .then(this.onSaveComplete)
            .catch(() => this.setState({ loading: false }));
    },

    editSubscription(stripeToken) {
        const { auth, subscriptions } = this.props;
        const token = _.get(auth, 'user.token');
        const userId = _.get(auth, 'user._id');
        const subscriptionId = _.get(subscriptions, '[0].id');
        const selectedPlan = _.get(auth, 'selectedPlan');
        this.setState({ loading: true });
        this.props.dispatch(Actions.updateSubscription(token, userId, subscriptionId, { token: stripeToken }, selectedPlan))
          .then(this.onSaveComplete)
          .catch(() => this.setState({ loading: false }));
    },

    spinnerStart() {
        this.props.dispatch(Actions.updateDisplay('appSpinner', 'CREDIT_CARD_PROCESSING'));
    },

    spinnerStop() {
        this.props.dispatch(Actions.updateDisplay('appSpinner', 'STOP'));
    },

    render() {
        const { subscriptions } = this.props,
            onSaveCard = _.isEmpty(subscriptions) ? this.addSubscription : this.editSubscription;

        return (
          <Elements stripe={stripePromise}>
              <div className="container">
                  <div className="row">
                      <div className="col-12 col-lg-8 offset-lg-2">
                          <div className="card mt-4" style={{ border: 'none' }}>
                              <div className="card-body">
                                  <div className="d-flex align-items-center">
                                      <BSButton variant="flat-blue" linkTo="/account">
                                          <MaterialCommunityIcons name="arrow-left" size={24} color={BLACK} />
                                      </BSButton>
                                      <h3 className="card-title">
                                          <FormattedMessage
                                            id="account_payment_section_title"
                                            defaultMessage="Payment Information"
                                            description="Payment page, page title"
                                          />
                                      </h3>
                                  </div>
                                  <CardForm
                                    loading={this.state.loading}
                                    onSubmit={onSaveCard}
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </Elements>
        )
    }
});

export default connect(
    state => ({
        auth: state.auth,
        plans: state.plans.plans,
        subscriptions: _.get(state, 'subscriptions.subscriptions.data')
    })
)(PaymentInformationPage);

