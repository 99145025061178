import React from "react";
import {FormattedMessage} from "react-intl";
import Actions from '../../actions';
import BSButton from './BSButton.component';
import {connect} from "react-redux";

const SaveToolButton = React.createClass({

    propTypes: {
        save: React.PropTypes.func,
        disabled: React.PropTypes.bool,
    },

    save: async function() {
        const { save } = this.props;
        const saveMessage = CUAC_SETTINGS.GROUP.save_success_message
            ? CUAC_SETTINGS.GROUP.save_success_message
            : 'Save successful';
        const res = await save();
        if (res)
            await this.props.dispatch(Actions.notify({
                title: "Save Successful",
                message: saveMessage,
                level: 'success',
                autoDismiss: 4
            }));
        else
            await this.props.dispatch(Actions.notify({
                title: "Save Error",
                message: "There was a problem while saving",
                level: 'error',
                autoDismiss: 4
            }));
    },

    render: function() {
        if (!!CUAC_SETTINGS.GROUP.save_enabled) {
            const { disabled } = this.props;
            const defaultMessage = "Save"
            return (
                <BSButton
                    disabled={disabled || CUAC_SETTINGS.GROUP.isDisabled}
                    variant="green"
                    onClick={this.save}
                >
                    <FormattedMessage
                        id="screener_save_button"
                        description="Saves user date without continuing to the next page"
                        defaultMessage={defaultMessage}
                    />
                </BSButton>
            );
        } else {
            return <></>
        }
    }
});

export default connect()(SaveToolButton);
