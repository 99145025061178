'use strict';

import _ from 'lodash';

import {
    SET_COUPON,
    REQUEST_GET_PLANS_FAILURE,
    REQUEST_GET_PLANS,
    REQUEST_GET_PLANS_SUCCESS
} from 'actions/types';

export function reducer(state = {
    plans: [],
    couponCode: '',
    couponCodeError: false,
    fetching: false,
}, action = { type: '' }) {
    switch (action.type) {
        case SET_COUPON:
            const plans = _.filter(_.get(state, 'plans', []), (plan) => {
                return _.get(plan, 'coupon_code', '').toLowerCase() === action.payload.couponCode;
            });
            return {
                ...state,
                couponCode: (!_.isEmpty(plans) ? action.payload.couponCode : ''),
                couponCodeError: (_.isEmpty(plans) ? true : false)
            }
        case REQUEST_GET_PLANS:
            return {
                ...state,
                fetching: true,
            };
        case REQUEST_GET_PLANS_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case REQUEST_GET_PLANS_SUCCESS:
            const hasCoupons = !_.isEmpty(
                _.filter(
                    action.payload.plans,
                    (plan) => { return 'coupon_code' in plan; }
                )
            );
            return {
                ...state,
                plans: action.payload.plans,
                hasCoupons,
                fetching: false,
            }
        default:
            return state;
    }
}