'use strict';

export const handleResponse = (response) => {
    function ApiError(value) {
       this.statusText = value.status_code;
       this.message = value.message;
       this.toString = function() {
          return this.statusText + ': ' + this.message;
       };
    }
    if (response.status === 200 || response.status === 201) {
        return response.json();
    } else if (response.status >= 202 && response.status < 300) {
        return response;
    } else {
        return response.json().then((results) => {
            throw new ApiError(results);
        });
    }
};

