'use strict';

import _ from 'lodash';

import React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { bacFeedback } from 'utils/bac';
import { NumberInput } from '../blocks/NumericInput.component.web';
import { DARK_GRAY, FOREST_GREEN, LIGHT_GRAY } from '../../stylesheets/colors';

const messages = defineMessages({
    "bac_find_hours_drinking": { id: "bac_find_hours_drinking", defaultMessage: "Hours" },
    "bac_find_drinks": { id: "bac_find_drinks", defaultMessage: "Drinks" }
});

const BACTableInternal = React.createClass({

    propTypes: {
        data_key: React.PropTypes.string,
        responses: React.PropTypes.object,
        regular_limit_data_key: React.PropTypes.string,
        absolute_limit_data_key: React.PropTypes.string
    },

    getInitialState() {
        return {
            findHours: '',
            findDrinks: ''
        };
    },

    handleFindHoursChange(event) {
        const num = (!event || !isNaN(event))
            ? Number(event)
            : Number(_.get(event, 'target.value'));
        this.setState({
            findHours: num,
            bacLevel: this.getBAC(num, null)
        });
    },

    handleFindDrinksChange(event) {
        const num = (!event || !isNaN(event))
            ? Number(event)
            : Number(_.get(event, 'target.value'));
        this.setState({
            findDrinks: num,
            bacLevel: this.getBAC(null, num)
        });
    },

    getBAC(inputHours, inputDrinks){

        const table = _.get(this.props, 'responses.bac_table');

        return bacFeedback(inputHours, inputDrinks, table, this.state.findDrinks, this.state.findHours);
    },

    renderBACForm() {
        const { findHours, findDrinks, bacLevel } = this.state;
        const { responses, regular_limit_data_key, absolute_limit_data_key} = this.props;
        const regularLimit = parseFloat(_.get(responses, regular_limit_data_key, 0));
        const absoluteLimit = parseFloat(_.get(responses, absolute_limit_data_key, 0));

        function renderLimitLegend() {
            if (!regularLimit || !absoluteLimit) return null;
            return (
              <div className="d-flex">
                  <div className="limit-regular d-flex me-2">
                      <div className="color-block me-1">
                          <span className="bold">&gt; .</span>
                          {regularLimit.toFixed(3).split('.')[1]}
                      </div>
                      <FormattedMessage id="bac_table_legend_limit_regular"
                                        defaultMessage="Above Regular Limit" />
                  </div>
                  <div className="ms-2 limit-absolute d-flex">
                      <div className="color-block me-1">
                          <span className="bold">&gt; .</span>
                          {absoluteLimit.toFixed(3).split('.')[1]}
                      </div>
                      <FormattedMessage id="bac_table_legend_limit_absolute"
                                        defaultMessage="Exceeds Absolute Limit" />
                  </div>
              </div>
            )
        }

        return (
          <div className="d-flex justify-content-center">
              <div className="card" style={{ maxWidth: 700, border: 'none' }}>
                  <div className="card-body">
                      <div className="card-title">
                          { renderLimitLegend() }
                      </div>
                      <div className="d-flex flex-column flex-lg-row">
                          <NumberInput
                            value={findDrinks}
                            min="0"
                            max="20"
                            label={this.props.intl.formatMessage(messages.bac_find_drinks)}
                            onChange={this.handleFindDrinksChange}
                            labelClassName="text-muted"
                            placeholder="0"
                            style={{ width: 70, border: 'none', textAlign: 'center', backgroundColor: LIGHT_GRAY }}
                          />
                          <div className="my-2 my-lg-0">
                              <NumberInput
                                value={findHours}
                                min="0"
                                max="20"
                                label={this.props.intl.formatMessage(messages.bac_find_hours_drinking)}
                                onChange={this.handleFindHoursChange}
                                labelClassName="text-muted"
                                placeholder="0"
                                style={{ width: 70, border: 'none', textAlign: 'center', backgroundColor: LIGHT_GRAY }}
                              />
                          </div>
                          <div
                            className="d-flex mt-2 mt-lg-0 flex-row flex-lg-column align-items-center justify-content-lg-center rounded p-lg-3"
                            style={{ minWidth: 150 }}
                          >
                              <p className="fw-bold mb-0" style={{ textAlign: 'center', color: DARK_GRAY }}>
                                  <FormattedMessage
                                    id="bac_find_legend_instructions"
                                    defaultMessage="Your BAC"
                                  />
                              </p>
                              <p className="h4 fw-bold mb-0 ms-3 ms-lg-0">
                                  {
                                      typeof bacLevel === 'number' ? (bacLevel === 0) ? bacLevel : ('.' + bacLevel.toFixed(4).split('.')[1]) : 0
                                  }
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        )
    },

    render() {
        const { data_key, responses, regular_limit_data_key, absolute_limit_data_key} = this.props;
        const bacTable =  _.get(responses, data_key, []);
        const regularLimit = parseFloat(_.get(responses, regular_limit_data_key, 0));
        const absoluteLimit = parseFloat(_.get(responses, absolute_limit_data_key, 0));
        const greenBAC = (regularLimit) ? regularLimit : 0.055;
        const yellowBAC = (absoluteLimit) ? absoluteLimit : 0.08;

        if (!bacTable) return null;

        return (
          <div className="d-flex flex-column">
              <div style={{ position: 'relative' }}>
                  <div className="table-responsive d-flex">
                      <div style={{ marginTop: 50 }}>
                          <caption style={{ transform:"rotate(270deg)" }}>
                              <FormattedMessage id="bac_table_legend_total_drinks" defaultMessage="Drinks" />
                          </caption>
                      </div>
                      <table className="table bac-table caption-top">
                          <caption>
                              <FormattedMessage
                                id="bac_table_legend_hours_drinking"
                                defaultMessage="Total hours spent drinking"
                              />
                          </caption>
                          <thead>
                          <tr>
                              <td/>
                              {[0,1,2,3,4,5,6,7,8,9,10,11,12].map((hours,i) =>
                                <td key={"bac-column-header-"+i}>{hours}</td>
                              )}
                          </tr>
                          </thead>
                          <tbody>
                          {bacTable.map((row, i)=>
                            <tr className="bac-row" key={"bac-row-"+i}>
                                <td>{i}</td>
                                {row.map((cell, j) =>
                                  <td className="bac-cell" key={"bac-cell-"+j} style={
                                      {
                                          background:
                                            (cell === 0 || cell < greenBAC)
                                              ?
                                              (regularLimit)
                                                ?
                                                'rgb(139,195,74)'
                                                :
                                                'rgb(' + Math.round((112*(cell/greenBAC)) + 139) + ',' + Math.round((17*(cell/greenBAC)) + 195) + ',' + Math.round((-41*(cell/greenBAC)) + 74) + ')'
                                              :
                                              (cell < yellowBAC)
                                                ?
                                                'rgb(' + Math.round((-15*((cell-.03)/yellowBAC)) + 251) + ',' + Math.round((-151*((cell-.03)/yellowBAC)) + 212) + ','  + Math.round((28*((cell-.03)/yellowBAC)) + 33) + ')'
                                                :
                                                '#ed5756'
                                      }
                                  }
                                  >
                                        <span style={{color: (cell < yellowBAC) ? '#282828' : 'white'}}>{
                                            (cell === 0) ? cell : ('.' + cell.toFixed(3).split('.')[1])
                                        }</span>
                                  </td>
                                )}
                            </tr>
                          )}
                          </tbody>
                      </table>
                  </div>
              </div>
              {
                  this.renderBACForm()
              }
          </div>
        )
    }
});

export const BACTable = injectIntl(BACTableInternal);
