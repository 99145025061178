'use strict';

import { handleResponse } from 'utils/api';

export const requestPasswordReset = (email, code) => {
    return fetch(
        CUAC_SETTINGS.API_ROOT + '/password-reset-requests',
        {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                code
            })
        }
    )
        .then(handleResponse);
};
