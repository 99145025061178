import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import Coupon from 'components/forms/coupon';
import { CouponDisplay } from 'components/account/coupon-display';
import Actions from 'actions';
import { initialize } from 'redux-form';

type Props = {
    couponCodeError?: string;
    activeCouponCode?: any;
    hasCoupons?: boolean;
    subscriptions?: any[];
    setCoupon: (coupon?: string) => void;
    initialize: (str: string) => void;
}

const CouponCapture = (props: Props) => {
    const { couponCodeError, activeCouponCode } = props;

    const clearCoupon = () => props.setCoupon('');

    const saveCoupon = (data) => {
        props.setCoupon(data.coupon_code.toLowerCase());
        props.initialize('coupon');
    }

    const coupon = _.isEmpty(activeCouponCode)
        ? <Coupon onSubmit={saveCoupon} activeCouponCode={activeCouponCode} />
        : <CouponDisplay onClickNav={clearCoupon} activeCouponCode={activeCouponCode} />;

    return (
        <div className="card" style={{ border: 'none' }}>
           <div className="card-body">
               <h5 className="card-title">
                   <FormattedMessage
                       id="coupon_section_title"
                       description=""
                       defaultMessage="Have a coupon?"
                   />
               </h5>
               { coupon }
               {
                   couponCodeError
                       ? (
                           <FormattedMessage
                               id="coupon_error"
                               description="Error when looking for a coupon"
                               defaultMessage="We couldn't find that coupon."
                           />
                       )
                       : null
               }
           </div>
        </div>
    )
}

export default connect(state => ({
    activeCouponCode: state.plans.couponCode,
    couponCodeError: state.plans.couponCodeError,
    subscriptions: _.get(state, 'subscriptions.subscriptions.data'),
    hasCoupons: state.plans.hasCoupons,
}), {
    setCoupon: Actions.setCoupon,
    initialize
})(CouponCapture);
