'use strict';

import { getResponses as getResponsesAPI } from 'api/responses';

import {
    REQUEST_GET_RESPONSES,
    REQUEST_GET_RESPONSES_SUCCESS,
    REQUEST_GET_RESPONSES_FAILURE
} from 'actions/types';

function requestGetResponses() {
    return {
        type: REQUEST_GET_RESPONSES
    };
}

function requestGetResponsesSuccess(json) {
    return {
        type: REQUEST_GET_RESPONSES_SUCCESS,
        payload: {
            responses: json
        }
    };
}

function requestGetResponsesFailure(error) {
    return {
        type: REQUEST_GET_RESPONSES_FAILURE,
        payload: error,
        error: true
    };
}

export default function getResponses(token, userId) {
    return dispatch => {
        dispatch(requestGetResponses());
        return getResponsesAPI(token, userId)
            .then((json) => {
                return dispatch(requestGetResponsesSuccess(json));
            })
            .catch((error) => {
                return dispatch(requestGetResponsesFailure(error));
            });
    };
}