'use strict';

import Actions from 'actions';

import {
    REQUEST_SEND_COMPLETION_EMAIL,
    REQUEST_SEND_COMPLETION_EMAIL_SUCCESS,
    REQUEST_SEND_COMPLETION_EMAIL_FAILURE
} from 'actions/types';

export function reducer(state = {
    isFetching: false
}, action = { type: '' }) {
    switch (action.type) {
        case REQUEST_SEND_COMPLETION_EMAIL:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_SEND_COMPLETION_EMAIL_SUCCESS:
            return {
                ...state,
                isFetching: true,
                completion_sent: {
                  [action.payload.tool_id]: true
                }
            };
        case REQUEST_SEND_COMPLETION_EMAIL_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        default:
            return state;
    }
}
