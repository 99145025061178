import React from 'react';
import {FontAwesome} from '@expo/vector-icons';
import {BLUE, LIGHTEST_BLUE} from "../../stylesheets/colors";
import BSButton from "./BSButton.component.web";
import {FormattedMessage} from "react-intl";
import InfoBug from 'components/popover/infobug';
import { withRouter } from 'react-router';

type Props = {
    location: any;
}

const TrackerNav = (props: Props) => {
    const active = (type: string) => {
        const { pathname } = props.location;
        switch (type) {
            case 'moods':
                return pathname.includes('mood');
            case 'drinks':
                return pathname.includes('drink')
            case 'urges':
                return pathname.includes('urge');
            default:
                return false;
        }
    }

    return (
        <div
            className="nav tracker-nav d-flex flex-column flex-lg-row justify-content-center"
            style={{ border: `1px solid ${LIGHTEST_BLUE}`, padding: '10px', borderRadius: '.25rem', position: 'relative' }}
        >
            <BSButton
                className="mx-2 justify-content-center my-1 my-lg-0"
                style={{ flex: 1 }}
                variant={active('moods') ? 'blue' : 'flat-gray'}
                linkTo="/moods"
            >
                <FontAwesome name="adjust" color={active('moods') ? 'white' : BLUE} size={20}/>
                <div className="ms-2">
                    <FormattedMessage id="tracker_nav_mood" defaultMessage="Mood Tracker"/>
                </div>
            </BSButton>
            <BSButton
                className="mx-2 justify-content-center my-1 my-lg-0"
                style={{ flex: 1 }}
                variant={active('urges') ? 'blue' : 'flat-gray'}
                linkTo="/urges"
            >
                <FontAwesome name="bolt" color={active('urges') ? 'white' : BLUE} size={20} />
                <div className="ms-2">
                    <FormattedMessage id="tracker_nav_urge" defaultMessage="Urge Tracker"/>
                </div>
            </BSButton>
            <BSButton
                className="mx-2 justify-content-center my-1 my-lg-0"
                style={{ flex: 1 }}
                variant={active('drinks') ? 'blue' : 'flat-gray'}
                linkTo="/drinks"
            >
                <FontAwesome name="glass" color={active('drinks') ? 'white' : BLUE} size={20} />
                <div className="ms-2">
                    <FormattedMessage id="tracker_nav_drink" defaultMessage="Drink/No Drink Tracker"/>
                </div>
            </BSButton>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: 30, width: 30, borderRadius: 15, backgroundColor: 'white', position: 'absolute', top: -10, right: -10 }}
            >
                <InfoBug size={22}>
                    <FormattedMessage
                        id="trackers_infobug"
                        defaultMessage="Using the trackers every time you login will allow you to see the progress you're making over time towards your goals."
                    />
                </InfoBug>
            </div>
        </div>
    )
}

export default withRouter(TrackerNav);
