'use strict';

import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Actions from 'actions';
import Button from 'components/ux/mdl/button';
import UrgeList from 'components/trackers/urge-list';
import { faPrint, faBookmark, faBell as faBellO, faComment as faCommentingO } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
const UrgeTrackerPage = React.createClass({

    propTypes: {
        auth: React.PropTypes.object.isRequired,
        dispatch: React.PropTypes.func.isRequired,
        trackerEntries: React.PropTypes.object.isRequired
    },

    showRemindersModal() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'newReminder',
            edit_reminder: {},
            modalContent: true
        }));
    },

    showHelpFeedbackModal() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'helpFeedback',
            modalContent: true
        }));
    },

    render() {
        const { trackerEntries, dispatch } = this.props;
        const isFetching = _.get(trackerEntries, 'isFetching');
        return (
          <div className="container mb-4">
              <div className="col-12 col-lg-8 offset-lg-2">
                  <UrgeList trackerEntries={trackerEntries} urgeListClassName="table" />
              </div>
          </div>
        );
    }
});

export default connect(
    state => ({
        auth: state.auth,
        trackerEntries: state.trackerEntries
    })
)(UrgeTrackerPage);
