import React, {useState} from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";
import BSButton from "../components/blocks/BSButton.component.web";
import Actions from 'actions';
import {FontAwesome, MaterialCommunityIcons} from '@expo/vector-icons';
import {Dropdown} from "react-bootstrap";
import {LIGHT_YELLOW} from "../stylesheets/colors";
import _ from 'lodash';
import {Dispatch} from "redux";
import ConfirmModal from "../components/blocks/ConfirmModal.component.web";

type Props = {
    reminders: any[];
    dispatch: Dispatch<any>;
    auth: any;
}

const RemindersPage = (props: Props) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [onConfirmDelete, setOnConfirmDelete] = useState(undefined);

    const addNew = () => props.dispatch(Actions.showModal('appModal', {
        modalNotifications: 'newReminder',
        edit_reminder: {},
        modalContent: true
    }));

    const onEdit = reminder => props.dispatch(Actions.showModal('appModal', {
        modalNotifications: 'newReminder',
        edit_reminder: reminder,
        modalContent: true
    }));

    const onDelete = async (reminderId) => {
        const userId = _.get(props.auth, 'user._id');
        const token = _.get(props.auth, 'user.token');
        props.dispatch(Actions.updateDisplay('appSpinner', 'PLEASE_WAIT'));
        await props.dispatch(Actions.deleteReminder(userId, token, reminderId));
        props.dispatch(Actions.updateDisplay('appSpinner', 'STOP'));
        setShowConfirmModal(false);
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-8 offset-lg-2">
                    <div className="card my-4" style={{ border: 'none' }}>
                        <div className="card-body">
                            <h3 className="card-title">
                                <FormattedMessage id="reminders_title" defaultMessage="Reminders"/>
                            </h3>
                            <table className="table table-borderless">
                                <thead style={{ backgroundColor: LIGHT_YELLOW }}>
                                <tr>
                                    <th>
                                        <FormattedMessage
                                            id="reminders_table_header_title"
                                            defaultMessage="Title"
                                        />
                                    </th>
                                    <th>
                                        <FormattedMessage
                                            id="reminders_table_header_message"
                                            defaultMessage="Message"
                                        />
                                    </th>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (props.reminders || []).map(item => (
                                        <tr
                                            key={item._id}
                                            title={item._id}
                                        >
                                            <td>{item.message_alert.message_title}</td>
                                            <td>{item.message_alert.message_content}</td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="light" size="sm">
                                                        <MaterialCommunityIcons name="dots-vertical" size={24} color="black" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            onClick={() => onEdit(item)}
                                                            className="d-flex align-items-center"
                                                        >
                                                            <FontAwesome name="edit" size={24} color="black" />
                                                            <span className="ms-2">Edit</span>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                setShowConfirmModal(true);
                                                                setOnConfirmDelete(() => () => onDelete(item._id));
                                                            }}
                                                            className="d-flex align-items-center"
                                                        >
                                                            <FontAwesome name="trash" size={24} color="black" />
                                                            <span className="ms-2">Delete</span>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                            <div className="d-flex align-items-center">
                                <BSButton variant="green" onClick={addNew}>
                                    <FontAwesome name="plus" color="white" size={20} />
                                    <div className="ms-2">
                                        <FormattedMessage id="add_new_reminder" defaultMessage="Add New"/>
                                    </div>
                                </BSButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmModal
                show={showConfirmModal}
                onConfirm={() => onConfirmDelete()}
                onCancel={() => {
                    setShowConfirmModal(false);
                    setOnConfirmDelete(undefined);
                }}
                message={
                    <FormattedMessage
                        id="delete_reminder_confirm_instructions"
                        defaultMessage="Are you sure you want to delete this reminder?"
                        description="Reminder -- Delete entry, confirmation message"
                    />
                }
            />
        </div>
    )
}

export default connect(state => ({
    auth: state.auth,
    reminders: state.reminders?.reminders || state.modal.alerts_list
}))(RemindersPage);
