'use strict';

import { getAllReminders as getAllRemindersAPI } from 'api/reminders';
import notify from 'actions/notify';

import {
    REQUEST_GET_REMINDERS,
    REQUEST_GET_REMINDERS_SUCCESS,
    REQUEST_GET_REMINDERS_FAILURE

} from 'actions/types';

function requestGetReminders() {
    return {
        type: REQUEST_GET_REMINDERS
    };
}

function requestGetRemindersSuccess(json) {
    return {
        type: REQUEST_GET_REMINDERS_SUCCESS,
        payload: {
            reminders: json
        }
    };
}

function requestGetRemindersFailure(error) {
    return {
        type: REQUEST_GET_REMINDERS_FAILURE,
        payload: error,
        error: true
    };
}

export default function getAllReminders(userId, token) {
    return dispatch => {
        dispatch(requestGetReminders());
        return getAllRemindersAPI(userId, token)
        .then((json) => {
            return dispatch(requestGetRemindersSuccess(json));
        })
        .catch((error) => {            
            dispatch(notify({
                title: "Network Error",
                message: "Cannot GET List of Reminders. Please try again in a few minutes.",
                level: 'error',
                autoDismiss: 4
            }));

            return dispatch(requestGetRemindersFailure(error));
        });
    };
}