'use strict';

import _ from 'lodash';
import Moment from 'moment'; Moment.locale('en');
import React from 'react';
import { findDOMNode } from 'react-dom';
import { c3formatDates } from 'utils/tools';
import { canUseTool } from 'utils/render';
import { Block } from 'components/blocks/block';

import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import d3 from 'd3';
import c3 from 'c3';

const ToolComparisonChart = React.createClass({

    propTypes: {
        intl: React.PropTypes.object,
        period: React.PropTypes.number,
        responses: React.PropTypes.object,
        stat_group: React.PropTypes.string,
        sub_title: React.PropTypes.object,
        title: React.PropTypes.object,
        trackerEntryStats: React.PropTypes.object,
        metrics: React.PropTypes.arrayOf(React.PropTypes.object)
    },

    getInitialState() {
        return(
            {
                feedback_type : null
            }
        )
    },

    componentDidMount() {
        this.buildChart(this.props);
    },

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (this.state.feedback_type !== nextState.feedback_type) {
            this.buildChart(nextProps);
        }
    },

    buildChart(props) {
        // this block and take many props:
        // for multiple data key comparisons, include a 'compare_multi_data_keys' object with the value being the readable label of the data key
        // for single data keys, include a 'compared_data_key' that just has the data key to compare it to as the value. we use the title as the readable label
        // in the case of a multi compare, you may add a baseline by including a 'compared_baseline' with the value being the baseline key
        const { intl: { formatMessage }, lines, responses, allResponses, chart, chart_type, tools, compare_to_tools, compared_data_key, compare_multi_data_keys, compare_calculated_data_key, compared_baseline, sub_title, title, auth: { user }} = props;
        const el = this.refs.chartTarget;
        let comparedToolScore = 0;
        let compare_to_tool_id = false;
        let toolName;
        
        //declare empty vars for later use
        var responsesToSort = [];
        var toolScores = [];
        var toolNames = [];
        var toolBaseline = [];
        var multiScoreColumns = {};

        // need to get our responses so we can sort them later
        for (var key in allResponses) {
            if (_.get(allResponses[key].response, 'dt_c')){
                responsesToSort.push({response: allResponses[key].response});
            }
        }
        // sort our reponses by date created
        const sortedResponses = _.sortBy(responsesToSort, 'response.dt_c');

        // loop through our reponses that have been sorted
        for (var i=0; i < sortedResponses.length; i++) {
            // make sure there is a tool id to compare against
            compare_to_tool_id = sortedResponses[i].response.tool_id;

            var lastUpdatedUrl = _.get(sortedResponses[i].response.progress, 'last_updated_url', 'url');

            // get our string of tools to compare against
            for (var j=0; j < compare_to_tools.length; j++) {
                if (lastUpdatedUrl.indexOf(compare_to_tools[j]) !== -1 && lastUpdatedUrl !== 'url') {

                    if (compare_to_tool_id !== false && _.get(sortedResponses[i].response, 'tool_id') == compare_to_tool_id && (_.get(sortedResponses[i].response.responses, compared_data_key) ||_.get(sortedResponses[i].response.responses, compared_data_key) === 0)) {
                        // grab response and push to array for building our data to chart
                        comparedToolScore = _.get(sortedResponses[i].response.responses, compared_data_key);
                        toolScores.push(comparedToolScore);

                        // if there is a baseline, push that as well
                        if (typeof compared_baseline !== 'undefined') {
                            const comparedBaselineScore = _.get(sortedResponses[i].response.responses, compared_baseline);
                            toolBaseline.push(comparedBaselineScore);
                        }
                        // push tool name and formatted start date also
                        const toolCompared = _.find(tools, {'_id' : compare_to_tool_id})
                        const toolStartDate = Moment.utc(sortedResponses[i].response.dt_c).format('l h:mm A');
                        toolName = _.get(toolCompared, "short_title.defaultMessage", 'Unnamed Tool') + ' - ' + toolStartDate;
                        toolNames.push(toolName);
                    } else if (compare_to_tool_id !== false && _.get(sortedResponses[i].response, 'tool_id') == compare_to_tool_id && typeof compare_multi_data_keys !== 'undefined') {
                        // this is the multi data key compare

                        //set push tool to false so we know to only push tool name once
                        let pushToolName = false;

                        //loop through our multi data keys
                        compare_multi_data_keys.forEach(function (arrayItem) {
                            var key = Object.keys(arrayItem);
                            if (_.get(sortedResponses[i].response.responses, key) || _.get(sortedResponses[i].response.responses, key) === 0 ){
                                comparedToolScore = _.get(sortedResponses[i].response.responses, key);
                                // if we have a response, push tool name
                                pushToolName = true;

                                // we are building the data for the columns here, if the key exists as the first item in the data array, then we just push our score, otherwise we push both the key name and the score
                                if(typeof multiScoreColumns[arrayItem[key]] !== 'undefined') {
                                    multiScoreColumns[arrayItem[key]].push(comparedToolScore);
                                } else {
                                    // this should only happen once per key
                                    multiScoreColumns[arrayItem[key]] = [arrayItem[key], comparedToolScore];
                                }
                            } else if (!_.get(sortedResponses[i].response.responses, key)) {
                                // if we have a response, push tool name
                                pushToolName = true;
                                
                                if(typeof multiScoreColumns[arrayItem[key]] !== 'undefined') {
                                    multiScoreColumns[arrayItem[key]].push('0');
                                } else {
                                    // this should only happen once per key
                                    multiScoreColumns[arrayItem[key]] = [arrayItem[key], '0'];
                                }
                            }
                        })
                        if (typeof compared_baseline !== 'undefined' &&  _.get(sortedResponses[i].response.responses, compared_baseline)) {
                            // pushing our baseline if one exists here
                            const comparedBaselineScore = _.get(sortedResponses[i].response.responses, compared_baseline);
                            toolBaseline.push(comparedBaselineScore);
                        }
                        if (pushToolName) {
                            // push tool name and formatted start date also
                            const toolCompared = _.find(tools, {'_id' : compare_to_tool_id});
                            const toolStartDate = Moment.utc(sortedResponses[i].response.dt_c).format('l h:mm A');
                            toolName = _.get(toolCompared, "short_title.defaultMessage", 'Unnamed Tool') + ' - ' + toolStartDate;
                            toolNames.push(toolName);
                        }
                    }
                }
            }
        }

        //compare the last two data keys and get our feedback type
        this.compareScores(toolScores.slice(-2));

        // this needs to happen for c3 to build the chart, it declares that the tool names will go on the x axis as labels
        toolNames.unshift("x");
        // this is for single data keys, we put the title in as the score label
        toolScores.unshift(title.defaultMessage);

        // build our chart column builder
        let columns = [toolNames];
        // for multi data keys:
        if (typeof compare_multi_data_keys !== 'undefined') {
            var count = 0;
            for(var key in multiScoreColumns) {
                if (count === 0) {
                    this.compareScores(multiScoreColumns[key].slice(-2));
                }
                columns.push(multiScoreColumns[key]);
                count ++;
            }
        } else {
            // for single data keys
            columns.push(toolScores);
        }

        // and last check for baseline
        if (typeof compared_baseline !== 'undefined') {
            toolBaseline.unshift(sub_title.defaultMessage);
            columns.push(toolBaseline);
        }


        let type = "bar";
        if(chart_type) {
            type = chart_type;
        }
        let labels = true;
        var that = this;
        this.chart = c3.generate({
            bindto: el,
            data: {
                x: 'x',
                columns: columns,
                
                xFormat: '%Y-%m-%dT%H:%M:%S.000Z',
                color:function(color,d){
                        return color;
                },

                labels: labels
            },
            bar: {
                width: {
                    ratio: 0.15 // this makes bar width 50% of length between ticks
                }
            },
            axis: {
                x: {
                    type: 'category',
                    label: { // ADD
                        text: 'Tool',
                        position: 'outer-center'
                    }
                },
                y: {
                    label: { // ADD
                        text: 'Score',
                        position: 'outer-middle'
                    },
                    tick: {
                        format: d3.format('d')
                    }
                }
            },
            grid: {
                x: {
                    show: false
                },
                y: {
                    show: true
                }
            },
            zoom: {
                enabled: true
            },
            color: {
                pattern: ['#8BC34A', '#de3226', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a']
            },
            tooltip: {
                format: {
                    // need this to format decimals dynamically
                    value: function(value) {
                        let format = d3.format();
                        if (value % 1 != 0) {
                            const decimalPlaces = (value + '').split('.')[1].length;
                            if (decimalPlaces) {
                                format = d3.format('.'+decimalPlaces+'f');
                            }
                            
                        }
                        return format(value);
                    }
                }
            },
        });

    },

    compareScores(toolScores) {
        let feedbackType;
        if (toolScores[0] < toolScores[1]) {
            feedbackType = 'greater_than';
        }
        if (toolScores[0] > toolScores[1]) {
            feedbackType = 'less_than';
        }
        if (toolScores[0] == toolScores[1]) {
            feedbackType = 'equals';
        }

        this.setState({feedback_type: feedbackType});
    },


    render() {
        const { title, sub_title, stat_group, text_feedback, responses, dispatch, allResponses} = this.props;
        const feedback_type = this.state.feedback_type; 
        return (
            <div className="chart-outer-wrap" id={stat_group}>
                <h3 className="chart-title"><FormattedMessage {...title} /></h3>
                {!sub_title ? null : <div className="instructions"><FormattedMessage {...sub_title} /></div>}
                <div className="chart-inner-wrap" ref="chartTarget" />
                { feedback_type && text_feedback ? 
                        text_feedback[feedback_type].components.map((component, index) => {
                        return (
                            <Block
                                key={'block_' + index}
                                component={component}
                                index={index}
                                responses={responses}
                                dispatch={dispatch}
                                allResponses={allResponses}
                                />
                            )
                        })
                        :
                        null
                }
            </div>
        );
    }
});

export default injectIntl(ToolComparisonChart);
