'use strict';

import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Actions from 'actions';
import Button from 'components/ux/mdl/button';
import DrinkList from 'components/trackers/drink-list';
// /import DrinkTracker from 'components/forms/drink-tracker';
import { faPrint, faBookmark, faBolt, faWineGlassAlt as faGlass, faThList, faComment as faCommentingO, faBell as faBellO, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
const DrinkTrackerPage = React.createClass({

    propTypes: {
        auth: React.PropTypes.object.isRequired,
        dispatch: React.PropTypes.func.isRequired,
        trackerEntries: React.PropTypes.object.isRequired
    },

    showRemindersModal() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'newReminder',
            edit_reminder: {},
            modalContent: true
        }));
    },

    showHelpFeedbackModal() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'helpFeedback',
            modalContent: true
        }));
    },

    render() {
        const { trackerEntries } = this.props;
        const isFetching = _.get(trackerEntries, 'isFetching');
        const initialValues = {
            dt_occurred: new Date().toISOString(),
            std_drink_count: 1
        };
        return (
          <DrinkList trackerEntries={trackerEntries}/>
        );
    }
});

export default connect(
    state => ({
        auth: state.auth,
        trackerEntries: state.trackerEntries
    })
)(DrinkTrackerPage);
