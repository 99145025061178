/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonSpinner from 'components/ux/button-spinner'
import {LandscapeOrientation} from "../blocks/Landscape";


export const PrintButton = React.createClass({

    onClick: function() {
        print();
    },

    render() {
        return (
            <ButtonSpinner noColor={true} flat={true} onClick={this.onClick} spinner={false}>
                <LandscapeOrientation />
                <FormattedMessage {...this.props.text} />
            </ButtonSpinner>
        );
    }
});
