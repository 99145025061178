'use strict';

import {
    SELECT_PLAN
} from 'actions/types';

const selectPlan = (plan) => dispatch => {
	dispatch({
        type: SELECT_PLAN,
        payload: {
            plan
        }
    })
	return Promise.resolve()
}

export default selectPlan;