'use strict';

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import Actions from 'actions';
import Moment from 'moment'; Moment.locale('en');
import CloseAccountSurvey from 'components/forms/close-account-survey';
import {
    REQUEST_DEACTIVATE_USER_SUCCESS
} from 'actions/types';

const CloseAccountSurveyModal = React.createClass({

    propTypes: {
        auth: React.PropTypes.object.isRequired,
        dispatch: React.PropTypes.func.isRequired
    },

    getInitialState: function() {
        const { auth } = this.props;
        const user_timezone = _.get(auth, 'user.user_timezone');

        return {
            isFetching: false
        };
    },

    onCloseAccountFromModal(values, event) {
        event.preventDefault();
        const { auth } = this.props;
        const token = _.get(auth, 'user.token');
        const userId = _.get(auth, 'user._id');
        let jsonValues = {};
        if(typeof values !== 'object') {
          jsonValues = {
            "close_account_check" : "1",
            "close_account_reason_text" : "",
            "close_account_contact_permission" : "",
            "close_account_reason" : []
          };
        } else {
          let close_account_reason = [];
          if(_.get(values, 'close_account_reason')) {
            close_account_reason = values.close_account_reason;
          }
          let close_account_contact_permission = '';
          if(_.get(values, 'close_account_contact_permission')) {
            close_account_contact_permission = values.close_account_contact_permission;
          }
          let close_account_reason_text = '';
          if(_.get(values, 'close_account_reason_text')) {
            close_account_reason_text = values.close_account_reason_text;
          }
          jsonValues = {
            "close_account_check" : "1",
            "close_account_reason_text" : close_account_reason_text,
            "close_account_contact_permission" : close_account_contact_permission,
            "close_account_reason" : close_account_reason
          }
        }
        this.setState({isFetching: true})
        const that = this;
        if(typeof this.props.subscriptions.subscriptions.data[0] !== 'undefined') {
            this.props.dispatch(Actions.deleteSubscription(token, userId, this.props.subscriptions.subscriptions.data[0].id, jsonValues))
            .then(() => { 
                this.props.dispatch(Actions.deactivateUser(token, userId, jsonValues))
                    .then((result) => {
                        that.setState({isFetching: false});
                        if(result.type === REQUEST_DEACTIVATE_USER_SUCCESS) {
                            browserHistory.push('/logout');
                        }
                    });
            });
        } else {
            this.props.dispatch(Actions.deactivateUser(token, userId, jsonValues))
                .then(() => {
                    that.setState({isFetching: false});
                    browserHistory.push('/logout');
                });
        }
    },

    closeModal() {
        this.props.dispatch(Actions.closeModal('appModal', {
            modalNotifications: '',
            modalContent: false
        }));
    },

    render() {
        return (
            <div className="container">
                <CloseAccountSurvey submitCloseAccountModal={this.onCloseAccountFromModal} isFetching={this.state.isFetching}/>
            </div>
        );
    }
});

export default connect(
    state => ({
        auth: state.auth,
        subscriptions: state.subscriptions
    })
)(CloseAccountSurveyModal);
