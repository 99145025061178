/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import _ from 'lodash';
import React from 'react';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import InfoBug from 'components/popover/infobug';
import { connect } from 'react-redux';
import { TrackerTypes } from 'api/tracker-entries';
import Actions from 'actions';
import Accordion from 'components/ux/accordion';
import Button from 'components/ux/mdl/button';
import ButtonConfirm from 'components/ux/button-confirm';
import Paginator from 'lib/Paginator';
import Moment from 'moment'; Moment.locale('en');
import momentTZ from 'lib/moment-timezone-with-data';
import { itemsPerPage } from 'utils/pagination';
import { PrintButton } from 'components/elements/print-button';
import { faChevronCircleDown, faChevronCircleRight, faTimes, faEdit as faPencilSquareO, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
export const GoalList = React.createClass({

    propTypes: {
        tag: React.PropTypes.string,
        trackerEntries: React.PropTypes.object.isRequired
    },

    getInitialState() {
        return {
            openEntries: [],
            displayedEntries: {
                start: 0,
                end: 5
            }
        };
    },

    toggleEntry(index){
        let openEntries = this.state.openEntries;
        if (openEntries.indexOf(index) == -1){
            openEntries.push(index);
        } else {
            openEntries = _.without(openEntries, index);
        }

        this.setState({
            openEntries: openEntries
        });
    },

    addGoalTrackerEntry() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'newGoalTracker',
            modalContent: true
        }));
    },

    editGoalTrackerEntry(trackerEntryId, trackerEntry) {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'editGoalTracker',
            goalTrackerEntry: {
                _id: trackerEntryId,
                entry: trackerEntry
            },
            modalContent: true
        }));
    },

    deleteGoalTrackerEntry(trackerEntryId, currentPage, totalEntries) {
        this.props.dispatch(Actions.updateDisplay('appSpinner', 'PLEASE_WAIT'));
        const { auth, dispatch } = this.props;
        const token = _.get(auth, 'user.token');
        const userId = _.get(auth, 'user._id');
        dispatch(Actions.deleteTrackerEntry(token, userId, trackerEntryId, TrackerTypes.GOAL))
        .then(() => {
            this.updateCurrentPage(currentPage, totalEntries);
            dispatch(Actions.updateDisplay('appSpinner', 'STOP'));
        });
    },

    updateCurrentPage(currentPage, totalEntries) {
        if(currentPage.start >= itemsPerPage && (totalEntries - 1) === 0) {
            this.setState({
                displayedEntries: {
                    start: this.state.displayedEntries.start - itemsPerPage,
                    end: this.state.displayedEntries.end - itemsPerPage
                }
            });
        }
    },

    onChangePage(page) {
        var end = page * itemsPerPage;
        var start = end - itemsPerPage;
        this.setState({
            displayedEntries: {
                start: start,
                end: end
            }
        });
    },

    render() {
        const { tag, trackerEntries } = this.props;
        const locus = this, openEntries = this.state.openEntries, toggleEntry = this.toggleEntry;
        const fullOrder = _.get(trackerEntries, TrackerTypes.GOAL + 'Order', []);
        const fullOrderEntries = _.map(fullOrder, (trackerEntryId) => {
            return _.get(trackerEntries, trackerEntryId, {});
        });
        const filteredEntries = typeof tag !== 'undefined'
            ?
            _.filter(fullOrderEntries, (trackerEntry) => {
                return _.get(trackerEntry, 'tag') === tag;
            })
            : fullOrderEntries;
        const page = {start: this.state.displayedEntries.start, end: this.state.displayedEntries.end};
        const sortedEntries = _.sortByOrder(filteredEntries, ['dt_u'], ['desc']);
        const orderEntries = sortedEntries.slice(page.start, page.end);

        const { auth } = this.props;
        const user_timezone = _.get(auth, 'user.user_timezone');

        const confirmMessage = {
            id: "delete_goal_tracker_confirm_instructions",
            description: "Goal Tracker -- Delete entry, confirmation message",
            defaultMessage: "Are you sure you want to delete this tracker entry?"
        };        

        const printButtonText = { 
            id : "print-button", 
            defaultMessage : "Click here to print" 
        };

        return (
            <div className="section section-tracker-list col-1-1" style={{marginTop: '52px'}}>
                <div className="contain-box-wrap" style={{marginTop: '20px'}}>
                    <div className="contain-box-main" >

                        <h1 className="page-title contain-box-title">
                            <FormattedMessage id="goal_list_title" defaultMessage="Your Recent Goals" />
                        </h1>
                        <div className="tracker-list-data">
                            <div className="tracker-episode header">
                                <div className="tracker-row">
                                    <div className="tracker-entry tracker-entry-date-time px-5" style={{display:"inline-block"}}>
                                        <FormattedMessage id="drink_data_header_date"
                                                          defaultMessage="Last Updated"/>
                                    </div>
                                    <div className="tracker-entry tracker-entry-type px-5" style={{display:"inline-block"}}>
                                        <FormattedMessage id="goal_data_header_area" defaultMessage="Area Of Focus" />
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                            {orderEntries.map((fullTrackerEntry, index) => {
                                const trackerEntry = _.get(fullTrackerEntry, 'data', {});
                                var dtOccurred = Moment(fullTrackerEntry.dt_u.split('+')[0]).format('YYYY-MM-DD HH:mm:ss');
                                dtOccurred = momentTZ.tz(dtOccurred, 'UTC');
                                var dateOccurred = dtOccurred.clone().tz(user_timezone);
                                var timeOccurred = dateOccurred.format('hh:mm A');
                                dateOccurred = dateOccurred.format('MMM DD, YYYY');
                                const dateUpdated = new Date(fullTrackerEntry.dt_u),
                                    isOpen = openEntries.indexOf(index) !== -1,
                                    accordionButton = 'accordion-toggle' + (isOpen ? ' open' : '') + ' tracker-entry-btn',
                                    accordionButtonIcon = (isOpen ? faChevronCircleDown : faChevronCircleRight);

                                return !_.isEmpty(trackerEntry) ?
                                    <div className="tracker-episode" key={'goal_' + index}>
                                        <div className="tracker-row">
                                            <Button className={accordionButton} flat={true} onClick={toggleEntry.bind(locus, index)} style={{float:"left"}}>
                                                <FontAwesomeIcon icon={accordionButtonIcon} />
                                            </Button>
                                            <div className="tracker-entry tracker-entry-date-time list-content plan-list" style={{display:"inline-block"}}>
                                                <div className="tracker-date px-5" style={{display:"inline-block"}}>
                                                    {dateOccurred}
                                                </div>
                                                <div className="tracker-time px-5" style={{display:"inline-block"}}>
                                                    {timeOccurred}
                                                </div>
                                            </div>
                                            <div className="tracker-entry tracker-entry-title list-content plan-list px-5" style={{display:"inline-block"}}>
                                                {trackerEntry.area}
                                            </div>
                                            <div className="tracker-delete-btn" style={{display:"inline-block"}}>
                                                <ButtonConfirm
                                                    flat={false} noColor={false} ripple={true}
                                                    message={confirmMessage}
                                                    className="tracker-entry-btn tracker-action-btn"
                                                    style={{display:"inline-block"}}
                                                    onClick={(event) => this.deleteGoalTrackerEntry(fullTrackerEntry._id, page, orderEntries.length) }>
                                                    <FontAwesomeIcon icon={faTimes}/>
                                                    <FormattedMessage id="delete_goal_tracker_button"
                                                                      description="Delete Goal Tracker Entry"
                                                                      defaultMessage="Delete"/>
                                                </ButtonConfirm>
                                            </div>
                                            <Button className="tracker-entry-btn tracker-action-btn arrange-tracker-btn" key={'edit' + fullTrackerEntry._id} id={fullTrackerEntry._id} onClick={() => this.editGoalTrackerEntry(fullTrackerEntry._id, trackerEntry)}  style={{display:"inline-block"}}>
                                                <FontAwesomeIcon icon={faPencilSquareO} />
                                                <span className="hidden-sm">Edit</span>
                                            </Button>
                                            <div className="clearfix" />
                                            <Accordion collapsed={!isOpen} className="tracker-entry-block">
                                                    <h4>
                                                        {dateOccurred}
                                                    </h4>
                                                    <p style={{ fontSize: '13px',
                                                                padding: '0 0 4px 0',
                                                                margin: '-12px 0 8px 0',
                                                                lineHeight: '16px'}}>
                                                        {timeOccurred}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="goal_data_popover_area_label"
                                                                defaultMessage="Area Of Focus" />
                                                        </span>
                                                        {trackerEntry.area}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="goal_data_popover_expected_outcome_label"
                                                                defaultMessage="Expected Outcome" />
                                                        </span>
                                                        {trackerEntry.expected_outcome}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="goal_data_popover_goal_label"
                                                                defaultMessage="Specific Goal"/>
                                                        </span>
                                                        {trackerEntry.goal}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="goal_data_popover_actions_label"
                                                                defaultMessage="Actions" />
                                                        </span>
                                                        {trackerEntry.actions}
                                                    </p>
                                                    <p className="tracker-entry-p">
                                                        <span className="tracker-entry-label">
                                                            <FormattedMessage
                                                                id="goal_data_popover_time_frame_label"
                                                                defaultMessage="Time Frame" />
                                                        </span>
                                                        {trackerEntry.time_frame}
                                                    </p>
                                                    <PrintButton text={printButtonText} />
                                            </Accordion>
                                            <div className="clearfix" />
                                        </div>
                                    </div>
                                    : null;
                            })}
                            <div className="mdl-layout__tab-bar-right-button addNewTrackerBtn">
                                <Button key="button" ref="menuButton" onClick={this.addGoalTrackerEntry}>
                                    <FontAwesomeIcon icon={faPlusSquare}/>
                                    Add New
                                </Button>
                            </div>
                        </div>
                        {orderEntries.length > 0
                            ? <Paginator className={'pagination'} page={this.state.displayedEntries.end / itemsPerPage} max={Math.ceil(fullOrder.length / itemsPerPage)} onChange={this.onChangePage}/>
                            : null
                        }
                    </div>
                </div>
            </div>
        );
    }
});
export default connect(
    state => ({
        auth: state.auth
    })
)(GoalList);
