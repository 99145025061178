'use strict';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import BSButton from '../blocks/BSButton.component.web';
import { FontAwesome } from '@expo/vector-icons';

export const CouponDisplay = React.createClass({

    propTypes: {
        style: React.PropTypes.object
    },

    render() {
        const { activeCouponCode, onClickNav, selfContained, style } = this.props;

        const couponDisplay = (
          <div className="d-flex flex-column align-items-stretch">
              <p className="d-flex align-items-center mb-0">
                <FormattedMessage
                  id="account_overview_profile_label"
                  description="Account Overview -- profile label"
                  defaultMessage="Active Coupon:"
                />
                <strong className="ms-2">{ activeCouponCode }</strong>
                <BSButton variant="flat-blue" className="justify-content-center" onClick={onClickNav}>
                  <FontAwesome name="times" size={24} color="red" />
                </BSButton>
              </p>
          </div>
        )
        if (selfContained) {
            return (
                <div className="offset-shadow-box" style={style}>
                    {couponDisplay}
                </div>
            );
        }
        
        return couponDisplay;
    }
});
