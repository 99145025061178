'use strict';

import Actions from 'actions';
import { sendCompletionEmail as sendCompletionEmailAPI } from 'api/emails';

import {
    REQUEST_SEND_COMPLETION_EMAIL,
    REQUEST_SEND_COMPLETION_EMAIL_SUCCESS,
    REQUEST_SEND_COMPLETION_EMAIL_FAILURE
} from 'actions/types';

function requestSendCompletionEmail() {
    return {
        type: REQUEST_SEND_COMPLETION_EMAIL
    };
}

function requestSendCompletionEmailSuccess(json) {
    return {
        type: REQUEST_SEND_COMPLETION_EMAIL_SUCCESS,
        payload: json
    };
}

function requestSendCompletionEmailFailure(error) {
    return {
        type: REQUEST_SEND_COMPLETION_EMAIL_FAILURE,
        payload: error,
        error: true
    };
}


export default function sendCompletionEmail(token, user_id, tool_id) {
    return dispatch => {
        dispatch(requestSendCompletionEmail());
        return sendCompletionEmailAPI(token, user_id, tool_id)
        .then((json) => {
          return dispatch(requestSendCompletionEmailSuccess(json));
        })
        .catch((error) => {
          dispatch(requestSendCompletionEmailFailure(error));
          throw error;
        });
    };
}
