/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require */
"use strict";
var moment = require('moment')

var React = require('react');

module.exports = function(){

    // ======== INTERACT.JS GLOBAL FUNCTIONS =========================
        function dragMoveListener (event) {
            var target = event.target,
            // keep the dragged position in the data-x/data-y attributes
            x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
            y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

            // translate the element
            target.style.webkitTransform =
            target.style.transform =
            'translate(' + x + 'px, ' + y + 'px)';

            // update the posiion attributes
            target.setAttribute('data-x', x);
            target.setAttribute('data-y', y);
        }
        window.dragMoveListener = dragMoveListener;

        function resizeMoveListener (event) {
            var target = event.target,
                x = (parseFloat(target.getAttribute('data-x')) || 0),
                y = (parseFloat(target.getAttribute('data-y')) || 0);

            // update the element's style
            target.style.width  = event.rect.width + 'px';
            target.style.height = event.rect.height + 'px';

            // translate when resizing from top or left edges
            x += event.deltaRect.left;
            y += event.deltaRect.top;

            target.style.webkitTransform = target.style.transform =
                'translate(' + x + 'px,' + y + 'px)';

            target.setAttribute('data-x', x);
            target.setAttribute('data-y', y);
        }
        window.resizeMoveListener = resizeMoveListener;
    // ======== END -- INTERACT.JS GLOBAL FUNCTIONS =================



    function randomInt (min, max){
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function randomString(length){
        const len = length || 12;
        let charSet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
            delivery ='', i;
            for (i=0; i < len; i++){
                delivery += charSet.charAt(randomInt(0,51));
            }
            return delivery;
    }

    function confirmObject(){
        let i, candidate, response = true;

        candidate = arguments[0];
        if (!candidate) { return false; }

        for (i=1; i < arguments.length; i++){
            candidate = candidate[arguments[i]];
            if (!candidate) { response = false; break; }
        }
        return response;
    }


    function renderEl(comp) {
        return React.createElement(
            comp[0],
            comp[1],
            typeof comp[2] === 'string' ? comp[2].replace(/  /g, '\u00a0\u00a0') :
                typeof comp[2] === 'number' ? comp[2] :
                    !comp[2] ? null :
                        comp[2].map((internal)=> {
                            internal[1] = internal[1] || {};
                            internal[1].key = internal[1].key || randomString(8);
                            return renderEl(internal);
                        })
        );
    }

    function deUnderline(data){
        if (Array.isArray(data)){
            return data.map((item)=> {
                return deUnderline(item);
            });
        }

        if (typeof data === 'object') {
            _.forOwn(data, (val, key)=> {
                data[key] = deUnderline(val);

                if (key.slice(0,3) === '__ ') {
                    data[key.slice(3)] = val;
                    delete data[key];
                }
            });
            return data;
        }

        if (typeof data === 'string') {
            if (data.slice(0,3) === '__ ') {
                return data.slice(3);
            }
            return data;
        }
        return data;
    }

    function getMessage(elem, defaultMsg){
        return defaultMsg;

        /*
        let delivery = null;
        let messageBank = !structure ? null :
            _.find(structure, function(entity){
                return entity[0] === 'messageBank';
            });
        messageBank = messageBank ? messageBank[1] : null;

        if (!messageBank) {
            delivery =  !structure ? null :
                _.find(structure, function(entity){
                    return entity[0] === item;
                });
            delivery = delivery ? delivery[1].label : null;
        }
        return !messageBank ? (delivery || defaultMsg || '') : (messageBank[item] || defaultMsg || '');
        */

    }


    var toString = Object.prototype.toString;

    function equals(a, b, enforce_properties_order, cyclic) {
        return a === b &&  // strict equality should be enough unless zero
            a !== 0 || // because 0 === -0, requires test by _equals()
            _equals(a, b) // handles not strictly equal or zero values
        ;

        function _equals(aa, bb) {
            // a and b have already failed test for strict equality or are zero
            var a = aa, b = bb;
            var s, l, p, x, y;

            // They should have the same toString() signature
            if ((s = toString.call(a)) !== toString.call(b)) { return false; }

            switch (s) {
                default: // Boolean, Date, String
                    return a.valueOf() === b.valueOf();

                case '[object Number]':
                    // Converts Number instances into primitive values
                    // This is required also for NaN test bellow
                        a = Number(a);
                    b = Number(b);

                    return a ? // a is Non-zero and Non-NaN
                        a === b : // a is 0, -0 or NaN
                        a === a ? // a is 0 or -O
                        1 / a === 1 / b // 1/0 !== 1/-0 because Infinity !== -Infinity
                        : b !== b // NaN, the only Number not equal to itself!
                    ;
                    // [object Number]

                case '[object RegExp]':
                        return a.source == b.source && a.global == b.global && a.ignoreCase == b.ignoreCase && a.multiline == b.multiline && a.lastIndex == b.lastIndex; // jshint ignore: line
                    // [object RegExp]

                case '[object Function]':
                        return false; // functions should be strictly equal because of closure context
                    // [object Function]

                case '[object Array]':
                        if (cyclic && (x = reference_equals(a, b)) !== null) { return x; }// intentionally duplicated bellow for [object Object]

                    if ((l = a.length) != b.length) return false;  // jshint ignore: line
                    // Both have as many elements

                    while (l--) {
                        if ((x = a[l]) === (y = b[l]) && x !== 0 || _equals(x, y)) { continue; }

                        return false;
                    }

                    return true;
                    // [object Array]

                case '[object Object]':
                        if (cyclic && (x = reference_equals(a, b)) !== null) { return x; }// intentionally duplicated from above for [object Array]

                    l = 0; // counter of own properties

                    if (enforce_properties_order) {
                        var properties = [];

                        for (p in a) {
                            if (a.hasOwnProperty(p)) {
                                properties.push(p);

                                if ((x = a[p]) === (y = b[p]) && x !== 0 || _equals(x, y)) { continue; }

                                return false;
                            }
                        }

                        // Check if 'b' has as the same properties as 'a' in the same order
                        for (p in b)
                            if (b.hasOwnProperty(p) && properties[l++] != p) { // jshint ignore: line
                                return false;
                            }
                    } else {
                        for (p in a) {
                            if (a.hasOwnProperty(p)) {
                                ++l;

                                if ((x = a[p]) === (y = b[p]) && x !== 0 || _equals(x, y)) { continue; }

                                return false;
                            }
                        }

                        // Check if 'b' has as not more own properties than 'a'
                        for (p in b) {
                            if (b.hasOwnProperty(p) && --l < 0) {
                                return false;
                            }
                        }
                    }

                    return true;
                    // [object Object]
            } // switch toString.call( a )
        } // _equals()

        /* -----------------------------------------------------------------------------------------
           reference_equals( a, b )

           Helper function to compare object references on cyclic objects or arrays.

           Returns:
             - null if a or b is not part of a cycle, adding them to object_references array
             - true: same cycle found for a and b
             - false: different cycle found for a and b

           On the first call of a specific invocation of equal(), replaces self with inner function
           holding object_references array object in closure context.

           This allows to create a context only if and when an invocation of equal() compares
           objects or arrays.
        */
        function reference_equals(a, b) {
            var object_references = [];

            /*eslint-disable */
            return (reference_equals = _reference_equals)(a, b);
            /*eslint-enable */

            function _reference_equals(a, b) {
                var l = object_references.length;

                while (l--) {
                    if (object_references[l--] === b) {
                        return object_references[l] === a;
                    }
                }
                object_references.push(a, b);

                return null;
            } // _reference_equals()
        } // reference_equals()
    } // equals()

    function getPosition(ee){
        // Event -- document position of the click, with scroll included
        var e = ee || window.event;
        var posX = 0;
        var posY = 0;
        if (e.pageX || e.pageY) {
            posX = e.pageX;
            posY = e.pageY;
        }
        else if (e.clientX || e.clientY) 	{
            posX = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            posY = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }

        return ({x: posX, y: posY});
    }

    function isSameDay(d1, d2) {
        return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();
    }

    function scrollToTop(scrollDuration) {
        const scrollHeight = window.scrollY,
            scrollStep = Math.PI / ( scrollDuration / 15 ),
            cosParameter = scrollHeight / 2;
        let scrollCount = 0,
            scrollMargin,
            scrollInterval;

        function step () {
            setTimeout(() => {
                if ( window.scrollY !== 0 ) {
                        requestAnimationFrame(step);
                    scrollCount = scrollCount + 1;
                    scrollMargin = cosParameter - cosParameter * Math.cos( scrollCount * scrollStep );
                    window.scrollTo( 0, ( scrollHeight - scrollMargin ) );
                }
            }, 15 );
        }

        if (window.requestAnimationFrame) {
            requestAnimationFrame(step);
        } else {
            scrollInterval = setInterval( () => {
                if ( window.scrollY !== 0 ) {
                    scrollCount = scrollCount + 1;
                    scrollMargin = cosParameter - cosParameter * Math.cos( scrollCount * scrollStep );
                    window.scrollTo( 0, ( scrollHeight - scrollMargin ) );
                }
                else { clearInterval(scrollInterval); }
            }, 15 );
        }
    }

    var requestAnimFrame = (function(){return window.requestAnimationFrame||window.webkitRequestAnimationFrame||window.mozRequestAnimationFrame||function(callback){window.setTimeout(callback,1000/60);};})();

    var easeInOutQuad = function (t, b, c, d) {
        t /= d/2;
        if (t < 1) { return c/2*t*t + b; }
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    };

     function scrollTo(element, to, duration, callback) {
        if(element == document.body) {
            element = document.scrollingElement ? document.scrollingElement : document.body;
        }
        var start = element.scrollTop,
        change = to - start,
        animationStart = Number(new Date());
        var animating = true;
        var lastpos = null;

        var animateScroll = function() {
            if (!animating) {
                return;
            }
            requestAnimFrame(animateScroll);
            var now = Number(new Date());
            var val = Math.floor(easeInOutQuad(now - animationStart, start, change, duration));
            if (lastpos) {
                if (lastpos === element.scrollTop) {
                    lastpos = val;
                    element.scrollTop = val;
                } else {
                    animating = false;
                }
            } else {
                lastpos = val;
                element.scrollTop = val;
            }
            if (now > animationStart + duration) {
                element.scrollTop = to;
                animating = false;
                if (callback) { callback(); }
            }
        };
        requestAnimFrame(animateScroll);
    }

    function eventPreventDefault(event){
        if (event && event.preventDefault) { event.preventDefault(); }
    }

    function buildValidator(config){
        const REQUIRED = 'required',
            CONFIRM = 'confirm',
            NUMBER = 'number',
            MINLENGTH = 'minLength',
            MINNUMBER = 'minNumber',
            EMAIL = 'email',
            DATE = 'date',
            PASSWORD = 'password',
            USPHONE = 'usphone',
            PHONENUMBER = 'phoneNumber',
            MINDATE = 'minDate';

        const CheckError = {};
        CheckError[REQUIRED] = function(val) { 
            if(val === 0) { 
                return false; 
            } else { 
                return !val; 
            }
        };
        CheckError[CONFIRM] = function(val, ruleData, data){ return (data[ruleData.confirm_target] !== val); };
        CheckError[NUMBER] = function(val){ return isNaN(parseFloat(val)); };
        CheckError[MINLENGTH] = function(val, ruleData){ return (!val || val.length < ruleData.minimum); };
        CheckError[MINNUMBER] = function(val, ruleData, data) { 
            if(_.get(ruleData, 'key')) {
                if(_.get(data, ruleData.key)) {
                    return (!val || parseFloat(val) < parseFloat(ruleData[data[ruleData.key]].minimum)); 
                } else {
                    return (!val || parseFloat(val) < parseFloat(ruleData.default.minimum)); 
                }
            }
            return (!val || parseFloat(val) < parseFloat(ruleData.minimum)); 
        };
        CheckError[PHONENUMBER] = function(val) {
            if(val) {
                // set up our regular expression
                var re = /^\+?[1-9]\d{9,14}$/;
                // parse the string to strip out unneeded characters
                val = val.replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/\s/g, '');
                // make sure the regex passes
                return (!re.test(val));
            }
        };
        CheckError[USPHONE] = function(val){ var re = /^$|([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-|\.)?([0-9]{3}( |-|\.)?[0-9]{4}|[0-9]{7})$/; return (!re.test(val)); };
        CheckError[EMAIL] = function(val){
            // source http://stackoverflow.com/questions/46155/validate-email-address-in-javascript#1373724
            var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            return (!val || !re.test(val.toLowerCase()) );
        };
        CheckError[DATE] = function(val){ return isNaN(Date.parse(val)); };
        CheckError[MINDATE] = function(val) {
            const mom = moment(val)
            return !mom.isValid() || mom.isSameOrBefore(moment('1900-01-01'))
        }
        CheckError[PASSWORD] = function(val){ return (!val || val.length < 6); };

        const ruleNames = [REQUIRED, CONFIRM, NUMBER, MINLENGTH, MINNUMBER, EMAIL, DATE, USPHONE, PASSWORD, PHONENUMBER, MINDATE];

        function emitError(data, allData){
            if(_.get(data, 'key')) {
                if(_.get(allData, data.key)) {
                    return data[allData[data.key]].error_message;
                } else {
                    return data.default.error_message;
                }
            }
            if (typeof data === 'string') { return data; }
            if (data.error_message) { return data.error_message; }
            if (Array.isArray(data)) { return data[0]; }
        }

        function validator(data){
            let errors = {}, i, ruleData, ruleName;

            _.forOwn(data, (val, key)=> {
                const rules = config[key];
                if (rules) {
                    for (i = 0; i < ruleNames.length; i++) {

                        ruleName = ruleNames[i];
                        ruleData = rules[ruleName];
                        if (ruleData && CheckError[ruleName](val, ruleData, data)) {
                            errors[key] = emitError(ruleData, data);
                            break;
                        }
                    }
                }
            });

            return errors;
        }

        return validator;
    }

    function c3formatDates(dateArray){
        const delivery = [];

        dateArray.forEach((date) => {
            const d = new Date(date);
            delivery.push(d.getFullYear() + '-' + (Number(d.getMonth() + 1)) + '-' + d.getDate());
        });
        return delivery;
    }

    const space = "\u00a0";


    return {
        c3formatDates: c3formatDates,
        randomString: randomString,
        randomInt: randomInt,
        confirmObject: confirmObject,
        renderEl: renderEl,
        deUnderline: deUnderline,
        equalCheck: equals,
        getPosition: getPosition,
        getMessage: getMessage,
        scrollToTop: scrollToTop,
        scrollTo: scrollTo,
        isSameDay: isSameDay,
        eventPreventDefault: eventPreventDefault,
        validator: buildValidator,
        space: space
    };
}();
