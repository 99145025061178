'use strict';

import { getTrackerEntries as getTrackerEntriesAPI } from 'api/tracker-entries';

import {
    REQUEST_GET_TRACKER_ENTRIES,
    REQUEST_GET_TRACKER_ENTRIES_SUCCESS,
    REQUEST_GET_TRACKER_ENTRIES_FAILURE
} from 'actions/types';

function requestGetTrackerEntries() {
    return {
        type: REQUEST_GET_TRACKER_ENTRIES
    };
}

function requestGetTrackerEntriesSuccess(json) {
    return {
        type: REQUEST_GET_TRACKER_ENTRIES_SUCCESS,
        payload: {
            trackerEntries: json
        }
    };
}

function requestGetTrackerEntriesFailure(error) {
    return {
        type: REQUEST_GET_TRACKER_ENTRIES_FAILURE,
        payload: error,
        error: true
    };
}

export default function getTrackerEntries(token, userId, trackerTypeName) {
    return dispatch => {
        dispatch(requestGetTrackerEntries());
        return getTrackerEntriesAPI(token, userId, trackerTypeName)
            .then((json) => {
                return dispatch(requestGetTrackerEntriesSuccess(json));
            })
            .catch((error) => {
                return dispatch(requestGetTrackerEntriesFailure(error));
            });
    };
}