import React, {useState} from 'react';
import { connect } from "react-redux";
import DrinkTrackerForm from 'components/forms/drink-tracker';
import DrinkCalculator from 'components/forms/drink-calculator'
import momentTZ from 'lib/moment-timezone-with-data';
import _ from 'lodash';
import { Modal, Spinner } from "react-bootstrap";
import Moment from "moment";
import Actions from 'actions';
import { TrackerTypes } from 'api/tracker-entries';
import { withRouter } from 'react-router';
import {BLACK, LIGHTER_FOREST_GREEN} from "../stylesheets/colors";
import { FormattedScoreMessage } from 'components/elements/formatted-score-message';
import BSButton from "../components/blocks/BSButton.component.web";
import { MaterialCommunityIcons } from '@expo/vector-icons';

type Props = {
    auth: any;
    addTrackerEntry: (token: string, userId: number, payload: any, trackerType: string) => Promise<any>;
    updateTrackerEntry: (token: string, userId: number, trackerEntryId: string, payload: any) => Promise<any>;
    params?: { id?: string };
    trackerEntry?: any;
    notify: (alert: any) => void;
}

const DrinkTrackerFormPage = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedbackDate, setFeedbackDate] = useState(undefined);

    const onSubmit = async (data) => {
        const user_timezone = _.get(props.auth, 'user.user_timezone');
        let dtOccurred = Moment(data.dt_occurred).format('YYYY-MM-DD') + ' ' + data.tm_occurred;
        dtOccurred = Moment(dtOccurred, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm:ss');
        dtOccurred = momentTZ.tz(dtOccurred, user_timezone); //currently it is in NY.
        //convert it to UTC.
        const utcDtOccurred = dtOccurred.clone().tz('UTC');
        const payload = {
            dt_occurred: utcDtOccurred.format('YYYY-MM-DDTHH:mm:ss'),
            drink_type: data.drink_type,
            std_drink_count: data.std_drink_count,
            with_whom: data.with_whom,
            where: data.where,
            mood: data.mood
        };
        try {
            if (props.params?.id) await updateDrink(payload);
            else await addDrink(payload);
        }
        catch (e) {
            setLoading(false);
            props.notify({
                title: 'Error',
                message: e.message || 'An error occurred!',
                level: 'error',
                autoDismiss: 4
            });
            throw e;
        }
    }

    const updateDrink = async payload => {
        const token = _.get(props.auth, 'user.token');
        const userId = _.get(props.auth, 'user._id');
        setLoading(true);
        await props.updateTrackerEntry(token, userId, props.params.id, payload);
        setLoading(false);
    }

    const addDrink = async payload => {
        const token = _.get(props.auth, 'user.token');
        const userId = _.get(props.auth, 'user._id');
        setLoading(true);
        getDateFromDTOccured(payload.dt_occurred);
        await props.addTrackerEntry(token, userId, payload, TrackerTypes.DRINK);
        setLoading(false);
    }

    const getDateFromDTOccured = (data) => {
        const user_timezone = _.get(props.auth, 'user.user_timezone');
        const date = momentTZ.tz(data+"+00:00", user_timezone).format('YYYY-MM-DD');
        setFeedbackDate(date);
    }

    const initValues = () => {
        const user_timezone = _.get(props.auth, 'user.user_timezone');
        if (!!props.trackerEntry) {
            let tmOccurred = Moment(props.trackerEntry.data.dt_occurred.split('+')[0]).format('YYYY-MM-DD HH:mm:ss');
            tmOccurred = momentTZ.tz(tmOccurred, 'UTC');
            const userTzTmOccurred = user_timezone ? tmOccurred.clone().tz(user_timezone) : tmOccurred.clone();
            return {
                drink_type: props.trackerEntry.data.drink_type,
                dt_occurred: userTzTmOccurred.format('YYYY-MM-DD'),//YYYY-MM-DD
                tm_occurred: userTzTmOccurred.format('hh:mm A'),
                mood: props.trackerEntry.data.mood,
                std_drink_count: props.trackerEntry.data.std_drink_count,
                where: props.trackerEntry.data.where,
                with_whom: props.trackerEntry.data.with_whom
            };
        }
        const initialDateTime = momentTZ.tz(new Date(), user_timezone);
        return {
            dt_occurred: initialDateTime.format('YYYY-MM-DD'),
            tm_occurred: initialDateTime.format('hh:mm A'),
            std_drink_count: 1
        };
    }

    const feedbackMsg = () => {
        const { trackerEntries, trackerEntryStats } = props;
        const isFetching = _.get(trackerEntries, 'isFetching');
        if (isFetching) return <Spinner animation="border" variant="info" />
        let peakBAC = "";
        if(_.get(trackerEntryStats, 'trackerEntryStats.peak_bac_day')) {
            trackerEntryStats.trackerEntryStats?.peak_bac_day?.forEach(data => {
                if (_.get(data, '_id')) {
                    if (data._id.split('T')[0] == feedbackDate) {
                        peakBAC = data.peak_bac;
                    }
                }
            });
        }
        const date = Moment(feedbackDate, 'YYYY-MM-DD').format("MMMM Do");
        return (
            <FormattedScoreMessage
                id="peak_bac_day_text"
                values={{ BAC: peakBAC, date: date }}
                defaultMessage="{date} estimated peak BAC: {BAC}."
            />
        )
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2">
                        <div className="card mt-4" style={{ border: 'none' }}>
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <BSButton variant="flat-blue" linkTo="/drinks">
                                        <MaterialCommunityIcons name="arrow-left" size={24} color={BLACK} />
                                    </BSButton>
                                    <h3 className="ms-2 card-title mb-0">
                                        New Drink Tracker
                                    </h3>
                                </div>
                                <DrinkTrackerForm
                                    onSubmit={onSubmit}
                                    isFetching={loading}
                                    initialValues={initValues()}
                                    onDrinkCalculatorClick={() => setShowModal(true)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showFeedback} onHide={() => setShowFeedback(false)}>
                <Modal.Header closeButton style={{ backgroundColor: LIGHTER_FOREST_GREEN }}>
                    <Modal.Title>Drink Feedback</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        { feedbackMsg() }
                    </p>
                </Modal.Body>
            </Modal>
            <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton style={{ backgroundColor: LIGHTER_FOREST_GREEN }}>
                    <Modal.Title>Drink Calculator</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DrinkCalculator
                        closeModal={() => setShowModal(false)}
                        buttonText={{
                            id: "back_modal_drink_calculator",
                            description: "Back Drink Calculator Modal",
                            defaultMessage: "Back"
                        }}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default connect((state, props) => {
    const { id } = props.params,
        trackerEntries = state.trackerEntries,
        trackerEntry = id ? trackerEntries[id] : null;
    return {
        auth: state.auth,
        trackerEntry,
        trackerEntryStats: state.trackerEntryStats,
    }
}, {
    addTrackerEntry: Actions.addTrackerEntry,
    updateTrackerEntry: Actions.updateTrackerEntry,
    notify: Actions.notify,
})(withRouter(DrinkTrackerFormPage));
