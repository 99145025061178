/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require */
"use strict";

// ========= LIBRARY COMPONENTS =========
import _ from 'lodash';
import React from 'react';
import Actions from 'actions';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ButtonSpinner from 'components/ux/button-spinner';

const WrongSubdomain = React.createClass({

    getInitialState() {
        return {
            code: null
        };
    },

    closeModal: function() {
        this.props.dispatch(Actions.closeModal('appModal', {
            modalNotifications: '',
            modalContent: false
        }));
    },

    addGroupToUser() {
        const that = this;
        this.props.dispatch(Actions.addGroupToUser(this.props.auth.user.token, this.props.auth.user._id))
            .then(() => {
                that.props.dispatch(Actions.getTools(that.props.auth.user.token))
                    .then(() => {
                        that.closeModal();
                    });
            });
    },

    componentDidMount() {
        const token = _.get(this.props.auth, 'user.token');
        const group_id = _.get(this.props.auth, 'user.roles.subscriber[0]');
        this.props.dispatch(Actions.getGroup(token, group_id)).then((result) => {
            this.setState({code: result});
        })
    },

    render: function() {
        return (
            <div className="container">
                {
                    CUAC_SETTINGS.GROUP.default_program_id
                        ?
                            _.get(this.props.auth, 'user.stripe_customer.subscriptions.data[0].plan.id')
                            ?
                                <div>
                                    <p>
                                        <FormattedMessage 
                                                id='subdomain-change-paid' 
                                                defaultMessage="Sorry, you are not permitted to use this implementation."
                                            />
                                    </p>
                                    {
                                        this.state.code 
                                            ?
                                                <p>
                                                    <FormattedMessage 
                                                        id='subdomain-login-link-paid' 
                                                        defaultMessage="Please login here: "
                                                    />
                                                    <a href={"https://" + this.state.code + ".checkupandchoices.com/login"}>{"https://" + this.state.code + ".checkupandchoices.com/login"}</a>
                                                </p>
                                            :
                                                null
                                    }
                                </div>
                            :
                                <div>
                                    <p>
                                        <FormattedMessage 
                                            id='subdomain-change' 
                                            defaultMessage="If you'd like to start using this site, click on the button below. Your data will be saved. You can still use the previous site and your data will remain preserved there."
                                        />
                                    </p>
                                    {
                                        this.state.code 
                                            ?
                                                <p>
                                                    <FormattedMessage 
                                                        id='subdomain-login-link-non-paid' 
                                                        defaultMessage="Or you can login into your original implementation here: "
                                                    />
                                                    <a href={"https://" + this.state.code + ".checkupandchoices.com/login"}>{"https://" + this.state.code + ".checkupandchoices.com/login"}</a>
                                                </p>
                                            :
                                                null
                                    }
                                    <p>
                                        <ButtonSpinner spinner={this.props.isFetching} onClick={this.addGroupToUser}>
                                            <FormattedMessage 
                                                id="switch-subdomain-button"
                                                defaultMessage="Start Using This Subdomain"
                                            />
                                        </ButtonSpinner>
                                    </p>
                                </div>
                        :
                            <div>
                                {
                                    this.state.code 
                                        ?
                                            <p>
                                                <FormattedMessage 
                                                    id='subdomain-login-link-paid-redirect' 
                                                    defaultMessage="Please click the following link to access your original account: "
                                                />
                                                <a href={"https://" + this.state.code + ".checkupandchoices.com/login"}>{"https://" + this.state.code + ".checkupandchoices.com/login"}</a>
                                            </p>
                                        :
                                            null
                                }
                                <p>
                                    <FormattedMessage 
                                        id='subdomain-no-change' 
                                        defaultMessage="If you no longer have access to an unpaid account and would like to continue using CheckUp & Choices, you'll need to register for a paid account using a different email address. Your data will not be migrated from your original account."
                                    />
                                </p>
                                <p>
                                    <FormattedMessage 
                                        id='subdomain-help-contact' 
                                        defaultMessage="Please direct any questions to "
                                    />
                                    <a href="mailto:help@checkupandchoices.com">help@checkupandchoices.com</a>
                                </p>
                            </div>
                }
            </div>
        );
    }
});

export default connect(
    state => ({
        auth: state.auth,
        isFetching: state.auth.isFetching
        })
)(WrongSubdomain);
