'use strict';

import _ from 'lodash';

import {
    REQUEST_ADD_TRACKER_ENTRY,
    REQUEST_ADD_TRACKER_ENTRY_SUCCESS,
    REQUEST_ADD_TRACKER_ENTRY_FAILURE,
    REQUEST_GET_TRACKER_ENTRIES,
    REQUEST_GET_TRACKER_ENTRIES_SUCCESS,
    REQUEST_GET_TRACKER_ENTRIES_FAILURE,
    REQUEST_LOG_OUT_USER_SUCCESS,
    REQUEST_UPDATE_TRACKER_ENTRY,
    REQUEST_UPDATE_TRACKER_ENTRY_SUCCESS,
    REQUEST_UPDATE_TRACKER_ENTRY_FAILURE,
    REQUEST_DELETE_TRACKER_ENTRY,
    REQUEST_DELETE_TRACKER_ENTRY_SUCCESS,
    REQUEST_DELETE_TRACKER_ENTRY_FAILURE
} from 'actions/types';

export function reducer(state = {
    isFetching: false,
    abcOrder: [],
    drinkOrder: [],
    goalOrder: [],
    happinessOrder: [],
    lapseOrder: [],
    moodOrder: [],
    planOrder: [],
    solutionOrder: [],
    urgeOrder: [],
    valueOrder: [],
    agreementOrder: []
}, action = { type: '' }) {
    switch (action.type) {
        case REQUEST_ADD_TRACKER_ENTRY:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_ADD_TRACKER_ENTRY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                [action.payload.trackerEntry._id]: {
                    ...action.payload.trackerEntry
                },
                [action.payload.trackerEntryName + 'Order']: [
                    action.payload.trackerEntry._id,
                    ...state[action.payload.trackerEntryName + 'Order']
                ]
            };
        case REQUEST_ADD_TRACKER_ENTRY_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_DELETE_TRACKER_ENTRY:
            return {
                ...state
            };
        case REQUEST_DELETE_TRACKER_ENTRY_SUCCESS:
            const trackerIndex = state[action.payload.trackerEntryName + 'Order'].indexOf(action.payload.trackerEntryId)
            return {
                ...state,
                [action.payload.trackerEntryName + 'Order']: [
                    ...state[action.payload.trackerEntryName + 'Order'].slice(0, trackerIndex),
                    ...state[action.payload.trackerEntryName + 'Order'].slice(trackerIndex + 1)
                ]
            };
        case REQUEST_DELETE_TRACKER_ENTRY_FAILURE:
            return {
                ...state
            };
        case REQUEST_GET_TRACKER_ENTRIES:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_GET_TRACKER_ENTRIES_SUCCESS:
            const trackerEntries = {
                abcOrder: [],
                drinkOrder: [],
                goalOrder: [],
                happinessOrder: [],
                lapseOrder: [],
                moodOrder: [],
                planOrder: [],
                solutionOrder: [],
                urgeOrder: [],
                valueOrder: [],
                agreementOrder: []
            };
            action.payload.trackerEntries.forEach((trackerEntry) => {
                trackerEntries[trackerEntry._id] = {
                    ...trackerEntry
                };
                trackerEntries[trackerEntry.tracker_type_name + 'Order'].push(trackerEntry._id);
            });
            return {
                ...state,
                isFetching: false,
                ...trackerEntries
            };
        case REQUEST_GET_TRACKER_ENTRIES_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_LOG_OUT_USER_SUCCESS:
            return {
                isFetching: false
            };
        case REQUEST_UPDATE_TRACKER_ENTRY:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_UPDATE_TRACKER_ENTRY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                [action.payload.trackerEntryId]: {
                    ...action.payload.trackerEntry
                }
            };
        case REQUEST_UPDATE_TRACKER_ENTRY_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        default:
            return state;
    }
}
