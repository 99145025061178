/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require, componentHandler, PubSub, Topics */
'use strict';

const React = require('react'),
    classnames = require('classnames');


const baseClasses = {
    'switch-wrapper': true
};

var ToggleSwitch = React.createClass({
    displayName: 'ToggleSwitch',

    propTypes: {
        className: React.PropTypes.string,
        switchId: React.PropTypes.string,
        switchChecked: React.PropTypes.bool,
        ripple: React.PropTypes.bool,
        onSwitch: React.PropTypes.func
    },

    getInitialState: function(){
        return { isChecked: !!this.props.checked };
    },

    componentDidMount: function() { },

    componentWillUnmount: function() { },

    /* ========= LOCAL METHODS ============ */

    onSwitch: function(event){
        let checked = event.target.checked || event.target.getAttribute('data-checked');
        if (this.props.onSwitch) {
            this.setState({isChecked: checked});
            this.props.onSwitch(event.target.id, checked);
        }
    },

    /* ========= RENDERING ================ */

    render: function(){
        let { className, labelFalse, labelTrue, style, switchId } = this.props;

        let classes = classnames(baseClasses, {'isChecked': this.props.checked}, className);

        return (
            <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={this.props.checked}
                  role="switch"
                  id={this.props.switchId}
                  onChange={this.onSwitch}
                />
                <label className="form-check-label" htmlFor={this.props.switchId}>
                    { labelTrue }
                </label>
            </div>
        )
    }

});

export default ToggleSwitch;
