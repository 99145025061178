/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';
import React from 'react';
import Button from 'components/ux/mdl/button';

const AppFreeTrial = React.createClass({
    propTypes:{
        params: React.PropTypes.object.isRequired,
    },
    render() {
        const freeTrialOffer = CUAC_SETTINGS.GROUP.free_trial_offer;
        if(!freeTrialOffer || this.props.params.email)
            return(<div style={{display:"none"}}></div>);

        const styles = {backgroundColor:'#F6891F',height:"60px", fontSize:"24px",color:"white",textAlign:"center"};

        return (
            <div className="free-trial-header" style={styles} >
                <Button linkTo="/screener/plan"  className="free-trial-button" flat={true} >
                    CLICK HERE FOR A SPECIAL FREE TRIAL OFFER!
                </Button>
            </div>
        );
    }
});

export default AppFreeTrial;
