import React from 'react';
import { Field } from 'redux-form';
import { Form } from "react-bootstrap";

type InputProps = {
    id: string;
    label?: string|React.ReactNode;
    name: string;
    input?: {
        value?: string;
        onChange: (val: string) => void;
        name?: string;
    };
    meta?: {
        error?: string;
    }
}

const Input = (props: InputProps) => {
    return (
        <Form.Check
            inline
            type="checkbox"
            id={props.input?.name}
            label={props.label}
            name={props.input?.name}
            checked={!!props.input?.value}
            // onChange={this.onChange}
            { ...props.input }
        />
   )
}

type Props = {
    name: string;
    label?: string|React.ReactNode;
    guide?: boolean;
}

const CheckboxReduxForm = (props: Props) => {
    return (
        <Field
            component={Input}
            { ...props }
        />
    )
}

export default CheckboxReduxForm;
