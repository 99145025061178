/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import _ from 'lodash';
import React from 'react';
import Link from 'components/ux/linker';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Actions from 'actions';
import Selectors from 'selectors';
const { responsesByToolSelector, userNormsSelector, userProfileSelector } = Selectors;
import InfoBug from 'components/popover/infobug';
import { Block } from 'components/blocks/block';
import ToolTOC from '../components/ToolTOC/ToolTOC.container';
import TrackerNav from '../components/blocks/TrackerNav.component.web';
import { Accordion } from 'react-bootstrap';
import Moment from 'moment';
import BSButton from '../components/blocks/BSButton.component.web';
import ToggleSwitch from '../components/ux/switch';
import ToolLayout from '../components/blocks/ToolLayout.component.web';
import {Entypo, Ionicons} from '@expo/vector-icons';
import { BLUE } from '../stylesheets/colors';

export const ToolOverviewPage = React.createClass({
    getInitialState() {
        return {
            allow_email: false
        };
    },

    UNSAFE_componentWillMount() {
        const { activeResponse: { progress } } = this.props;
        const navOpen = {};
        _.forOwn(progress.modules, (data, name) => { navOpen[name] = false; });
        const token = _.get(this.props, 'auth.user.token');
        const userId = _.get(this.props, 'auth.user._id');
        const toolId = this.props.tool._id;
        this.props.dispatch(Actions.getUserEmailEntry(token, userId, toolId))
        .then((json) => {
            var checked = json.payload.response.allow_email ? 'allow_email' : '';
            this.setState({
                allow_email: !!checked
            });
        });
        this.setState({
            navOpen: navOpen
        });
    },

    onToggleNavBlock(navId) {
        this.state.navOpen[navId] = !this.state.navOpen[navId];
        this.setState({ navOpen: this.state.navOpen });
    },

    onKeyDownToggleNavBlock(event, moduleId) {
      if(event.which == 32 || event.which == 13) {
        this.onToggleNavBlock(moduleId);
      }
    },

    updateSubscription(toolId, checked) {
        const token = _.get(this.props, 'auth.user.token');
        const userId = _.get(this.props, 'auth.user._id');
        this.props.dispatch(Actions.updateUserEmailEntry(token, userId, toolId, !!checked))
        .then((json) => {
            var checked = json.payload.response.allow_email ? 'allow_email' : '';
            this.setState({
                allow_email: !!checked
            });
        });
      },
    showRemindersModal() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'newReminder',
            edit_reminder: {},
            modalContent: true
        }));
    },

    showHelpFeedbackModal() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'helpFeedback',
            modalContent: true
        }));
    },

    renderTrackerNavButtons() {
        const { tool } = this.props;
        
        if(tool.slug === "checkup" || tool.slug === "precheck") {
            return null;
        }
        return (
          <div className="mb-3 card" style={{ border: 'none' }}>
              <div className="card-body">
                  <TrackerNav />
              </div>
          </div>
        )
    },

    progressBar(progress) {
        const { question_count, response_count, dt_updated } = progress || {};
        if (typeof question_count !== 'number') return null;
        const dateString = dt_updated ? Moment(dt_updated).format('D MMM YYYY') : '';
        const width = typeof question_count === 'number' && !!question_count
          ? Math.round((((response_count || 0) / question_count) * 100))
          : null;
        if (!width) return null;
        return (
          <div className="mx-4 text-x-small text-uppercase progress-bar-single">
              <span>{width}% Completed</span>
              <div className="progress">
                  <div className="progress-bar"
                       role="progressbar"
                       style={{ width: `${width}%`}}
                       aria-valuenow={width}
                       aria-valuemin="0"
                       aria-valuemax="100"
                  />
              </div>
              <span>{ dateString }</span>
          </div>
        )
    },

    render() {
        const { activeResponse, norms, tool, trackerEntryStats, userProfile, tools, params } = this.props;
        const trackerNav = this.renderTrackerNavButtons();
        const components = _.get(tool, 'metadata.components', []);
        const postComponents = _.get(tool, 'metadata.post_components', []);
        const progress = _.get(activeResponse, 'progress', {});
        let dashboardButtonCorrected = true;

        if (trackerNav == null) {
            dashboardButtonCorrected = false;
        }

        const slug = _.get(tool, 'slug');
        let feedbackLink;
        if (slug == 'checkup') {
            feedbackLink = "/checkup/consider/game-plan/feedback?override=true";
        } else if (slug == 'checkup25') {
            feedbackLink = "/checkup25/consider/game-plan/feedback?override=true";
        }

        return (
          <ToolLayout tool={this.props.tool} activeResponse={this.props.activeResponse}>
              { this.renderTrackerNavButtons() }
              <div className="d-flex align-items-center justify-content-between mb-3">
                  <h3 className="card-title fw-bold">
                      <FormattedMessage {...tool.long_title} />
                  </h3>
                  <div className="d-flex">
                      {
                          _.get(tool, 'guided_email_id') && (
                            <div className="ms-4 d-flex align-items-center">
                                <div className="d-flex align-items-center me-2">
                                    <p className="mb-0">Receiving Guided Program Emails</p>
                                    <InfoBug>
                                        <FormattedMessage
                                          id="guided_email_infobug"
                                          defaultMessage="We recommend using the guided email feature to move through the program. If you check the box, we'll send you an email weekly for 12 weeks. The email will guide you through the program step by step which improves your chances of learning the tools."
                                        />
                                    </InfoBug>
                                </div>
                                <ToggleSwitch
                                  switchId={tool._id}
                                  onSwitch={this.updateSubscription}
                                  checked={this.state.allow_email}
                                  labelTrue="On"
                                  labelFalse="Off"
                                />
                            </div>
                          )
                      }
                      {
                          (_.get(tool, 'slug').includes('checkup')) && (
                            <BSButton variant="flat-blue" className="ms-3 print-feedback" linkTo={feedbackLink}>
                                <Entypo name="bar-graph" size={24} color={BLUE} />
                                <div className="ms-2">
                                    <FormattedMessage
                                      id="tool_overview_checkup_feedback"
                                      description="tool overview -- link to feedback summary"
                                      defaultMessage="Feedback Summary"
                                    />
                                </div>
                            </BSButton>
                          )
                      }
                  </div>
              </div>
              {
                  components.map((component, index) => {
                      return (
                        <Block
                          key={'block_' + index}
                          component={component}
                          index={index}
                          norms={norms}
                          responses={_.get(activeResponse, 'responses', {})}
                          userProfile={userProfile}
                          trackerEntryStats={trackerEntryStats}/>
                      );
                  })
              }
              <div className="w-100 card" style={{ border: 'none' }}>
                  <div className="card-body">
                      <Accordion flush style={{ width: '100%' }}>
                          {
                              tool.metadata.order.map((moduleId) => {
                                  const module = tool.metadata.modules[moduleId];
                                  const moduleProgress = _.get(progress, 'modules.' + moduleId);
                                  return (
                                    <Accordion.Item key={moduleId} eventKey={moduleId}>
                                        <Accordion.Header>
                                            <div className="flex-grow-1 d-flex align-items-center">
                                                <div className="flex-grow-1">
                                                    <FormattedMessage {...module.long_title} />
                                                </div>
                                                <div className="me-2">
                                                    { this.progressBar(moduleProgress) }
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Accordion flush>
                                                {
                                                    module.order.map(sectionId => {
                                                        const { [sectionId]: section } = module.sections || {},
                                                          sectionProgress = _.get(moduleProgress, `sections.${sectionId}`);
                                                        return (
                                                          <Accordion.Item key={sectionId} eventKey={sectionId}>
                                                              <Accordion.Header>
                                                                  <div className="flex-grow-1">
                                                                      <FormattedMessage {...section.long_title} />
                                                                  </div>
                                                                  <div className="me-2">
                                                                      { this.progressBar(sectionProgress) }
                                                                  </div>
                                                              </Accordion.Header>
                                                              <Accordion.Body>
                                                                  <ul className="list-group list-group-flush">
                                                                      {
                                                                          section.order.map(pageId => {
                                                                              const { [pageId]: page } = section.pages || {},
                                                                                pageProgress = _.get(sectionProgress, `pages.${pageId}`);
                                                                              return (
                                                                                <li key={pageId} className="m-0 p-0 list-group-item d-flex align-items-center">
                                                                                    <Link className={`text-dark flex-grow-1 d-flex align-items-center nav-link section-to-page`} to={'/' + tool.slug + '/' + module.slug + '/' + section.slug  + '/' + page.slug} href="#">
                                                                                        <div className="flex-grow-1">
                                                                                            <FormattedMessage {...page.long_title} />
                                                                                        </div>
                                                                                        { this.progressBar(pageProgress) }
                                                                                    </Link>
                                                                                </li>
                                                                              )
                                                                          })
                                                                      }
                                                                  </ul>
                                                              </Accordion.Body>
                                                          </Accordion.Item>
                                                        )
                                                    })
                                                }
                                            </Accordion>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                  )
                              })
                          }
                      </Accordion>
                  </div>
              </div>
              {
                  postComponents.map((component, index) => {
                      return (
                        <div className="my-4" key={'block_' + index}>
                            <Block
                              component={component}
                              index={index}
                              norms={norms}
                              params={params}
                              responses={_.get(activeResponse, 'responses', {})}
                              userProfile={userProfile}
                              trackerEntryStats={trackerEntryStats}
                            />
                        </div>
                      );
                  })
              }
          </ToolLayout>
        )
    }
});

export default connect(
    (state, props) => ({
        auth: state.auth,
        activeResponseId: state.responses.activeResponseId,
        activeResponse: _.get(state.responses[state.responses.activeResponseId], 'response'),
        trackerEntryStats: state.trackerEntryStats,
        ...responsesByToolSelector(state, props),
        ...userNormsSelector(state),
        ...userProfileSelector(state)
    })
)(ToolOverviewPage);
