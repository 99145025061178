/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require */
"use strict";

// ========= LIBRARY COMPONENTS =========
import React  from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import TextFieldReduxForm from 'components/ux/text-field-redux-form';
import BSButton from '../blocks/BSButton.component.web';
import { FontAwesome } from '@expo/vector-icons';

const validate = values => {
    const errors = {};
    const { message, email, title } = values;
    if (!message || !message.length) errors.message = 'Required';
    if (!title || !title.length) errors.title = 'Required';
    if (!email || !email.length) errors.email = 'Required';
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i.test(email)) errors.email = 'Invalid email address';
    return errors;
}

const messages = defineMessages({
    "feedback_title": {
        id: "feedback_title",
        description: "choose label -- Title",
        defaultMessage: "Title"
    },
    "feedback_email": {
        id: "feedback_email",
        description: "choose label -- Email",
        defaultMessage: "Email"
    },
    "feedback_message": {
        id: "feedback_message",
        description: "choose label -- Message",
        defaultMessage: "Message"
    }
});

const HelpFeedbackForm = React.createClass({
    displayName: 'HelpFeedback',
    contextTypes: { history: React.PropTypes.object },
    propTypes: {
        fields: React.PropTypes.object.isRequired,
        handleSubmit: React.PropTypes.func.isRequired,
        isFetching: React.PropTypes.bool,
    },

    isValid() {
        const errors = { ...(this.props.errors || {}) };
        if (this.props.loggedIn) {
            try {
                delete errors.email;
            }
            catch (e) {}
        }
        return !Object.keys(errors).length;
    },

    render: function() {
        const { handleSubmit, intl: { formatMessage }} = this.props;
        return (
            <div>
                <p className="text-muted">
                    <FormattedMessage 
                        id='feedback_form_text' 
                        defaultMessage='Use this form to send us a direct message if you are having technical difficulties or want to provide program feedback to CheckUp & Choices.'
                    />
                </p>
                <form id="add_help_feedback" name="add_help_feedback">
                    {
                        !this.props.loggedIn && (
                          <TextFieldReduxForm
                            name="email"
                            type="text"
                            floatingLabel={true}
                            labelText={formatMessage(messages.feedback_email)}
                          />
                        )
                    }
                    <TextFieldReduxForm
                      name="title"
                      type="text"
                      floatingLabel={true}
                      labelText={formatMessage(messages.feedback_title)}
                    />
                    <TextFieldReduxForm
                      name="message"
                      type="text"
                      textArea={true}
                      floatingLabel={true}
                      labelText={formatMessage(messages.feedback_message)}
                    />
                    <div className="mt-3">
                        <BSButton
                          loading={this.props.isFetching}
                          disabled={this.props.isFetching || !this.isValid()}
                          variant="green"
                          onClick={handleSubmit}
                        >
                            <FontAwesome name="star-o" color="white" size={20} />
                            <div className="ms-2">
                                <FormattedMessage id="add_help_feedback_button"
                                                  description="Send"
                                                  defaultMessage="Send" />
                            </div>
                        </BSButton>
                    </div>
                </form>
            </div>
        );
    }
});

const formFields = ['title', 'message', 'email'];
let form = reduxForm({
    form: 'addHelpFeedback',
    fields: formFields,
    validate
})(HelpFeedbackForm);

const selector = formValueSelector('addHelpFeedback') // <-- same as form name
form = connect(state => {
    return formFields.reduce((dict, fieldName) => Object.assign({ [fieldName]: selector(state, fieldName) }, dict), {
        errors: state.form.addHelpFeedback?.syncErrors,
    });
})(form);

export default injectIntl(form);
