'use strict';

import {
    CLEAR_SELECTED_PLAN
} from 'actions/types';

export default function clearSelectedPlan() {
    return {
        type: CLEAR_SELECTED_PLAN
    };
}