/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import _ from 'lodash';
import React from 'react';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Moment from 'moment'; Moment.locale('en');

import Button from 'components/ux/mdl/button';
import { faThList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
export const TermsPage = React.createClass({
    
    render() {
        const { terms } = this.props;
        return (
            <div className="body-column privacy">
                <div className="grid-layout grid-pad">
                    {
                        this.props.hideHeader
                            ?
                                null
                            :
                                <div className="section" style={{marginBottom: '-40px'}}>
                                    <div className="sub-header">
                                        <Button flat={true} noColor={true} linkTo="/privacy"
                                            style={{float: 'right', fontSize: '12px'}} >
                                            <FontAwesomeIcon icon={faThList} />
                                            <FormattedMessage id="terms_link_to_privacy"
                                                description=""
                                                defaultMessage="Privacy" />
                                        </Button>
                                    </div>
                                </div>
                    }
                    <div className="section baseline-text">
                        {
                            this.props.hideHeader
                                ?
                                    null
                                :
                                    <h1 className="section-title">
                                        <FormattedMessage id="terms_section_title" defaultMessage="Checkup and Choices LLC Terms of Service" />
                                    </h1>
                        }
                        <div dangerouslySetInnerHTML={{__html: terms.terms_text}}></div>
                    </div>
                </div>
            </div>
        );
    }
});

export default connect(
    state => ({
        auth: state.auth,
        terms: {
            terms_text: _.get(state, 'terms.terms_text'),
            dt_u: _.get(state, 'terms.dt_u')
        }
    })
)(TermsPage);
