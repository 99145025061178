import Constants from 'expo-constants';
const {
    API_URL,
    SENTRY,
    GOOGLE_ANALYTICS,
    GOOGLE_TAG,
    STRIPE_KEY,
    PURGE_STATE_ON_LOAD,
    DEFAULT_GROUP_ID,
    DEFAULT_GROUP_CODE,
    DEFAULT_GROUP_TERMS_ID,
} = Constants.manifest?.extra ?? {};

export interface AppConfig {
    API_URL: string;
    SENTRY: string;
    GOOGLE_ANALYTICS: string;
    GOOGLE_TAG: string;
    STRIPE_KEY: string;
    PURGE_STATE_ON_LOAD: boolean;
    DEFAULT_GROUP_ID: string;
    DEFAULT_GROUP_CODE: string;
    DEFAULT_GROUP_TERMS_ID?: string;
}
// Compose new object:
const config: AppConfig = {
    API_URL,
    SENTRY,
    GOOGLE_ANALYTICS,
    GOOGLE_TAG,
    STRIPE_KEY,
    PURGE_STATE_ON_LOAD,
    DEFAULT_GROUP_ID,
    DEFAULT_GROUP_CODE,
    DEFAULT_GROUP_TERMS_ID,
};

console.log('config.js loaded:', config);

export default config





