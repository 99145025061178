'use strict';

import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import React from 'react';

export const FormattedBACMessage = React.createClass({
    propTypes: {
        defaultMessage: React.PropTypes.string
    },

    render() {
        let newValues = null;

        if (this.props.values) {
            _.forOwn(this.props.values, (val, key) => {
              if (key.startsWith('bac_') || key.startsWith('peak_bac_')) {
                  newValues = {};
              }
            });

            // get the value
            let messageKey = false;
            if(this.props.defaultMessage.indexOf('{') > -1 && this.props.defaultMessage.indexOf('}') > -1) {
                messageKey = this.props.defaultMessage.split("{")[1].split('}')[0];
                if(messageKey.indexOf(', number') > -1) {
                    messageKey = messageKey.split(", number")[0];
                }    
            }
            // check if it is in the values and if not, set it
            if(messageKey) {
                if(!this.props.values.hasOwnProperty(messageKey)) {
                    newValues[messageKey] = <span className='score'>incomplete</span>;
                }
            }

            if (newValues){
                _.forOwn(this.props.values, (val, key) => {
                    if (key.startsWith('bac_') || key.startsWith('peak_bac_')) {
                      val = parseFloat(val).toFixed(2).toString(10).substring(1);
                      newValues[key] = <span className='score'>{val}</span>;
                    } else {
                        newValues[key] = val;
                    }
                });
            }
        }

        return newValues ? (
            <FormattedMessage {...this.props} values={newValues}>{this.props.children}</FormattedMessage>
        ) : (
            <FormattedMessage {...this.props}>{this.props.children}</FormattedMessage>
        );
    }
});
