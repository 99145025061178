'use strict';

import React, { findDOMNode } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import Moment from 'moment'; Moment.locale('en');

import { eventPreventDefault as prevent } from 'utils/tools';
import ButtonSpinner from 'components/ux/button-spinner';
import renderJSON from '../../render-json';
import { connect } from 'react-redux';
import Actions from 'actions';

import { validator } from 'utils/tools';

import {
    REQUEST_GET_WEARABLE_LOGIN_CODE_SUCCESS
} from 'actions/types';

const GetWearableLoginCode = React.createClass({

    getInitialState() {
        return { 
            wearableCode: null,
            isWorking : false 
        };
    },

    getWearableCode() {
        this.setState({isWorking : true});
        const token = _.get(this.props, 'auth.user.token');
        const userId = _.get(this.props, 'auth.user._id');
        this.props.dispatch(Actions.getWearableLoginCode(token, userId))
            .then((result) => {
                if (result.type === REQUEST_GET_WEARABLE_LOGIN_CODE_SUCCESS ){
                    const loginCode = result.payload.user.one_time_login_token;
                    this.setState({
                        wearableCode : 'Your code is: '+loginCode,
                        isWorking: false
                    });
                }
            });
    },


    render() {

        const wearableCode = this.state.wearableCode;
        return (
            <div className="container" >
                <h2 style={{letterSpacing: '1px'}}>
                    {wearableCode}
                </h2>
                <p>This code is used to login to the wearable app, and is a one time use code that expires in 5 minutes.</p>
                <ButtonSpinner onClick={this.getWearableCode} ripple={true} raised={true} colored={true} spinner={this.state.isWorking}>
                        <FormattedMessage id="wearable_login_button"
                            description="Get wearable app login code"
                            defaultMessage="Get Login Code" />
                </ButtonSpinner>
                <div className="clearfix" />
            </div>
        );
    }
});

export default connect(
    state => ({
        auth: state.auth
    })
)(GetWearableLoginCode);