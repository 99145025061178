'use strict';

import _ from 'lodash';
import React from 'react';
import { findDOMNode } from 'react-dom';
import Link from 'components/ux/linker';
import { FormattedMessage } from 'react-intl';

import Selectors from 'selectors';
const { sectionSelector } = Selectors;

import Accordion from 'components/ux/accordion';
import ProgressBar from 'components/elements/progress-bar';

const LinkPages = React.createClass({

    propTypes: {
        collapsed: React.PropTypes.bool.isRequired,
        tool: React.PropTypes.object.isRequired,
        progress: React.PropTypes.object.isRequired,
        sectionId: React.PropTypes.string.isRequired,
        module: React.PropTypes.object.isRequired
    },

    render(){
        const { tool, progress, collapsed, sectionId, module } = this.props;
        const section = _.find(module.sections, (section) => section.id === sectionId );

        return !module.sections ? null : (
            <Accordion collapsed={collapsed} className="sub-links">
                {   section.order.map((pageId) => {
                        const page = section.pages[pageId];
                        const pageProgress = _.get(progress, 'modules.' + module.id + '.sections.' + section.id + '.pages.' + pageId),
                            responses = _.get(pageProgress, 'response_count', 0),
                            pageLink = "nav-link section-to-page" + (responses ? " in-progress": "");

                        return (
                            <div className="sub-link-wrap" key={pageId}>
                                <Link className={pageLink} to={'/' + tool.slug + '/' + module.slug + '/' + section.slug  + '/' + page.slug} href="#">
                                    <FormattedMessage {...page.long_title} />
                                </Link>
                            </div>
                        );

                    })
                }
            </Accordion>
        );
    }
});

export default LinkPages;