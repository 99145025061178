'use strict';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MaterialIcons } from '@expo/vector-icons';
import { BLUE } from '../../stylesheets/colors';
import BSButton from '../blocks/BSButton.component.web';

export const WearableLoginDisplay = React.createClass({

    propTypes: {
        auth: React.PropTypes.object.isRequired,
        style: React.PropTypes.object
    },

    render() {
        const locus = this, state = locus.state, props = locus.props,
            { auth, onClickNav, selfContained, style , onOpenWearableLoginModal} = props;

        const wearableLoginDisplay = (
                <div className="flex-grow-1 d-flex align-items-center justify-content-between">
                    <strong>
                        <FormattedMessage
                          id="account_overview_wearable_login_code_label"
                          description="Account Overview -- wearable login code"
                          defaultMessage="Wearable Login"
                        />
                    </strong>
                    <div>
                        <BSButton variant="flat-blue" size="sm" tooltip="Get code" onClick={onOpenWearableLoginModal}>
                            <MaterialIcons name="cast-connected" size={24} color={BLUE} />
                        </BSButton>
                    </div>
                </div>
        );

        if (selfContained) {
            return (
                <div className="offset-shadow-box" style={style}>
                    {wearableLoginDisplay}
                </div>
            );
        }
        return wearableLoginDisplay;

    }
});
