'use strict';
import { SET_TOOL_TOC_OPEN } from '../actions/types';

export function reducer(state = {
  toolTOCOpen: true,
}, action = { type: '' }) {
  switch (action.type) {
    case SET_TOOL_TOC_OPEN:
      return {
        ...state,
        toolTOCOpen: !!action.payload,
      }
    default:
      return state;
  }
}
