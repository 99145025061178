import React, {useCallback, useState} from 'react';
import {Modal, Form} from 'react-bootstrap';
import {User} from '../../types';
import {connect} from 'react-redux';
import Actions from '../../actions';
import BSButton from '../blocks/BSButton.component.web';

interface Props {
    open: boolean;
    close: () => void;
    user?: User;
    requestPasswordReset: (email: string) => Promise<void>;
    notify: (payload: { title: string; message: string; level: 'error'|'success' }) => void;
}

function PasswordResetModal({ notify, requestPasswordReset, user, open, close }: Props) {
    const [loading, setLoading] = useState(false)

    const sendReset = useCallback(() => {
        (async () => {
            if (!user?.email?.length) return;
            try {
                setLoading(true)
                await requestPasswordReset(user.email);
                notify({ title: 'Success!', message: 'Password reset email sent!', level: 'success' });
                setLoading(false)
                close();
            }
            catch (e) {
                setLoading(false)
                notify({ title: 'Error!', message: 'A problem occurred!', level: 'error' })
            }
        })()
    }, [ close, user, setLoading ])

    return (
        <Modal
            show={open}
            onHide={close}
            backdrop="static"
            keyboard={true}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Reset Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to send a password reset email to the following address?</p>
                <Form>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control disabled={true} value={user?.email ?? ''} type="email" placeholder="Enter email" />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <BSButton loading={loading} disabled={loading} label="Cancel" variant="link-green" onClick={close} />
                <BSButton loading={loading} disabled={loading} variant="green" label="Send Reset" onClick={sendReset} />
            </Modal.Footer>
        </Modal>
    )
}

export default connect(undefined, {
    requestPasswordReset: Actions.requestPasswordReset,
    notify: Actions.notify,
})(PasswordResetModal)
