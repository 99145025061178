import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Form, InputGroup} from 'react-bootstrap';
import {FontAwesome, Ionicons, MaterialCommunityIcons} from '@expo/vector-icons';
import DataTable, { TableColumn, SortOrder } from 'react-data-table-component';
import {connect} from 'react-redux';
import {useDebouncedEffect} from '@react-hookz/web';
import {adminGetUsers} from '../../api/users';
import UserFormModal from './UserFormModal';
import {Group, User} from '../../types';
import PasswordResetModal from './PasswordResetModal';
import moment from 'moment';

interface Props {
    user: User;
    group?: Group;
}

enum Status {
    All = 'all',
    Normal = 'normal',
    Unsubscribed = 'unsubscribed'
}

enum UserState {
    Anonymous = 'anonymous',
    Confirmed = 'confirmed',
}

type Sort = { sort_col?: string; sort_dir: SortOrder }

const useGetUsers = (tableParams: TableParams, token?: string, groupId?: string): [ User[], number, boolean ] => {
    const [users, setUsers ] = useState<User[]>([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (!token?.length || !groupId?.length) return;
            setLoading(true)
            try {
                const { page, length, q, status, user_state, sort_dir, sort_col } = tableParams || {};
                const p = {
                    ...(q?.length ? { q } : {}),
                    page,
                    length,
                    ...(status !== Status.All ? { status } : {}),
                    ...(user_state?.length ? { user_state } : {}),
                    ...(sort_dir?.length ? { sort_dir } : {}),
                    ...(sort_col?.length ? { sort_col } : {}),
                }
                const res = await adminGetUsers(token, groupId, p);
                setUsers(res.users);
                setCount(res.count);
                setLoading(false)
            }
            catch (e) {
                console.error(e)
                setLoading(false)
            }
        })()
    }, [ groupId, token, tableParams ])

    return [ users, count, loading ]
}

type TableParams = {
    page: number;
    length: number;
    status?: Status;
    user_state?: UserState;
    q?: string;
    sort_col?: string;
    sort_dir?: SortOrder;
}

function Users({ user, group }: Props) {
    const [q, setQ] = useState<string>()
    const [tableParams, setTableParams] = useState<TableParams>({
        page: 0,
        length: 25,
        status: Status.Normal,
        user_state: UserState.Confirmed,
        sort_col: 'updated_at',
        sort_dir: 'desc' as SortOrder,
    })
    const [ users, totalRows, loading ] = useGetUsers(tableParams, user?.token, group?._id);
    const [targetUser, setTargetUser] = useState<User>();
    const [pwResetUser, setPwResetUser] = useState<User>();
    const [resetPage, setResetPage] = useState(false);
    const searchInput = useRef<HTMLInputElement>(null);

    useDebouncedEffect(() => {
        setTableParams(prev => ({ ...prev, q }))
    }, [ q, setTableParams ], 500)

    const handleSort = useCallback((column: TableColumn<User>, sortDirection: SortOrder) => {
        setTableParams(prev => ({ ...prev, sort_col: column.sortField, sort_dir: sortDirection }))
    }, [ setTableParams ])

    const onLengthChange = useCallback((len: number) => {
        setTableParams(prev => ({ ...prev, page: 0, length: len }))
    }, [ setTableParams ])

    const onPageChange = useCallback((page: number) => setTableParams(prev => ({ ...prev, page })), [ setTableParams ])

    const deleteUser = useCallback(async (user: User) => {
        if (!confirm('Are you sure you want to delete this user?')) return;
    }, [])

    const COLUMNS = useMemo<TableColumn<User>[]>(() => {
        return [
            {
                name: 'Name',
                selector: user => user.name,
                sortable: true,
                sortField: 'name',
                cell: u => (
                    <div className="fw-bold">
                        { u.name }
                    </div>
                )
            },
            {
                name: 'Email',
                selector: user => user.email,
                sortable: true,
                sortField: 'email',
                cell: u => (
                    <div className="fw-bold">
                        { u.email }
                    </div>
                )
            },
            {
                name: 'ID',
                selector: user => user._id,
                sortable: true,
                sortField: '_id',
                cell: u => (
                    <div>
                        { u._id }
                    </div>
                )
            },
            {
                name: 'Updated',
                selector: user => user.dt_u,
                sortable: true,
                sortField: 'dt_u',
                cell: u => (
                    <div>
                        { u.dt_u ? moment(u.dt_u).format('llll') : '' }
                    </div>
                )
            },
            {
                name: 'Created',
                selector: user => user.dt_c,
                sortable: true,
                sortField: 'dt_c',
                cell: u => (
                    <div>
                        { u.dt_c ? moment(u.dt_c).format('llll') : '' }
                    </div>
                )
            },
            {
                name: 'Actions',
                sortField: 'actions',
                width: '170px',
                cell: u => (
                    <div className="d-flex align-items-center">
                        <Button size="sm" onClick={() => setTargetUser(u)} className="me-2" variant="primary">
                            <FontAwesome color="white" name="pencil" size={20} />
                        </Button>
                        <Button size="sm" onClick={() => setPwResetUser(u)} className="me-2" variant="primary">
                            <MaterialCommunityIcons color="white" name="lock-reset" size={20} />
                        </Button>
                    </div>
                ),
                sortable: false,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            }
        ]
    }, [ setTargetUser, deleteUser, setPwResetUser ])

    const clearSearch = useCallback(() => {
        setQ('');
        searchInput.current?.focus();
        setResetPage(!resetPage);
    }, [ setQ, searchInput?.current, resetPage, setResetPage ])

    const setStatus = useCallback((status: Status) => (e: any) =>  {
        e.preventDefault();
        setTableParams(prev => ({ ...prev, status }))
    }, [ setTableParams ])

    const setUserState = useCallback((userState: UserState) => (e: any) =>  {
        e.preventDefault();
        setTableParams(prev => ({ ...prev, user_state: userState }))
    }, [ setTableParams ])

    return (
        <>
            <div className="d-flex flex-column align-items-stretch p-3 bg-white">
                <div className="mb-2 d-flex flex-row align-items-center justify-content-between">
                    <h2>Users</h2>
                </div>
                {
                    !group && (
                        <div className="text-danger mb-3 d-flex align-items-center">
                            Please select a Group using the Group dropdown in the header.
                        </div>
                    )
                }
                <div className="mb-3 d-flex align-items-center">
                    <div className="d-flex align-items-center me-2">
                        <span className="fw-light me-2" style={{ fontSize: 12, color: 'gray' }}>Status</span>
                        <a
                            onClick={setStatus(Status.All)}
                            href="#"
                            className={`btn btn-sm btn-outline-primary me-2 ${tableParams.status === Status.All ? 'active' : ''}`}
                        >
                            All
                        </a>
                        <a
                            onClick={setStatus(Status.Normal)}
                            href="#"
                            className={`btn btn-sm btn-outline-primary me-2 ${tableParams.status === Status.Normal ? 'active' : ''}`}
                        >Active</a>
                        <a
                            onClick={setStatus(Status.Unsubscribed)}
                            href="#"
                            className={`btn btn-sm btn-outline-primary me-2 ${tableParams.status === Status.Unsubscribed ? 'active' : ''}`}
                        >Deactivated</a>
                        <span className="fw-light me-2" style={{ fontSize: 12, color: 'gray' }}>Type</span>
                        <a
                            onClick={setUserState(UserState.Anonymous)}
                            href="#"
                            className={`btn btn-sm btn-outline-secondary me-2 ${tableParams.user_state === UserState.Anonymous ? 'active' : ''}`}
                        >
                            Anonymous
                        </a>
                        <a
                            onClick={setUserState(UserState.Confirmed)}
                            href="#"
                            className={`btn btn-sm btn-outline-secondary me-2 ${tableParams.user_state === UserState.Confirmed ? 'active' : ''}`}
                        >
                            Confirmed
                        </a>
                    </div>
                    <InputGroup className="">
                        <InputGroup.Text id="search-icon">
                            <FontAwesome name="search" />
                        </InputGroup.Text>
                        <Form.Control
                            ref={searchInput}
                            placeholder="Search users..."
                            aria-label="Search"
                            aria-describedby="search-icon"
                            onChange={e => setQ(e.target.value)}
                            value={q}
                        />
                        <Button onClick={clearSearch} variant="danger" id="button-addon2">
                            <Ionicons name="close" size={24} color="white" />
                        </Button>
                    </InputGroup>
                </div>
                <div className="border rounded">
                    <DataTable
                        progressPending={loading}
                        columns={COLUMNS}
                        data={users || []}
                        onSort={handleSort}
                        sortServer
                        defaultSortFieldId="dt_u"
                        defaultSortAsc={false}
                        noDataComponent="No users found"
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationResetDefaultPage={resetPage}
                        onChangeRowsPerPage={onLengthChange}
                        paginationRowsPerPageOptions={[ 10, 25, 50, 100 ]}
                        paginationPerPage={tableParams.length}
                        onChangePage={onPageChange}
                        striped
                        pointerOnHover
                    />
                </div>
            </div>
            <UserFormModal
                group={group}
                targetUser={targetUser}
                open={!!targetUser}
                close={() => setTargetUser(undefined)}
            />
            <PasswordResetModal
                open={!!pwResetUser}
                close={() => setPwResetUser(undefined)}
                user={pwResetUser}
            />
        </>
    )
}

export default connect(state => ({
    user: state.auth?.user,
}))(Users)
