/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require, componentHandler, PubSub, Topics */
'use strict';

import React from 'react';
import { Field } from 'redux-form';
import classnames from 'classnames';
import { BLUE, LIGHT_GRAY } from '../../stylesheets/colors';

const baseClasses = {
    'checkbox-set': true
};

var CheckboxSet = React.createClass({
    displayName: 'CheckboxSet',

    propTypes: {
        checkboxData: React.PropTypes.array.isRequired,
        checkboxID: React.PropTypes.string.isRequired,
        onSelect: React.PropTypes.func,
        inline: React.PropTypes.bool,
        column2: React.PropTypes.bool,
        column3: React.PropTypes.bool,
        labelUnder: React.PropTypes.bool,
        className: React.PropTypes.string,
        style: React.PropTypes.object
    },

    getInitialState: function(){
        /*
        let locus = this, checked  = [], values = [];
        if (locus.props.hasOwnProperty('selected')) {
            values = locus.props.selected;
            values.forEach(function(item){
                let index = _.findIndex(locus.props.checkboxData, function(candidate) {
                        return candidate.value === item;
                    });
                checked.push(index);
            });
        }

        return {
            checked: checked,
            values: values
        };
        */
        return this.updateBatch();
    },

    updateBatch: function (selected) {
        let locus = this, state = locus.state, props = locus.props;

        selected = selected || this.props.selected;

        let delivery = {
            checked: [],
            values: []
        };

        if (!selected){
            delivery.checked = (state && state.checked) ? state.checked : [];
            delivery.values = (state && state.values) ? state.values : [];
        } else {
            delivery.values = selected;
            delivery.values.forEach(function(item){
                let index = _.findIndex(props.checkboxData, function(candidate) {
                    return candidate.value === item;
                });
                delivery.checked.push(index);
            });
        }
        return delivery;
    },

    UNSAFE_componentWillReceiveProps: function(nextProps){
        if (nextProps.selected){
            this.setState(this.updateBatch(nextProps.selected));
        }
    },

    componentWillUnmount: function() { },

    /* ========= LOCAL METHODS ============ */

    onSelect: function(event){
        let { values, checked } = this.state;
        let thisChecked = event.target.checked,
            thisValue = event.target.value,
            thisIndex = event.target.getAttribute('data-check-index');

        if (thisChecked){
            checked = _.union(checked,[+thisIndex]);
            values = _.union(values, [thisValue]);
        } else {
            checked  =_.without(checked, +thisIndex);
            values = _.without(values, thisValue);
        }
        if (this.props.onSelect) {
            this.props.onSelect(this.props.checkboxID, values);
        }

        if (!this.props.selected){
            // selected data is "unmanaged", not updated via props, so we update state internally
            this.setState({
                checked: checked,
                values: values
            });
        }
    },

    /* ========= RENDERING ================ */
    renderCheckBox: function (data, index, toggle = false) {
        return (field) => {
            let checkedArray = this.state.checked,
                renderJSON = this.props.renderJSON,
                unique = this.props.unique + '--' + index,
                checked = checkedArray.indexOf(index) !== -1;
            if (toggle) {
                return (
                  <>
                    <input
                      {...field.input}
                      role="checkbox"
                      aria-labelledby={unique}
                      aria-checked={checked}
                      type="checkbox"
                      id={unique}
                      className="box"
                      data-check-index={index}
                      value={data.value}
                      className="btn-check"
                      checked={checked}
                    />
                    <label
                      className={"btn mb-2 d-block"}
                      for={unique}
                      style={{
                          border: 'none',
                          backgroundColor: checked ? BLUE : LIGHT_GRAY,
                          color: checked ? 'white' : '#0E323E'
                      }}
                    >
                        {data.label}
                    </label>
                  </>
                );
            }
            return (
              <div key={'checkbox_' + index} className="checkbox">
                  <input {...field.input} role="checkbox" aria-labelledby={unique} aria-checked={checkedArray.indexOf(index) !== -1} type="checkbox" id={unique} className="box" data-check-index={index} value={data.value}
                         checked={checkedArray.indexOf(index) !== -1} />
                  <label htmlFor={unique} id={unique} className={data.InfoBug ? "label-infobug" : ""}>
                      {data.label}
                  </label>
              </div>
            );
        }
    },

    render: function(){
        let context = this;
        let { checkboxData, className, column2, column3, inline, labelUnder, style } = this.props;

        let classes = classnames(baseClasses, {
            'column-2': column2,
            'column-3': column3,
            'inline': inline,
            'label-under': labelUnder
        }, className);

        let boxSet = [];

        checkboxData = checkboxData || {
            "checkboxID": "_no_data_supplied",
            "checkboxData": [ { "label": "NO DATA SUPPLIED", "value": "_no_data_supplied" } ]
        };

        checkboxData.forEach((item, index) => {
            boxSet.push(
              <Field
                name={this.props.name}
                component={context.renderCheckBox(item, index, this.props.asToggleButton)}
                onChange={this.props.onSelect}
              />
            );
        });

        return <div className={classes} style={style}>{boxSet}</div>;
    }

});

export default CheckboxSet;
export { CheckboxSet };
