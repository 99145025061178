/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require, componentHandler */
'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
let findDOMNode = ReactDOM.findDOMNode;
import TextFieldReduxForm from 'components/ux/text-field-redux-form';
import { eventPreventDefault as prevent} from 'utils/tools';

const mdl = window.componentHandler,
    classnames = require('classnames');

const baseClasses = {
    div: {
        'mdl-textfield': true,
        'mdl-js-textfield': true
    },
    input: {
        'mdl-textfield__input': true
    },
    label: {
        'mdl-textfield__label': true
    },
    error: {
        'mdl-textfield__error': true
    }
};

var SelectInput = React.createClass({
    displayName: 'MDL_SelectInput',

    propTypes: {
        className: React.PropTypes.string,
        type: React.PropTypes.string,
        name: React.PropTypes.string,
        pattern: React.PropTypes.string,
        floatingLabel: React.PropTypes.bool,
        expanding: React.PropTypes.bool,
        expandingIcon: React.PropTypes.element,
        disabled: React.PropTypes.bool,
        maxLength: React.PropTypes.number,
        wrapperClassName: React.PropTypes.string,
    },

    mdlComponent: null,

    getInitialState: function() {
        return {
            textValue: this.props.value
        }
    },

    componentDidMount: function() {
        let node = findDOMNode(this.refs.target);
        if (node){
            this.mdlComponent = mdl.upgradeElement(node, 'MaterialTextfield');
        }
    },

    UNSAFE_componentWillReceiveProps: function() {},

    componentDidUpdate: function(prevProps, prevState){
        if(this.props.value !== this.state.textValue)
            this.setState({textValue: this.props.value});
        this.updateDomMenu();
    },

    componentWillUnmount: function() {
        if (findDOMNode(this.refs.target)){ mdl.downgradeElements(findDOMNode(this.refs.target)); }
    },


    updateDomMenu: function() {
        let menuList = findDOMNode(this.refs.menuList);
        if (menuList && !menuList.MaterialMenu) {
            mdl.upgradeElement(menuList, 'MaterialMenu');
            mdl.upgradeElement(menuList,  'MaterialRipple');
        }
    },

    onMenuButtonClick: function(e){ prevent(e);
        const menu = findDOMNode(this.refs.menuList);

        if (!menu) { return false; }
        if (menu.parentNode.classList.contains('is-visible')){
            menu.MaterialMenu.hide();
        } else {
            const { currentIndex, crumbIndex } = this.props;

            if (this.props.onMenuButtonClick) {
                this.props.onMenuButtonClick(crumbIndex).then(()=> {
                    menu.MaterialMenu.show(e);
                    alignMenu(menu);
                });
            }
            else {
                menu.MaterialMenu.show(e);
            }
        }
    },

    renderList: function() {
        const { list } = this.props;
        const renderListItem = this.renderListItem;
        if (!list || list.length === 0) { return null; }

        return (<ul key="menuList" ref="menuList"
                    //className="mdl-menu mdl-js-menu mdl-js-ripple-effect mdl-menu--bottom-right"
                    className="mdl-menu mdl-js-menu mdl-menu--bottom-right"
                    htmlFor="demo-menu-lower-left">
                        {
                            list.map((item, index) => renderListItem(item, index, item.text === this.props.value)
                        )}
               </ul>);
    },

    renderListItem: function(item, index, current=false, disabled=false) {
        return (
            <li key={item.value}
                style={{maxWidth: '400px'}}
                onClick={this.onChange}
                className={"mdl-menu__item" + (current ? ' current' : '') + (disabled ? ' inactive' : '')  }
                >{item.text}</li>
        );
    },

    onChange: function(event) {
        this.setState({textValue: event.target.value})
        this.props.onChange(event.target.value);
    },

    render: function() {
        const { id, labelText, onChange, value, list } = this.props;
        const renderList = this.renderList;
        const selected = this.props.options.filter(o => o.value === value);

        return (
            <div className={`input-group mb-3 input-group-fancy ${this.props.wrapperClassName || ''}`}>
                <span className="input-group-text" onClick={() => this.selectRef?.focus()}>{labelText}</span>
                <select
                  ref={select => this.selectRef = select}
                  className={"form-control " + ((selected.length === 0 && "required" in this.props)? "is-invalid ": " ")}
                  id={id} value={value}
                  onChange={this.onChange}
                  role="listbox"
                  style={this.props.style || {}}
                  required={'required' in this.props}
                >
                    {selected.length === 0 && <option selected={true}>{this.props.placeHolder}</option>}
                    {
                        this.props.options &&
                        this.props.options.map(o => (<option value={o.value} selected={o.value == value}>{o.display}</option>))
                    }
                </select>
            </div>
        );
    }

});

export default SelectInput;
