'use strict';

import {
    REQUEST_ADD_FEEDBACK,
    REQUEST_ADD_FEEDBACK_SUCCESS,
    REQUEST_ADD_FEEDBACK_FAILURE
} from 'actions/types';

export function reducer(state = {
    isFetching: false
}, action = { type: '' }) {
    switch (action.type) {
        case REQUEST_ADD_FEEDBACK:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_ADD_FEEDBACK_SUCCESS:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_ADD_FEEDBACK_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        default:
            return state;
    }
}
