/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, require */
'use strict';

import velocityHelpers from 'velocity-react/velocity-helpers';
require('velocity-animate/velocity.ui');


var Animations = {
  // Register these with UI Pack so that we can use stagger later.
  ChosenPhrase_In: velocityHelpers.registerEffect({
    calls: [[{
      transformPerspective: [800, 800],
      transformOriginX: ['50%', '50%'],
      transformOriginY: ['100%', '100%'],
      marginBottom: 0,
      opacity: 1,
      rotateX: [0, 130]
    }, 1, {
      easing: 'ease-out',
      display: 'block'
    }]]
  }),

  ChosenPhrase_Out: velocityHelpers.registerEffect({
    calls: [[{
      transformPerspective: [800, 800],
      transformOriginX: ['50%', '50%'],
      transformOriginY: ['0%', '0%'],
      marginBottom: -50,
      opacity: 0,
      rotateX: -70
    }, 1, {
      easing: 'ease-out',
      display: 'block'
    }]]
  })
};

const facade = {
    chosenPhrase_enter: {
        animation: Animations.ChosenPhrase_In,
        stagger: 300,
        duration: 300,
        backwards: true,
        display: 'block',
        style: {
            // Since we're staggering, we want to keep the display at "none" until Velocity runs
            // the display attribute at the start of the animation.
            display: 'none'
        }
    },

    chosenPhrase_leave: {
        animation: Animations.ChosenPhrase_Out,
        stagger: 300,
        duration: 300,
        backwards: true
    }

};

export default facade;