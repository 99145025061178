'use strict';

import { changePassword as changePasswordAPI } from 'api/users';
import notify from 'actions/notify';

import { getSelf as getSelfAPI } from 'api/users';
import Cookies from 'cookies-js';

import {
    REQUEST_CHANGE_PASSWORD,
    REQUEST_CHANGE_PASSWORD_SUCCESS,
    REQUEST_CHANGE_PASSWORD_FAILURE,
    REQUEST_LOG_IN_USER_SUCCESS
} from 'actions/types';

function requestChangePassword() {
    return {
        type: REQUEST_CHANGE_PASSWORD
    };
}

function requestChangePasswordSuccess(json) {
    return {
        type: REQUEST_CHANGE_PASSWORD_SUCCESS
    };
}

function requestChangePasswordFailure(error) {
    return {
        type: REQUEST_CHANGE_PASSWORD_FAILURE,
        payload: error,
        error: true
    };
}

function silentTokenUpdate(dispatch, newPassword, email){
    getSelfAPI(email, newPassword)
        .then((json) => {
            Cookies.set('userData', json._id + ':' + json.token);
            dispatch(requestLogInUserSuccess(json));
        })
        .catch((error) => {
        });
}

function requestLogInUserSuccess(json) {
    return {
        type: REQUEST_LOG_IN_USER_SUCCESS,
        payload: {
            user: json
        }
    };
}

export default function changePassword(token, userId, oldPassword, newPassword, email) {
    return dispatch => {
        dispatch(requestChangePassword());
        return changePasswordAPI(token, userId, oldPassword, newPassword)
            .then(() => {
                dispatch(notify({
                    title: "Password Update",
                    message: "New password saved.",
                    level: 'success'
                }));
                silentTokenUpdate(dispatch, newPassword, email);
                return dispatch(requestChangePasswordSuccess(oldPassword, newPassword));
            })
            .catch((error) => {
                dispatch(notify({
                    title: "No Password Change",
                    message: "The original password had an error.  Your password was not changed.",
                    level: 'error',
                    autoDismiss: 0
                }));
                return dispatch(requestChangePasswordFailure(error));
            });
    };
}