/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import _ from 'lodash';
import React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Block } from 'components/blocks/block';
import Button from 'components/ux/mdl/button';

export const DrinkUrgeMoodFeedback = React.createClass({

    getInitialState() {
        return(
            {chartToShow : 'drink_charts'}
        )
    },

    renderDataKeyButtons() {
        const charts = [
            {
                chart: 'drink_charts',
                title: {
                    id: 'drink_chart_button_text',
                    defaultMessage: 'Drink Feedback'
                }
            },
            {
                chart: 'urge_charts',
                title: {
                    id: 'urge_chart_button_text',
                    defaultMessage: 'Urge Feedback'
                }
            },
            {
                chart: 'mood_charts',
                title: {
                    id: 'mood_chart_button_text',
                    defaultMessage: 'Mood Feedback'
                }
            }
        ];
        const that = this;

        return (
            <div className="nav tracker-nav">
                {charts.map((chart, index) => {
                    let style = {width: 'auto'};
                    if(chart.chart === that.state.chartToShow) {
                        style = {
                            width: 'auto',
                            color: 'white'
                        };
                    }
                    return (
                        <Button 
                            flat={chart.chart === that.state.chartToShow ? false : true} 
                            raised={chart.chart === that.state.chartToShow ? true : false} 
                            colored={chart.chart === that.state.chartToShow ? true : false} 
                            key={"chart_button" + index} 
                            onClick={() => that.chartToShow(chart.chart)} 
                            style={style}
                        >
                            <FormattedMessage {...chart.title} />
                        </Button>
                    )
                })}
            </div>
        )
    },

    chartToShow(dataKey) {
        this.setState({chartToShow: dataKey});
    },

    render() {


        const drink_charts = [
            {
                "stat_group" : "drink_day",
                "title" : {
                    "defaultMessage" : "Drinks Per Day",
                    "id" : "moderate_drink_day_chart_title"
                },
                "lines" : [ 
                    {
                        "response_key" : "drinks_regular_limit",
                        "label" : {
                            "defaultMessage" : "Your Regular Limit",
                            "id" : "moderate_drink_day_std_drink_count_drinks_regular_limit_label"
                        }
                    }, 
                    {
                        "response_key" : "drinks_absolute_limit",
                        "label" : {
                            "defaultMessage" : "Your Absolute Limit",
                            "id" : "moderate_drink_day_std_drink_count_drinks_absolute_limit_label"
                        }
                    }
                ],
                "period" : 1,
                "metrics" : [ 
                    {
                        "key" : "std_drink_count",
                        "label" : {
                            "defaultMessage" : "Drinks Per Day",
                            "id" : "moderate_drink_day_chart_label"
                        }
                    }
                ],
                "type" : "chart"
            },
            {
                "stat_group" : "drink_week",
                "title" : {
                    "defaultMessage" : "Drinking Days Per Week",
                    "id" : "moderate_drink_week_day_count_chart_title"
                },
                "lines" : [ 
                    {
                        "response_key" : "drinking_days_limit",
                        "label" : {
                            "defaultMessage" : "Your Limit",
                            "id" : "moderate_drink_week_day_count_drinking_days_limit_label"
                        }
                    }
                ],
                "period" : 7,
                "metrics" : [ 
                    {
                        "key" : "day_count",
                        "label" : {
                            "defaultMessage" : "Drinking Days Per Week",
                            "id" : "moderate_drink_week_day_count_chart_label"
                        }
                    }
                ],
                "type" : "chart"
            },
            {
                "stat_group" : "peak_bac_day",
                "title" : {
                    "defaultMessage" : "Peak BAC Per Day",
                    "id" : "moderate_peak_bac_day_chart_title"
                },
                "lines" : [ 
                    {
                        "response_key" : "peak_bac_regular_limit",
                        "label" : {
                            "defaultMessage" : "Your Regular Limit",
                            "id" : "moderate_peak_bac_day_peak_bac_peak_bac_regular_limit_label"
                        }
                    }, 
                    {
                        "response_key" : "peak_bac_absolute_limit",
                        "label" : {
                            "defaultMessage" : "Your Absolute Limit",
                            "id" : "moderate_peak_bac_day_peak_bac_peak_bac_absolute_limit_label"
                        }
                    }
                ],
                "period" : 1,
                "metrics" : [ 
                    {
                        "key" : "peak_bac",
                        "label" : {
                            "defaultMessage" : "Peak BAC Per Day",
                            "id" : "moderate_peak_bac_day_chart_label"
                        }
                    }
                ],
                "type" : "chart"
            },
            {
                "stat_group" : "hours_intoxicated_week",
                "title" : {
                    "defaultMessage" : "Hours Intoxicated Per Week",
                    "id" : "moderate_hours_intoxicated_week_chart_title"
                },
                "lines" : [],
                "period" : 7,
                "metrics" : [ 
                    {
                        "key" : "hours",
                        "label" : {
                            "defaultMessage" : "Hours Intoxicated Per Week",
                            "id" : "moderate_hours_intoxicated_week_chart_label"
                        }
                    }
                ],
                "type" : "chart"
            }
        ];

        const urge_charts = [
            {
                "type": "chart",
                "stat_group": "urge_day",
                "period": 1,
                "title": {
                    "id": "abstain_urges_tracking_feedback_urge_day_chart_title",
                    "defaultMessage": "Urges Per Day"
                },
                "metrics": [
                    {
                        "key": "count",
                        "label": {
                            "id": "abstain_urges_tracking_feedback_urge_day_chart_label",
                            "defaultMessage": "Urges Per Day"
                        }
                    }
                ]
            },
            {
                "type": "chart",
                "stat_group": "urge_week",
                "period": 7,
                "title": {
                    "id": "abstain_urges_tracking_feedback_urge_week_count_chart_title",
                    "defaultMessage": "Urges Per Week"
                },
                "metrics": [
                    {
                        "key": "count",
                        "label": {
                            "id": "abstain_urges_tracking_feedback_urge_day_week_chart_label",
                            "defaultMessage": "Urges Per Week"
                        }
                    }
                ]
            },
            {
                "type": "chart",
                "stat_group": "urge_day",
                "period": 1,
                "title": {
                    "id": "abstain_urges_tracking_feedback_urge_day_avg_intensity_chart_title",
                    "defaultMessage": "Average Urge Intensity"
                },
                "metrics": [
                    {
                        "key": "avg_intensity",
                        "label": {
                            "id": "abstain_urges_tracking_feedback_urge_day_avg_intensity_chart_label",
                            "defaultMessage": "Average Urge Intensity"
                        }
                    }
                ]
            },
            {
                "type": "chart",
                "stat_group": "urge_day",
                "period": 1,
                "title": {
                    "id": "abstain_urges_tracking_feedback_urge_day_avg_duration_chart_title",
                    "defaultMessage": "Average Urge Duration"
                },
                "metrics": [
                    {
                        "key": "avg_duration",
                        "label": {
                            "id": "abstain_urges_tracking_feedback_urge_day_avg_duration_chart_label",
                            "defaultMessage": "Average Urge Duration"
                        }
                    }
                ]
            }
        ];

        const mood_charts = [
            {
                "type": "chart",
                "stat_group": "mood_day",
                "chart_type": "bar",
                "period": 1,
                "title": {
                    "id": "moderate_<module>_<section>_<page>_mood_day_chart_title",
                    "defaultMessage": "Mood Scores Per Day"
                },
                "metrics": [
                    {
                        "key": "avg_positive_score",
                        "label": {
                            "id": "moderate_<module>_<section>_<page>_positive_score_chart_label",
                            "defaultMessage": "Positive Score"
                        }
                    },
                    {
                        "key": "avg_negative_score",
                        "label": {
                            "id": "moderate_<module>_<section>_<page>_negative_score_chart_label",
                            "defaultMessage": "Negative Score"
                        }
                    }
                ]
            },
            {
                "type": "chart",
                "stat_group": "mood_week",
                "period": 7,
                "chart_type": "bar",
                "title": {
                    "id": "moderate_<module>_<section>_<page>_mood_week_chart_title",
                    "defaultMessage": "Mood Scores Per Week"
                },
                "metrics": [
                    {
                        "key": "avg_positive_score",
                        "label": {
                            "id": "moderate_<module>_<section>_<page>_positive_score_week_chart_label",
                            "defaultMessage": "Positive Score"
                        }
                    },
                    {
                        "key": "avg_negative_score",
                        "label": {
                            "id": "moderate_<module>_<section>_<page>_negative_score_week_chart_label",
                            "defaultMessage": "Negative Score"
                        }
                    }
                ]
            }
        ];
        
        // get the moderate tool, so we can get the moderate responses
        let moderate_tool = false;
        let moderate_response = false;
        for(const key in this.props.tools) {
            if(this.props.tools[key].slug == 'moderate') {
                moderate_tool = this.props.tools[key];
            }
        }
        if(moderate_tool) {
            for(const key in this.props.responses) {
                if(_.get(this.props.responses[key], 'response.tool_id') == moderate_tool._id) {
                    moderate_response = this.props.responses[key].response;
                }
            }
        }

        let whichCharts = drink_charts;
        if(this.state.chartToShow === 'mood_charts') {
            whichCharts = mood_charts;
        } else if (this.state.chartToShow === 'urge_charts') {
            whichCharts = urge_charts;
        }

        return (
            <div>
                <h1 className="section-title"><FormattedMessage id='your_feedback_block_title' defaultMessage='Your Feedback' /></h1>
                {this.renderDataKeyButtons()}
                {
                    whichCharts.map((component, index) => {
                        return (
                            <Block
                                key={'block_' + index}
                                component={component}
                                index={index}
                                norms={this.props.norms}
                                params={{toolSlug: 'moderate'}}
                                responses={_.get(moderate_response, 'responses', {})}
                                userProfile={this.props.userProfile}
                                trackerEntryStats={this.props.trackerEntryStats}
                            />
                        );
                    })
                }
            </div>
        );
    }
});

export default injectIntl(DrinkUrgeMoodFeedback);
