import React, {MouseEvent, Dispatch, SetStateAction, useCallback} from 'react';
import {FontAwesome, Entypo} from '@expo/vector-icons';

export enum AdminPage {
    Dashboard = 'Dashboard',
    Tools = 'Tools',
    Users = 'Users',
    ActivityLog = 'ActivityLog'
}

type Props = {
    setPage: Dispatch<SetStateAction<AdminPage>>;
};

const PAGES = [
    { name: 'Dashboard', page: AdminPage.Dashboard, icon: <Entypo size={20} name="gauge" /> },
    { name: 'Tools', page: AdminPage.Tools, icon: <Entypo size={20} name="tools" /> },
    { name: 'Users', page: AdminPage.Users, icon: <FontAwesome size={20}  name="users" /> },
    { name: 'Activity Log', page: AdminPage.ActivityLog, icon: <FontAwesome size={20} name="list-alt" /> },
]

const Sidenav = ({ setPage }: Props) => {

    const onClick = useCallback((page: AdminPage) => (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        setPage(page)
    }, [ setPage ])

    return (
        <div className="d-flex flex-column w-full p-2">
            {
                PAGES.map(({ name, page, icon }) => (
                    <a
                        key={name}
                        href="#"
                        onClick={onClick(page)}
                        className="d-flex align-items-center p-2"
                    >
                        { icon }
                        <span className="ms-2">
                            { name }
                        </span>
                    </a>
                ))
            }
        </div>
    )
};

export default Sidenav
