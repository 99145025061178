'use strict';

import { UPDATE_DISPLAY } from 'actions/types';

export function reducer(state = {}, action = { type: '' }) {
    switch (action.type) {
        case UPDATE_DISPLAY:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}