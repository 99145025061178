'use strict';

import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from 'reducers';

export default function initializeStore(initialState) {
    const middleware = applyMiddleware(thunk);
    // uncomment to enable trace
    // const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || compose;
    const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
    const composition = composeEnhancers(middleware);
    const store = composition(createStore)(rootReducer, initialState);
    return store;
}
