'use strict';

import _ from 'lodash';
import React from 'react';
import { FormattedScoreMessage } from 'components/elements/formatted-score-message';

export const HighlightedInformationalText = React.createClass({

    propTypes: {
        components: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
        default_responses: React.PropTypes.object.isRequired,
        norms: React.PropTypes.object,
        responses: React.PropTypes.object.isRequired
    },

    renderElement(component, index, responses, norms) {
        const componentType = _.get(component, 'type');
        const className = _.get(component, 'className');
        let props = _.get(component, 'props', { key: 'rE' + index, className: className });
        const children = _.get(component, 'children', []);
        let elementType;
        switch (componentType) {
            case 'formatted_message':
                elementType = FormattedScoreMessage;
                props = {
                    ...props,
                    ...component.text,
                    values: responses
                };
                break;
            default:
                elementType = componentType;
        }
        return React.createElement(
            elementType,
            props,
            ...children.map((childComponent, index) => {
                if(_.get(childComponent, 'highlight_key')) {
                    if(_.get(responses, childComponent.highlight_key)){
                        let key = _.get(responses, childComponent.highlight_key);
                        if(_.get(childComponent, 'highlight_key2')) {
                            const secondary_key = _.get(responses, childComponent.highlight_key2);
                            if(secondary_key > key) {
                                key = secondary_key;
                            }
                        }
                        const min = _.get(childComponent, 'highlight_min');
                        const max = _.get(childComponent, 'highlight_max');
                        if(key >= min && key < max) {
                            childComponent.className = "highlight";
                        }
                    }
                }
                return this.renderElement(childComponent, index, responses, norms);
            })
        );
    },

    render() {
        const { components, responses, norms } = this.props;
        return (
            <div className="informational-text">
                {
                    components.map((component, index) => {
                        return (
                            this.renderElement(component, index, responses, norms)
                        );
                    })
                }
            </div>
        );
    }
});
