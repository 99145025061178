/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import _ from 'lodash';
import React from 'react';
import Link from 'components/ux/linker';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Selectors from 'selectors';
const { userNormsSelector, userProfileSelector } = Selectors;

import { Block } from 'components/blocks/block';
import Actions from 'actions';
import Button from 'components/ux/mdl/button';
import InfoBug from 'components/popover/infobug';
import { faPrint, faAdjust, faBell as  faBellO, faComment as faCommentingO, faBolt, faWineGlassAlt as faGlass, faThList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import TrackerNav from "../components/blocks/TrackerNav.component.web";
import BSButton from "../components/blocks/BSButton.component.web";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import {BLACK} from "../stylesheets/colors";
export const UrgeTrackerFeedbackPage = React.createClass({

    renderTrackerNavButtons() {
        const { auth: { user }, tools} = this.props;
        const toolIds = _.get(user, 'tool_ids', []);
        var userTools = _.map(toolIds, (toolId) => {
            return _.get(tools, toolId);
        });
        _.remove(userTools, (tool) => {
            if(_.get(tool, 'slug')) {
                return (tool.slug.indexOf('checkup') > -1 || tool.slug.indexOf('precheck') > -1);
            }
        });
        if(userTools.length === 0) {
            return null;
        }
        else {
            return <TrackerNav/>
        }
    },

    showRemindersModal() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'newReminder',
            edit_reminder: {},
            modalContent: true
        }));
    },

    showHelpFeedbackModal() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'helpFeedback',
            modalContent: true
        }));
    },


    render() {


        const charts = [
            {
                "type" : "informational_text",
                "default_responses" : {},
                "components" : [ 
                    {
                        "type" : "p",
                        "children" : [ 
                            {
                                "type" : "formatted_message",
                                "text" : {
                                    "id" : "urge_feedback_p0",
                                    "defaultMessage" : "Here is your feedback on your tracked urges."
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "type": "chart",
                "stat_group": "urge_day",
                "period": 1,
                "title": {
                    "id": "abstain_urges_tracking_feedback_urge_day_chart_title",
                    "defaultMessage": "Urges Per Day"
                },
                "metrics": [
                    {
                        "key": "count",
                        "label": {
                            "id": "abstain_urges_tracking_feedback_urge_day_chart_label",
                            "defaultMessage": "Urges Per Day"
                        }
                    }
                ]
            },
            {
                "type": "chart",
                "stat_group": "urge_week",
                "period": 7,
                "title": {
                    "id": "abstain_urges_tracking_feedback_urge_week_count_chart_title",
                    "defaultMessage": "Urges Per Week"
                },
                "metrics": [
                    {
                        "key": "count",
                        "label": {
                            "id": "abstain_urges_tracking_feedback_urge_day_week_chart_label",
                            "defaultMessage": "Urges Per Week"
                        }
                    }
                ]
            },
            {
                "type": "chart",
                "stat_group": "urge_day",
                "period": 1,
                "title": {
                    "id": "abstain_urges_tracking_feedback_urge_day_avg_intensity_chart_title",
                    "defaultMessage": "Average Urge Intensity"
                },
                "metrics": [
                    {
                        "key": "avg_intensity",
                        "label": {
                            "id": "abstain_urges_tracking_feedback_urge_day_avg_intensity_chart_label",
                            "defaultMessage": "Average Urge Intensity"
                        }
                    }
                ]
            },
            {
                "type": "chart",
                "stat_group": "urge_day",
                "period": 1,
                "title": {
                    "id": "abstain_urges_tracking_feedback_urge_day_avg_duration_chart_title",
                    "defaultMessage": "Average Urge Duration"
                },
                "metrics": [
                    {
                        "key": "avg_duration",
                        "label": {
                            "id": "abstain_urges_tracking_feedback_urge_day_avg_duration_chart_label",
                            "defaultMessage": "Average Urge Duration"
                        }
                    }
                ]
            }
        ];

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2">
                        <div className="card my-4" style={{ border: 'none' }}>
                            <div className="card-body">
                                {this.renderTrackerNavButtons()}
                                <div className="d-flex align-items-center my-2">
                                    <BSButton variant="flat-blue" linkTo="/urges">
                                        <MaterialCommunityIcons name="arrow-left" size={24} color={BLACK} />
                                    </BSButton>
                                    <h3 className="ms-2 card-title mb-0">
                                        <FormattedMessage
                                            id='urge-feedback-page-title'
                                            defaultMessage='Urge Feedback'
                                        />
                                    </h3>
                                </div>
                                <p>
                                    <em>Instructions:</em> You can focus on a period of time in the history by zooming in (or out) with your scroll wheel or pinching/zooming on mobile. When you're zoomed, you can drag left or right to change the period of time being focused upon.
                                </p>
                                {
                                    charts.map((component, index) => {
                                        return (
                                            <Block
                                                key={'block_' + index}
                                                component={component}
                                                index={index}
                                                norms={this.props.norms}
                                                params={{toolSlug: 'abstain'}}
                                                responses={{}}
                                                userProfile={this.props.userProfile}
                                                trackerEntryStats={this.props.trackerEntryStats}
                                            />
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
});

export default connect(
    state => ({
        auth: state.auth,
        activeResponseId: state.responses.activeResponseId,
        activeResponse: _.get(state.responses[state.responses.activeResponseId], 'response'),
        responses: state.responses,
        plans: state.plans.plans,
        tools: state.tools,
        trackerEntryStats: state.trackerEntryStats,
        ...userNormsSelector(state),
        ...userProfileSelector(state)
    })
)(UrgeTrackerFeedbackPage);
