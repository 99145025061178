'use strict';

import _ from 'lodash';

import { updateTrackerEntry as updateTrackerEntryAPI } from 'api/tracker-entries';
import notify from 'actions/notify';
import getTrackerEntryStats from 'actions/get-tracker-entry-stats';

import {
    REQUEST_UPDATE_TRACKER_ENTRY,
    REQUEST_UPDATE_TRACKER_ENTRY_SUCCESS,
    REQUEST_UPDATE_TRACKER_ENTRY_FAILURE
} from 'actions/types';

function requestUpdateTrackerEntry(trackerEntryId) {
    return {
        type: REQUEST_UPDATE_TRACKER_ENTRY,
        payload: {
            trackerEntryId
        }
    };
}

function requestUpdateTrackerEntrySuccess(trackerEntryId, trackerEntry) {
    return {
        type: REQUEST_UPDATE_TRACKER_ENTRY_SUCCESS,
        payload: {
            trackerEntryId,
            trackerEntry
        }
    };
}

function requestUpdateTrackerEntryFailure(error) {
    return {
        type: REQUEST_UPDATE_TRACKER_ENTRY_FAILURE,
        payload: error,
        error: true
    };
}

export default function updateTrackerEntry(token, userId, trackerEntryId, updates) {
    return dispatch => {
        dispatch(requestUpdateTrackerEntry(trackerEntryId));
        return updateTrackerEntryAPI(token, userId, trackerEntryId, updates)
            .then((json) => {
                dispatch(notify({
                    title: "Entry Updated",
                    message: "Your changes have been saved.",
                    level: 'success',
                    autoDismiss: 4
                }));
                var trackerType = json.tracker_type_name;
                dispatch(getTrackerEntryStats(token, userId, trackerType, 1));
                return dispatch(requestUpdateTrackerEntrySuccess(trackerEntryId, json));
            })
            .catch((error) => {
                dispatch(requestUpdateTrackerEntryFailure(error));
                return Promise.reject(error);
            });
    };
}
