/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require, componentHandler */
'use strict';

import React from 'react';
class TextInput extends React.Component {
    displayName = 'MDL_TextInput';
    constructor(props) {
        super();
        this.state = { value: props.value? props.value: "" };
    }

    static propTypes = {
        className: React.PropTypes.string,
        type: React.PropTypes.string,
        name: React.PropTypes.string,
        pattern: React.PropTypes.string,
        floatingLabel: React.PropTypes.bool,
        expanding: React.PropTypes.bool,
        expandingIcon: React.PropTypes.element,
        disabled: React.PropTypes.bool,
        maxLength: React.PropTypes.number,
        labelClassName: React.PropTypes.string,
        textArea: React.PropTypes.bool,
    }

    onChange(event) {
        this.setState({'value': event.target.value });
        if(this.props.onChange) {
            this.props.onChange(event);
        }
    }

    expander() {
        const { fieldId, name, labelText, errorText, expanding, expandingIcon } = this.props;
        if (!expanding) return null;
        return (
          <>
              <label key="expandLabel" htmlFor={fieldId ? fieldId: name} className="">
                  {expandingIcon}
              </label>
              <div key="expandHolder" className="">
                  <input id={fieldId ? fieldId: name} onChange={onChange} aria-required={(errorText == "Required") ? "true" : "false"} role="textbox" aria-describedby={fieldId ? fieldId + '-error': name + '-error'} { ... this.props } />
                  <label htmlFor="expandable">{labelText}</label>
              </div>
          </>
        )
    }

    nonExpander() {
        const { fieldId, name, labelText, errorText, textArea, floatingLabel, isInvalid, ...rest } = this.props;
        const onChange = this.onChange.bind(this);
        const value = this.state.value;
        if (this.props.expanding) return null;
        if (textArea) return (
          <div className="input-group input-group-fancy">
            <div
              onClick={() => this.inputRef.focus()}
              className="input-group-text d-flex flex-column justify-content-center align-items-start"
            >
                { labelText }
                {
                    errorText && (
                      <small className="text-danger">
                          { errorText }
                      </small>
                    )
                }
            </div>
            <textarea
              ref={input => this.inputRef = input}
              id={fieldId ? fieldId : name}
              className="form-control"
              value={value}
              name={name}
              {...rest}
            />
            {this.props.children}
          </div>
        )
        return (
          <div className="input-group mb-3 input-group-fancy">
              {
                  labelText && (
                    <div
                      className="input-group-text d-flex flex-column justify-content-center align-items-start"
                      onClick={() => this.inputRef.focus()}
                    >
                        {labelText}
                        {
                            errorText && (
                              <small className="text-danger">
                                  { errorText }
                              </small>
                            )
                        }
                    </div>
                  )
              }
              <input
                id={fieldId ? fieldId : name}
                ref={input => this.inputRef = input}
                className="form-control"
                type="text"
                aria-label={labelText}
                onChange={onChange}
                aria-required={(errorText == "Required") ? "true" : "false"}
                title={this.props.tooltip}
                value={value}
                name={name}
                {...rest}
              />
              {this.props.children}
          </div>
        )
    }

    render() {
        const { style } = this.props;
        return (
            <div ref="target" style={style}>
                {this.expander()}
                {this.nonExpander()}
            </div>
        );
    }

};

export default TextInput;
export {TextInput};
