'use strict';

import _ from 'lodash';
import React from 'react';

import {FormattedMessage} from 'react-intl';
import Button from 'components/ux/mdl/button';
import Accordion from 'components/ux/accordion';
import {faFilm, faTimesCircle as faTimesCircleO, faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';

export const Video = React.createClass({
    propTypes: {
        title: React.PropTypes.object,
        src: React.PropTypes.string
    },

    getInitialState() {
        let initialState = false;
        if (this.props.display === 'prominent') {
            initialState = true
        }
        return {showVideo: initialState};
    },

    onToggleVideo() {
        this.setState({showVideo: !this.state.showVideo});
    },

    render() {
        const {title, src} = this.props;
        return (
            <div className="video-section">
                <div className="title d-flex align-items-center justify-content-between">
                    <div>
                        <h4 className="sub-title"><FormattedMessage id="video_title_description"
                                                                    defaultMessage="Video"/></h4>
                        <FormattedMessage {...title} />
                    </div>
                </div>
                    <div className="video-display">
                        <div className="embed-container">
                            <video key={src} width="100%" height="auto" controls autoPlay>
                                <source src={src}/>
                            </video>
                        </div>
                    </div>
                </div>
        );
    }
});
