import React from 'react';
import App from './App';
import * as Sentry from 'sentry-expo';
import config from '../../../config';

Sentry.init({
    dsn: config.SENTRY,
    enableInExpoDevelopment: true,
    debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

interface Props {

}

const AppContainer = (props: Props) => {
    return <App />
}

export default AppContainer