'use strict';

import React, { findDOMNode } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import ButtonSpinner from 'components/ux/button-spinner';
import TextFieldReduxForm from 'components/ux/text-field-redux-form';
import { faStar as faStarO } from '@fortawesome/free-solid-svg-icons';
import { validator } from 'utils/tools';

const messages = defineMessages({
    "value_tracker_title_label": {
        id: "value_tracker_title_label",
        description: "Title",
        defaultMessage: "Title"
    },
    "value_tracker_first_taught_label": {
        id: "value_tracker_first_taught_label",
        description: "Who first taught you about this value?",
        defaultMessage: "Who first taught you about this value?"
    },
    "value_tracker_admire_label": {
        id: "value_tracker_admire_label",
        description: "Who do you most admire in relation to this value?",
        defaultMessage: "Who do you most admire in relation to this value?"
    },
    "value_tracker_example_label": {
        id: "value_tracker_example_label",
        description: "What is the greatest example of this value that you know?",
        defaultMessage: "What is the greatest example of this value that you know?"
    },
    "value_tracker_best_done_label": {
        id: "value_tracker_best_done_label",
        description: "What's the best you've done in relation to this value?",
        defaultMessage: "What's the best you've done in relation to this value?"
    },
    "value_tracker_best_could_label": {
        id: "value_tracker_best_could_label",
        description: "What is the best you could ever do in relation to this value?",
        defaultMessage: "What is the best you could ever do in relation to this value?"
    },
    "value_tracker_relationship_label": {
        id: "value_tracker_relationship_label",
        description: "What got in the way of your relationship with this value?",
        defaultMessage: "What got in the way of your relationship with this value?"
    }
});


const ValueTracker = React.createClass({

    propTypes: {
        fields: React.PropTypes.object.isRequired,
        handleSubmit: React.PropTypes.func.isRequired
    },

    onChange(fieldName, event) {
        this.props.change(fieldName, event.target.value);
    },

    render() {
        const { handleSubmit, intl: { formatMessage }} = this.props;
        return (

                    <div className="section section-account-overview col-1-1">
                        <div className="contain-box-wrap" style={{maxWidth:'690px', marginTop: '52px'}}>
                            <div className="contain-box-main" >
                                <h1 className="page-title contain-box-title">
                                    <FormattedMessage
                                        id="value_tracker_title"
                                        description="Title for the Change Value Tracker form"
                                        defaultMessage="Exploring Your Value" />
                                </h1>

                                <form id="value_tracker" name="value_tracker" className="stack-input" style={{margin: '0 auto 40px auto' /*, maxWidth: '290px'*/}}>
                                    <div>
                                        <TextFieldReduxForm name="title"
                                            floatingLabel={true}
                                            labelText={formatMessage(messages.value_tracker_title_label)}/>
                                        <TextFieldReduxForm name="first_taught"
                                            textArea={true}
                                            floatingLabel={true}
                                            labelText={formatMessage(messages.value_tracker_first_taught_label)} />
                                        {/* <InfoBug>
                                            <FormattedMessage
                                                id="value_tracker_changes_to_make_infobug"
                                                defaultMessage="What happened? What did someone else do or say?"
                                            />
                                        </InfoBug> */}
                                        <TextFieldReduxForm name="admire"
                                            textArea={true}
                                            floatingLabel={true}
                                            labelText={formatMessage(messages.value_tracker_admire_label)} />
                                        {/* <InfoBug>
                                            <FormattedMessage
                                                id="value_tracker_reasons_to_change_infobug"
                                                defaultMessage="What am I saying to myself about this event? Which of them are facts? Which are reasons to change or opinions? Which are helpful? Are there any which may be self-defeating? Are there some you'd rather not have?"
                                            />
                                        </InfoBug> */}
                                        <TextFieldReduxForm name="example"
                                            textArea={true}
                                            floatingLabel={true}
                                            labelText={formatMessage(messages.value_tracker_example_label)} />
                                        {/* <InfoBug>
                                            <FormattedMessage
                                                id="value_tracker_steps_to_changing_infobug"
                                                defaultMessage="What are the immediate, short-term steps to changing of how I responded? How did I feel and behave? What are the long-term steps to changing? How did I feel or behave? Were these feelings and/or behaviors helpful or not?"
                                            />
                                        </InfoBug> */}
                                        <TextFieldReduxForm name="best_done"
                                            textArea={true}
                                            floatingLabel={true}
                                            labelText={formatMessage(messages.value_tracker_best_done_label)}/>
                                        {/* <InfoBug>
                                            <FormattedMessage
                                                id="value_tracker_how_others_can_help_infobug"
                                                defaultMessage="How others can help or challenge the thoughts or reasons to change that are self-defeating. What is the objective evidence that my thoughts or reasons to change are true (and not just my belief or opinion)? Is there another way to look at it? How are my thoughts and reasons to change likely to be helpful or not in the long run?"
                                            />
                                        </InfoBug> */}
                                        <TextFieldReduxForm name="best_could"
                                            textArea={true}
                                            floatingLabel={true}
                                            labelText={formatMessage(messages.value_tracker_best_could_label)}/>
                                        {/* <InfoBug>
                                            <FormattedMessage
                                                id="value_tracker_possible_setbacks_infobug"
                                                defaultMessage="What more helpful reasons to change or thoughts can I use to replace the unhelpful thoughts? How might they lead to better steps to changing?"
                                            />
                                        </InfoBug> */}
                                        <TextFieldReduxForm name="relationship"
                                            textArea={true}
                                            floatingLabel={true}
                                            labelText={formatMessage(messages.value_tracker_relationship_label)}/>
                                        {/* <InfoBug>
                                            <FormattedMessage
                                                id="value_tracker_good_things_from_changing_infobug"
                                                defaultMessage="If I take this different approach to thinking about this event, what are my new feelings or reactions?"
                                            />
                                        </InfoBug> */}
                                        <ButtonSpinner spinner={this.props.isFetching}
                                            onClick={handleSubmit} ripple={true} raised={true} colored={true} >
                                                <FontAwesomeIcon icon={faStarO}/>
                                                <FormattedMessage id="value_tracker_button_save"
                                                    description="Submit the Value tracker form to save a Value Exploration"
                                                    defaultMessage="Save" />
                                        </ButtonSpinner>
                                    </div>
                                    <div className="clearfix" />
                                </form>

                            </div>
                        </div>
                    </div>
        );
    }
});

const formFields = ['title', 'first_taught', 'admire', 'example', 'best_done', 'best_could', 'relationship'];
let form = reduxForm({
    form: 'valueTracker',
    fields: formFields,
    validate: validator ({
        'title': { required: 'Required' },
        'first_taught': { required: 'Required' },
        'admire': { required: 'Required' },
        'example': { required: 'Required' },
        'best_done': { required: 'Required' },
        'best_could': { required: 'Required' },
        'relationship': { required: 'Required' }
    })
})(ValueTracker);
const selector = formValueSelector('valueTracker') // <-- same as form name
form = connect(state => {
    return formFields.reduce((dict, fieldName) => Object.assign({ [fieldName]: selector(state, fieldName) }, dict), {});
})(form)



export default injectIntl(form);
