/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import RadioSet from 'components/ux/radio-set-dynamic';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
export const MultipleChoiceTableQuestion = React.createClass({
//    mixins: [],
//    statics: {},

    propTypes: {
        field: React.PropTypes.object.isRequired,
        fieldName: React.PropTypes.string.isRequired,
        options: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
        style: React.PropTypes.object,
        text: React.PropTypes.object.isRequired,
        updateResponse: React.PropTypes.func.isRequired
    },

//    getInitialState() {},
//    getDefaultProps() {},
//    componentWillMount() {},
//    componentDidMount() {},
//    componentWillReceiveProps(nextProps) {},
//    shouldComponentUpdate(nextProps, nextState) {},
//    componentWillUpdate(nextProps, nextState) {},
//    componentDidUpdate(prevProps, prevState) {},
//    componentWillUnmount() {},

    shouldComponentUpdate: function(nextProps, nextState) {
        const next = _.get(nextProps, 'field.value'),
            current = _.get(this.props, 'field.value');
        return (next !== current);
    },

    onChange(event) {
        this.props.field.onChange(event.target.value);
        this.props.updateResponse({[event.target.name]: event.target.value});
    },

    render() {
        const { field, fieldName, options, style, text} = this.props;
        return (
            <div className="table-question-block multiple-choice clearfix" style={style}>
                <div className="question"><FontAwesomeIcon icon={faAngleDoubleRight} /><FormattedMessage {...text} /></div>
                <RadioSet options={options} inline={true} labelUnder={true} fieldName={fieldName} {...field} onChange={this.onChange} />
            </div>
        );
    }
});
