/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import Actions from 'actions';
import Notify from 'components/modal/notify';
import AppSpinner from 'components/modal/app-spinner';
import AppModal from 'components/modal/app-modal';
import AppFreeTrial from 'components/headers/app-free-trial';
import AppHeader from 'containers/app-header';
import FeedbackBanner from 'containers/FeedbackBanner.container.web';
import AppFooter from 'containers/app-footer';
import { browserHistory } from 'react-router';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box } from '@mui/material';

const App = React.createClass({
    //mixins: [],
    //statics: {},
    //propTypes: {},
    //getDefaultProps() {},
    //componentWillMount() {},
    //componentWillReceiveProps(nextProps) {},
    //shouldComponentUpdate(nextProps, nextState) {},
    //componentWillUpdate(nextProps, nextState) {},
    //componentWillUnmount() {},

    componentDidMount() {
        this.checkForCompatibility();
        this.checkForMessages();
        // load plans in the background since it requires stripe which takes a long time
        const worker = () => this.props.dispatch(Actions.getPlans(this.props.auth.user.token, _.get(CUAC_SETTINGS, 'GROUP._id')));
        if(this.props.auth.user.token) {
            worker();
        } else {
            console.log("Trying to load plans before I have a token")
            const timer = setInterval(() => {
                if(this.props.auth.user.token) {
                    clearInterval(timer);
                    console.log("Now I have a token")
                    worker();
                }
            }, 1000);
        }
    },


    checkForDomain() {
        const { roles } = this.props.auth?.user ?? {},
            { admin, system } = roles ?? {},
            isAdmin = !!admin?.length || (!!system?.length && system.includes('admin'));
        if(!isAdmin && _.get(this.props.auth, 'user.roles.subscriber')) {
            if(this.props.auth.user.roles.subscriber.indexOf(CUAC_SETTINGS.GROUP._id) === -1) {
                this.props.dispatch(Actions.showModal('appModal', {
                    modalNotifications: 'wrongSubdomain',
                    modalContent: true
                }));
            }
        }
    },

    checkForMessages() {
        const messages = _.get(this.props.auth, 'user.messages');
        if(messages) {
            messages.map((message) => {
                if(!_.get(message, 'viewed')) {
                    this.props.dispatch(Actions.showModal('appModal', {
                    modalNotifications: 'importantMessage',
                    modalContent: true
                }));
                }
            })
        }
    },

    checkForCompatibility() {
        //Fake commit
        const isiOS = /(iPhone|iPod|iPad).*AppleWebKit/i.test(navigator.userAgent);
        if(isiOS && false) {
            // check for chrome
            if(navigator.userAgent.indexOf('CriOS') > -1) {
                window.location.href = 'https://checkupandchoices.com/requirements/';
            }
        }
    },

    getInitialState() {
        return {logoutTimeoutPointer: null}
    },

    componentDidUpdate(prevProps, prevState) {
        if(this.props !== prevProps) {
            if(!_.isEmpty(this.props.auth.user)) {
                // clear previous logout timeout
                if(this.state.logoutTimeoutPointer) {
                    clearTimeout(this.state.logoutTimeoutPointer);
                }
                // set up our new logout timeout
                const that = this;
                const logoutTimeoutPointer = setTimeout(function() {
                    that.props.dispatch(Actions.logOutUser()).then(() => {
                        browserHistory.push('/logout');
                    });
                }, 30*60*1000);
                // save the new pointer to the state
                this.setState({logoutTimeoutPointer: logoutTimeoutPointer});
            }
        }
        if(this.props.auth !== prevProps.auth) {
            this.checkForDomain();
            this.checkForMessages();
        }
    },

    onLogOut() {
        this.props.dispatch(Actions.logOutUser());
    },

    render() {
        const { params, route } = this.props;
        return (
            <div className="app-frame site-wrapper">
                <AppSpinner />
                <AppModal />
                <Notify />
                <AppFreeTrial params={this.props.auth.user}/>
                {
                    !window.location.href.includes('/admin') && <AppHeader params={params} />
                }
                <FeedbackBanner showGlobally={false} />
                <div className="app-body site-content">
                    <div className={!CUAC_SETTINGS.GROUP.isDisabled ? '' : 'relative'}>
                        {CUAC_SETTINGS.GROUP.isDisabled ?
                            <Box style={{ backgroundColor: 'green', padding: '1em', borderRadius: '1em', position: 'fixed', left: '50%', transform: 'translate(-50%)', marginTop: '5%' }} className='container mb-4 page-screener screener top-0 left-0 right-0 center-items'>
                                <Box style={{ backgroundColor: 'white', padding: '1em' }} className='mdl-cell mdl-cell--4-col'>
                                    <h1>Thank You for coming!</h1>
                                    <br />
                                    <p>
                                        Your organization no longer provides free access to CheckUp & Choices—a confidential, evidence-based, online program for people who want to learn more about their relationship with alcohol and change it if they choose to.
                                    </p>
                                    <p>
                                        If you want your organization to reactivate this valuable resource, please have an administrator contact us at <a href="mailto:info@checkupandchoices.com">info@checkupandchoices.com</a>.
                                    </p>
                                    <p>
                                        If you want to personally sign up for CheckUp & Choices, you can pay with your credit card and get started for as little as $34. <a href='https://checkupandchoices.com/plans/'>Click here</a> to begin.
                                    </p>
                                </Box>
                            </Box>
                        :
                            this.props.children
                        }
                    </div>

                </div>
                <AppFooter params={params} />
            </div>
        );
    }
});
export default connect(
    (state, props) => ({
        auth: state.auth
    })
)(App);
