/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import classnames from 'classnames';
import React  from 'react';
import { View } from 'react-native';
import { FormattedMessage } from 'react-intl';
import { Element } from 'components/elements/element';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { base } from 'stylesheets/index';
import { rules } from 'stylesheets/base';
import { FontAwesome } from "@expo/vector-icons";
import { BLUE } from '../../stylesheets/colors';
import FieldLabel from '../blocks/FieldLabel.component.web';

const baseClasses = {
    'radio-set': true
};

const RadioSetDynamic = React.createClass({

    propTypes: {
        options: React.PropTypes.array.isRequired,
        onChange: React.PropTypes.func.isRequired,
        vertical: React.PropTypes.bool,
        labelUnder: React.PropTypes.bool,
        className: React.PropTypes.string,
        style: React.PropTypes.object
    },

    /* ========= RENDERING ================ */
    renderOption: function(data, index) {
        const { fieldName, onChange, labelledBy, responses, value } = this.props;
        const working_value = (responses || {})[fieldName] || value;
        const outerStyles = [ base.checkBoxOptionOutter, data.value === working_value? base.checkBoxSelected: null, { 'textAlign': 'center' } ].filter(v=>v);
        // clicking the <View.inner> should toggle the checkbox
        // the stylesheets fail if the <View> becomes a <label>
        // htmlFor on <View> does nothing
        // onChange is coming from redux-form
        // onClick transforms the click event on <View> to a change on <input> 
        // Sorry
        return (
            <View key={fieldName + '-' + data.value} style={outerStyles} onClick={e => onChange(Object.assign(e, { target: document.getElementById(fieldName + '-' + data.value) }))}>
                <View style={base.checkBoxOptionInner}>
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ backgroundColor: 'white', border: data.value === working_value ? 'none' : '2px solid #C6CBCE', minHeight: 24, minWidth: 24, height: 24, width: 24, borderRadius: 12 }}
                    >
                        {
                            data.value === working_value && (
                              <FontAwesome name="check" color={BLUE} size={13} />
                            )
                        }
                    </div>
                    <input
                      type="radio"
                      className="d-none"
                      checked={data.value === working_value}
                      name={fieldName}
                      id={fieldName + '-' + data.value}
                      value={data.value} onChange={onChange}
                      aria-labelledby={fieldName + '-' + data.value + '-label'}
                    />
                    <label style={rules.checkBoxOptionLabel} key={'radio_' + index} className="radio label" id={fieldName + '-' + data.value + '-label'}>
                        
                        <span className="outer" style={data.option == 'center-label' ? {float: "none"} : null}><span className="inner" /></span>
                        <span className="label" style={data.option == 'center-label' ? {padding: 0, margin: 0} : {}}><FormattedMessage {...data.text} /></span>
                    </label>
                </View>
            </View>
        );
    },

    render: function() {
        const { options, title, error, touched, number, inline, className, vertical, labelUnder, borderTop, style, column2, column3, errorClass, labelledBy, extreme_first, extreme_last, questionText, responses, infobug } = this.props;
        const renderOption = this.renderOption;
        const classes = classnames(baseClasses, {
            'clearfix': !inline,
            'border-top': borderTop,
            'grid-column-2': column2,
            'grid-column-3': column3,
            'block': vertical,
            'label-under': labelUnder || number,
            'number': number
        }, className);

        var radioErrorClass = errorClass ? errorClass : 'radio-error-label'

        const titleEl = title ? <h5>{this.props.title}</h5> : null;
        const radioError = (error && touched) ? <div className={radioErrorClass}>{error}</div> : null;
        const optionSet = [];
        options.forEach(function(item, index){
            if(number == true) 
                item.value = parseInt(item.value);
            optionSet.push(renderOption(item, index));
        });
        let extremeStyles = {width: '14%'};
        if(optionSet.length <= 5) {
            extremeStyles = null;
        }

        return (
          <div className={extreme_first ? classes + ' with-extremes' : classes} style={style} id={this.props.fieldName}>
              {titleEl}
              {radioError}
              {extreme_first ? <span className='farLeft' style={extremeStyles}>{extreme_first}</span> : null}
              {extreme_last ? <span className='farRight' style={extremeStyles}>{extreme_last}</span> : null}
              <fieldset tabIndex="0" role="group" aria-describedby={labelledBy} aria-required={(error == "Required") ? "true" : "false"}>
                  {
                      questionText && (
                        <FieldLabel text={questionText} id={this.props.fieldName} responses={responses}/>
                      )
                  }
                  {optionSet}
              </fieldset>
          </div>
        );
    }
});

export default RadioSetDynamic;
