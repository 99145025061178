'use strict';

import _ from 'lodash';

import { createSelector } from 'reselect';

import { sectionSelector } from 'selectors/section';

export const pageSlugSelector = (state, props) => props.params.pageSlug;
const getPageFromSlug = (slug, section) => {
    for (let page of _.values(_.get(section, 'pages', {}))) {
        if (page.slug === slug) {
            return page;
        }
    }
    return {};
};

export const pageSelector = createSelector(
    pageSlugSelector,
    sectionSelector,
    (pageSlug, section) => {
        return {
            ...section,
            page: getPageFromSlug(pageSlug, section.section)
        };
    }
);