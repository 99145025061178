'use strict';

import { addTrackerEntry as addTrackerEntryAPI } from 'api/tracker-entries';
import Actions from 'actions';

import {
    REQUEST_ADD_TRACKER_ENTRY,
    REQUEST_ADD_TRACKER_ENTRY_SUCCESS,
    REQUEST_ADD_TRACKER_ENTRY_FAILURE
} from 'actions/types';

function requestAddTrackerEntry() {
    return {
        type: REQUEST_ADD_TRACKER_ENTRY
    };
}

function requestAddTrackerEntrySuccess(trackerEntryName, json) {
    return {
        type: REQUEST_ADD_TRACKER_ENTRY_SUCCESS,
        payload: {
            trackerEntryName,
            trackerEntry: json
        }
    };
}

function requestAddTrackerEntryFailure(trackerEntryName, error) {
    return {
        type: REQUEST_ADD_TRACKER_ENTRY_FAILURE,
        payload: error,
        error: true
    };
}

export default function addTrackerEntry(token, userId, data, trackerEntryName, tag) {
    return dispatch => {
        dispatch(requestAddTrackerEntry());
        return addTrackerEntryAPI(token, userId, data, trackerEntryName, tag)
            .then((json) => {
                dispatch(Actions.notify({
                    title: 'Entry Added',
                    message: 'Your entry has been added.',
                    level: 'success',
                    autoDismiss: 4
                }));
                dispatch(Actions.getTrackerEntryStats(token, userId, trackerEntryName, 1));
                return dispatch(requestAddTrackerEntrySuccess(trackerEntryName, json));
            })
            .catch((error) => {
                dispatch(requestAddTrackerEntryFailure(error));
                return Promise.reject(error);
            });
    };
}
