/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require */
"use strict";

import React from 'react';
import Actions from 'actions';
import { connect } from 'react-redux';
import NewReminderForm from 'components/forms/new-reminder-form';
import notify from 'actions/notify';
import { getReminderTypeEnum, getMessageTypeEnum } from 'utils/reminders-helper';
import Moment from 'moment'; Moment.locale('en');
import momentTZ from 'lib/moment-timezone-with-data';
const NewReminder = React.createClass({
    displayName: 'NewReminder',

    getInitialState: function() {
        const { auth } = this.props;
        const user_email = _.get(auth, 'user.email');
        const user_phoneNo = _.get(auth, 'user.phone_number');
        const user_timezone = _.get(auth, 'user.user_timezone');
        const reminder = this.props.reminder;
        var messageType = 'new';
        //Get current User Time from user's timezone.
        var initialDateTime = momentTZ.tz(new Date(), user_timezone);
        //Translating it to user time zone.
        var initialValues = {
            date_to_send: initialDateTime.format('YYYY-MM-DD'),
            time_to_send: initialDateTime.format("hh:mm A"),
            user_email: user_email,
            user_phoneNo: user_phoneNo,
            message_type: 'once',
            isNew: true
        };

        if(reminder._id) {
            messageType = 'edit';
            //Converting UTC time to local Time (user's timezone)
            var timeFormat = 'YYYY-MM-DD HH:mm:ss'
            if(this.isRecurring(reminder)) {
                timeFormat = 'HH:mm:ss';
            }
            var fullDate = Moment(reminder.message_alert.trigger_time, timeFormat).format('YYYY-MM-DD HH:mm:ss');
            var targetDateTime = momentTZ.tz(fullDate, 'UTC');
            var triggeredTime = targetDateTime.clone().tz(user_timezone);

            initialValues = {
                date_to_send: triggeredTime.format('YYYY-MM-DD'),
                time_to_send: triggeredTime.format("hh:mm A"),
                user_email: user_email,
                user_phoneNo: user_phoneNo,
                reminder_type: this.setReminderType(reminder.alert_type),
                message_type: reminder.message_alert.message_type === 1 ? 'once' : 'recurring',
                reminder_title: reminder.message_alert.message_title,
                reminder_message: reminder.message_alert.message_content,
                days_of_the_week: reminder.message_alert.recurring_days,
                isNew: false
            };
        }

        return {
            messageType: messageType,
            initialValues: initialValues
        };
    },

    triggerTime: function(dateString, timeString){
        const { auth } = this.props;
        var user_timezone = _.get(auth, 'user.user_timezone');
        if(!user_timezone) {
            user_timezone = 'UTC';
        }

        var fullDate = Moment(dateString).format().split('T')[0] + " " + timeString;
        fullDate = Moment(fullDate, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm:ss');
        var targetDateTime = momentTZ.tz(fullDate, user_timezone);

        var UTCTime = targetDateTime.clone().tz('UTC');

        return UTCTime.format('YYYY-MM-DDTHH:mm:ss');
    },

    setReminderType: function(value){
        if(value == 3){
            return ['email', 'phoneNo'];
        }

        return value === 1 ? ['email'] : ['phoneNo'];
    },

    isMessageTimePast: function(targetDate, targetTime){
        const { auth } = this.props;
        var user_timezone = _.get(auth, 'user.user_timezone');
        if(!user_timezone) {
            user_timezone = 'UTC';
        }

        var fullDate = Moment(targetDate).format().split('T')[0] + " " + targetTime;
        fullDate = Moment(fullDate, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm:ss');
        var targetDateTime = momentTZ.tz(fullDate, user_timezone);
        var currentTime = momentTZ.tz(new Date(), user_timezone);
        return (targetDateTime.format('YYYY-MM-DD HH:mm:ss') < currentTime.format('YYYY-MM-DD HH:mm:ss'));
    },

    isRecurring: function(reminder) {
        return (reminder.message_alert.message_type == 2) ? true : false;
    },

    onFormSubmit: async function(data) {
        const {auth, dispatch} = this.props;
        if(data.reminder_type.length === 0) {
            dispatch(notify({
                title: "Empty Receiver Type",
                message: "Reminder type must be either Email or Phone Number",
                level: 'warning',
                autoDismiss: 4
            }));

            return;
        }

        if(!data.message_type){
            dispatch(notify({
                title: "Invalid Occurrence",
                message: "Please select a reminder schedule type.",
                level: 'warning',
                autoDismiss: 4
            }));
            return;
        }

        if(data.message_type === 'recurring' && data.days_of_the_week.length === 0) {
            dispatch(notify({
                title: "Empty Receiver Type",
                message: "Please select at least 1 day.",
                level: 'warning',
                autoDismiss: 4
            }));
            return;
        }

        if(data.message_type === 'once' && this.isMessageTimePast(data.date_to_send, data.time_to_send)){
            dispatch(notify({
                title: "Invalid Time",
                message: "Please select a Date/Time in the future.",
                level: 'warning',
                autoDismiss: 4
            }));
            return;
        }

        var payload = {};
        var user = {
            _id: _.get(auth, 'user._id'),
            token: _.get(auth, 'user.token')
        }

        payload.alert_type = getReminderTypeEnum(data.reminder_type);

        payload.message_alert = {
            message_type: getMessageTypeEnum(data.message_type),
            message_content: data.reminder_message,
            message_title: data.reminder_title,
            trigger_time: this.triggerTime(data.date_to_send, data.time_to_send),
            recurring_days: []
        }

        if(payload.message_alert.message_type === 2) {
            _.each(data.days_of_the_week, (value, index)=>{
                value = parseInt(value);
                payload.message_alert.recurring_days.push(value);
            });
        }

        dispatch(Actions.updateDisplay('appSpinner', 'PLEASE_WAIT'));
        if(this.state.messageType === 'new'){
            await dispatch(Actions.addReminder(user._id, user.token, payload));
        }
        else {
            var reminderId = this.props.reminder._id;
            await dispatch(Actions.updateReminder(user._id, user.token, payload, reminderId));
        }
        dispatch(Actions.updateDisplay('appSpinner', 'STOP'));
        this.props.dispatch(Actions.closeModal('appModal', {
            modalNotifications: '',
            edit_reminder: {},
            modalContent: false
        }));
    },

    render: function() {
        return (
          <NewReminderForm onSubmit={this.onFormSubmit} initialValues={this.state.initialValues} />
        );
    }
});

export default connect(
    state => ({
        auth: state.auth,
        reminder: state.modal.edit_reminder
        })
)(NewReminder);
