'use strict';

import React, { findDOMNode } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { faStar as faStarO } from '@fortawesome/free-solid-svg-icons';
import TimePicker from 'react-time-picker';
import Moment from 'moment'; Moment.locale('en');
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { LongTextQuestion } from 'components/fields/long-text-question';

import InfoBug from 'components/popover/infobug';
import ButtonSpinner from 'components/ux/button-spinner';
import DateInputReduxForm from 'components/ux/input-date-redux-form';
import NumberInputReduxForm from 'components/ux/number-input-redux-form';
import { NumberQuestion } from 'components/fields/number-question';
import RadioSetReduxForm from 'components/ux/RadioGroupReduxForm.component';
import TextFieldReduxForm from 'components/ux/text-field-redux-form';

import { validator } from 'utils/tools';

const messages = defineMessages({
    "goal_tracker_expected_outcome_label": {
        id: "goal_tracker_expected_outcome_label",
        description: "What would your life look like if your rating in this area were higher?",
        defaultMessage: "What would your life look like if your rating in this area were higher?"
    },
    "goal_tracker_goal_label": {
        id: "goal_tracker_goal_label",
        description: "What is your specific goal?",
        defaultMessage: "What is your specific goal?"
    },
    "goal_tracker_actions_label": {
        id: "goal_tracker_actions_label",
        description: "What actions will your take to achieve this goal?",
        defaultMessage: "What actions will your take to achieve this goal?"
    },
    "goal_tracker_time_frame_label": {
        id: "goal_tracker_time_frame_label",
        description: "When will you take these actions?",
        defaultMessage: "When will you take these actions?"
    }
});

const options = [
    {
        "text": {
            "id": "abstinence_sobriety",
            "defaultMessage": "Abstinence/Sobriety"
        },
        "value": "abstinence"
    },
    {
        "text": {
            "id": "job_or_education_progress",
            "defaultMessage": "Job Or Education Progress"
        },
        "value": "professional"
    },
    {
        "text": {
            "id": "money_management",
            "defaultMessage": "Money Management"
        },
        "value": "money"
    },
    {
        "text": {
            "id": "social_life",
            "defaultMessage": "Social Life"
        },
        "value": "social"
    },
    {
        "text": {
            "id": "personal_life",
            "defaultMessage": "Personal Life"
        },
        "value": "personal"
    },
    {
        "text": {
            "id": "marriage_family_relationships",
            "defaultMessage": "Marriage/Family Relationships"
        },
        "value": "family"
    },
    {
        "text": {
            "id": "legal_issues",
            "defaultMessage": "Legal Issues"
        },
        "value": "legal"
    },
    {
        "text": {
            "id": "emotional_life",
            "defaultMessage": "Emotional Life"
        },
        "value": "emotional"
    },
    {
        "text": {
            "id": "communication",
            "defaultMessage": "Communication"
        },
        "value": "communication"
    },
    {
        "text": {
            "id": "eating_sleeping_or_exercise",
            "defaultMessage": "Eating, Sleeping, Or Exercise"
        },
        "value": "health"
    }
];


const GoalTracker = React.createClass({

    propTypes: {
        fields: React.PropTypes.object.isRequired,
        handleSubmit: React.PropTypes.func.isRequired
    },

    onChange(fieldName, event) {
        this.props.change(fieldName, event.target.value);
    },

    render() {
        const { handleSubmit, intl: { formatMessage }} = this.props;
        return (
                    <div className="section section-account-overview col-1-1 no-print">
                        <div className="contain-box-wrap" style={{marginTop: '52px'}}>
                            <div className="contain-box-main" >
                                <h1 className="page-title contain-box-title">
                                    <FormattedMessage
                                        id="plan_tracker_title"
                                        description="Title for the Goal Tracker form"
                                        defaultMessage="Your Goal" />
                                </h1>
                                <form id="goal_tracker" name="goal_tracker" className="stack-input" style={{margin: '0 auto 40px auto' /*, maxWidth: '290px'*/}}>
                                    <div>
                                        <label className="baseline-form" htmlFor="area">
                                            <FormattedMessage
                                                id="happiness_tracker_area_label"
                                                defaultMessage="Select an area in which you want to set a goal:"/>
                                        </label>
                                        <RadioSetReduxForm name="area"
                                            options={options}
                                            fieldName="area"
                                            vertical={true}
                                            errorClass="mood-radio-error-label"
                                            onChange={(event) => this.onChange('area', event)}/>
                                        <TextFieldReduxForm name="expected_outcome"
                                            textArea={true}
                                            floatingLabel={true}
                                            labelText={formatMessage(messages.goal_tracker_expected_outcome_label)}/>
                                        <div className="row">&nbsp;</div>
                                        <TextFieldReduxForm name="goal"
                                            floatingLabel={true}
                                            labelText={formatMessage(messages.goal_tracker_goal_label)}/>
                                        <div className="row">&nbsp;</div>
                                        <TextFieldReduxForm name="actions"
                                            textArea={true}
                                            floatingLabel={true}
                                            labelText={formatMessage(messages.goal_tracker_actions_label)} />
                                        <div className="row">&nbsp;</div>
                                        <TextFieldReduxForm name="time_frame"
                                            textArea={true}
                                            floatingLabel={true}
                                            labelText={formatMessage(messages.goal_tracker_time_frame_label)} />
                                        <div className="row">&nbsp;</div>
                                        <div className="row">&nbsp;</div>
                                        <ButtonSpinner spinner={this.props.isFetching}
                                            onClick={handleSubmit} ripple={true} raised={true} colored={true} className="height-auto" >
                                                <FontAwesomeIcon icon={faStarO}/>
                                                <FormattedMessage id="goal_tracker_button_save"
                                                    description="Submit the Goal tracker form to save an Goal"
                                                    defaultMessage="Save" />
                                        </ButtonSpinner>
                                    </div>
                                    <div className="clearfix" />
                                </form>
                            </div>
                        </div>
                    </div>
        );
    }
});

const formFields = ['area', 'expected_outcome', 'goal', 'actions', 'time_frame'];
let form = reduxForm({
    form: 'goalTracker',
    fields: formFields,
    validate: validator ({
        'area': { required: 'Required' },
        'expected_outcome': { required: 'Required' },
        'goal': { required: 'Required' },
        'actions': { required: 'Required' },
        'time_frame': { required: 'Required' }
    })
})(GoalTracker);

const selector = formValueSelector('goalTracker') // <-- same as form name
form = connect(state => {
    return formFields.reduce((dict, fieldName) => Object.assign({ [fieldName]: selector(state, fieldName) }, dict), {});
})(form);
export default injectIntl(form);