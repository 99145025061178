'use strict';

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import Actions from 'actions';
import { TrackerTypes } from 'api/tracker-entries';
import MoodTracker from 'components/forms/mood-tracker';
import momentTZ from 'lib/moment-timezone-with-data';
import Moment from 'moment'; Moment.locale('en');

const MoodTrackerModal = React.createClass({

    propTypes: {
        auth: React.PropTypes.object.isRequired,
        dispatch: React.PropTypes.func.isRequired
    },

    getInitialState: function() {
        const { auth } = this.props;

        var moodEntry = this.props.moodEntry || {};
        var isNew = moodEntry._id === undefined;

        const user_timezone = _.get(auth, 'user.user_timezone');
        const initialDateTime = momentTZ.tz(new Date(), user_timezone);

        var initialValues = {
            dt_occurred: initialDateTime.format('YYYY-MM-DD'),
            tm_occurred: initialDateTime.format('hh:mm A')
        };

        if(!isNew){
            const { data: {
                dt_occurred,
                interested,
                distressed,
                excited,
                upset,
                strong,
                guilty,
                scared,
                hostile,
                enthusiastic,
                proud,
                irritable,
                alert,
                ashamed,
                inspired,
                nervous,
                determined,
                attentive,
                jittery,
                active,
                afraid
            } } = this.props.moodEntry;

            var tmOccurred = moment(dt_occurred.split('+')[0]).format('YYYY-MM-DD HH:mm:ss');
            tmOccurred = momentTZ.tz(tmOccurred, 'UTC');
            var userTzTmOccurred = tmOccurred.clone().tz(user_timezone);

            initialValues = {
                dt_occurred: userTzTmOccurred.format('YYYY-MM-DD'),
                tm_occurred: userTzTmOccurred.format('hh:mm A'),
                interested,
                distressed,
                excited,
                upset,
                strong,
                guilty,
                scared,
                hostile,
                enthusiastic,
                proud,
                irritable,
                alert,
                ashamed,
                inspired,
                nervous,
                determined,
                attentive,
                jittery,
                active,
                afraid
            }
        }
        return {
            isNew: isNew,
            initialValues: initialValues
        };
    },

    closeModal() {
        this.props.dispatch(Actions.closeModal('appModal', {
            modalNotifications: '',
            modalContent: false
        }));
    },

    addMood(token, userId, data, trackerType) {
        const { dispatch } = this.props;
        dispatch(Actions.addTrackerEntry(token, userId, data, trackerType))
        .then(() => {
            this.closeModal();
        });
    },

    updateMood(token, userId, data, trackerId) {
        const { dispatch } = this.props;
        dispatch(Actions.updateTrackerEntry(token , userId, trackerId, data))
        .then(() => {
            this.closeModal();
        });
    },

    onAltClick() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'newMoodTracker',
            modalContent: true
        }));
    },

    onFormSubmit(data) {
        const { auth, dispatch, moodEntry } = this.props;
        const token = _.get(auth, 'user.token');
        const userId = _.get(auth, 'user._id');
        const user_timezone = _.get(auth, 'user.user_timezone');

        var dtOccurred = data.dt_occurred.split('T')[0] + ' ' + data.tm_occurred;
        dtOccurred = Moment(dtOccurred, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm:ss');
        dtOccurred = momentTZ.tz(dtOccurred, user_timezone); //currently it is in NY.
        var utcDtOccurred = dtOccurred.clone().tz('UTC');

        var payload = {
            "active": data.active,
            "afraid": data.afraid,
            "alert": data.alert,
            "ashamed": data.ashamed,
            "attentive": data.attentive,
            "determined": data.determined,
            "distressed": data.distressed,
            "dt_occurred": utcDtOccurred.format('YYYY-MM-DDTHH:mm:ss'),
            "enthusiastic": data.enthusiastic,
            "excited": data.excited,
            "guilty": data.guilty,
            "hostile": data.hostile,
            "inspired": data.inspired,
            "interested": data.interested,
            "irritable": data.irritable,
            "jittery": data.jittery,
            "negative_score": parseInt(data.negative_score),
            "nervous": data.nervous,
            "positive_score": parseInt(data.positive_score),
            "proud": data.proud,
            "scared": data.scared,
            "strong": data.strong,
            "upset": data.upset
        };

        if(this.state.isNew) {
            this.addMood(token, userId, payload, TrackerTypes.MOOD);
        }
        else {
            this.updateMood(token, userId, payload, moodEntry._id);
        }
    },

    render() {
        const { trackerEntries } = this.props;
        const isFetching = _.get(trackerEntries, 'isFetching');

        return (
            <MoodTracker onSubmit={this.onFormSubmit} onClick={this.onAltClick} isFetching={isFetching} initialValues={this.state.initialValues} />
        );
    }
});

export default connect(
    state => ({
        auth: state.auth,
        trackerEntries: state.trackerEntries,
        moodEntry: state.modal.moodEntry
    })
)(MoodTrackerModal);
