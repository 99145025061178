'use strict';

import { getTermsById as getTermsAPI } from 'api/terms';

import {
    REQUEST_GET_TERMS,
    REQUEST_GET_TERMS_SUCCESS,
    REQUEST_GET_TERMS_FAILURE
} from 'actions/types';

function requestGetTerms() {
    return {
        type: REQUEST_GET_TERMS
    };
}

function requestGetTermsSuccess(json) {
    return {
        type: REQUEST_GET_TERMS_SUCCESS,
        payload: {
            terms: json
        }
    };
}

function requestGetTermsFailure(error) {
    return {
        type: REQUEST_GET_TERMS_FAILURE,
        payload: error,
        error: true
    };
}

export default function getTerms(termsId) {
    return dispatch => {
        dispatch(requestGetTerms());
        return getTermsAPI(termsId)
            .then((json) => {
                return dispatch(requestGetTermsSuccess(json));
            })
            .catch((error) => {
                return dispatch(requestGetTermsFailure(error));
            });
    };
}
