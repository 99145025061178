/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require, self, goToUrl */
"use strict";

// ========= LIBRARY COMPONENTS =========
import React from 'react';
import Tools from 'utils/tools';

// ======= APP COMPONENTS ===============
let rawComponents = {
        Button: require('components/ux/mdl/button'),
        Switch: require('components/ux/switch'),
        Table: require('components/ux/mdl/table'),
        TextField: require('components/ux/mdl/text-field'),

        InfoBug: require('components/popover/infobug'),
        ButtonSpinner: require('components/ux/button-spinner'),
        RadioSet: require('components/ux/radio-set'),
        CheckboxSet: require('components/ux/checkbox-set')
    },


    componentFactory = {
        // RegisterForm: React.createFactory(rawComponents.register_form)
    };

/* ------------- OVERVIEW ---------------------------------------

    React.createElement(elementType, props, children);

    https://facebook.github.io/react/docs/top-level-api.html#react.createelement

    -----

    The methods here allow us to feed in pure JSON descriptions of a page structure.
    Like so....

        ["div", {"className": "_instructions"},[
            ["span", null, "Lorem ipsum wic soleius commonplace digerat."],
            ["InfoBug", null, [
                    [ "h5", null, "Info Bug Title"],
                    [ "p", null, "You can be the owner of a proud infoBug!" ],
                    [ "Button", { "action": "_someRandomAction"}, "Sign me up!" ]
                ]
            ],
            [ "CheckboxSet", {
                "checkboxID": "_testBoxes02",
                "checkboxData": [
                            { "label": "AWSumm!", "value": "_awesome"},
                            { "label": "SWeet!", "value": "_sweet"},
                            { "label": "KaBOOM!", "value": "_nice"},
                            { "label": "Kooll!", "value": "_good"}
                    ]
                }
            ]
        ]

    The structure might seem complicated.  Hopefully not.  The general idea is
     -- Each level is built of 3 items in an array
     -- item[0] must be a string (Element Type) or an array
     -- item[1] must be an object or null
     -- item[2] must be a string or an array

    item[0] -- Element Type -- Things to take note of:
    The Element Type must be a normal HTML entity like "div", "span", "p"
        --- OR ---
    The Element Type must be listed above in the "rawComponents" object.



   -------------------------------------------------------------- */
/*
function n_(str){
    if (typeof str !== 'string') { return str; }
    if (str.charAt(0) === '_') { return str.slice(1); }
    return str;
}
*/

function renderError(type, comp) {
    console.error('--- RenderJSON [ ' + type + ' ] ------');
    return renderJSON(["div", {"className": "DATA-ERROR", "key": "data-error" }, "DATA ERROR [ " + type + " ]  -- SEE CONSOLE"]);
}

function renderChildren(childArray){
    let count = 0;
    return childArray.map(function(internal){
        count++;
        internal[1] = internal[1] || {};
        internal[1].key = internal[1].key || internal[0] + count;
        return renderJSON(internal);  // jshint ignore: line
    });
}

function renderChild(childComp){
    return !childComp ? null :
            typeof childComp === 'string' ? childComp.replace(/  /g, '\u00a0\u00a0') :
                typeof childComp === 'number' ? childComp :
                    typeof childComp === 'boolean' ? childComp :
                        !Array.isArray(childComp) ? renderError('secondary', childComp) :
                            typeof childComp[0] === 'string' ? renderJSON(childComp) :
                                !Array.isArray(childComp[0]) ?  renderError('tertiary', childComp[0]) :
                                    renderChildren(childComp);
}


function renderJSON(comp) {
    if (!Array.isArray(comp)) { return renderError('top', comp); }

    let type = comp[0];

    if (typeof type !== 'string') { return renderChild(comp); }

    function illegalElement(el) {
        let legalElements = [
            'div', 'span', 'ul', 'ol', 'li', 'p', 'i', 'strong', 'em',
            'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7', 'h8', 'h9'
        ];

        return legalElements.indexOf(el) === -1;
    }

    if (rawComponents[type]){
        if (!componentFactory[type]) {
            componentFactory[type] =  React.createFactory(rawComponents[type]);
        }

        comp[1] = comp[1] || {};
        comp[1].renderJSON = renderJSON;
        let thisFactory = componentFactory[comp[0]];
        
        return thisFactory(
            comp[1],  // props
            renderChild(comp[2]) // children
        );
    } else if (illegalElement(type)) {
        return renderError('unregistered component', comp);
    }


    return React.createElement(
        comp[0], // element type ("div", "p", "span", etc...)
        comp[1], // props
        renderChild(comp[2]) // children
    );
}


export default renderJSON;