/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import RadioSet from 'components/ux/radio-set-dynamic';

export const MultipleChoiceQuestion = React.createClass({

    propTypes: {
        field: React.PropTypes.object.isRequired,
        fieldName: React.PropTypes.string.isRequired,
        options: React.PropTypes.arrayOf(React.PropTypes.object),
       // options_data_key: React.PropTypes.string,
        responses: React.PropTypes.object,
        style: React.PropTypes.object,
        text: React.PropTypes.object.isRequired,
        updateResponse: React.PropTypes.func.isRequired
    },

    shouldComponentUpdate: function(nextProps) {
        const next = _.get(nextProps, 'field.value'),
            current = _.get(this.props, 'field.value');
        if (nextProps.hasInterpolation && !_.isEqual(this.props.responses, nextProps.responses)) return true;
        return (next !== current);
    },

    onChange(event) {
        if(this.props.field.onChange) this.props.field.onChange(event.target.value);
        this.props.updateResponse({[event.target.name]: event.target.value});
        this.props.prepopulateAnswers(event.target.value);
    },

    render() {
        const { field, fieldName, options, options_data_key, responses, style, text, infobug, answer_options, answer_option_key, userProfile} = this.props;
        let passedOptions = options;

        if (typeof options_data_key !== 'undefined') {
            passedOptions = [];
 
            if (Array.isArray(options_data_key)) {
                for(var i = 0; i < options_data_key.length; i++) {
                    const value = _.get(responses, options_data_key[i], "");
                    passedOptions.push({
                        text: {id: options_data_key[i], defaultMessage: value},
                        value: value
                    });
                }
            } else {
                _.get(responses, options_data_key, [])
                    .map((value) => {
                        passedOptions.push({
                            text: {id: options_data_key, defaultMessage: value},
                            value: value
                        });
                    })
            }
        } 
        else if (typeof answer_options !== 'undefined') {
            const answer = _.get(responses, answer_option_key);
            if (answer) {
                passedOptions = answer_options[answer];
            }
        }

        return (
            <div className="question-block multiple-choice" style={style}>
                <RadioSet labelledBy={fieldName+"-label"} questionText={text} responses={responses} options={passedOptions} vertical={true} fieldName={fieldName} {...field} onChange={this.onChange} infobug={infobug}/>
            </div>
        );
    }
});
