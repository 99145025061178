'use strict';

import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Actions from 'actions';
import { TrackerTypes } from 'api/tracker-entries';
import Moment from 'moment'; Moment.locale('en');
import momentTZ from 'lib/moment-timezone-with-data';
import AgreementTracker from 'components/forms/agreement-tracker';

const AgreementTrackerModal = React.createClass({

    getInitialState() {
        const { auth } = this.props;
        const user_timezone = _.get(auth, 'user.user_timezone');
        const initialDateTime = momentTZ.tz(new Date(), user_timezone);
        const agreementEntry = this.props.agreementEntry || {};
        var isNew = true;

        var initialValues = {
        };

        if(agreementEntry._id) {
            var isNew = false;

            const entry = agreementEntry.entry;

            var start_date = entry.dt_start_date.split('T')[0];
            start_date = Moment(start_date, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm:ss');
            start_date = momentTZ.tz(start_date, user_timezone); //currently it is in NY.
            //convert it to UTC.
            var utcDtStartDate = start_date.clone().tz('UTC');

            initialValues = {
                title: entry.title,
                dt_start_date: utcDtStartDate.format('YYYY-MM-DD'),//YYYY-MM-DD
                how_long: entry.how_long
            };
        }
        return {
            isNew: isNew,
            initialValues: initialValues
        }
    },

    addAgreement(token, userId, data) {
        const { dispatch } = this.props;
        dispatch(Actions.addTrackerEntry(token, userId, data, TrackerTypes.AGREEMENT))
        .then(() => {
            this.closeModal();
        });
    },

    editAgreement(token, userId, data, trackerEntryId) {
        const { dispatch, urgeEntry } = this.props;
        dispatch(Actions.updateTrackerEntry(token , userId, trackerEntryId, data))
        .then(() => {
            this.closeModal();
        });
    },

    closeModal() {
        this.props.dispatch(Actions.closeModal('appModal', {
            modalNotifications: '',
            modalContent: false
        }));
    },

    onAltClick() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'newAgreementTracker',
            modalContent: true
        }));
    },

    onFormSubmit(data) {
        const { auth, dispatch } = this.props;
        const token = _.get(auth, 'user.token');
        const userId = _.get(auth, 'user._id');
        const user_timezone = _.get(auth, 'user.user_timezone');

        var start_date = data.dt_start_date.split('T')[0];
        start_date = Moment(start_date, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm:ss');
        start_date = momentTZ.tz(start_date, user_timezone); //currently it is in NY.
        //convert it to UTC.
        var utcDtStartDate = start_date.clone().tz('UTC');

        var payload = {
            "title": data.title,
            "dt_start_date": utcDtStartDate.format('YYYY-MM-DDTHH:mm:ss'),
            "how_long": data.how_long,
        };
        if(this.state.isNew){
            this.addAgreement(token, userId, payload, TrackerTypes.AGREEMENT);
        }
        else {
            this.editAgreement(token, userId, payload, this.props.agreementEntry._id);
        }
    },


    render() {
        const { trackerEntries } = this.props;
        const isFetching = _.get(trackerEntries, 'isFetching');

        return (
            <div className="container">
                <AgreementTracker onSubmit={this.onFormSubmit} onClick={this.onAltClick} isFetching={isFetching} initialValues={this.state.initialValues} />
            </div>
        );
    }
})

export default connect(
    state => ({
        auth: state.auth,
        trackerEntries: state.trackerEntries,
        agreementEntry: state.modal.agreementEntry
    })
)(AgreementTrackerModal);
