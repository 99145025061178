/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import _ from 'lodash';
import React from 'react';
import Link from 'components/ux/linker';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import Actions from 'actions';
import Selectors from 'selectors';
const { sectionSelector, userNormsSelector, userProfileSelector } = Selectors;

import Button from 'components/ux/mdl/button';
import ProgressBar from 'components/elements/progress-bar';

import { Block } from 'components/blocks/block';
import { faPrint, faAdjust, faBolt, faWineGlassAlt as faGlass, faThList, faComment as faCommentingO, faBell as faBellO, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import ToolTOC from '../components/ToolTOC/ToolTOC.container';
import ToolLayout from '../components/blocks/ToolLayout.component.web';


export const SectionOverviewPage = React.createClass({

    propTypes: {
        tool: React.PropTypes.object.isRequired,
        module: React.PropTypes.object.isRequired,
        section: React.PropTypes.object.isRequired
    },

    showRemindersModal() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'newReminder',
            edit_reminder: {},
            modalContent: true
        }));
    },

    showHelpFeedbackModal() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'helpFeedback',
            modalContent: true
        }));
    },

    progressBar(pageProgress) {
        const { question_count, response_count } = pageProgress || {};
        if (typeof question_count !== 'number') return null;
        const progress = typeof question_count === 'number' && !!question_count
          ? Math.round((((response_count || 0) / question_count) * 100))
          : null;
        if (!progress) return null;
        return (
          <div className="ms-4 text-x-small text-uppercase progress-bar-single">
              <span>{progress}% Completed</span>
              <div className="progress">
                  <div className="progress-bar"
                       role="progressbar"
                       style={{ width: `${progress}%`}}
                       aria-valuenow="25"
                       aria-valuemin="0"
                       aria-valuemax="100"
                  />
              </div>
          </div>
        )
    },

    render() {
        const { activeResponse, module, norms, section, tool, userProfile } = this.props;
        const components = _.get(section, 'components', []);
        const progress = _.get(activeResponse, 'progress', {});

        return (
          <ToolLayout tool={this.props.tool} activeResponse={this.props.activeResponse}>
            <h3 className="card-title fw-bold">
              <FormattedMessage {...section.long_title} />
            </h3>
            {
              components.map((component, index) => {
                return (
                  <Block
                    key={'block_' + index}
                    component={component}
                    index={index}
                    norms={norms}
                    responses={_.get(activeResponse, 'responses', {})}
                    userProfile={userProfile}
                  />
                );
              })
            }
            <div className="card" style={{ border: 'none' }}>
              <div className="card-body">
                <ul className="list-group list-group-flush checkup-items">
                  {
                    section.order.map((pageId) => {
                      const page = section.pages[pageId];
                      const pageProgress = _.get(progress, 'modules.' + module.id + '.sections.' + section.id + '.pages.' + pageId),
                        responses = _.get(pageProgress, 'response_count', 0),
                        navClass = "nav-block" + (responses ? " in-progress": ""),
                        pageLink = "nav-link section-to-page" + (responses ? " in-progress": "");

                      return (
                        <li className={`m-0 p-0 list-group-item d-flex align-items-center ${navClass}`} key={pageId}>
                          <Link className={`text-dark flex-grow-1 d-flex align-items-center ${pageLink}`} to={'/' + tool.slug + '/' + module.slug + '/' + section.slug  + '/' + page.slug} href="#">
                            <div className="flex-grow-1">
                              <FormattedMessage {...page.long_title} />
                            </div>
                            { this.progressBar(pageProgress) }
                          </Link>
                        </li>
                      );
                    })
                  }
                </ul>
              </div>
            </div>
          </ToolLayout>
        )
    }
});

export default connect(
    (state, props) => ({
        auth: state.auth,
        activeResponseId: state.responses.activeResponseId,
        activeResponse: _.get(state.responses[state.responses.activeResponseId], 'response'),
        ...sectionSelector(state, props),
        ...userNormsSelector(state),
        ...userProfileSelector(state)
    })
)(SectionOverviewPage);
