/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require, componentHandler, PubSub, Topics */
'use strict';

const React = require('react'),
    Tools = require('utils/tools'),
    classnames = require('classnames');

const baseClasses = {
    'radio-set': true
};

var RadioSet = React.createClass({
    displayName: 'RadioSet',

    propTypes: {
        radioData: React.PropTypes.array.isRequired,
        onSelect: React.PropTypes.func,
        selected: React.PropTypes.number,
        vertical: React.PropTypes.bool,
        labelUnder: React.PropTypes.bool,
        className: React.PropTypes.string,
        style: React.PropTypes.object
    },

    getInitialState: function(){
        let checked = -1;
        if (this.props.hasOwnProperty('selected')) {
           checked = Number(this.props.selected);
        }
        return {
            originalChecked: this.props.selected,
            checked: checked
        };
    },

    UNSAFE_componentWillReceiveProps: function(nextProps){
        if (nextProps.selected !== this.state.originalChecked){
            this.setState({
                originalChecked: nextProps.selected,
                checked: nextProps.selected
            });
        }
    },

    /* ========= LOCAL METHODS ============ */

    onSelect: function(event){
        this.setState({checked: event.target.getAttribute('data-radio-index') });
        if (this.props.onSelect) {
            this.props.onSelect(this.props.radioID, event.target.value);
        }
    },

    /* ========= RENDERING ================ */
    renderOption: function(data, index) {
        let context = this;
        // rand = Tools.randomString(10);
        return (
            <div style={{ position: "relative", display: "flex", flexDirection: "column", minWidth: 0, wordWwrap: "break-word", backgroundColor: "#fff", backgroundClip: "border-box", border: "1px solid rgba(0,0,0,.125)", borderRadius: ".25rem" }}>
            <label key={'radio_' + index} className="radio">
                <input value={data.value} type="checkbox" data-radio-index={index} name="radios" checked={index === +context.props.selected} onChange={context.onSelect}/>
                <span className="outer"><span className="inner" /></span>
                <span className="label">{data.label}</span>
            </label>
            </div>
        );
    },

    render: function(){
        let context = this;
        let { radioData, title, isInvalid, radioError, className, vertical, labelUnder, borderTop, style, column2, column3, inline } = this.props;

        let classes = classnames(baseClasses, {
            'clearfix': !inline,
            'border-top': borderTop,
            'grid-column-2': column2,
            'grid-column-3': column3,
            'block': vertical,
            'label-under': labelUnder
        }, className);

        let optionSet = [];

        let titleEl = title ? <h5>{this.props.title}</h5> : null;

        let error = (isInvalid) ? <div className="radio-error-label">{radioError}</div> : null;

        radioData.forEach(function(item, index){
            optionSet.push(context.renderOption(item, index));
        });

        return <div className={classes} style={style}>
                {titleEl}
                {error}
                <fieldset role="radiogroup" aria-required={(error == "Required") ? "true" : "false"}>{optionSet}</fieldset>
        </div>;
    }

});

export default RadioSet;
export { RadioSet };