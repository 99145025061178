'use strict';

import { addUnregisteredUser as addUnregisteredUserAPI } from 'api/users';
import notify from 'actions/notify';

import {
    REQUEST_ADD_UNREGISTERED_USER,
    REQUEST_ADD_UNREGISTERED_USER_SUCCESS,
    REQUEST_ADD_UNREGISTERED_USER_FAILURE

} from 'actions/types';

function requestAddUnregisteredUser() {
    return {
        type: REQUEST_ADD_UNREGISTERED_USER
    };
}

function requestAddUnregisteredUserSuccess(json) {
    return {
        type: REQUEST_ADD_UNREGISTERED_USER_SUCCESS,
        payload: {
            users: json
        }
    };
}

function requestAddUnregisteredUserFailure(error) {
    return {
        type: REQUEST_ADD_UNREGISTERED_USER_FAILURE,
        payload: error,
        error: true
    };
}

export default function addUnregisteredUser() {
    return dispatch => {
        dispatch(requestAddUnregisteredUser());
        return addUnregisteredUserAPI()
        .then((json) => {
            return dispatch(requestAddUnregisteredUserSuccess(json));
        })
        .catch((error) => {
            return dispatch(requestAddUnregisteredUserFailure(error));
        });
    };
}
