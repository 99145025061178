'use strict';

import { UPDATE_DISPLAY } from 'actions/types';

export default function updateDisplay(name, data) {
    let delivery = { };
    delivery[name] = data;
    return {
        type: UPDATE_DISPLAY,
        payload: delivery
    };
}