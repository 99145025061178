import React from 'react';
import {Spinner} from 'react-bootstrap';

type Props = {
    loading: boolean;
    text?: string|React.ReactNode;
    variant?: string;
    animation?: 'border'|'grow';
}

const Loading = (props: Props) => {

    if (!props.loading) return null;

    return (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <Spinner variant={props.variant || 'info'} animation={props.animation || 'border'} role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            { props.text }
        </div>
    )
}

export default Loading;
