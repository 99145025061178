/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, Stripe */
'use strict';

import React from 'react';

import { Block } from 'components/blocks/block';
import CompletionNotice from 'components/elements/completion-notice';

export const StringSwitch = React.createClass({

    propTypes: {
        addTrackerEntry: React.PropTypes.func.isRequired,
        options: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
        responses: React.PropTypes.object.isRequired,
        trackerEntries: React.PropTypes.object.isRequired,
        updateResponse: React.PropTypes.func.isRequired
    },

    render() {
        const { addTrackerEntry, norms, options, data_key, responses, trackerEntries, updateResponse, userProfile, required_responses, match_type } = this.props;
        const mergedResponses = {
            ...userProfile,
            ...responses
        };

        var result = false;

        if(match_type === 'in_array') {
            result = {}
            result.components = []
            const response = _.get(responses, data_key, [])
            for (var i = 0; i < options.length; i++) {
                let hasMatch = false
                for (var j = 0; j < options[i].match.length; j++) {
                    if(response.indexOf(options[i].match[j]) > -1) {
                        hasMatch = true 
                    } 
                }
                if(hasMatch) {
                    options[i].components.map((component) => {
                        result.components.push(component) 
                    })
                }
            }
        } else if(match_type === 'match_array') {
            const response = _.get(responses, data_key, [])
            // go through all of our options
            for (var i = 0; i < options.length; i++) {
                let matchesAll = true;
                // go through all the matches in each option
                for (var j = 0; j < options[i].match.length; j++) {
                    let hasMatch = false;
                    // go through each match and if any doesn't match, move on
                    for (var k = 0; k < options[i].match[j].length; k++) {
                        if(response.indexOf(options[i].match[j][k]) > -1) {
                            hasMatch = true 
                        }
                    }
                    if(!hasMatch) {
                        matchesAll = false;
                        break;
                    }
                }
                if(matchesAll) {
                    result = options[i];
                    break;
                }
            }
        } else {
            for (var i = 0; i < options.length; i++) {
                if (options[i].match == responses[data_key]) {
                    result = options[i];
                    break;
                }
            }
        }

        
        return (
            <div className="conditional-options">
                {required_responses ? <div className="conditional-feedback"><CompletionNotice { ...this.props } /></div> : null}
                {result ? result.components.map((component, index) => {
                    return (
                        <Block
                            key={'block_string_switch_' + index}
                            component={component}
                            index={index}
                            norms={norms}
                            responses={responses}
                            userProfile={userProfile}
                            updateResponse={updateResponse}
                            addTrackerEntry={addTrackerEntry}
                            trackerEntries={trackerEntries}
                        />
                    );
                }) : null}
            </div>
        );
    }
});
