'use strict';

import { requestPasswordReset as requestPasswordResetAPI } from 'api/password-reset-requests';
import notify from 'actions/notify';

import {
    REQUEST_REQUEST_PASSWORD_RESET,
    REQUEST_REQUEST_PASSWORD_RESET_SUCCESS,
    REQUEST_REQUEST_PASSWORD_RESET_FAILURE
} from 'actions/types';

function requestRequestPasswordReset() {
    return {
        type: REQUEST_REQUEST_PASSWORD_RESET
    };
}

function requestRequestPasswordResetSuccess(json) {
    return {
        type: REQUEST_REQUEST_PASSWORD_RESET_SUCCESS
    };
}

function requestRequestPasswordResetFailure(error) {
    return {
        type: REQUEST_REQUEST_PASSWORD_RESET_FAILURE,
        payload: error,
        error: true
    };
}

export default function requestPasswordReset(email) {
    return dispatch => {
        dispatch(requestRequestPasswordReset());
        return requestPasswordResetAPI(email, CUAC_SETTINGS.GROUP.code)
            .then((json) => {
                return dispatch(requestRequestPasswordResetSuccess());
            })
            .catch((error) => {
                dispatch(notify({
                    title: "Network Error",
                    message: "Your request didn't send properly.  Please try again in a few minutes.",
                    level: 'error',
                    autoDismiss: 0
                }));
                return dispatch(requestRequestPasswordResetFailure(error));
            });
    };
}