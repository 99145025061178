/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import React from 'react';
import { RadioGroup } from '../ux/RadioGroupReduxForm.component.web';
import FieldLabel from '../blocks/FieldLabel.component.web';

export const MultipleChoiceNumberQuestion = React.createClass({
    propTypes: {
        field: React.PropTypes.object.isRequired,
        fieldName: React.PropTypes.string.isRequired,
        options: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
        style: React.PropTypes.object,
        text: React.PropTypes.object.isRequired,
        updateResponse: React.PropTypes.func.isRequired,
        fieldLabel: React.PropTypes.node,
    },

    onChange(event) {
        if(this.props.field.onChange) this.props.field.onChange(event.target.value);
        this.props.updateResponse({[this.props.fieldName]: event.target.value});
        this.props.field.value = event.target.value;
    },

    render() {
        const { fieldName, options, style, text, extreme_first, extreme_last} = this.props;
        let field = this.props.field;
        field.value = parseInt(field.value);
        return (
          <div className="question-block multiple-choice d-flex flex-column justify-content-center align-items-center" style={style}>
              {
                  text && (
                    <FieldLabel text={text} id={fieldName} responses={this.props.responses}/>
                  )
              }
              <RadioGroup
                name={fieldName}
                onChange={this.onChange}
                options={options}
                value={field.value}
                fieldsetClassName="mt-3 d-flex flex-wrap align-items-center"
                fieldContainerClassName={`d-flex flex-column align-items-center ${extreme_last || extreme_first ? 'mx-1 mx-md-2' : 'me-1 me-md-2'}`}
                extreme_first={extreme_first}
                extreme_last={extreme_last}
              />
          </div>
        );
    }
});
