/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Form } from 'react-bootstrap';

export const Checkbox = React.createClass({

    propTypes: {
        field: React.PropTypes.object.isRequired,
        fieldName: React.PropTypes.string.isRequired,
        style: React.PropTypes.object,
        text: React.PropTypes.object.isRequired,
        updateResponse: React.PropTypes.func.isRequired
    },

    shouldComponentUpdate: function(nextProps, nextState) {
        const next = _.get(nextProps, 'field.value'),
            current = _.get(this.props, 'field.value');
        return (next !== current);
    },

    onChange(event) {
        this.props.updateResponse({ [this.props.fieldName]: event.target.checked });
    },

    render() {
        const { fieldName, text, responses} = this.props;

        return (
          <Form.Check
            inline
            type="checkbox"
            id={fieldName}
            label={<FormattedMessage {...text} />}
            name={fieldName}
            checked={!!responses[fieldName]}
            onChange={this.onChange}
          />
        )
    }
});
