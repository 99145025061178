'use strict';

import _ from 'lodash';
import { deactivateUser as deactivateUserAPI } from 'api/users';
import notify from 'actions/notify';

import {
    REQUEST_DEACTIVATE_USER,
    REQUEST_DEACTIVATE_USER_SUCCESS,
    REQUEST_DEACTIVATE_USER_FAILURE
} from 'actions/types';

function requestDeactivateUser(user) {
    return {
        type: REQUEST_DEACTIVATE_USER,
        payload: { user }
    };
}

function requestDeactivateUserSuccess(user) {
    return {
        type: REQUEST_DEACTIVATE_USER_SUCCESS,
        payload: { user }
    };
}

function requestDeactivateUserFailure(error) {
    return {
        type: REQUEST_DEACTIVATE_USER_FAILURE,
        payload: error,
        error: true
    };
}

export default function deactivateUser(token, userId, data) {
    return dispatch => {
        dispatch(requestDeactivateUser());
        return deactivateUserAPI(token, userId, data)
            .then((json) => {
                dispatch(notify({
                    title: "Account Closed",
                    message: "Account Closed",
                    level: 'success'
                }));

                // window.dataLayer.push({
                //     'event': 'userUpdated',
                //     'userId': userId,
                //     'groupCode': CUAC_SETTINGS.GROUP.code,
                //     ...trackedUpdates
                // });
                return dispatch(requestDeactivateUserSuccess(json));
            })
            .catch((error) => {
                dispatch(notify({
                    title: "Network Error",
                    message: "Account unable to be closed, please try again.",
                    level: 'error',
                    autoDismiss: 0
                }));
                return dispatch(requestDeactivateUserFailure(error));
            });
    };
}