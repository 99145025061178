'use strict';

import { CLOSE_MODAL } from 'actions/types';

export default function closeModal(name, data) {
    return {
        type: CLOSE_MODAL,
        payload: data
    };
}
