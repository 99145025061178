'use strict';

import { getPasswordResetByResetKey as getPasswordResetByResetKeyAPI } from 'api/password-resets';

import {
    REQUEST_GET_PASSWORD_RESET,
    REQUEST_GET_PASSWORD_RESET_SUCCESS,
    REQUEST_GET_PASSWORD_RESET_FAILURE,
} from 'actions/types';

function requestGetPasswordReset() {
    return {
        type: REQUEST_GET_PASSWORD_RESET
    };
}

function requestGetPasswordResetSuccess() {
    return {
        type: REQUEST_GET_PASSWORD_RESET_SUCCESS
    };
}

function requestGetPasswordResetFailure(error) {
    return {
        type: REQUEST_GET_PASSWORD_RESET_FAILURE,
        payload: error,
        error: true
    };
}

export default function getPasswordReset(resetKey) {
    return dispatch => {
        dispatch(requestGetPasswordReset());
        return getPasswordResetByResetKeyAPI(resetKey)
            .then(() => {
                return dispatch(requestGetPasswordResetSuccess());
            })
            .catch((error) => {
                return dispatch(requestGetPasswordResetFailure(error));
            });
    };
}