'use strict';

import { handleResponse } from 'utils/api';

export const getPasswordResetByResetKey = (resetKey) => {
    return fetch(
        CUAC_SETTINGS.API_ROOT + '/password-resets/' + resetKey,
        {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
        .then(handleResponse);
};

export const resetPassword = (resetKey, password) => {
    return fetch(
        CUAC_SETTINGS.API_ROOT + '/password-resets/' + resetKey,
        {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                password
            })
        }
    )
        .then(handleResponse);
};
