'use strict';

import {
    CLEAR_SELECTED_PLAN,
    DEVELOP_HACK_USER,
    REQUEST_ADD_UNREGISTERED_USER,
    REQUEST_ADD_UNREGISTERED_USER_SUCCESS,
    REQUEST_ADD_UNREGISTERED_USER_FAILURE,
    REQUEST_CHANGE_PASSWORD,
    REQUEST_CHANGE_PASSWORD_SUCCESS,
    REQUEST_CHANGE_PASSWORD_FAILURE,
    REQUEST_GET_PASSWORD_RESET,
    REQUEST_GET_PASSWORD_RESET_SUCCESS,
    REQUEST_GET_PASSWORD_RESET_FAILURE,
    REQUEST_GET_USER,
    REQUEST_GET_USER_SUCCESS,
    REQUEST_GET_USER_FAILURE,
    REQUEST_LOG_IN_USER,
    REQUEST_LOG_IN_USER_SUCCESS,
    REQUEST_LOG_IN_USER_FAILURE,
    REQUEST_LOG_OUT_USER_SUCCESS,
    REQUEST_REGISTER_USER,
    REQUEST_REGISTER_USER_SUCCESS,
    REQUEST_REGISTER_USER_FAILURE,
    REQUEST_RESET_PASSWORD,
    REQUEST_RESET_PASSWORD_SUCCESS,
    REQUEST_RESET_PASSWORD_FAILURE,
    REQUEST_UPDATE_USER,
    REQUEST_UPDATE_USER_SUCCESS,
    REQUEST_UPDATE_USER_FAILURE,
    SELECT_PLAN
} from 'actions/types';

export function reducer(state = {
    isFetching: false,
    user: {}
}, action = { type: '' }) {
    switch (action.type) {
        case CLEAR_SELECTED_PLAN:
            return {
                ...state,
                selectedPlan: {}
            };
        case DEVELOP_HACK_USER:
            return state;
        case REQUEST_ADD_UNREGISTERED_USER:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_ADD_UNREGISTERED_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                user: action.payload.users
            };
        case REQUEST_ADD_UNREGISTERED_USER_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_GET_PASSWORD_RESET:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_GET_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_GET_PASSWORD_RESET_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_CHANGE_PASSWORD:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_GET_USER:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_GET_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                user: action.payload.user
            };
        case REQUEST_GET_USER_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_LOG_IN_USER:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_LOG_IN_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                user: action.payload.user
            };
        case REQUEST_LOG_IN_USER_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_LOG_OUT_USER_SUCCESS:
            return {
                ...state,
                selectedPlan: {},
                user: {}
            };
        case REQUEST_RESET_PASSWORD:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_RESET_PASSWORD_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_REGISTER_USER:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_REGISTER_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                user: {
                    ...action.payload.user
                }
            };
        case REQUEST_REGISTER_USER_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_UPDATE_USER:
            return {
                ...state,
                isFetching: true,
                user: {
                    ...state.user,
                    ...action.payload.user
                }
            };
        case REQUEST_UPDATE_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                user: {
                    ...action.payload.user
                }
            };
        case REQUEST_UPDATE_USER_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        case SELECT_PLAN:
            return {
                ...state,
                selectedPlan: {
                    ...action.payload.plan
                }
            };
        default:
            return state;
    }
}
