'use strict';

import { getTools as getToolsAPI } from 'api/tools';

import {
    REQUEST_GET_TOOLS,
    REQUEST_GET_TOOLS_SUCCESS,
    REQUEST_GET_TOOLS_FAILURE
} from 'actions/types';

function requestGetTools() {
    return {
        type: REQUEST_GET_TOOLS
    };
}

function requestGetToolsSuccess(json) {
    return {
        type: REQUEST_GET_TOOLS_SUCCESS,
        payload: {
            tools: json
        }
    };
}

function requestGetToolsFailure(error) {
    return {
        type: REQUEST_GET_TOOLS_FAILURE,
        payload: error,
        error: true
    };
}

export default function getTools(token) {
    return dispatch => {
        dispatch(requestGetTools());
        return getToolsAPI(token)
            .then((json) => {
                return dispatch(requestGetToolsSuccess(json));
            })
            .catch((error) => {
                return dispatch(requestGetToolsFailure(error));
            });
    };
}
