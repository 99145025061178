'use strict';

import _ from 'lodash';

import { createSelector } from 'reselect';
import { canUseTool } from 'utils/render';

const toolSlugSelector = (state, props) => props.params.toolSlug;
const toolsSelector = state => {
    const unfilteredTools = state.tools;
    let tools = {};
    for(const key in unfilteredTools) {
        if(canUseTool(state.auth.user, unfilteredTools[key], state.responses, state.plans)) {
            tools[key] = unfilteredTools[key]
        }
    }
    return tools;
};
const getToolFromSlug = (slug, tools) => {
    for (let tool of _.values(tools)) {
        if (_.get(tool, 'slug', '') === slug) {
            return tool;
        }
    }
    return {};
};

export const toolSelector = createSelector(
    toolSlugSelector,
    toolsSelector,
    (toolSlug, tools) => {
        return {
            tool: getToolFromSlug(toolSlug, tools)
        };
    }
);

