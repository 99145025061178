/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require, componentHandler */
'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
let findDOMNode = ReactDOM.findDOMNode;

import { eventPreventDefault as prevent } from 'utils/tools';
import classnames  from 'classnames';
import {browserHistory} from 'react-router';

const mdl = window.componentHandler;


const baseClasses = {
    'mdl-button': true,
    //'mdl-js-button': true
};

var Button = React.createClass({
    displayName: 'MDL_Button',

    contextTypes: { history: React.PropTypes.object },

    propTypes: {
        className: React.PropTypes.string,
        linkURL: React.PropTypes.string,
        action: React.PropTypes.string,
        floating: React.PropTypes.bool,
        noColor: React.PropTypes.bool,
        noRipple: React.PropTypes.bool,
        flat: React.PropTypes.bool,
        primary: React.PropTypes.bool,
        accent: React.PropTypes.bool,
        icon: React.PropTypes.bool,
        mini: React.PropTypes.bool,
        onClick: React.PropTypes.func
    },

    componentDidMount: function() {
        let node = findDOMNode(this);
        if (node){
            mdl.upgradeElement(node, 'MaterialButton');
            if(!this.props.noRipple){ mdl.upgradeElement(node, 'MaterialRipple'); }
        }
    },

    componentWillUnmount: function() {
        if (findDOMNode(this)){ mdl.downgradeElements(findDOMNode(this)); }
    },

    /* ========= LOCAL METHODS ============ */

    goToLink: function(event){
        prevent(event);
        if (this.props.linkTo){
            if(this.props.linkTo.indexOf('http') === -1) {
                browserHistory.push(this.props.linkTo);
            } else {
                window.location = this.props.linkTo;
            }
        }
    },

    onClick: function(event){ prevent(event);
        let arg = event.target.getAttribute('data-argument');
        if (!arg) { 
            arg = event.target.parentNode.getAttribute('data-argument');
        }
        if (this.props.onClickArgument){
            this.props.onClickArgument(arg);
        }
    },

    /* ========= RENDERING ============ */
    render: function() {
        let { children, className, linkTo, action,
              floating, flat, noRipple, noColor,
              primary, accent, icon, mini, micro, ripple
              } = this.props;

        let classes = classnames(baseClasses, {
            'btn': true,
            'mdl-button--fab': floating,
            'mdl-button--colored': !noColor,
            'mdl-js-ripple-effect': ripple || !noRipple,
            'mdl-button--raised': !flat,
            'btn-primary': primary,
            'mdl-button--accent': accent,
            'mdl-button--icon': icon,
            'mdl-button--mini-fab': floating && mini || floating && micro,
            'micro-fab': micro
        }, className);

        const htmlAttributes = Object.keys(this.props).filter(key => !["children", "className", "linkTo", "action", "floating", "flat", "ripple", "noRipple", "noColor", "primary", "accent", "icon", "mini", "micro"].includes(key)).reduce((a, k) => {
            a[k] = this.props[k];
            return a;
        }, {});

        var onClick = this.props.onClick;
        if (!onClick && linkTo) { onClick = this.goToLink; }
        if (!onClick) { onClick = this.onClick; }

        return (
            <button className={classes} data-action={action} onClick={onClick} onTouchStart={onClick} {...htmlAttributes}>
                {children}
            </button>
        );

    }

});

export default Button;
export { Button };
