'use strict';

import React from 'react';

export const Unescaped = React.createClass({

    componentDidMount () {
      if(document.getElementById('mce-EMAIL')) {
         componentHandler.upgradeDom();
       }
    },

    render() {
      // TODO: Check for location. Try to use localized key ahead of defaultMessage;
      
      const content = this.props.children.props.defaultMessage;

        if (this.props.className == 'li' || this.props.classname == 'li') {

          return (
              <li dangerouslySetInnerHTML={{__html: content}} />
          );

        } else {

          return (
              <div className={this.props.className} dangerouslySetInnerHTML={{__html: content}} />
          );

       }
    }
});
