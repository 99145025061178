'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import BSInput from '../blocks/BSInput.component.web';
import { LIGHT_GRAY } from '../../stylesheets/colors';
import BSButton from '../blocks/BSButton.component.web';
import { FontAwesome } from "@expo/vector-icons";
import { Form } from 'react-bootstrap';

const messages = defineMessages({
    "plan_tracker_title_label": {
        id: "plan_tracker_title_label",
        description: "Title",
        defaultMessage: "Title"
    },
    "plan_tracker_changes_to_make_label": {
        id: "plan_tracker_changes_to_make_label",
        description: "What changes are you going to make?",
        defaultMessage: "What changes are you going to make?"
    },
    "plan_tracker_reasons_to_change_label": {
        id: "plan_tracker_reasons_to_change_label",
        description: "What are your most important reasons for changing?",
        defaultMessage: "What are your most important reasons for changing?"
    },
    "plan_tracker_steps_to_changing_label": {
        id: "plan_tracker_steps_to_changing_label",
        description: "How do you plan to achieve your goals. What are the specific steps involved? When, where, and how will you take them?",
        defaultMessage: "How do you plan to achieve your goals. What are the specific steps involved? When, where, and how will you take them?"
    },
    "plan_tracker_how_others_can_help_label": {
        id: "plan_tracker_how_others_can_help_label",
        description: "How can others help? How can you get them to help you?",
        defaultMessage: "How can others help? How can you get them to help you?"
    },
    "plan_tracker_possible_setbacks_label": {
        id: "plan_tracker_possible_setbacks_label",
        description: "What could go wrong or undermine your plans? How can you stick with your plan despite these setbacks or problems?",
        defaultMessage: "What could go wrong or undermine your plans? How can you stick with your plan despite these setbacks or problems?"
    },
    "plan_tracker_good_things_from_changing_label": {
        id: "plan_tracker_good_things_from_changing_label",
        description: "What good things will happen as a result of changing?",
        defaultMessage: "What good things will happen as a result of changing?"
    }
});

const formFields = ['title', 'changes_to_make', 'reasons_to_change', 'steps_to_changing', 'how_others_can_help', 'possible_setbacks', 'good_things_from_changing'];

const validate = values => {
    const errors = {};
    formFields.forEach(key => {
        const { [key]: val } = values || {};
        if (!val || !val.length) errors[key] = 'Required';
    })
    return errors;
}

const PlanTracker = React.createClass({
    propTypes: {
        handleSubmit: React.PropTypes.func.isRequired
    },

    getInitialState() {
        return {
            validated: false,
        }
    },

    onChange(fieldName, event) {
        this.props.change(fieldName, event.target.value);
    },

    submit(e) {
        this.setState({ validated: true });
        if (Object.values(this.props.errors || {}).length) return;
        this.props.handleSubmit();
    },

    render() {
        const { intl: { formatMessage } } = this.props;

        return (
          <div className="card my-4" style={{ border: 'none' }}>
              <div className="card-body">
                  <h3 className="card-title">
                      <FormattedMessage
                        id="plan_tracker_title"
                        description="Title for the Change Plan Tracker form"
                        defaultMessage="Your Change Plan"
                      />
                  </h3>
                  <Form validated={this.state.validated} noValidate id="plan_tracker" name="plan_tracker">
                      {
                          [
                              { name: 'title', label: 'plan_tracker_title_label' },
                              { name: 'changes_to_make', label: 'plan_tracker_changes_to_make_label' },
                              { name: 'reasons_to_change', label: 'plan_tracker_reasons_to_change_label' },
                              { name: 'steps_to_changing', label: 'plan_tracker_steps_to_changing_label' },
                              { name: 'how_others_can_help', label: 'plan_tracker_how_others_can_help_label' },
                              { name: 'possible_setbacks', label: 'plan_tracker_possible_setbacks_label' },
                              { name: 'good_things_from_changing', label: 'plan_tracker_good_things_from_changing_label' },
                          ].map(({ name, label }) => (
                            <div className="my-3" key={`plan_${name}`}>
                                <Field
                                  as={name === 'title' ? undefined : 'textarea'}
                                  name={name}
                                  label={formatMessage(messages[label])}
                                  component={BSInput}
                                  style={{ border: 'none', backgroundColor: LIGHT_GRAY }}
                                />
                            </div>
                          ))
                      }
                      <BSButton
                        disabled={this.props.isFetching || !!Object.values(this.props.errors || {}).length}
                        loading={this.props.isFetching}
                        onClick={this.submit}
                        variant="green"
                      >
                          <FontAwesome name="star-o" color="white" size={20} />
                          <div className="ms-2">
                              <FormattedMessage
                                id="plan_tracker_button_save"
                                description="Submit the Change Plan tracker form to save an Change Plan"
                                defaultMessage="Save"
                              />
                          </div>
                      </BSButton>
                  </Form>
              </div>
          </div>
        )
    }
});

let form = reduxForm({
    form: 'planTracker',
    fields: formFields,
    validate
})(PlanTracker);

const selector = formValueSelector('planTracker') // <-- same as form name
form = connect(state => {
    return formFields.reduce((dict, fieldName) => Object.assign({ [fieldName]: selector(state, fieldName) }, dict), {
        errors: state.form.planTracker?.syncErrors,
    });
})(form);

export default injectIntl(form);
