'use strict';

import React, { findDOMNode } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import TimePicker from 'react-time-picker';
import Moment from 'moment'; Moment.locale('en');
import { faStar as faStarO } from '@fortawesome/free-solid-svg-icons';
import InfoBug from 'components/popover/infobug';
import ButtonSpinner from 'components/ux/button-spinner';
import RadioSetReduxForm from 'components/ux/RadioGroupReduxForm.component.web';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { validator } from 'utils/tools';

const landscapeDefaults = {
    fieldsetClassName: "d-flex flex-row mb-3",
    fieldContainerClassName: "d-flex align-items-center mb-1 me-4",
    labelClassName: "form-label",
    inputClassName: "me-1"
};
const toRadioSet = o => ({ value: o.value, text: o.text });

const options = [
    {
        "text": {
            "id": "number_1",
            "defaultMessage": "1"
        },
        "value": "1"
    },
    {
        "text": {
            "id": "number_2",
            "defaultMessage": "2"
        },
        "value": "2"
    },
    {
        "text": {
            "id": "number_3",
            "defaultMessage": "3"
        },
        "value": "3"
    },
    {
        "text": {
            "id": "number_4",
            "defaultMessage": "4"
        },
        "value": "4"
    },
    {
        "text": {
            "id": "number_5",
            "defaultMessage": "5"
        },
        "value": "5"
    },
    {
        "text": {
            "id": "number_6",
            "defaultMessage": "6"
        },
        "value": "6"
    },
    {
        "text": {
            "id": "number_7",
            "defaultMessage": "7"
        },
        "value": "7"
    },
    {
        "text": {
            "id": "number_8",
            "defaultMessage": "8"
        },
        "value": "8"
    },
    {
        "text": {
            "id": "number_9",
            "defaultMessage": "9"
        },
        "value": "9"
    },
    {
        "text": {
            "id": "number_10",
            "defaultMessage": "10"
        },
        "value": "10"
    }];


const HappinessTracker = React.createClass({

    propTypes: {
        fields: React.PropTypes.object.isRequired,
        handleSubmit: React.PropTypes.func.isRequired
    },

    getInitialState() {
        return {
            defaultTime: Moment(new Date()).format('h:mm A')
        };
    },

    onChange(fieldName, event) {
        this.props.change(fieldName, event.target.value);
    },

    componentDidUpdate(prevProps) { 
        const average = Object.entries(this.props || {})
            .filter(([key, value]) => this.props.fields.includes(key))
            .filter(([key, value]) => key !== 'average')
            .map(([key, value]) => value || 0)
            .map(v => parseInt(v)).reduce((avg, value, index, all) => { return avg + value / all.length; }, 0);
        if(prevProps?.average !== average.toString()) {
            console.count("setting average");
            this.props.change('average', average.toString());
        }
    },

    render() {
        const { 
            handleSubmit,
            intl: { formatMessage }
        } = this.props;
        return (
                    <div className="section section-account-overview col-1-1">
                        <div className="contain-box-wrap" style={{maxWidth:'690px', marginTop: '52px'}}>
                            <div className="contain-box-main" >
                                <h1 className="page-title contain-box-title">
                                    <FormattedMessage
                                        id="happiness_tracker_title"
                                        description="Title for the Happiness Tracker form"
                                        defaultMessage="Happiness Tracker" />
                                    <InfoBug>
                                        <p>
                                            <FormattedMessage
                                                id="mood_tracker_instructions"
                                                defaultMessage="On this scale to indicate your current level of happiness with your life in each area. Ask yourself the following question. How happy am I with this area of my life? Rate how you feel today with 1 being completely unhappy and 10 completely happy."/>
                                        </p>
                                        <p>
                                            <FormattedMessage
                                                id="happiness_tracker_instructions_infobug"
                                                defaultMessage="Used with permission from Meyers & Smith (1995), Clinical guide to alcohol treatment: The community reinforcement approach."/>
                                        </p>
                                    </InfoBug>
                                </h1>

                                <form id="happiness_tracker" name="happiness_tracker" className="stack-input" style={{margin: '0 auto 40px auto' /*, maxWidth: '290px'*/}}>
                                    <div>
                                        <label className="baseline-form" htmlFor="abstinence">
                                            <FormattedMessage
                                                id="happiness_tracker_abstinence_label"
                                                defaultMessage="Abstinence/Sobriety"/>
                                        </label>
                                        <RadioSetReduxForm name="abstinence"
                                            options={options.map(toRadioSet)}
                                            name="abstinence"
                                            labelUnder={true}
                                            onChange={(event) => this.onChange('abstinence', event)} {...landscapeDefaults} />
                                        <label className="baseline-form" htmlFor="professional">
                                            <FormattedMessage
                                                id="happiness_tracker_professional_label"
                                                defaultMessage="Job Or Education Progress"/>
                                        </label>
                                        <RadioSetReduxForm name="professional"
                                            options={options.map(toRadioSet)}
                                            name="professional"
                                            labelUnder={true}
                                            onChange={(event) => this.onChange('professional', event)}  {...landscapeDefaults} />
                                        <label className="baseline-form" htmlFor="money">
                                            <FormattedMessage
                                                id="happiness_tracker_money_label"
                                                defaultMessage="Money Management"/>
                                        </label>
                                        <RadioSetReduxForm name="money"
                                            options={options.map(toRadioSet)}
                                            name="money"
                                            labelUnder={true}
                                            onChange={(event) => this.onChange('money', event)} {...landscapeDefaults} />
                                        <label className="baseline-form" htmlFor="social">
                                            <FormattedMessage
                                                id="happiness_tracker_social_label"
                                                defaultMessage="Social Life"/>
                                        </label>
                                        <RadioSetReduxForm name="social"
                                            options={options.map(toRadioSet)}
                                            name="social"
                                            labelUnder={true}
                                            onChange={(event) => this.onChange('social', event)} {...landscapeDefaults} />
                                        <label className="baseline-form" htmlFor="personal">
                                            <FormattedMessage
                                                id="happiness_tracker_personal_label"
                                                defaultMessage="Personal Life"/>
                                        </label>
                                        <RadioSetReduxForm name="personal"
                                            options={options.map(toRadioSet)}
                                            name="personal"
                                            labelUnder={true}
                                            onChange={(event) => this.onChange('personal', event)} {...landscapeDefaults} />
                                        <label className="baseline-form" htmlFor="family">
                                            <FormattedMessage
                                                id="happiness_tracker_family_label"
                                                defaultMessage="Marriage/Family Relationships" {...landscapeDefaults} />
                                        </label>
                                        <RadioSetReduxForm name="family"
                                            options={options.map(toRadioSet)}
                                            name="family"
                                            labelUnder={true}
                                            onChange={(event) => this.onChange('family', event)} {...landscapeDefaults} />
                                        <label className="baseline-form" htmlFor="legal">
                                            <FormattedMessage
                                                id="happiness_tracker_legal_label"
                                                defaultMessage="Legal Issues"/>
                                        </label>
                                        <RadioSetReduxForm name="legal"
                                            options={options.map(toRadioSet)}
                                            name="legal"
                                            labelUnder={true}
                                            onChange={(event) => this.onChange('legal', event)} {...landscapeDefaults} />
                                        <label className="baseline-form" htmlFor="emotional">
                                            <FormattedMessage
                                                id="happiness_tracker_emotional_label"
                                                defaultMessage="Emotional Life"/>
                                        </label>
                                        <RadioSetReduxForm name="emotional"
                                            options={options.map(toRadioSet)}
                                            name="emotional"
                                            labelUnder={true}
                                            onChange={(event) => this.onChange('emotional', event)} {...landscapeDefaults} />
                                        <label className="baseline-form" htmlFor="communication">
                                            <FormattedMessage
                                                id="happiness_tracker_communication_label"
                                                defaultMessage="Communication"/>
                                        </label>
                                        <RadioSetReduxForm name="communication"
                                            options={options.map(toRadioSet)}
                                            name="communication"
                                            labelUnder={true}
                                            onChange={(event) => this.onChange('communication', event)} {...landscapeDefaults} />
                                        <label className="baseline-form" htmlFor="health">
                                            <FormattedMessage
                                                id="happiness_tracker_health_label"
                                                defaultMessage="Eating, Sleeping, Or Exercise"/>
                                        </label>
                                        <RadioSetReduxForm name="health"
                                            options={options.map(toRadioSet)}
                                            name="health"
                                            labelUnder={true}
                                            onChange={(event) => this.onChange('health', event)} {...landscapeDefaults} />
                                        <ButtonSpinner spinner={this.props.isFetching}
                                            onClick={handleSubmit} ripple={true} raised={true} colored={true} className="height-auto" >
                                                <FontAwesomeIcon icon={faStarO}/>
                                                <FormattedMessage id="happiness_tracker_button_save"
                                                    description="Submit the Happiness tracker form to save an Happiness"
                                                    defaultMessage="Save" />
                                        </ButtonSpinner>
                                    </div>
                                    <div className="clearfix" />
                                </form>

                            </div>
                        </div>
                    </div>
        );
    }
});

const formFields = [
    'average',
    'abstinence',
    'professional',
    'money',
    'social',
    'personal',
    'family',
    'legal',
    'emotional',
    'communication',
    'health'
];
let form = reduxForm({
    form: 'happinessTracker',
    fields: formFields,
    validate: validator ({
        'abstinence': { required: 'Required' },
        'professional': { required: 'Required' },
        'money': { required: 'Required' },
        'social': { required: 'Required' },
        'personal': { required: 'Required' },
        'family': { required: 'Required' },
        'legal': { required: 'Required' },
        'emotional': { required: 'Required' },
        'communication': { required: 'Required' },
        'health': { required: 'Required' }
    })
});

const selector = formValueSelector('happinessTracker') // <-- same as form name
form = form(connect(state => {
    return formFields.reduce((dict, fieldName) => Object.assign({ [fieldName]: selector(state, fieldName) }, dict), state);
})(HappinessTracker));
export default injectIntl(form);