/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";
// ========= LIBRARY COMPONENTS =========
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Actions from 'actions';

const spinMessages = {
    CREDIT_CARD_PROCESSING: <FormattedMessage id="app_spinner_credit_card_processing"
        defaultMessage="Saving your card..." />,
    ADDING_SUBSCRIPTION: <FormattedMessage id="app_spinner_adding_subscription"
                                              defaultMessage="Creating your subscription..."/>,
    PLAN_CHANGE_PROCESSING: <FormattedMessage id="app_spinner_plan_change_processing"
                                              defaultMessage="Updating your subscription..."/>,
    PLEASE_WAIT: <FormattedMessage id="app_spinner_please_wait"
        defaultMessage="Please wait..." />,
    LOGGING_YOU_IN: <FormattedMessage id="app_spinner_logging_you_in"
        defaultMessage="Logging you in..." />
};

var AppSpinner = React.createClass({
    propTypes: {
        dispatch: React.PropTypes.func,
        display: React.PropTypes.object
    },

    // ======= REACT LIFECYCLE ===========================
    componentDidMount: function() {
        this.props.dispatch(Actions.updateDisplay('appSpinner', 'STOP'));
    },

    // ======== RENDER ==============
    render: function() {
        const { display: { appSpinner } } = this.props,
            message = appSpinner ? spinMessages[appSpinner] : null,
            spinClass = message ? "app-spinner-layer active" : "app-spinner-layer";
        return (
            <div className={spinClass}>
                <div className="spinner-message">
                    <div className="message">{message}</div>
                    <div className="spin-wrapper">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
            </div>
        );
    }
});

export default connect(
    state => ({
        display: state.display
    })
)(AppSpinner);
