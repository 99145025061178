import React from 'react';
import { Field } from 'redux-form';
import MaskedInput from 'react-text-mask'

type InputProps = {
    placeholder?: string;
    guide?: boolean;
    id: string;
    label?: string|React.ReactNode;
    name: string;
    input?: {
        value?: string;
        onChange: (val: string) => void;
    };
    meta?: {
        error?: string;
    }
}

const Input = (props: InputProps) => {
    const onChange = e => {
        props.input?.onChange(e.target.value);
    }

    return (
        <MaskedInput
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholder={props.placeholder}
            guide={!!props.guide}
            id={props.id}
            name={props.name}
            onChange={onChange}
            value={props.input?.value}
            render={(ref, p) => (
                <div className="input-group input-group-fancy mb-3">
                    {
                        props.label && (
                            <div className="input-group-text d-flex flex-column align-items-start justify-content-center">
                                { props.label }
                                {
                                    props.meta?.error && (
                                        <small className="text-danger">{ props.meta.error }</small>
                                    )
                                }
                            </div>
                        )
                    }
                    <input className="form-control" style={{ textAlign: 'right' }} ref={ref} { ...p } />
                </div>
            )}
        />
    )
}

type Props = {
    name: string;
    label?: string|React.ReactNode;
    guide?: boolean;
}

const PhoneInputReduxForm = (props: Props) => {
   return (
       <Field
           component={Input}
           { ...props }
       />
   )
}

export default PhoneInputReduxForm;
