'use strict';

import { addNewReminder as addReminderAPI } from 'api/reminders';
import Actions from 'actions';
import notify from 'actions/notify';

import {
    REQUEST_ADD_REMINDER,
    REQUEST_ADD_REMINDER_SUCCESS,
    REQUEST_ADD_REMINDER_FAILURE

} from 'actions/types';

function requestAddReminder() {
    return {
        type: REQUEST_ADD_REMINDER
    };
}

function requestAddReminderSuccess(json) {
    return {
        type: REQUEST_ADD_REMINDER_SUCCESS,
        payload: {
            reminders: json
        }
    };
}

function requestAddReminderFailure(error) {
    return {
        type: REQUEST_ADD_REMINDER_FAILURE,
        payload: error,
        error: true
    };
}

export default function addReminder(userId, token, reminder) {
    return dispatch => {
        dispatch(requestAddReminder());
        return addReminderAPI(userId, token, reminder)
        .then((json) => {
            return dispatch(Actions.getAllReminders(userId, token))
            .then(() => {
                dispatch(notify({
                    title: "Reminder added",
                    message: "Your Reminder was added successfully.",
                    level: 'success',
                    autoDismiss: 4
                }));
                return dispatch(requestAddReminderSuccess(json)); 
            });
        })
        .catch((error) => {
            
            dispatch(notify({
                title: "Network Error",
                message: "Unable to add Reminder. Please try again in a few minutes.",
                level: 'error',
                autoDismiss: 4
            }));

            return dispatch(requestAddReminderFailure(error));
        });
    };
}