/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonSpinner from 'components/ux/button-spinner'
import Actions from 'actions';
import { connect } from 'react-redux';


const GenericModalButton = React.createClass({

    onClick: function(event) {
        event.preventDefault();
        const that = this;
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'genericModal',
            modalContent: true,
            modalCustomTitle: that.props.modal_title,
            modalCustomContent: that.props.modal_content,
            modalHTML: that.props.modal_html,
            modalNorms: that.props.norms,
            modalResponses: that.props.responses,
            closeButton: that.props.closeButton,
            scroll: that.props.scroll
        }));
    },

    render() {
        let button = (
                    <ButtonSpinner noColor={true} flat={true} onClick={this.onClick} spinner={false}>
                        <FormattedMessage {...this.props.link_text} />
                    </ButtonSpinner>
                );
        if(this.props.link_only) {
            button = (
                    <a href="#" onClick={this.onClick} className="mx-2">
                        <FormattedMessage {...this.props.link_text} />
                    </a>
            );
        }
        return button;

        
    }
});

export default connect()(GenericModalButton);
