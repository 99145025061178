'use strict';

import _ from 'lodash';
import App from 'containers/app';
import AccountOverviewPage from 'containers/account-overview-page';
import DrinkTrackerPage from 'containers/drink-tracker-page';
import DynamicPage from 'containers/dynamic-page';
import LoginPage from 'containers/login-page';
import ModuleOverviewPage from 'containers/module-overview-page';
import MoodTrackerPage from 'containers/mood-tracker-page';
import OverviewPage from 'containers/overview-page';
import PrivacyPage from 'containers/privacy-page';
import TermsPage from 'containers/terms-page';
import PasswordResetRequestPage from 'containers/password-reset-request-page';
import PaymentInformationPage from 'containers/payment-information-page';
import PlanSelectionPage from 'containers/plan-selection-page';
import RegistrationPage from 'containers/registration-page';
import ResetPasswordPage from 'containers/reset-password-page';
import ScreenerPage from 'containers/screener-page';
import ScreenerPlanSelectionPage from 'containers/screener-plan-page';
import SectionOverviewPage from 'containers/section-overview-page';
import ToolOverviewPage from 'containers/tool-overview-page';
import UserProfilePage from 'containers/user-profile-page';
import MedicalHistoryPage from 'containers/medical-history-page';
import UrgeTrackerPage from 'containers/urge-tracker-page';
import ProgressPage from 'containers/progress-page';
import DrinkTrackerFeedbackPage from 'containers/drink-tracker-feedback-page';
import UrgeTrackerFeedbackPage from 'containers/urge-tracker-feedback-page';
import AgreementTrackerPage from 'containers/agreement-tracker-page';
import AdminDashboard from 'containers/admin-crud';

import Actions from 'actions';
import DrinkTrackerFormPage from './containers/DrinkTrackerFormPage.component.web';
import RemindersPage from './containers/RemindersPage.component.web';
const logOutUser = Actions.logOutUser

export default (store) => {

    const routeByUserRole = (nextState, replaceState, callback) => {
        const state = store.getState(),
            { user } = state.auth || {},
            { roles } = user || {},
            { admin, system } = roles || {},
            isAdmin = !!admin?.length || (!!system?.length && system.includes('admin'));
        if (!isAdmin && nextState.location.pathname.includes('/admin')) replaceState('/dashboard', {});
        if (callback) callback();
    }

    const routeByUserStatus = (nextState, replaceState, callback) => {
        let newRoute;
        const state = store.getState();
        const user = _.get(state, 'auth.user'),
            { roles } = user || {},
            { admin, system } = roles || {},
            isAdmin = !!admin?.length || (!!system?.length && system.includes('admin'));
        const userStatus = _.get(user, 'status');
        const isUnsubscribed = userStatus === 'unsubscribed';
        const isNormal = userStatus === 'normal';
        const isLoggedIn = isNormal || isUnsubscribed;

        const { session_brokering } = CUAC_SETTINGS?.GROUP ?? {},
            { medical_history, status } = user ?? {};

        if (!isAdmin && nextState.location.pathname.includes('/admin')) {
            newRoute = '/';
        }

        if (!isLoggedIn) {
            const hasScreener = Boolean(_.get(CUAC_SETTINGS, 'GROUP.screener_tool_id'));
            if (nextState.location.pathname === '/' && hasScreener) {
                newRoute = '/screener' + nextState.location.search || '';
            } else {
                newRoute = `/login${nextState.location.search?.length ?  nextState.location.search + '&' : '?'}redirect=` + nextState.location.pathname;
            }
        }
        else if (isUnsubscribed) {
            if (_.isEmpty(_.get(state, 'auth.selectedPlan', {}))) {
                newRoute = '/account/plans' + nextState.location.search || '';
            } else {
                newRoute = '/account/payment' + nextState.location.search || '';
            }
        }
        else {
            if(Boolean(_.get(CUAC_SETTINGS, 'GROUP.allow_skip_profile') === true)) {
                newRoute = nextState.location.pathname;
            }
            // If this user was created by the Rails app and has not yet completed their medical history, send them there
            else if (!!session_brokering && !medical_history && status === 'normal') {
                if (nextState.location.pathname !== '/account/medical-history') newRoute = `/account/medical-history?new=true`;
            }
            else {
                if (!_.get(user, 'dt_dob') || !_.get(user, 'weight') || !_.get(user, 'gender')) {
                    if(nextState.location.pathname === "/account/medical-history") {
                        newRoute = nextState.location.pathname;
                    } else {
                        newRoute = '/account/profile';
                    }
                } else {
                    newRoute = nextState.location.pathname;
                }
            }
        }
        if (newRoute !== nextState.location.pathname) {
            replaceState(newRoute, {});
        }
        if (callback) {
            callback();
        }
    };

    const requireLogin = (nextState, replaceState, callback) => {
        const state = store.getState();
        const status = _.get(state, 'auth.user.status');
        const isLoggedIn = status === 'normal' || status === 'unsubscribed';
        if (!isLoggedIn) {
            replaceState('/login?redirect=' + nextState.location.pathname, {
                nextPathname: nextState.location.pathname
            });
        }
        if (callback) {
            callback();
        }
    };

    const requireLoginAndSubscription = (nextState, replaceState, callback) => {
        const state = store.getState();
        const status = _.get(state, 'auth.user.status');
        const isUnsubscribed = status === 'unsubscribed';
        const isLoggedIn = status === 'normal' || isUnsubscribed;
        if (!isLoggedIn) {
            replaceState('/login?redirect=' + nextState.location.pathname, {
                nextPathname: nextState.location.pathname
            });
        } else if (isUnsubscribed) {
            replaceState('/account', {});
        }
        if (callback) {
            callback();
        }
    };

    const logOut = (nextState, replaceState, callback) => {
        const state = store.getState();
        const userStatus = _.get(state, 'auth.user.status');
        const isLoggedIn = userStatus === 'normal' || userStatus === 'unsubscribed';
        if (isLoggedIn) {
            store.dispatch(logOutUser())
            .then(() => {
                window.location = "/login";
            });
        }
        if (callback) {
            callback();
        }
    };

    const requireValidResponse = (level) => {
        return async function(nextState, replaceState, callback) {
            const state = store.getState();
            const userStatus = _.get(state, 'auth.user.status');
            const isLoggedIn = userStatus === 'normal';
            if (!isLoggedIn) {
                if (_.get(nextState.location.query, 'override')) {
                    let extraQueryArgs = '';
                    for(const key in nextState.location.query) {
                        if(key !== 'override') {
                            extraQueryArgs += '&' + key + '=' + nextState.location.query[key];
                        }
                    }
                    replaceState('/login?redirect=' + nextState.location.pathname + '?override=true' + extraQueryArgs, {
                        nextPathname: nextState.location.pathname
                    });
                } else {
                    replaceState('/', {
                        nextPathname: nextState.location.pathname
                    });
                }
            } else {
                const activeResponseId = _.get(state, 'responses.activeResponseId');
                const activeResponse = _.get(state, 'responses.' + activeResponseId + '.response');
                const activeTool = _.get(state, 'tools.' + activeResponse?.tool_id);
                if (!activeTool) {
                    replaceState(
                        { nextPathname: nextState.location.pathname},
                        '/'
                    );
                } else {
                    await checkPathValidity(nextState, replaceState, callback, level, activeTool);
                }
            }

            if (callback) {
                callback();
            }
        };
    };

    const requireTool = () => {
        return function(nextState, replaceState, callback) {
            const state = store.getState();
            const userStatus = _.get(state, 'auth.user.status');
            const isLoggedIn = userStatus === 'normal';
            if (!isLoggedIn) {
                if (_.get(nextState.location.query, 'override')) {
                    replaceState('/login?redirect=' + nextState.location.pathname + '?override=true', {
                        nextPathname: nextState.location.pathname
                    });
                } else {
                    replaceState('/', {
                        nextPathname: nextState.location.pathname
                    });
                }
            } else {
                let activeTool = false;
                const tools = _.get(state, 'tools', []);
                const path = nextState.location.pathname;
                const toolSlug = path.split('/')[2];
                for(var key in tools) {
                    if(tools[key].slug == toolSlug) {
                        activeTool = true;
                    }
                }
                if (!activeTool) {
                    replaceState(
                        { nextPathname: nextState.location.pathname},
                        '/'
                    );
                }
            }

            if (callback) {
                callback();
            }
        };
    };

    const checkPathValidity = async (nextState, replaceState, callback, level, activeTool) => {
        const state = store.getState();
        const path = nextState.location.pathname;
        const currentToolSlug = path.split('/')[1];

        let pathIsGood = true;
        let reroutePath = '/';

        const getModuleFromPath = (modules, path) => {
            const moduleSlug = path.split('/')[2];
            let delivery = null;
            _.forOwn(modules, (module, moduleId) =>{
                if (module.slug === moduleSlug) { delivery = module; }
            });
            return delivery;
        };

        const getSectionFromPath = (module, path) => {
            const sectionSlug = path.split('/')[3];
            let delivery = null;
            _.forOwn(module.sections, (section, sectionId) => {
                if (section.slug === sectionSlug) { delivery = section; }
            });
            return delivery;
        };

        const getPageFromPath = (section, path) => {
            const pageSlug = path.split('/')[4];
            let delivery = null;
            _.forOwn(section.pages, (page, pageId) => {
                if (page.slug === pageSlug) { delivery = page; }
            });
            return delivery;
        };

        let module, section, page;
        if (activeTool.slug !== currentToolSlug) {
            if(_.get(nextState.location.query, 'override')) {
                pathIsGood = true;
                // make this the active tool
                const tools = _.get(state, 'tools');
                const responses = _.get(state, 'responses');
                const user = _.get(state.auth, 'user');
                // loop through tools to find the current slug
                for(var id in tools) {
                    if(tools[id].slug === currentToolSlug) {
                        // loop through the responses, if we find one, set it as active
                        let addResponse = true;
                        for(var responseId in responses) {
                            if(_.get(responses[responseId].response, 'tool_id') === id) {
                                store.dispatch(Actions.setResponse(user.token, user._id, responseId));
                                addResponse = false;
                            }
                        }
                        // if no response was found, set add it
                        if(addResponse) {
                            await store.dispatch(Actions.addResponse(user.token, user._id, tools[id]));
                        }
                    }
                }
            } else {
                pathIsGood = false;
                reroutePath = '/' + activeTool.slug;
            }
        } else {
            if (level === 'module' || level === 'section' || level === 'page'){
                module = getModuleFromPath(activeTool.metadata.modules, path);
                if (!module) {
                    pathIsGood = false;
                    reroutePath = '/' + activeTool.slug;
                }
            }
            if (level === 'section' || level === 'page'){
                section = module ? getSectionFromPath(module, path) : null;
                if (module && !section) {
                    pathIsGood = false;
                    reroutePath = '/' + activeTool.slug + '/' + module.slug;
                }
            }
            if (level === 'page'){
                page = section ? getPageFromPath(section, path) : null;
                if (module && section && !page) {
                    pathIsGood = false;
                    reroutePath = '/' + activeTool.slug + '/' + module.slug + '/' + section.slug;
                }
            }
        }

        if (!pathIsGood) {
            replaceState(
                reroutePath,
                { nextPathname: reroutePath }
            );
        }

        if (callback) {
            callback();
        }
    };

    const requireScreener = (nextState, replaceState, callback) => {
        const state = store.getState();
        const userStatus = _.get(state, 'auth.user.status', 'unregistered');
        const isUnregistered = userStatus === 'unregistered';
        if (!isUnregistered) {
            replaceState('/', {});
        }
        const hasScreener = Boolean(_.get(CUAC_SETTINGS, 'GROUP.screener_tool_id'));
        if (!hasScreener) {
            replaceState('/register', {
                nextPathname: nextState.location.pathname
            });
        }
        if (callback) {
            callback();
        }
    };

    const requireScreenerCompletion = (nextState, replaceState, callback) => {
        const state = store.getState();
        const userStatus = _.get(state, 'auth.user.status', 'unregistered');
        const isUnregistered = userStatus === 'unregistered';
        let isOverridden = false;
        if (nextState.location.query.override == "true") {
            isOverridden = true;
        } else if (Boolean(_.get(CUAC_SETTINGS, 'GROUP.bypass_screener'))) {
            isOverridden = true;
        }
        if (!isUnregistered) {
            replaceState('/', {});
        }
        const tools = state.tools;
        const responses = _.get(state, 'responses');
        const activeResponse = _.get(responses, responses.activeResponseId);
        let userResponses;

        let questionLength = 0;
        let userResponseLength = 0;

        for (var key in tools) {
            if (_.get(tools[key], 'metadata.pages')) {
                const screenerPages = tools[key].metadata.pages;
                for (var key in screenerPages) {
                    const pageComponents = screenerPages[key].components;
                    for (var key in pageComponents) {
                        if (_.get(pageComponents[key], 'fields')) {
                            const fields = _.get(pageComponents[key], 'fields');
                            questionLength += fields.length;
                        }
                    }
                }
            }
        }

        userResponses = _.get(activeResponse, 'response.responses');
        if (userResponses) {
            userResponseLength = Object.keys(userResponses).length;
        }

        if(userResponseLength) {
            userResponseLength -= (["screener_question_score", "screener_readiness_score"]
                .filter(special => (special in userResponses))).length;
        }

        if (questionLength > userResponseLength && !isOverridden) {
            store.dispatch(Actions.notify({
                title: "Please complete the screener",
                message: "Please complete the following questions before registering.",
                level: 'error',
                autoDismiss: 5
            }));
            replaceState('/screener/questions?completion_notice=true', {
                nextPathname: nextState.location.pathname
            });
        }
        if (callback) {
            callback();
        }
    };

    const linkExternalUser = async (nextState, replaceState, callback) => {
        const state = store.getState(),
            { user } = state.auth || {},
            { external_user_id } = nextState.location.query || {};
        if (external_user_id && external_user_id.length && (!user.external_user_id || !user.external_user_id.length)) {
            try {
                await store.dispatch(Actions.updateUser(user.token, user._id, { external_user_id }));
            }
            catch (e) {}
        }
        callback();
    }

    return [
        {
            path: '/',
            component: App,
            onEnter: linkExternalUser,
            indexRoute: {
                component: OverviewPage,
                onEnter: routeByUserStatus
            },
            childRoutes: [
                {
                    path: '/plan-select',
                    component: ScreenerPlanSelectionPage
                },
                {
                    path: 'reminders',
                    component: RemindersPage,
                    onEnter: requireLoginAndSubscription,
                },
                {
                    path: 'dashboard',
                    component: OverviewPage,
                    onEnter: requireLoginAndSubscription
                },
                {
                    path: 'drink-feedback',
                    component: DrinkTrackerFeedbackPage,
                    onEnter: requireLoginAndSubscription
                },
                {
                    path: 'urge-feedback',
                    component: UrgeTrackerFeedbackPage,
                    onEnter: requireLoginAndSubscription
                },
                {
                    path: 'login',
                    component: LoginPage
                },
                {
                    path: 'logout',
                    component: LoginPage,
                    onEnter: logOut
                },
                {
                    path: 'register',
                    component: RegistrationPage,
                    onEnter: requireScreenerCompletion
                },
                {
                    path: 'forgot-password',
                    component: PasswordResetRequestPage
                },
                {
                    path: 'reset-password',
                    component: ResetPasswordPage
                },
                {
                    path: 'reset-password/:reset_key',
                    component: ResetPasswordPage
                },
                {
                    path: 'privacy',
                    component: PrivacyPage
                },
                {
                    path: 'terms',
                    component: TermsPage
                },
                {
                    path: 'admin',
                    component: AdminDashboard,
                    onEnter: routeByUserRole
                },
                {
                    path: 'account',
                    component: AccountOverviewPage,
                    onEnter: routeByUserStatus
                },
                {
                    path: 'account/payment',
                    component: PaymentInformationPage,
                    onEnter: routeByUserStatus
                },
                {
                    path: 'account/profile',
                    component: UserProfilePage,
                    onEnter: routeByUserStatus
                },
                {
                    path: 'account/medical-history',
                    component: MedicalHistoryPage,
                    onEnter: routeByUserStatus
                },
                {
                    path: 'account/plans',
                    component: PlanSelectionPage,
                    onEnter: routeByUserStatus
                },
                {
                    path: 'drinks',
                    component: DrinkTrackerPage,
                    onEnter: requireLoginAndSubscription
                },
                {
                    path: 'drink-tracker(/:id)',
                    component: DrinkTrackerFormPage,
                    onEnter: requireLoginAndSubscription
                },
                {
                    path: 'urges',
                    component: UrgeTrackerPage,
                    onEnter: requireLoginAndSubscription
                },
                {
                    path: 'moods',
                    component: MoodTrackerPage,
                    onEnter: requireLoginAndSubscription
                },
                {
                    path: 'agreements',
                    component: AgreementTrackerPage,
                    onEnter: requireLoginAndSubscription
                },
                {
                    path: 'screener',
                    component: ScreenerPage,
                    onEnter: requireScreener
                },
                {
                    path: 'screener/plan',
                    component: ScreenerPlanSelectionPage,
                    onEnter: requireScreener
                },
                {
                    path: 'screener/:pageSlug',
                    component: ScreenerPage,
                    onEnter: requireScreener
                },
                {
                    path: 'progress/:toolSlug',
                    component: ProgressPage,
                    onEnter: requireTool()
                },
                {
                    path: ':toolSlug',
                    component: ToolOverviewPage,
                    onEnter: requireValidResponse('tool')
                },
                {
                    path: ':toolSlug/:moduleSlug',
                    component: ModuleOverviewPage,
                    onEnter: requireValidResponse('module')
                },
                {
                    path: ':toolSlug/:moduleSlug/:sectionSlug',
                    component: SectionOverviewPage,
                    onEnter: requireValidResponse('section')
                },
                {
                    path: ':toolSlug/:moduleSlug/:sectionSlug/:pageSlug',
                    component: DynamicPage,
                    onEnter: requireValidResponse('page'),
                },
                {
                    path: '*',
                    component: LoginPage
                }
            ]
        }
    ];
};
