'use strict';

import Cookies from 'cookies-js';
import Actions from 'actions';
import { registerUser as registerUserAPI } from 'api/users';
const dataLayer = [];
import {
    REQUEST_REGISTER_USER,
    REQUEST_REGISTER_USER_SUCCESS,
    REQUEST_REGISTER_USER_FAILURE
} from 'actions/types';

function requestRegisterUser() {
    return {
        type: REQUEST_REGISTER_USER
    };
}

function requestRegisterUserSuccess(json) {
    return {
        type: REQUEST_REGISTER_USER_SUCCESS,
        payload: {
            user: json
        }
    };
}

function requestRegisterUserFailure(error) {
    return {
        type: REQUEST_REGISTER_USER_FAILURE,
        payload: error,
        error: true
    };
}


export function registerUser(registrationKey, name, email, password, heardFrom, terms_id, auto_start_tool_id) {
    return dispatch => {
        dispatch(requestRegisterUser());
        return registerUserAPI(registrationKey, name, email, password, heardFrom, terms_id, auto_start_tool_id)
        .then((json) => {
            Cookies.set('userData', json._id + ':' + json.token);
            return Promise.all([
                dispatch(Actions.getTools(json.token))
            ])
            .then(() => {
                window.dataLayer.push({
                    'event': 'registration',
                    'userId': json._id,
                    'groupCode': CUAC_SETTINGS.GROUP.code
                });
                return dispatch(requestRegisterUserSuccess(json));
            });
        })
        .catch((error) => {
            return dispatch(requestRegisterUserFailure(error));
        });
    };
}

export function registerAnonymousUser(registrationKey, auto_start_tool_id) {
    return dispatch => {
        dispatch(requestRegisterUser());
        return registerUserAPI(registrationKey, 'Anonymous User', null, null, null, null, auto_start_tool_id)
        .then((json) => {
            Cookies.set('userData', json._id + ':' + json.token);
            return Promise.all([
                dispatch(Actions.getTools(json.token))
            ])
            .then(() => {
                window.dataLayer.push({
                    'event': 'registration',
                    'userId': json._id,
                    'groupCode': CUAC_SETTINGS.GROUP.code
                });
                return dispatch(requestRegisterUserSuccess(json));
            });
        })
        .catch((error) => {
            return dispatch(requestRegisterUserFailure(error));
        });
    };
}
    
