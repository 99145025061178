/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import { NOTIFY } from 'actions/types';

export default function notify(data) {
    return {
        type: NOTIFY,
        payload: data
    };
}