'use strict';

import Cookies from 'cookies-js';
import { getSelf } from 'api/users';
import Actions from 'actions';
const dataLayer = [];
import {
    REQUEST_LOG_IN_USER,
    REQUEST_LOG_IN_USER_SUCCESS,
    REQUEST_LOG_IN_USER_FAILURE
} from 'actions/types';
import { getSelfById, getTwoFactorToken, getSelf2FA } from '../api/users';

function requestLogInUser() {
    return {
        type: REQUEST_LOG_IN_USER
    };
}

function requestLogInUserSuccess(json) {
    return {
        type: REQUEST_LOG_IN_USER_SUCCESS,
        payload: {
            user: json
        }
    };
}

function requestLogInUserFailure(error) {
    return {
        type: REQUEST_LOG_IN_USER_FAILURE,
        payload: error,
        error: true
    };
}


export default function twoFactorLogIn(id, token) {
    return dispatch => {
        dispatch(requestLogInUser());
        return getSelf2FA(id, token)
            .then((json) => {
                Cookies.set('userData', json._id + ':' + json.token);
                return Promise.all([
                    dispatch(Actions.getTrackerEntries(json.token, json._id)),
                    dispatch(Actions.getTrackerEntryStats(json.token, json._id)),
                    dispatch(Actions.getResponses(json.token, json._id)),
                    dispatch(Actions.getSubscriptions(json.token)),
                    dispatch(Actions.getTools(json.token)),
                    dispatch(Actions.getAllReminders(json._id, json.token))
                ])
                .then(() => {
                    window.dataLayer.push({
                        'event': 'login',
                        'userId': json._id,
                        'groupCode': CUAC_SETTINGS.GROUP.code
                    });
                    return dispatch(requestLogInUserSuccess(json));
                });
            })
            .catch((error) => {
                return dispatch(requestLogInUserFailure(error));
            });
    };
}