'use strict';

import React from 'react';
import { getMessage } from 'utils/tools';
import BSButton from 'components/blocks/BSButton.component.web';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import Cookies from 'cookies-js';
import { validator } from 'utils/tools';
import { browserHistory } from 'react-router';
import { Form } from 'react-bootstrap';
import { LIGHT_GRAY } from '../../stylesheets/colors';
import CheckboxReduxForm from '../ux/CheckboxReduxForm.component.web';

const Input = ({ label, placeholder, input, type, ...props}) => {
    const invalid = () => {
        const { error, dirty } = props.meta || {};
        return error && error.length && dirty;
    }
  
    const error = () => {
        const { error, dirty } = props.meta || {};
        if (!error || !error.length || !dirty) return null;
        return (
            <Form.Control.Feedback type="invalid">{ error }</Form.Control.Feedback>
        )
    }

    return (
        <Form.Group>
            <Form.Label>{ label }</Form.Label>
            <Form.Control
                type={type || 'text'}
                placeholder={placeholder}
                value={input?.value}
                onChange={input?.onChange}
                isInvalid={invalid()}
                { ...props }
                ref={input => {
                    if (props.setRef) props.setRef(input)
                }}
            />
            {
                error()
            }
        </Form.Group>
    )
}

const Login = React.createClass({

    propTypes: {
        fields: React.PropTypes.object.isRequired,
        message: React.PropTypes.string.isRequired,
        handleSubmit: React.PropTypes.func.isRequired,
        onClearLogOutMessage: React.PropTypes.func.isRequired
    },

    renderMessage: function() {
        if (!this.props.message || !this.props.message.length) return null;
        if ([
          'Your credentials are not valid.',
          'Too many failed login attempts. Please wait 15 minutes.',
          'There is a high number of failed logins on the site. For your protection, we are blocking all login attempts until this ceases.'
        ].includes(this.props.message)) return (
          <div className="alert alert-danger fade show">
              { this.props.message }
          </div>
        );
        if (this.props.message === 'LOGGED_OUT') return (
          <div className="alert alert-info fade show">
              You have been logged out
          </div>
        )
        return null;
    },

    componentDidUpdate() {
        const { remember_me, email } = this.props;
        if (remember_me && email && email.length) Cookies.set('rememberMe', email);
        else if (!remember_me) Cookies.expire('rememberMe');
    },

    forgotPassword(event) {
        event.preventDefault();
        const { email } = this.props,
            route = `/forgot-password${email && email.length ? `?email=${encodeURIComponent(email)}` : ''}`;
        browserHistory.push(route);
    },

    componentDidMount() {
        const rememberedEmail = Cookies.get('rememberMe');
        if (rememberedEmail && rememberedEmail.length) {
            this.props.change('email', rememberedEmail);
            this.props.change('remember_me', true);
            this.pwInput?.focus();
        }
    },

    isValid() {
        const { email, password } = this.props,
          fieldsPresent = [email, password].map(x => !!x && x.length).every(Boolean);
        return fieldsPresent && !Object.values(this.props.errors || {}).length;
    },

    onSubmit(e) {
        e.preventDefault();
        this.props.handleSubmit();
    },

    render() {
        const { handleSubmit } = this.props;
        const msg = this.renderMessage();

        return (
          <div className="container">
              <div className="row">
                  <div className="col-12 col-lg-6 offset-lg-3">
                      <div className="card my-4" style={{ border: 'none' }}>
                          <div className="card-body">
                              <h3 className="card-title">
                                  <FormattedMessage id="login_title" defaultMessage="Log In" />
                              </h3>
                              {
                                  msg && (
                                    <div className="my-2">{ msg }</div>
                                  )
                              }
                              <Form onSubmit={this.onSubmit}>
                                  <Field
                                    component={Input}
                                    name="email"
                                    id="email"
                                    label={getMessage('EmailInput', 'Email')}
                                    autoComplete="email"
                                    autoFocus={true}
                                    size="lg"
                                    style={{ border: 'none', backgroundColor: LIGHT_GRAY }}
                                  />
                                  <div className="my-2">
                                      <Field
                                        component={Input}
                                        name="password"
                                        id="password"
                                        type="password"
                                        label={getMessage('PasswordInput', 'Password')}
                                        autoComplete="email"
                                        size="lg"
                                        style={{ border: 'none', backgroundColor: LIGHT_GRAY }}
                                        setRef={input => {
                                            this.pwInput = input;
                                        }}
                                      />
                                  </div>
                                  <div className="my-2">
                                      <Field
                                        name="remember_me"
                                        component={CheckboxReduxForm}
                                        label={
                                            <FormattedMessage id="remember_me_label" defaultMessage="Remember me" />
                                        }
                                      />
                                  </div>
                                  <BSButton type="submit" disabled={!this.isValid()} loading={this.props.isFetching} variant="green" onClick={handleSubmit}>
                                      <div className="pe-1">
                                          <FormattedMessage
                                            id="login_btn_label"
                                            defaultMessage="Login"
                                          />
                                      </div>
                                      <MaterialCommunityIcons name="login-variant" size={20} color="white" />
                                  </BSButton>
                                  <div className="mt-3">
                                      <a href="#" onClick={this.forgotPassword} className="link-primary">Forgot Password?</a>
                                  </div>
                              </Form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        )
    }
});

const formFields = ['email', 'password', 'remember_me'];
let form = reduxForm({
    form: 'login',
    fields: formFields,
    validate: validator ({
        'email': { required: 'Required', email: 'Please enter a valid email' },
        'password': { required: 'Required', minLength: { error_message: 'Must be at least 6 characters', minimum: 6} }
    })

})(Login);
    
const selector = formValueSelector('login') // <-- same as form name
form = connect(state => {
    return formFields.reduce((dict, fieldName) => Object.assign({ [fieldName]: selector(state, fieldName) }, dict), {
        errors: state?.form?.login?.syncErrors
    });
})(form);

export default injectIntl(form);
