'use strict';

import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import BSButton from '../blocks/BSButton.component.web';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { BLUE, ORANGE } from '../../stylesheets/colors';

export const UnsubscribeFromEmails = React.createClass({

    propTypes: {
        auth: React.PropTypes.object.isRequired,
        style: React.PropTypes.object
    },

    render() {
        
        const { auth, onTurnOffMarketingEmails} = this.props;

        return (
                <div className="flex-grow-1 d-flex align-items-center justify-content-between">
                    <strong>
                        <FormattedMessage
                          id="account_overview_unsubscribe_from_emails_label"
                          description="Account Overview -- unsubscribe from emails"
                          defaultMessage="Marketing Emails"
                        />
                    </strong>
                    <div className="d-flex align-items-center">
                      <p className="mb-0 me-2">{ _.get(auth, 'user.email_opt_out') ? 'Off' : 'On' }</p>
                      <BSButton
                        variant="flat-blue"
                        size="sm"
                        onClick={onTurnOffMarketingEmails}
                        tooltip={_.get(auth, 'user.email_opt_out') ? 'Opt-in' : 'Opt-out'}
                      >
                        <MaterialCommunityIcons name={_.get(auth, 'user.email_opt_out') ? 'email-off' : 'email'} size={24} color={BLUE} />
                      </BSButton>
                    </div>
                </div>
        );
    }
});
