'use strict';

import _ from 'lodash';

import { createSelector } from 'reselect';

import { toolSelector } from 'selectors/tool';

const responsesSelector = state => state.responses;
export const responsesByToolSelector = createSelector(
    responsesSelector,
    toolSelector,
    (responses, tool) => {
        return {
            ...tool,
            responses: _.pick(responses, (response) => {
                return _.get(response, 'tool_id') === tool.tool._id;
            })
        };
    }
);
