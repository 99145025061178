/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonSpinner from 'components/ux/button-spinner'
import Actions from 'actions';


export const DrinkCalculatorButton = React.createClass({

    onClick: function(event) {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'drinkCalculator',
            modalContent: true
        }));
    },

    render() {
        const { responses, text, fields } = this.props;
        return (
            <ButtonSpinner noColor={true} flat={false} onClick={this.onClick} spinner={false}>
                    <FormattedMessage id="drink_calculator_button" defaultMessage="Open Drink Calculator" />
            </ButtonSpinner>
        );
    }
});