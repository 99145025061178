import React from 'react';
import Actions from 'actions';
import { connect } from "react-redux";
import ToolTOC from "../ToolTOC/ToolTOC.container";
import { FormattedMessage } from "react-intl";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {Spinner} from 'react-bootstrap';

type Props = {
    tool: any;
    activeResponse: any;
    children: React.ReactNode;
    toolTOCOpen: boolean;
    setToolTOCOpen: (open: boolean) => void;
    loading?: boolean;
}

const ToolLayout = (props: Props) => {
    return (
        <div className="d-flex align-items-stretch position-relative">
            {
                props.loading && (
                    <div
                        className="position-fixed w-100 h-100 top-0 right-0 left-0 bottom-0 d-flex align-items-center justify-content-center"
                        style={{ zIndex: 9999, backgroundColor: 'rgba(0,0,0,0.2)' }}
                    >
                        <Spinner variant="info" animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                )
            }
            {
                props.tool?._id !== CUAC_SETTINGS?.GROUP?.screener_tool_id && (
                    <ToolTOC
                        tool={props.tool}
                        response={props.activeResponse}
                        open={props.toolTOCOpen}
                        setOpen={props.setToolTOCOpen}
                    />
                )
            }
            <div id="content" className="flex-grow-1 mw-100">
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2">
                        <nav id="mobile-toggle" className={`bg-white mt-4 p-2`} style={{ maxWidth: 300, borderRadius: '0 8px 8px 0' }}>
                            <a style={{ color: '#0E323E', opacity: 0.5 }} className="btn d-flex align-items-center" role="button" onClick={() => props.setToolTOCOpen(true)}>
                                <p className="mb-0 me-3">
                                    <FormattedMessage { ...props.tool.title} />
                                </p>
                                <MaterialCommunityIcons name="chevron-double-right" size={28} color="#0E323E" style={{ opacity: 0.5 }} />
                            </a>
                        </nav>
                        <div className="card mt-4" style={{ backgroundColor: 'transparent', border: 'none'}}>
                            <div className="card-body">
                                { props.children }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="tool-toc-overlay" className={`${props.toolTOCOpen ? 'active' : ''} no-print`} onClick={() => props.setToolTOCOpen(false)} />
        </div>
    )
}

export default connect(state => ({
    toolTOCOpen: state.app?.toolTOCOpen,
}), {
    setToolTOCOpen: Actions.setToolTOCOpen,
})(ToolLayout);
