/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import _ from 'lodash';
import React from 'react';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import { TrackerTypes } from 'api/tracker-entries';
import TrackerEntryTable from '../blocks/TrackerEntryTable.component.web';
import BSButton from '../blocks/BSButton.component.web';
import {  BLUE } from '../../stylesheets/colors';
import { FontAwesome } from '@expo/vector-icons';
import {LandscapeOrientation} from "../blocks/Landscape";

const COLUMNS = [
    { label: 'Title', key: 'title' },
    { label: 'Last Updated', key: 'dt_u' },
    { label: 'What changes are you going to make?', key: 'changes_to_make', collapse: true },
    { label: 'What are your most important reasons for changing?', key: 'reasons_to_change', collapse: true },
    { label: 'How do you plan to achieve your goals. What are the specific steps involved? When, where, and how will you take them?', key: 'steps_to_changing', collapse: true },
    { label: 'How can others help? How can you get them to help you?', key: 'how_others_can_help', collapse: true },
    { label: 'What could go wrong or undermine your plans? How can you stick with your plan despite these setbacks or problems?', key: 'possible_setbacks', collapse: true },
    { label: 'What good things will happen as a result of changing?', key: 'good_things_from_changing', collapse: true },
];

export const PlanList = React.createClass({

    propTypes: {
        tag: React.PropTypes.string,
        trackerEntries: React.PropTypes.object.isRequired
    },

    getInitialState() {
        return {
            openEntries: []
        };
    },

    toggleEntry(index){
        let openEntries = this.state.openEntries;
        if (openEntries.indexOf(index) == -1){
            openEntries.push(index);
        } else {
            openEntries = _.without(openEntries, index);
        }

        this.setState({
            openEntries: openEntries
        });
    },

    dateUpdated(x) {
        const date = new Date(x.dt_u);
        return (
          <div className="d-flex">
              <div className="me-1">
                  <FormattedDate value={date} />
              </div>
              <FormattedTime value={date} />
          </div>
        )
    },

    render() {
        const { tag, trackerEntries } = this.props;
        const fullOrder = _.get(trackerEntries, TrackerTypes.PLAN + 'Order', []);
        const fullOrderEntries = _.map(fullOrder, (trackerEntryId) => {
            return _.get(trackerEntries, trackerEntryId, {});
        });
        const filteredEntries = typeof tag !== 'undefined'
          ?
          _.filter(fullOrderEntries, (trackerEntry) => {
              return _.get(trackerEntry, 'tag') === tag;
          })
          : fullOrderEntries;
        const orderEntries = filteredEntries.slice(0, 20);

        return (
          <div className="card" style={{ border: 'none' }}>
              <LandscapeOrientation />
              <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                      <h3 className="card-title">
                          <FormattedMessage id="plan_list_title" defaultMessage="Your Recent Change Plans" />
                      </h3>
                      <BSButton tooltip="Print" variant="link-green" onClick={() => print()}>
                          <FontAwesome name="print" color={BLUE} size={24} />
                      </BSButton>
                  </div>
                  <TrackerEntryTable
                      columns={COLUMNS}
                      rows={orderEntries.map(
                          x => ({ ...x, ...x.data, dt_u: this.dateUpdated(x) })
                      )}
                  />
              </div>
          </div>
        )
    }
});
