/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require, self, goToUrl */
"use strict";

// ========= LIBRARY COMPONENTS =========
import React from 'react';
import { findDOMNode } from 'react-dom';
import classnames from 'classnames';
import { FontAwesome } from '@expo/vector-icons';
import { BLACK, LIGHT_BLUE } from '../../stylesheets/colors';

var InfoBug = React.createClass({
    displayName: 'InfoBug',

    propTypes: {
        className: React.PropTypes.string,
        popClass: React.PropTypes.string,
        display: React.PropTypes.bool,
        style: React.PropTypes.object,
        size: React.PropTypes.number,
        icon: React.PropTypes.string,
    },

    getInitialState: function() {
        return { showPopOver: false, blockStyle: {} };
    },

    getDefaultProps: function() {},

    componentDidMount: function() {},

    UNSAFE_componentWillReceiveProps: function(nextProps) {},

    // ======== LOCAL METHODS ==============

    adjustScreenPosition: function(el){
        const { extended } = this.props;
        const { blockStyle } = this.state;

        function translate(offset){
            const delivery = extended ? _.clone(blockStyle) : {};

            delivery["WebkitTransform"] = "translate(" + offset + "px, 0)";
            delivery["MozTransform"] = "translate(" + offset + "px, 0)";
            delivery["msTransform"] = "translate(" + offset + "px, 0)";
            delivery["OTransform"] = "translate(" + offset + "px, 0)";
            delivery["transform"] = "translate(" + offset + "px, 0)";

            return delivery;
        }

        if (el && this.state.showPopOver){
            let adjust = false,
                rect = el.getBoundingClientRect(),
                newStyle = {};

            if (rect.left < 20 ) {  // too close to left edge
                adjust = true;
                newStyle = translate(20 - rect.left);
            } else if ((rect.right + 20) > window.innerWidth) { // too close to right edge
                adjust = true;
                newStyle = translate( -1 * (Math.floor(rect.right) + 20 - window.innerWidth) );
            }

            if (adjust){
                this.setState({blockStyle: newStyle});
            }
        }
    },

    onClickToggle: function (event) {
        const { extended } = this.props;

        event.preventDefault();
        event.stopPropagation();
        this.setState(
            {
                showPopOver: !this.state.showPopOver,
                blockStyle: (extended && !this.state.showPopOver)
                    ? { width: (window.innerWidth * 0.9) + 'px', maxWidth: '600px' }
                    : {}
            },
            function(){
                this.adjustScreenPosition(findDOMNode(this.refs.popoverBlock));
            }
        );
    },

    onKeyDownToggle: function(event) {
      if(event.which == 32) {
        this.onClickToggle(event);
      }
    },

    // ======== RENDER ==============

    render: function() {
        let { className, popClass, style, extended } = this.props;

        let outer = classnames('popOver-parent', { 'active': this.state.showPopOver }, className);
        let inner = classnames('popOver-block', { 'extended': extended }, popClass);

                return (
            <div className={outer} style={style}>
                <span className="popOver-trigger" onClick={this.onClickToggle} role="button" tabIndex="0" onKeyDown={this.onKeyDownToggle} aria-label="Toggle Help Bubble">
                    <FontAwesome name={this.props.icon || 'question-circle-o'} size={this.props.size || 20} color={LIGHT_BLUE} />
                </span>
                <div className={inner} ref="popoverBlock" style={this.state.blockStyle}>
                    <div className="close" onClick={this.onClickToggle}  role="button" tabIndex="0" onKeyDown={this.onKeyDownToggle} aria-label="Close Help Bubble">
                        <FontAwesome name="times" size={18} color={BLACK} />
                    </div>
                    {this.props.children}
                </div>
            </div>
        );
            }
});

export default InfoBug;
export { InfoBug };
