/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require */
"use strict";

// ========= LIBRARY COMPONENTS =========
import React  from 'react';

const BrandLogo = React.createClass({
    render: function(){
    	if(CUAC_SETTINGS.GROUP.header_image) {
    		return (
	            <div className="brand custom-brand">
	                <img src={CUAC_SETTINGS.S3_ROOT + CUAC_SETTINGS.GROUP.header_image} alt="CheckUp and Choices logo." height="56" />
	            </div>
	        );
    	} else {
    		return (
	            <div className="brand">
	                <img src={CUAC_SETTINGS.S3_ROOT + "/app/img/brand/logo-color.png"} alt="CheckUp and Choices logo." height="56" />{this.props.children}
	            </div>
	        );
    	}
    }
});

export default BrandLogo;
