/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import {initialize} from 'redux-form';
import { browserHistory } from 'react-router';
import Actions from 'actions';
import UserProfile from 'components/forms/user-profile';
import { Container } from 'react-bootstrap';
import BrandedString from "../components/blocks/branded-string";

const MedicalHistoryPage = React.createClass({
    propTypes: {
        auth: React.PropTypes.object.isRequired,
        display: React.PropTypes.object,
        dispatch: React.PropTypes.func.isRequired,
        messages: React.PropTypes.object
    },

    getInitialState(){
        return { changePasswordShown: false, profile: true };
    },

    componentDidMount() {
        this.updateProfileForm();
    },

    UNSAFE_componentWillReceiveProps(newProps){
        this.updateProfileForm(newProps);
    },


    // =========== LOCAL METHODS ===================
    checkNewUser(isNewUser) {
        this.setState({isNewUser});
    },

    updateProfileForm(props){
        props = props || this.props;
        const user = props.auth.user;
        this.props.dispatch(initialize('userProfile', {
            name: user.name,
            email: user.email,
            dt_dob: user.dt_dob,
            weight: user.weight,
            weight_unit : user.weight_unit,
            gender: user.gender,
            phone_number: user.phone_number || '',
            user_timezone: user.user_timezone || CUAC_SETTINGS.GROUP.default_timezone || 'US/Eastern',
            medical_history: user.medical_history
        }));
    },

    async nextRoute(result) {
        // whether or not this user just registered
        const isNewUser = [true, 'true'].includes(this.props.location.query?.new),
            { token, _id, auto_start_tool_id, tool_ids } = this.props.auth?.user ?? {},
            autoStartTool = CUAC_SETTINGS?.GROUP?.auto_start_tool_ids || auto_start_tool_id;
        let nextRoute = isNewUser ? '/' : '/account';

        // if new and should auto-start tool
        if (isNewUser && autoStartTool) {
            const activeResponseId = result.payload?.user?.active_response_id;
            // find the url for the active response's tool
            if (activeResponseId) {
                const responses = await this.props.dispatch(Actions.getResponses(token, _id)),
                    targetResponse = (responses.payload?.responses ?? []).find(({ _id }) => _id === activeResponseId),
                    targetTool = targetResponse ? this.props.tools[targetResponse.tool_id] : null,
                    { slug, auto_start_url } = targetTool || {};
                if (auto_start_url) nextRoute = `/${auto_start_url}`;
                else if (slug) nextRoute = `/${slug}`;
            }
            // Super gross. For Rails app session brokering only. Init a response for the user and direct them there.
            else if (CUAC_SETTINGS?.GROUP?.session_brokering && Array.isArray(tool_ids) && tool_ids.length) {
                const [ toolId ] = tool_ids,
                    { [toolId]: tool } = this.props.tools || {};
                if (tool) {
                    try {
                        const toolRes = await this.props.dispatch(Actions.addResponse(token, _id, tool));
                        if (toolRes?.type === 'REQUEST_ADD_RESPONSE_SUCCESS') {
                            const { slug, auto_start_url } = tool;
                            if (auto_start_url) nextRoute = `/${auto_start_url}`;
                            else if (slug) nextRoute = `/${slug}`;
                        }
                    }
                    catch (e) {}
                }
            }
        }
        this.props.dispatch(Actions.updateDisplay('appSpinner', 'STOP'));
        browserHistory.push(nextRoute);
    },

    onUserProfileUpdate(data) {
        const { auth: { user } } = this.props;
        const token = _.get(user, 'token');
        const userId = _.get(user, '_id');
        this.props.dispatch(Actions.updateDisplay('appSpinner', 'PLEASE_WAIT'));
        this.props.dispatch(Actions.updateUser(token, userId, data)).then(this.nextRoute);
    },

    onPasswordChange(data){
        const token = _.get(this.props, 'auth.user.token');
        const userId = _.get(this.props, 'auth.user._id');
        const email =  _.get(this.props, 'auth.user.email');
        if (data.password && data.newPassword && data.newPassword.length > 5) {
            this.onToggleChangePassword(false);
            this.props.dispatch(Actions.changePassword(token, userId, data.password, data.newPassword, email));
        } else {
            if (!data.newPassword) {
                this.props.dispatch(Actions.notify({
                    title: "Change Password",
                    message: "To change your password, please provide a new password.",
                    level: 'warning',
                    autoDismiss: 3
                }));
            } else if (data.newPassword.length < 6){
                this.props.dispatch(Actions.notify({
                    title: "Change Password",
                    message: "Your new password must be at least 6 characters.",
                    level: 'warning',
                    autoDismiss: 3
                }));
            } else if (!data.password) {
                this.props.dispatch(Actions.notify({
                    title: "Change Password",
                    message: "You must provide your old password for verification",
                    level: 'warning',
                    autoDismiss: 3
                }));
            }
        }
    },

    onToggleChangePassword(onOff, x, y){
        if (typeof onOff !== 'boolean') {
            onOff = !_.get(this.props, 'display.changePassword.show');
        }
        const data = onOff ? { show: onOff, clickX: x, clickY: y } : {};
        this.props.dispatch(Actions.updateDisplay('changePassword', data));
    },


    // =========== RENDER ==========================
    render() {
        const isFetching = _.get(this, 'props.auth.isFetching'),
            message = _.get(this, 'props.messages'),
            medicalHistory = _.get(this, 'props.auth.user.medical_history'),
            showCP = _.get(this.props, 'display.changePassword.show'),
            clickX = _.get(this.props, 'display.changePassword.clickX'),
            clickY = _.get(this.props, 'display.changePassword.clickY');

        let user = this.props.auth.user;
        if(_.get(user, 'weight_unit') === 'kilograms' && !_.get(user, 'converted')) {
            user.weight = Math.round((user.weight)/2.20462);
            user.converted = true;
        }

        const defaultString = 'We ask for your medical history so that we can provide you feedback about how your\ ' +
            'current or prior substance use could affect your medical conditions. Please check all that apply.'

        return (<Container>
            <div class="wrapper-sub-header">
            <div class="container bg-medical-history">
                <div class="row py-6">
                    <div class="col-md-6">
                        <h1>Medical History</h1>
                        <BrandedString string_type='medical_history_info' default_string={defaultString}/>
                    </div>
                </div>
            </div>
            </div>
            <div className="after-sub-header">
            <UserProfile
                onSubmit={this.onUserProfileUpdate}
                hidePassword={true}
                isFetching={isFetching}
                medicalHistory={medicalHistory}
                user={user}
                checkNewUser={this.checkNewUser}
                message={message}
                kilogramWeight={_.has(CUAC_SETTINGS, 'GROUP.weight_in_kg') ? _.get(CUAC_SETTINGS, 'GROUP.weight_in_kg') : false}
                showMedicalHistory={true}
            />
            </div>
        </Container>);
    }

});

export default connect(
    state => ({
        auth: state.auth,
        display: state.display,
        messages: state.messages,
        tools: state.tools
    })
)(MedicalHistoryPage);
