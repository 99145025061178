/* globals CUAC_SETTINGS */
import React, { useEffect, useState } from 'react';
import Registration from './Registration.component.web';
import { formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { validator } from 'utils/tools';
import Actions from 'actions';
import { browserHistory, withRouter } from 'react-router';
import _ from "lodash";
import { useStripe } from '@stripe/react-stripe-js';
import SSO from 'components/forms/sso';
import { param } from 'lib/query-params'
import Loading from "../blocks/Loading.component.web";

function postRegistrationRedirect() {
  const redirect = param('redirect')
  if(redirect) {
    return decodeURIComponent(redirect);
  }
  return _.get(CUAC_SETTINGS || {}, 'GROUP.post_registration_redirect') || '/';
}

function RegistrationContainer({ auth, dispatch, plans, location, ...props }) {
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auth.selectedPlan && !!Object.keys(auth.selectedPlan).length) return;
    // if plans haven't been fetched, or there's no plan query param, bail
    if (!plans.plans || !plans.plans.length || !param('plan')) return;
    // find the plan that matches the query param
    const plan = (plans.plans || []).find(({ _id }) => _id === param('plan'));
    if (plan) dispatch(Actions.selectPlan(plan))
  }, [ plans ]);

  useEffect(() => {
    (async () => {
      // if plans haven't been fetched, there is a plan query param, and there is an authed user
      // fetch plans
      if ((!plans.plans || !plans.plans.length) && param('plan') && !!auth.user?.token) {
        setLoading(true);
        await dispatch(Actions.getPlans(auth.user?.token, _.get(CUAC_SETTINGS, 'GROUP._id')));
        setLoading(false);
      }
      updatePlan();
      if (!validPlan()) onChangePlan();
    })()
  }, []);

  useEffect(() => {
    updatePlan();
  }, [ location ]);

  const onChangePlan = () => {
    dispatch(Actions.clearSelectedPlan());
    browserHistory.push('/screener/plan');
  }

  const updatePlan = () => {
    // if we have url parameters of plan, check if it matches an existing plan code
    if (location.search.indexOf('?plan=') > -1) {
      if (!(plans?.plans ?? []).length) return;
      const planCode = location.search.split('&')[0].replace('?plan=', '');
      _.forEach(plans?.plans, plan => {
        if (plan.stripe_key === planCode) dispatch(Actions.selectPlan(plan));
      });
    }
  }

  const tokenizeCard = async (cardElement) => {
    const { error, token } = await stripe.createToken(cardElement);
    if (error) {
      dispatch(Actions.notify({
        title: "Invalid card",
        message: error.message,
        level: 'error',
        autoDismiss: 4
      }));
      return;
    }
    return token?.id;
  }

  /**
   * Attach the card to the user
   *
   * @param stripeTokenID: stripe card token ID
   * @param userToken: user auth token
   */
  const attachSubscription = async (stripeTokenID, userToken) => {
    const userId = _.get(auth, 'user._id');
    const selectedPlan = _.get(auth, 'selectedPlan');
    dispatch(Actions.notify({
      title: 'Initiating payment',
      message: 'Please do not leave this page or click on any links.',
      level: 'success',
      autoDismiss: 4
    }));
    try {
      await dispatch(Actions.addSubscription(userToken, userId, selectedPlan, stripeTokenID));
      browserHistory.push(postRegistrationRedirect());
    }
    catch (e) {
      setLoading(false);
    }
  }

  const heardFrom = () => {
    let heardFromReason = props.heardFrom;
    if (props.heardFrom === "Other") heardFromReason = heardFromReason + ' - ' + props.heardFrom_other;
    return heardFromReason;
  }

  const autoStartTool = () => {
    let autoStartToolID = '0';
    if (location?.query?.auto_start_tool_id) autoStartToolID = location.query.auto_start_tool_id;
    return autoStartToolID;
  }

  const validPlan = () => {
    const queryParamPlan = param('plan');
    // if there's a valid plan query param, return true
    if (queryParamPlan && queryParamPlan.length) return true;
    if (_.isEmpty(auth.selectedPlan) && !CUAC_SETTINGS.GROUP.default_program_id) {
      dispatch(Actions.notify({
        title: "No plan selected",
        message: "Please select a plan",
        level: 'error',
        autoDismiss: 4
      }));
      return false;
    }
    return true;
  }

  const termsAccepted = () => {
    if (CUAC_SETTINGS?.GROUP.require_terms && !props.accept_terms) {
      dispatch(Actions.notify({
        title: "Error",
        message: "You need to accept the Terms of Service before we can complete your registration.",
        level: 'error',
        autoDismiss: 4
      }));
      return false;
    }
    return true;
  }

  const onSubmit = async (cardElement) => {
    setLoading(true);
    // ensure a plan is selected if appropriate
    if (!validPlan()) {
        setLoading(false);
        return false;
    }
    const termsID = CUAC_SETTINGS?.GROUP.require_terms ? CUAC_SETTINGS?.GROUP?.terms_id : undefined;
    // ensure TOS have been accepted if appropriate
    if (!termsAccepted()) {
        setLoading(false);
        return false;
    }
    // tokenize the user's card
    let stripeTokenID = null;

    // if no card element is passed, then the user didn't have a chance to provide a card number
    if (cardElement && !CUAC_SETTINGS?.GROUP?.default_program_id) {
      stripeTokenID = await tokenizeCard(cardElement);
      if (!stripeTokenID) {
        setLoading(false);
        return false;
      }
    }
    const regKey = auth.user?.registration_key;
    try {
      if (!regKey) await dispatch(Actions.addUnregisteredUser())
      const res = await dispatch(Actions.registerUser(regKey, props.name, props.email?.trim(), props.password, heardFrom(), termsID, autoStartTool())),
        { error, payload } = res,
        { token: userToken } = payload?.user ?? {};
      if (error) {
        dispatch(Actions.notify({
          title: "Error",
          message: payload || 'An error occurred!',
          level: 'error',
          autoDismiss: 4
        }));
        setLoading(false);
        return false;
      }
      if (stripeTokenID) {
        await attachSubscription(stripeTokenID, userToken);
      } else if(auth?.selectedPlan?.stripe_data?.amount === 0) {
        await attachSubscription(auth.selectedPlan.id, userToken); // why auth.selectedPlan.id instead of a token ID? because it doesn't resemble a stripe token ID. No collisions. :crossed_fingers: 
      } else {
        browserHistory.push(postRegistrationRedirect());
      }
    }
    catch (e) {
      console.log('reg error', e);
      dispatch(Actions.notify({
        title: 'Error',
        message: 'An error occurred!',
        level: 'error',
        autoDismiss: 4
      }));
      setLoading(false);
    }
  }

  const registerAnonymous = async () => {
    setLoading(true);
    const regKey = auth.user?.registration_key;
    if (!regKey) {
      console.error('Missing registration key');
      setLoading(false);
      return;
    }
    try {
      const res = await dispatch(Actions.registerAnonymousUser(regKey, autoStartTool()));
      const { error } = res || {};
      setLoading(false);
      if (error) {
        dispatch(Actions.notify({
          title: res.payload?.message ?? 'An error occurred!',
          message: "Please try again",
          level: 'error',
          autoDismiss: 4
        }));
        return;
      }
      browserHistory.push(postRegistrationRedirect());
    }
    catch (e) {
      console.log('reg anon error', e);
      setLoading(false);
    }
  }

  const sso = () => {
    const regKey = auth?.user?.registration_key;
    let ssoLink = "https://apiv2.checkupandchoices.com/sso?sso2&groupCode=" + CUAC_SETTINGS.GROUP.code;
    if (regKey) ssoLink += "&registrationKey=" + regKey;
    return (
      <SSO
        ssoLink={ssoLink}
        viewTerms={CUAC_SETTINGS?.GROUP.require_terms}
        showWarning={() => {
          dispatch(Actions.notify({
            title: "Error",
            message: "You need to accept the Terms of Service before we can complete your registration.",
            level: 'error',
            autoDismiss: 4
          }));
          setLoading(false);
        }}
      />
    )
  }

  if (CUAC_SETTINGS?.GROUP.require_sso) return sso();

  return (
    <Registration
      auth={auth}
      onChangePlan={onChangePlan}
      onSubmit={onSubmit}
      dispatch={dispatch}
      loading={loading}
      registerAnonymous={registerAnonymous}
      hasValidPlan={validPlan()}
      { ...props }
    />
  )
}

const formFields = ['name', 'email', 'confirmPassword', 'password', 'accept_terms'];
let form = reduxForm({
  form: 'register',
  fields: formFields,
  validate: validator ({
    'name': { required: 'Required' },
    'email': { required: 'Required', email: 'Please enter a valid email' },
    'password': { required: 'Required', minLength: { error_message: 'Password must be at least 6 characters', minimum: 6 } },
    'confirmPassword': { required: 'Required', confirm: { error_message: 'Does not match', confirm_target: 'password'} }
  })

})(RegistrationContainer);
const selector = formValueSelector('register')
form = connect(state => ({
  ...formFields.reduce((dict, fieldName) => Object.assign({ [fieldName]: selector(state, fieldName) }, dict), {}),
  auth: state.auth,
  plans: state.plans,
  terms: state.terms,
  errors: state.form?.register?.syncErrors,
}))(form);
export default withRouter(form);
