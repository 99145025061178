'use strict';

import _ from 'lodash';

import { createSelector } from 'reselect';

import { toolSelector } from 'selectors/tool';

const moduleSlugSelector = (state, props) => props.params.moduleSlug;
const getModuleFromSlug = (slug, tool) => {
    for (let module of _.values(_.get(tool.metadata, 'modules', {}))) {
        if (module.slug === slug) {
            return module;
        }
    }
    return {};
};

export const moduleSelector = createSelector(
    moduleSlugSelector,
    toolSelector,
    (moduleSlug, tool) => {
        return {
            ...tool,
            module: getModuleFromSlug(moduleSlug, tool.tool)
        };
    }
);