'use strict';

import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import Actions from 'actions';
import Button from 'components/ux/mdl/button';
import MoodList from 'components/trackers/mood-list';
import { faPrint, faComment as faCommentingO, faBell as faBellO, faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

const MoodTrackerPage = React.createClass({

    propTypes: {
        auth: React.PropTypes.object.isRequired,
        dispatch: React.PropTypes.func.isRequired,
        trackerEntries: React.PropTypes.object.isRequired
    },

    calculateScore(values) {
        return Object.values(values).reduce((previousValue, currentValue, index, array) => {
            previousValue = previousValue ? previousValue : 0;
            currentValue = currentValue ? currentValue : 0;
            return parseInt(previousValue, 10) + parseInt(currentValue, 10);
        });
    },

    showRemindersModal() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'newReminder',
            edit_reminder: {},
            modalContent: true
        }));
    },

    showHelpFeedbackModal() {
        this.props.dispatch(Actions.showModal('appModal', {
            modalNotifications: 'helpFeedback',
            modalContent: true
        }));
    },

    render() {
        const { trackerEntries } = this.props;
        return (
            <div className="container mb-4">
                <div className="col-12 col-lg-8 offset-lg-2">
                    <MoodList
                      trackerEntries={trackerEntries}
                      showTrackerNav={true}
                      showFeedbackLink={true}
                    />
                </div>
            </div>
        );
    }
});

export default connect(
    state => ({
        auth: state.auth,
        trackerEntries: state.trackerEntries
    })
)(MoodTrackerPage);
