'use strict';

import _ from 'lodash';

import { createSelector } from 'reselect';

import { getAge } from 'utils/date';

const authSelector = state => state.auth;
const normsSelector = state => state.norms;

export const userNormsSelector = createSelector(
    authSelector,
    normsSelector,
    (auth, norms) => {
        const gender = _.get(auth, 'user.gender');
        const age = getAge(_.get(auth, 'user.dt_dob'));
        const drinksPerWeekAge = age < 18 ? 18 : (age > 65 ? 65 : age);
        return {
            norms: {
                drinc: _.get(norms, 'drinc.norms.' + gender),
                drinks_per_week: _.get(norms, 'drinks_per_week.norms.' + gender + '.' + drinksPerWeekAge),
                rapi: _.get(norms, 'rapi.norms.' + gender),
                sip: _.get(norms, 'sip.norms.' + gender),
                marijuana_usage_28_days: _.get(norms, 'marijuana_usage_28_days.norms.' + gender)
            }
        };
    }
);

