'use strict';

import {
    REQUEST_GET_TOOL,
    REQUEST_GET_TOOL_SUCCESS,
    REQUEST_GET_TOOL_FAILURE,
    REQUEST_GET_TOOLS,
    REQUEST_GET_TOOLS_SUCCESS,
    REQUEST_GET_TOOLS_FAILURE
} from 'actions/types';

export const extractFormulas = tool => {
    const ret = {};
    const { metadata } = tool || {},
        { pages, modules } = metadata || {};
    Object.values(pages || {}).forEach(x => {
        const { components } = x;
        (components || []).forEach(({ formulas }) => {
            if (Array.isArray(formulas)) formulas.forEach(x => ret[x.data_key] = x);
        })
    });
    Object.values(modules || {}).forEach(x => {
        Object.values(x.sections || {}).forEach(y => {
            Object.values(y.pages || {}).forEach(z => {
                const { components } = z;
                (components || []).forEach(({ formulas }) => {
                    if (Array.isArray(formulas)) formulas.forEach(x => ret[x.data_key] = x);
                })
            })
        })
    })
    return {
        ...tool,
        formulas: ret,
    };
}

export function reducer(state = {
    isFetching: false
}, action = { type: '' }) {
    switch (action.type) {
        case REQUEST_GET_TOOL:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_GET_TOOL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                [action.payload.tool._id]: extractFormulas(action.payload.tool)
            };
        case REQUEST_GET_TOOL_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        case REQUEST_GET_TOOLS:
            return {
                ...state,
                isFetching: true
            };
        case REQUEST_GET_TOOLS_SUCCESS:
            let tools = {};
            action.payload.tools.forEach((tool) => {
                tools[tool._id] = extractFormulas(tool);
            });
            return {
                ...state,
                isFetching: false,
                ...tools
            };
        case REQUEST_GET_TOOLS_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        default:
            return state;
    }
}
