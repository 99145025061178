import React, { useState } from 'react';
import { getMessage } from 'utils/tools';
import PlanSelect from '../blocks/PlanSelect.component.web';
import { CardElement, useElements } from '@stripe/react-stripe-js';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import GenericModalButton from 'components/elements/generic-modal-button';
import BSButton from '../blocks/BSButton.component.web';
import { Ionicons } from '@expo/vector-icons';
import _ from "lodash";
import {Form, Spinner} from 'react-bootstrap';
import BrandedImage from "../blocks/branded-image";
import BrandedString from "../blocks/branded-string";

const Input = ({ label, placeholder, input, type, ...props}) => {
  const invalid = () => {
    const { error, dirty } = props.meta || {};
    return error && error.length && dirty;
  }

  const error = () => {
    const { error, dirty } = props.meta || {};
    if (!error || !error.length || !dirty) return null;
    return (
      <Form.Control.Feedback type="invalid">{ error }</Form.Control.Feedback>
    )
  }

  return (
    <Form.Group>
      <Form.Label>{ label }</Form.Label>
      <Form.Control
        type={type || 'text'}
        placeholder={placeholder}
        value={input?.value}
        onChange={input?.onChange}
        isInvalid={invalid()}
        { ...props }
      />
      {
        error()
      }
    </Form.Group>
  )
}

export default function Registration({ auth, onChangePlan, onSubmit, registerAnonymous, hasValidPlan, ...props }) {
  const elements = useElements();
  const [validated, setValidated] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    const valid = isValid();
    setValidated(valid);
    if (!valid) return;
    onSubmit(elements.getElement(CardElement));
  };

  const isValid = () => {
    const { email, name, confirmPassword, password } = props,
      fieldsPresent = [email, name, confirmPassword, password].map(x => !!x && x.length).every(Boolean);
    return fieldsPresent && !Object.values(props.errors || {}).length;
  }

  const requiresTOS = () => CUAC_SETTINGS?.GROUP?.require_terms;

  const anonMsg = () => {
    if (!CUAC_SETTINGS?.GROUP?.allow_anonymous) return null;
    const defaultAnon = (
        <>
          <p>
            Please consider registering in order to take advantage of all aspects of this program.
            If you’d prefer not to provide a personal email at this time, we welcome you to complete the CheckUp anonymously.
            Just know that if you elect to continue anonymously, you won't be able to return your information later.
          </p>
          <p>
            Providing a personal email:
            <ul>
              <li>
                Allows you to re-access the site and use other programs it has to offer: the
                Moderate and Abstain programs for people who want more support to make changes.
              </li>
              <li>
                Allows you to track your changes over time, to hold yourself accountable and see improvements.
              </li>
            </ul>
            And remember, we never share your personal email or any identifying information with anyone.
          </p>
        </>
    );
    return (
      <div className="my-4">
        <BrandedString id='anonMessage' string_type='anon_registration_info' default_string={defaultAnon} />
        <div className="d-flex flex-column align-items-center">
          <BSButton variant="green" loading={props.loading} onClick={registerAnonymous}>
            <div className="pe-1">
              Continue Without Registering
            </div>
            <Ionicons name="arrow-forward" size={24} color="white" />
          </BSButton>
        </div>
      </div>
    );
  }

  return (
    <div className="container position-relative">
      {
        props.loading && (
              <div className="w-100 h-100 position-absolute d-flex justify-content-center align-items-center" style={{ zIndex: 999, backgroundColor: 'rgba(0,0,0,0.2)' }}>
                <Spinner variant="info" animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
          )
      }
      <div className="row">
        <div className="col-12 col-lg-8 offset-lg-2">
          <BrandedString id='registrationHeaderString' string_type='registration_header_string' default_string='Register' header={true} />
          <BrandedImage image_type='register_image' />
          {
            !CUAC_SETTINGS.GROUP.default_program_id && (
              <div className="d-flex flex-column my-4">
                <PlanSelect auth={auth} onChangePlan={onChangePlan} />
                {
                  !hasValidPlan && (
                    <small className="text-danger">
                      <FormattedMessage
                        id="registration_select_plan_warning"
                        defaultMessage="Please select a plan before registering"
                        description="Registration warning shown if user has not selected a plan"
                      />
                    </small>
                  )
                }
              </div>
            )
          }
          <BrandedString id='customInfo' string_type='registration_info' />
          {anonMsg()}
          <Form noValidate validated={validated} id="registerForm" name="registerForm">
            <div className="row">
              <div className="col-12 col-lg-6">
                <Field
                  component={Input}
                  name="name"
                  label={getMessage('NameInput', 'Your Name')}
                  autoComplete="name"
                  autoFocus={true}
                  size="lg"
                  style={{ border: '1px solid white' }}
                />
              </div>
              <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                <Field
                  component={Input}
                  name="email"
                  label={getMessage('EmailInput', 'Your Email')}
                  autoComplete="email"
                  size="lg"
                  style={{ border: '1px solid white' }}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-lg-6 password-field">
                <Field
                  component={Input}
                  name="password"
                  type="password"
                  label={getMessage('PasswordInput', 'Password')}
                  size="lg"
                  style={{ border: '1px solid white' }}
                />
              </div>
              <div className="col-12 col-lg-6 mt-3 mt-lg-0 password-field">
                <Field
                  component={Input}
                  name="confirmPassword"
                  type="password"
                  label={getMessage('ConfirmPasswordInput', 'Confirm Password')}
                  size="lg"
                  style={{ border: '1px solid white' }}
                />
              </div>
            </div>
            {
              CUAC_SETTINGS?.GROUP?.default_program_id || _.get(auth, 'selectedPlan.stripe_data.amount') === 0
                ? null
                : <div className="row mt-3">
                  <div className="col-12">
                    <Form.Group>
                      <Form.Label>Card Info</Form.Label>
                      <div className="form-control form-control-lg" style={{ border: '1px solid white' }}>
                        <CardElement
                          options={{
                            style: {
                              iconStyle: 'solid',
                              base: {
                                fontSize: '1.25rem',
                                paddingVertical: '.5rem'
                              }
                            }
                          }}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
            }

            <div className="d-flex flex-column align-items-center">
              {
                requiresTOS() && (
                  <div className="checkbox my-3 d-flex align-items-center">
                    <Field
                      component="input"
                      type="checkbox"
                      id="accept_terms"
                      name="accept_terms"
                      value="true"
                      className="box"
                    />
                    <label className="ms-2" htmlFor="accept_terms" id="accept_terms">
                      <FormattedMessage id="terms_of_service_0" defaultMessage="I accept the "/>
                      <GenericModalButton
                        link_text={{ id: "terms_of_service_1", defaultMessage: "Terms of Service" }}
                        modal_title={{ id: "terms_of_service_1", defaultMessage: "Terms of Service" }}
                        link_only={true}
                        modal_html={props.terms?.terms_text}
                        dispatch={props.dispatch}
                        closeButton={true}
                        scroll={true}
                      />
                    </label>
                  </div>
                )
              }
              <div className="my-3">
                <BSButton disabled={!isValid() || props.loading} variant="green" loading={props.loading} onClick={handleSubmit}>
                  <div className="pe-1">
                    Register
                  </div>
                  <Ionicons name="arrow-forward" size={24} color="white" />
                </BSButton>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
